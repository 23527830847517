import React, { useState } from 'react'
import { NavLink,useNavigate } from "react-router-dom";
import { AuthApis } from '../../../apis/authApis';

function OTPVerify() {
  const navigate = useNavigate();
  const [userData, setUserdata] = useState({
    'one': "",
    'two': "",
    'three': "",
    'four': "",
    'email':"",
    'password':"",
    'confirm_password':""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData()
    formData.append('token', userData?.one + userData?.two + userData?.three + userData?.four)
    formData.append('email', userData?.email)
    formData.append('password', userData?.password)
    formData.append('password_confirmation', userData?.confirm_password)

    AuthApis.resetPassword(formData).then(
      (response) => {
        if (response?.data) {
          if(response?.data?.success){
            navigate('/reset-success');
           }
        } else {
          // toast.warn('Invalid Login Credentials');
        }

        // toast.success(response?.data?.message);
      }
    ).catch(function (error) {
      // handle error
      console.log(error.response.data);
      // toast.error("Offfline");
    }).finally(() => {
      // toast.error("No Internet Connection");

    });
  }
  return (
    <>
      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB]  body-font font-poppins ">
        <div className="flex justify-center py-24    ">



          <div className="px-24  mt-30">
            <div className="flex justify-center">
              <img src="/images/afripro.svg" alt="sign-in" />
            </div>
            <h1 className="max-w-2xl mt-6 text-3xl text-[#000000] font-semibold leading-10 md:text-3xl xl:text-4xl text-center ">
              Password Reset
            </h1>
            <p className="text-xs mt-3 text-gray-500 text-center">
              We sent a code to your Email
            </p>
            <form onSubmit={handleSubmit}>
              <div id="otp" className="flex flex-row justify-center text-center px-2 mt-5 mb-5">
                <input className="m-2 border h-14 w-12 text-center form-control focus:border-[#48B774] focus:ring-[#48B774] font-semibold text-3xl" type="text" id="first" name="one" onChange={handleChange} maxLength={1} />
                <input className="m-2 border h-14 w-12 text-center form-control focus:border-[#48B774] focus:ring-[#48B774] font-semibold text-3xl" type="text" id="second" name="two" onChange={handleChange} maxLength={1} />
                <input className="m-2 border h-14 w-12 text-center form-control focus:border-[#48B774] focus:ring-[#48B774] font-semibold text-3xl" type="text" id="third" name="three" onChange={handleChange} maxLength={1} />
                <input className="m-2 border h-14 w-12 text-center form-control focus:border-[#48B774] focus:ring-[#48B774] font-semibold text-3xl" type="text" id="fourth" name="four" onChange={handleChange} maxLength={1} />
              </div>

              <div className="mb-5">
                  <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                    Email
                  </label>
                  <input
                    type="email"
                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3"
                    placeholder="*****"
                    name="email"
                    required
                    onChange={handleChange}
                  />
                </div>

              <div className="mb-5">
                  <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                    Password
                  </label>
                  <input
                    type="password"
                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3"
                    placeholder="*****"
                    name="password"
                    required
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-5">
                  <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3"
                    placeholder="*****"
                    name="confirm_password"
                    required
                    onChange={handleChange}
                  />
                </div>
                {/* <div className="mb-6">
                  <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                    Comfirm Password
                  </label>
                  <input
                    type="password"
                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3"
                    placeholder="*****"
                    name="confirm_password"
                    required
                  />
                </div> */}

             
                <button
                  type="submit"
                  className="w-full text-white bg-[#48B774] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                >
                  Continue
                </button>
             
            </form>

            <p className="text-center text-xs font-semibold mt-4 text-gray-500">
              Don't receive any email?{" "}
              <NavLink to={'/forgot-password'}>
                <span className="text-[#000] cursor-pointer">click here to resend</span>
              </NavLink>
            </p>

            <NavLink to={"/sign-in"} className="flex justify-center mt-4">
              <img src="/images/arrow-back-icon.svg" className=" mr-2" alt="sign-in" />
              <p className="text-center text-xs font-semibold text-gray-500 mt-1">

                Back to login

              </p>
            </NavLink>


          </div>
        </div>
      </section>

      {/* <!-- End block --> */}
    </>
  )
}

export default OTPVerify