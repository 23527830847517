import React from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import { SvgElement, icontypesEnum } from '../../assets/svgElement'
import { useParams } from 'react-router-dom';
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';


function ViewSubAdmin() {

  const params: any = useParams();
  let userId: string = params?.id;
  const [userData, setUserData] = React.useState<any>([]);

  React.useEffect(() => {
    AdminApis.getUserDetailsById(userId).then(
        (response: AxiosResponse<any>) => {
            if (response?.data) {
              setUserData(response?.data?.data)
            }
        }
    );

}, []);


console?.log(userData)


  return (
    <>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />

        <div className=' lg:mt-8 mt-0 lg:px-16 px-8 pt-10 pb-10 lg:ml-8 lg:mr-12 mx-6 rounded border border-[#D9D9D9]'>

          <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl lg:px-10">         
            <div>
            <SvgElement type={icontypesEnum.DEFAULTIMAGE} />
            
            <div>
              <h2 className='font-semibold text-xl'>{userData?.name + ' ' + userData?.last_name}</h2>
              <h4 className='text-xs text-[#838383] mt-1'>Sub Admin</h4>  
            </div>
            </div>
            <div className='border-l-2 border-[#D9D9D9] mt-8 lg:mt-0'>
              <h3 className='pl-3 font-semibold text-sm'>Contact Details</h3>
              <div className='flex space-x-2 mt-2.5 pl-3'>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="M4 20q-.825 0-1.413-.588T2 18V6q0-.825.588-1.413T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.588 1.413T20 20H4ZM20 8l-7.475 4.675q-.125.075-.263.113t-.262.037q-.125 0-.263-.037t-.262-.113L4 8v10h16V8Zm-8 3l8-5H4l8 5ZM4 8v.25v-1.475v.025V6v.8v-.012V8.25V8v10V8Z"/></svg>
              <h4 className=' text-xs text-[#838383] '>{userData.email}</h4>
              </div>
              <div className='flex space-x-2 mt-2.5 pl-3'>
                <SvgElement type={icontypesEnum.CALL}/>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"/></svg> */}
              
              <h4 className='text-xs text-[#838383]'>{userData?.phone_number}</h4>
              </div>
              <div className='flex space-x-2 mt-2.5 pl-3'>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><g id="feTwitter0" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><g id="feTwitter1" fill="currentColor" fill-rule="nonzero"><path id="feTwitter2" d="M8.283 20.263c7.547 0 11.676-6.259 11.676-11.677c0-.176 0-.352-.008-.528A8.36 8.36 0 0 0 22 5.928a8.317 8.317 0 0 1-2.36.649a4.129 4.129 0 0 0 1.808-2.273a8.163 8.163 0 0 1-2.61.993A4.096 4.096 0 0 0 15.847 4a4.109 4.109 0 0 0-4.106 4.106c0 .32.04.632.104.936a11.654 11.654 0 0 1-8.46-4.29a4.115 4.115 0 0 0 1.273 5.482A4.151 4.151 0 0 1 2.8 9.722v.056a4.113 4.113 0 0 0 3.29 4.026a4.001 4.001 0 0 1-1.08.144c-.265 0-.521-.024-.77-.072a4.104 4.104 0 0 0 3.834 2.85a8.231 8.231 0 0 1-5.098 1.76c-.328 0-.656-.016-.976-.056a11.674 11.674 0 0 0 6.283 1.833"/></g></g></svg> */}
             <div className='bg-[#1DB459] text-xs font-normal text-white px-2 rounded-sm'>Active</div>
             
              <h4 className='text-xs text-[#838383]'>{userData?.status}</h4>
              </div>
              
            </div>
            <div className='border-l-2 border-[#D9D9D9] mt-8 lg:mt-0'>
              <div className="pl-4">
                <button
                  type="submit"
                  className="py-2.5 px-6  text-sm font-medium text-white bg-[#1DB459] rounded-lg "
                >
                  Send Message
                </button>
              </div>
              <div className="pl-4 mt-3">
                <button
                  type="submit"
                  className="py-2.5 px-6  text-sm font-medium text-white bg-[#E21E32] rounded-lg "
                >
                  Delete Account
                </button>
              </div>
              <div className="pl-4 mt-3">
                <button
                  type="submit"
                  className="py-2.5 px-6  text-sm font-medium text-white bg-[#838383] rounded-lg "
                >
                  Restrict Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewSubAdmin