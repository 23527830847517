import React from "react";
import Sidebar from "../../Sidebar/Sidebar";
import AdminSidebar from "../../Sidebar/AdminSidebar";

import UserNavbar from "../../Navbars/UserNavbar";
import { AdminApis } from "../../../apis/adminApi";
import { AxiosResponse } from "axios";


export default function AdminDashboard() {

  const [userLists, setUserList] = React.useState<any>([]);
  React.useEffect(() => {
    AdminApis.getUserCount().then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserList(response?.data?.data)
          console?.log(response?.data?.data)
          // setAvailableBalance(response?.data?.data?.account_balance)
          //  dispatch(login({email:email,token:response.data.data.token,id:response.data.data.account_id}))
          // setRoomData(response?.data)
          // device(roomData?.deviceId, roomData?.buildingId, roomData?.levelId)
        }
      }
    ).catch(function (error) {
      // handle error

    }) 
  }, []);



  return (
    <>
      <AdminSidebar />

      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />
        <div className="space-y-2 lg:grid lg:grid-cols-4 sm:gap-5  lg:space-y-0 lg:py-16 lg:px-10 px-6 ">

          <div className="bg-[#ffffff] border border-[#D8D8D8] rounded-lg flex justify-between px-4 py-4">
            <div>
              <h3 className=" text-base text-black">Total Students</h3>
              <p className="text-3xl  text-black">
                {userLists?.total_student}
              </p>
            </div>
            <div className=" mt-2">
              <img src="/images/Dashboard/icon-sch1.svg" alt="hero" />
            </div>

          </div>
          <div className="bg-[#ffffff] border border-[#D8D8D8] rounded-lg flex justify-between px-4 py-4">
            {/* <div>
              <h3 className=" text-base text-black">High School Students</h3>
              <p className="text-3xl  text-black">
                {userLists?.hight_school}
              </p>
            </div> */}
            <div className=" mt-2">
              <img src="/images/Dashboard/icon-sch.svg" alt="hero" />
            </div>

          </div>
          <div className="bg-[#ffffff] border border-[#D8D8D8] rounded-lg flex justify-between px-4 py-4">
            <div>
              <h3 className=" text-base text-black">Vocational Students</h3>
              <p className="text-3xl  text-black">
                {userLists?.vocational}
              </p>
            </div>
            <div className=" mt-2">
              <img src="/images/Dashboard/icon-sch.svg" alt="hero" />
            </div>

          </div>
          <div className="bg-[#ffffff] border border-[#D8D8D8] rounded-lg flex justify-between px-4 py-4">
            <div>
              <h3 className=" text-base text-black">Sub Admins</h3>
              <p className="text-3xl  text-black">
              {userLists?.sub_admin}
              </p>
            </div>
            <div className=" mt-2">
              <img src="/images/Dashboard/icon-sch1.svg" alt="hero" />
            </div>

          </div>

        </div>

      </div>
    </>


  );
}