import './App.css';
import { Routes, Route } from "react-router-dom";
//  import Navbar from './Components/Navbar';
// import Nav from './Components/Nav';
import Login from './Components/Pages/Auth/Login';
import Register from './Components/Pages/Auth/Register';
import Home from './Components/Pages/Home';
import ForgotPword from './Components/Pages/Auth/ForgotPword';
import EmailVerify from './Components/Pages/Auth/EmailVerify';
import EmailVerifyComplete from './Components/Pages/Auth/EmailVerifyComplete';
import OTPVerify from './Components/Pages/Auth/OTPVerify';
import ResetPassword from './Components/Pages/Auth/ResetPassword';
import PwordResetSucces from './Components/Pages/Auth/PwordResetSucces';
import Programs from './Components/Pages/Programs';
import AboutUs from './Components/Pages/AboutUs';
import MailImage from './Components/Pages/MailImage.png';
import ContactUs from './Components/Pages/ContactUs';
import ApplyLoan from './Components/Pages/ApplyLoan';
import HighSch from './Components/Pages/HighSch';
import VocationalSch from './Components/Pages/VocationalSch'; 
import EnglishDashboard from './Components/Screens/User/EnglishDashboard';
import Dashboard from './Components/Screens/User/Dashboard';
import DashType from './Components/Screens/User/dashType';
import Language from './Components/Screens/User/Programs';
import Profile from './Components/Screens/User/Profile';
import Consult from './Components/Screens/User/Consultation';
import PersonalInfo from './Components/ReuseableComp/PersonalInfo';
import EduHistory from './Components/ReuseableComp/EduHistory';
import ExamScore from './Components/ReuseableComp/ExamScore';
import UploadDoc from './Components/ReuseableComp/UploadDoc';
import AvailablePrograms from './Components/Screens/User/AvailablePrograms';
import FindProgramsDetails from './Components/Screens/User/FindProgramsDetails/FindProgramsDetails';
import Wallet from './Components/Screens/User/Wallet';
import PaymentCard from './Components/Screens/User/PaymentCard';
import TermsAndCon from './Components/Pages/Auth/TermsAndCon';
import Consultation from './Components/Pages/Consultation';
import JoinWaitList from './Components/Pages/JoinWaitList';
import FaqPage from './Components/Pages/FaqPage';
import TermsAndCondition from './Components/Pages/TermsAndCondition';
// import Blog from './Components/Pages/Blog';
import AdminDashboard from './Components/Screens/Admin/AdminDashboard';
import UsersList from './Components/Screens/Admin/UsersList'; 
import MasterProgramList from './Components/Screens/Admin/MasterProgramList';
import AgentList from './Components/Screens/Admin/AgentList';
import WinterProgram from './Components/Screens/Admin/WinterStudent';
import Payments from './Components/Screens/Admin/Payments';
import SubAdmins from './Components/Screens/Admin/SubAdmins';
import AddSubAdmin from './Components/Screens/Admin/AddSubAdmin';
import ViewUserDetails from './Components/Screens/Admin/ViewUserDetails';
import ViewSubAdmin from './Components/Screens/Admin/ViewSubAdmin';
import StudingInFinland from './Components/Pages/BlogsPages/StudingInFinland';
import HowToStudy from './Components/Pages/BlogsPages/HowToStudy';
import CanInternational from './Components/Pages/BlogsPages/CanInternational';
import VocationalTraining from './Components/Pages/BlogsPages/VocationalTraining';
import AfriProAndFinest from './Components/Pages/BlogsPages/AfriProAndFinest';
import VocationalSchools from './Components/Pages/BlogsPages/VocationalSchools';
import MidSummerInFinland from './Components/Pages/BlogsPages/MidSummerInFinland';
import BenefitsOfFootball from './Components/Pages/BlogsPages/BenefitsOfFootball';
import FinlandBestStudy from './Components/Pages/BlogsPages/FinlandBestStudy';
import CohortList from './Components/Screens/Admin/CohortList';
import AddCohort from './Components/Screens/Admin/AddCohort';
// import EditCohort from './Components/Screens/Admin/EditCohort';
import Saunas from './Components/Pages/BlogsPages/Saunas';
import Safety from './Components/Pages/BlogsPages/Safety';
import AddBlog from './Components/Screens/Admin/AddBlog';
import EditBlog from './Components/Screens/Admin/EditBlog';
import FinnishDegree from './Components/Pages/BlogsPages/FinnishDegree';
import BlogList from './Components/Screens/Admin/BlogList';
import PartnerSchool from './Components/Screens/Admin//PartnerSchoolList';
import CreatePartnerSchool from './Components/Screens/Admin/AddPartnerSchool';
import EditPartnerSchool from './Components/Screens/Admin/EditPartnerSchool';
import EditPartnerCourse from './Components/Screens/Admin/EditCourse';
import Message from './Components/Screens/Admin/Message';
import Notification from './Components/Screens/Admin/Notification';
import DegreeProgram from './Components/Pages/DegreeProgram';
import CourseList from './Components/Pages/CourseList';
import CourseDetails from './Components/Pages/CourseDetails';
import { SchoolDetails } from './Components/Pages/SchoolDetails';
import AddCourse from './Components/Screens/Admin/AddCourse';
import ListOfCourses from './Components/Screens/Admin/ListOfCourses';
import EditCourse from './Components/Screens/Admin/EditCourse';
import FindPrograms from './Components/Pages/FindPrograms';
import NewBlog from './Components/Pages/NewBlog';
import BlogDetail from './Components/Pages/BlogDetails';
import ApplyForm from './Components/Pages/ApplyForm';
import PaymentSuccessful from './Components/Pages/PaymentSuccessful';
import RegList from './Components/Screens/Admin/RegList';
import CookProgram from './Components/Screens/Admin//cookProgram.tsx';
import ViewRegUser from './Components/Screens/Admin/ViewRegUser';
import BecomeAnAgent from './Components/Pages/BecomeAnAgent';
import Resturant from './Components/Pages/Resturant';
import RegisterCook from './Components/Pages/RegisterCook';
import TravelDocuments from './Components/Screens/User/TravelDocuments';
import DependantInfo from './Components/Screens/User/DependantInfo';
import EnglishAvailablePrograms from './Components/Screens/User/EnglishAvailablePrograms';
import VocationalCourses from './Components/Screens/User/VocationalCourses';
import ResturantAndCatering from './Components/Screens/User/ResturantAndCatering';
import VocationalCoursesDetails from './Components/Screens/User/VocationalCoursesDetails';
import PostGraduate from './Components/Screens/User/PostGraduate';
import Degree from './Components/Screens/User/Degree';
import Afripay from './Components/Pages/Afripay';
import Gallery from './Components/Pages/Gallery';
import MastersApplication from './Components/Pages/MastersApplication';
import ElegibilityCriteria from './Components/Pages/ElegibilityCriteria';
import EnglishCriteria from './Components/Pages/EnglishCriteria';
import WinterCamp from './Components/Pages/WinterCamp';
import WinterCampForm from './Components/Pages/WinterCampForm';
import CleaningForm from './Components/Pages/CleaningForm';
import WaiterForm from './Components/Pages/WaiterForm';
import ResidentPermitApp from './Components/Screens/User/ResidentPermitApp';
import WaiterPaymentSuccess from './Components/Pages/WaiterPaymentSuccess';
import CleaningPaymentSuccess from './Components/Pages/CleaningPaymentSuccess';
import SocialAndHealthCareForm from './Components/Pages/SocialAndHealthCareForm';
import SocialHealthCarePaymentSuccess from './Components/Pages/SocialHealthCarePaymentSuccess';
import CaregiverForm from './Components/Pages/CaregiverForm';
import CaregiverPaymentSuccessful from './Components/Pages/CaregiverPaymentSuccessful';
import RegisterCookPaymentSuccessful from './Components/Pages/RegisterCookPaymentSuccessful';
import Welcome from './Components/Screens/Agent/Welcome';
import MyProfile from './Components/Screens/Agent/MyProfile';

import LUTCriteria from './Components/Pages/LUTCriteria';
import LUTApplicationForm from './Components/Pages/LUTApplicationForm';
import LUTScholarship from './Components/Pages/LUTScholarship';
import LUTPaymentSuccessful from './Components/Pages/LUTPaymentSuccessful';
import BecomeAnAgentLogin from './Components/Pages/BecomeAnAgentLogin';
import BankPage from './Components/Pages/BankPage';
import EasyGoEdu from './Components/Pages/EasyGoEdu';
import AsapEduGo from './Components/Pages/AsapEduGo';
import Transaction from './Components/Screens/User/Transaction';
import Faq from './Components/Faq';
import FaqAdmin from './Components/Screens/Admin/FaqAdmin';
import AddFaq from './Components/Screens/Admin/AddFaq';
import EditFaq from './Components/Screens/Admin/EditFaq';
import AfripayLoan from './Components/Screens/User/AfripayLoan';
import CareerEdu from './Components/Pages/CareerEdu';
import Edudirect from './Components/Pages/Edudirect';
import AgentDetails from './Components/Screens/Admin/AgentDetails';
// import Edudirect from './Components/Pages/Edudirect';


function App() {
  return (
    <div>
      {/* <Navbar/> */}
      <Routes>
           <Route  path="/" element={<Home/>}/>
           <Route  path="/banking-page" element={<BankPage/>}/>
           <Route  path="/sign-in" element={<Login/>}/>
           <Route  path="/terms" element={<TermsAndCon/>}/>
           <Route  path="/sign-up" element={<Register/>}/>
           <Route  path="/forgot-password" element={<ForgotPword/>}/> 
           <Route  path="/email-verify" element={<EmailVerify/>}/>
           <Route  path="/email-verify/:email" element={<EmailVerifyComplete/>}/>
           <Route  path="/otp-verify" element={<OTPVerify/>}/>
           <Route  path="/reset-password" element={<ResetPassword/>}/>
           <Route  path="/reset-success" element={<PwordResetSucces/>}/>
           <Route  path="/programs" element={<Programs/>}/>
           <Route  path="/about-us" element={<AboutUs/>}/>
           <Route  path="/apply-loan" element={<ApplyLoan/>}/>
           <Route  path="/become-an-agent" element={<BecomeAnAgent/>}/>
           <Route  path="/become-an-agent-login" element={<BecomeAnAgentLogin/>}/>
           <Route  path="/high-school" element={<HighSch/>}/>
           <Route  path="/vocational-school" element={<VocationalSch/>}/>
           <Route  path="/contact-us" element={<ContactUs/>}/>
           <Route  path="/consultation" element={<Consultation/>}/>
           <Route  path='/afripay' element={<Afripay/>}/>
           <Route  path='/faq' element={<FaqPage/>}/>
           <Route  path="/joinwaitlist" element={<JoinWaitList/>}/>
           <Route  path="/terms-condition" element={<TermsAndCondition/>}/>
           <Route  path='/gallery' element={<Gallery/>}/>
           <Route  path='/masters-application-form/:masters' element={<MastersApplication/>}/>
           <Route  path='/eligibility-criteria' element={<ElegibilityCriteria/>}/>
           <Route  path='/english-criteria' element={<EnglishCriteria/>}/>
           <Route  path='/winter-camp' element={<WinterCamp/>}/>
           <Route  path='/winter-camp-application-form' element={<WinterCampForm/>}/>
           <Route  path='/lut-admission-criteria' element={<LUTCriteria/>}/>
           <Route  path='/lut-scholarship' element={<LUTScholarship/>}/>
           <Route  path='/degree-application-form/:degree' element={<LUTApplicationForm/>}/>
           <Route  path='/degree-payment-successful' element={<LUTPaymentSuccessful/>}/>

           <Route  path='/find-programs' element={<FindPrograms/>}/>
           <Route  path='/degree-program' element={<DegreeProgram/>}/>
           <Route  path='/list-of-courses' element={<CourseList/>}/>
           <Route  path='/course-details/:courseId' element={<CourseDetails/>}/>
           <Route  path='/school-details/:schoolId' element={<SchoolDetails/>}/>
           <Route  path='/practical-nursing-application-form' element={<ApplyForm/>}/>
           <Route  path='/practical-nursing-payment-successful' element={<PaymentSuccessful/>}/>
           <Route  path='course-details/resturant-and-catering-services' element={<Resturant/>}/>
           <Route  path='/resturant-and-catering-registration-form' element={<RegisterCook/>}/>
           <Route  path='/resturant-and-catering-payment-successful' element={<RegisterCookPaymentSuccessful/>}/>
           <Route  path='/cleaning-and-property-registration-form' element={<CleaningForm/>}/>
           <Route  path='/waiter-waitress-registration-form' element={<WaiterForm/>}/>
           <Route  path='/waiter-waitress-payment-successful' element={<WaiterPaymentSuccess/>}/>
           <Route  path='/cleaning-payment-successful' element={<CleaningPaymentSuccess/>}/>
           <Route  path='/social-health-care-registration-form' element={<SocialAndHealthCareForm/>}/>
           <Route  path='/social-health-care-payment-successful' element={<SocialHealthCarePaymentSuccess/>}/>
           <Route  path='/caregiver-registration-form' element={<CaregiverForm/>}/>
           <Route  path='/caregiver-payment-successful' element={<CaregiverPaymentSuccessful/>}/>

           <Route  path="/blog" element={<NewBlog/>}/>
           {/* <Route  path="/blog" element={<NewBlog/>}/> */}
           <Route  path="/blog-detail/:id" element={<BlogDetail/>}/>
           <Route  path="/studing-in-finland" element={<StudingInFinland/>}/>
           <Route  path="/how-to-study-in-finland" element={<HowToStudy/>}/>
           <Route  path="/can-an-international-african-study" element={<CanInternational/>}/>
           <Route  path="/vocational-training-free-in-finland" element={<VocationalTraining/>}/>
           <Route  path="/afripro-and-finest-future-african-tour" element={<AfriProAndFinest/>}/>
           <Route  path="/vocational-school-in-finland" element={<VocationalSchools/>}/>
           <Route  path="/midsummer-in-finland" element={<MidSummerInFinland/>}/>
           <Route  path="/benefits-of-football" element={<BenefitsOfFootball/>}/>
           <Route  path="/why-finland-is-the-best-study-destination" element={<FinlandBestStudy/>}/>
           <Route  path="/finnish-saunas-culture" element={<Saunas/>}/>
           <Route  path="/why-finland-is-a-safe-country-to-study" element={<Safety/>}/>
           <Route  path="/the-power-of-a-finnish-degree" element={<FinnishDegree/>}/> 

           <Route  path="/english-dashboard" element={<EnglishDashboard/>}/>
           <Route  path="/post-graduate-courses" element={<PostGraduate/>}/>
           <Route  path="/degree-courses" element={<Degree/>}/>
           <Route  path="/travel-documents" element={<TravelDocuments/>}/>
           <Route  path="/dependant-information" element={<DependantInfo/>}/>
           <Route  path="/english-available-programs" element={<EnglishAvailablePrograms/>}/>
           <Route  path="/vocational-courses" element={<VocationalCourses/>}/>
           <Route  path='vocational-course-details/resturant-and-catering-services' element={<ResturantAndCatering/>}/>
           <Route  path='/vocational-course-details/:courseId' element={<VocationalCoursesDetails/>}/>
           <Route  path="/dashboard" element={<DashType/>}/>
           <Route  path="/language-learning" element={<Language/>}/>
           <Route  path="/user-profile" element={<Profile/>}/>
           <Route  path="/book-consultation" element={<Consult/>}/>
           <Route  path="/personal-info" element={<PersonalInfo/>}/>
           <Route  path="/edu-history" element={<EduHistory/>}/>
           <Route  path="/exam-score" element={<ExamScore/>}/>
           <Route  path="/upload-document" element={<UploadDoc/>}/>
           <Route  path="/available-programs" element={<AvailablePrograms/>}/>
           <Route  path="/find-programs-details" element={<FindProgramsDetails/>}/>
           <Route  path="/wallet" element={<Wallet/>}/>
           <Route  path="/payment" element={<PaymentCard/>}/>  
        <Route path="/study-in-finnish" element={<Programs />} />
        <Route path='/programs-overview' element={<FindPrograms />} />
        <Route path='/study-in-english' element={<DegreeProgram />} />
        <Route path='/list-of-courses' element={<CourseList />} />
        <Route path='/course-details/:courseId' element={<CourseDetails />} />
        <Route path='/school-details/:schoolId' element={<SchoolDetails />} />
        <Route path='/practical-nursing-application-form' element={<ApplyForm />} />
        <Route path='/practical-nursing-payment-successful' element={<PaymentSuccessful />} />
        <Route  path="/easygoedu-with-afriproedu" element={<EasyGoEdu />}/>
        <Route  path="/asapedugo-with-afriproedu" element={<AsapEduGo />}/>
        <Route  path="/career-edu-with-afriproedu" element={<CareerEdu />}/>
        <Route  path="/edudirect-with-afriproedu" element={<Edudirect />}/>

        

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/loan-with-afripay" element={<AfripayLoan/>} />
        <Route path="/language-learning" element={<Language />} />
        <Route path="/user-profile" element={<Profile />} />
        <Route path="/transaction" element={<Transaction />} />
        <Route path="/personal-info" element={<PersonalInfo />} />
        <Route path="/edu-history" element={<EduHistory />} />
        <Route path="/exam-score" element={<ExamScore />} />
        <Route path="/upload-document" element={<UploadDoc />} />
        <Route path="/available-programs" element={<AvailablePrograms />} />
        <Route path="/find-programs-details" element={<FindProgramsDetails />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/payment" element={<PaymentCard />} />

        <Route path="/resident-permit-app" element={<ResidentPermitApp/>} />

        
           <Route  path="/mail-image" element={<MailImage/>}/>
           
           <Route  path="/admin-dashboard" element={<AdminDashboard/>}/>
           <Route  path="/users" element={<UsersList/>}/> 
           <Route  path="/masters-program-list" element={<MasterProgramList/>}/>
           <Route  path="/agents" element={<AgentList/>}/>
           <Route  path="/agent-details" element={<AgentDetails/>}/>
           <Route  path="/winter-program" element={<WinterProgram/>}/>
           <Route  path="/payments" element={<Payments/>}/>
           <Route  path="/sub-admins" element={<SubAdmins/>}/>
           <Route  path="/add-admin" element={<AddSubAdmin/>}/>
           <Route  path="/user-details/:id" element={<ViewUserDetails/>}/>
           <Route  path="/subadmin-details/:id" element={<ViewSubAdmin/>}/>
           <Route  path="/cohorts" element={<CohortList/>}/>
           <Route  path="/add-cohort" element={<AddCohort/>}/>
           <Route  path="/add-blog" element={<AddBlog/>}/>
           <Route  path="/edit-blog/:id" element={<EditBlog/>}/>
           <Route  path="/blog-list" element={<BlogList/>}/>
           <Route  path="/add-partner-school" element={<CreatePartnerSchool/>}/>
           <Route  path="/edit-partner-school/:partnerId" element={<EditPartnerSchool/>}/>
           <Route  path="/edit-partner-course/:courseId" element={<EditPartnerCourse/>}/>
           <Route  path="/partner-schools" element={<PartnerSchool/>}/>
           <Route  path="/message" element={<Message/>}/>
           <Route  path="/notification" element={<Notification/>}/>
           <Route  path="/edit-cohort/:id" element={<AddCohort/>}/>
           <Route  path="/add-course" element={<AddCourse/>}/>
           <Route  path="/course-list" element={<ListOfCourses/>}/>
           <Route  path="/edit-course" element={<EditCourse/>}/>
           <Route  path="/faq-list" element={<FaqAdmin/>}/>
           <Route  path="/add-faq" element={<AddFaq/>}/>
           <Route  path="/edit-faq/:faqId" element={<EditFaq/>}/>
           <Route  path="/registered-users" element={<RegList/>}/>
           <Route  path="/cook-program" element={<CookProgram/>}/>
           <Route  path="/view-registered-user" element={<ViewRegUser/>}/>

           {/* <!-- Agent Portal --> */}
           <Route  path="/agent-portal" element={<Welcome/>}/>
           <Route  path="/my-profile" element={<MyProfile/>}/>

      </Routes>
      {/* <Nav/> */}
    </div>
  );
}

export default App;
