import React from 'react'
import { NavLink } from "react-router-dom";
import Sidebar from '../../Sidebar/Sidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import { DashboardApi } from '../../../apis/DashboardApi';
import { ToastContainer, toast } from 'react-toastify';

function DependantInfo() {
    const [userData, setUserdata] = React.useState<any>({
        "full_name": "",
        'phone_number': "",
        'address': "",

    });



    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUserdata({ ...userData, [name]: value });
    };

    const [passportPage, setSelectedPics2] = React.useState('No selected file');
    const changeHandler22 = (event: any) => {
        // console?.log(2)
        const target = event.target
        let size = (target.files[0].size / 1048576.0)
        let targetName = target.name
        if (target.files && target.files[0]) {
            if (size > 1) {
                if (targetName == 'passport_page') {
                    target.value = ''
                    console.log('error')
                }
            }
            if (size <= 1) {
                if (targetName == 'passport_page') {
                    setSelectedPics2(event.target.files[0]);
                }
            }
        }
    };

    const [bankStatement, setSelectedPics] = React.useState('No selected file');
    const changeHandler2 = (event: any) => {
        console?.log(event)
        const target = event.target
        let size = (target.files[0].size / 1048576.0)
        let targetName = target.name
        if (target.files && target.files[0]) {
            if (size > 1) {
                if (targetName == 'bank_statement') {
                    target.value = ''
                    console.log('error')
                }
            }
            if (size <= 1) {
                if (targetName == 'bank_statement') {
                    setSelectedPics(event.target.files[0]);
                }
            }
        }
    };

    const handleSubmit = React.useCallback(
        (e: any) => {
            e.preventDefault();
            const formData = new FormData()
            formData.append('full_name', userData?.full_name)
            formData.append('phone_number', userData?.phone_number)
            formData.append('address', userData?.address)
            formData.append('bank_statement', bankStatement)
            formData.append('passport_page', passportPage)
            DashboardApi.dependantInfo(formData).then(
                (response: any) => {
                    if (response?.data) {
                        toast.success(response?.data?.message);
                        console?.log(response?.data)
                    }
                }
            ).catch(function (error) {
                // handle error
                console.log(error.response.data);
                toast.error(error.response.data?.message);
            //   setLoading(false);
          
              })

        },
        [userData, bankStatement, passportPage]
    );

    const handleInput = (event:any) => {
        const value = event.target.value;
        const regex = /^[a-zA-Z]*$/; // Regular expression to match alphabetic characters only
    
        if (!regex.test(value)) {
          event.target.value = value.replace(/[^a-zA-Z]/g, ''); // Remove any non-alphabetic characters
        }
      };
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar title="Dependant Information" />
                <div className='md:py-16'>
                    <div>
                        <div className='flex space-x-3 md:pl-10 pl-6 pr-4 mt-5 '>
                            <NavLink to={"/upload-document"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1z" /></svg>
                            </NavLink>
                            <h3 className='font-semibold'>Dependant Information</h3>
                        </div>
                        <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto pl-3 pr-4 mt-5">
                            <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                <div className="flex-auto md:px-4  py-10 pt-0">
                                    <div className="flex flex-wrap">
                                        {/* <div className="w-full lg:w-6/12 px-4 ">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    htmlFor="gender"
                                                    className="block text-[#0A2E04] text-sm  font-medium mb-2 "
                                                >
                                                    Do you have dependents ?*
                                                </label>
                                                <select
                                                    id="gender"
                                                    name="programs_type"

                                                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px]  block w-full p-2.5 "
                                                >
                                                    <option selected></option>
                                                    <option value="high school">Yes</option>
                                                    <option value="vocational">No</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        {/* <div className="w-full lg:w-6/12 px-4 ">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    htmlFor="gender"
                                                    className="block text-[#0A2E04] text-sm  font-medium mb-2 "
                                                >
                                                    How many dependants do you have ?*
                                                </label>
                                                <select
                                                    id="gender"
                                                    name="programs_type"

                                                    className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px]  block w-full p-2.5 "
                                                >
                                                    <option selected></option>
                                                    <option value="high school">1</option>
                                                    <option value="vocational">2</option>
                                                </select>
                                            </div>
                                        </div> */}

                                        <form onSubmit={handleSubmit} className='w-full border border-[#d9d9d9] rounded-[15px] mx-4'>
                                            <div className='w-full bg-[#1DB459] text-white rounded-t-[14px] px-4 py-[15px] font-semibold'>Dependant Information</div>
                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-6 mt-4">
                                                    <label
                                                        className="block text-[#0A2E04] text-sm font-medium mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Full Name
                                                    </label>
                                                    <input
                                                        type="text"

                                                        name="full_name"
                                                        onChange={handleChange}
                              onInput={handleInput}

                                                        className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                                                        placeholder="Enter Name"
                                                        required

                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-6">
                                                    <label
                                                        className="block text-[#0A2E04] text-sm font-medium mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Phone Number
                                                    </label>
                                                    <input
                                                        type="number"

                                                        name="phone_number"
                                                        onChange={handleChange}
                                                        className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                                                        placeholder="Enter Phone"
                                                        required

                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-6">
                                                    <label
                                                        className="block text-[#0A2E04] text-sm font-medium mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        Home address
                                                    </label>
                                                    <input
                                                        type="text"

                                                        name="address"
                                                        onChange={handleChange}
                                                        className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                                                        placeholder="Enter address"
                                                        required

                                                    />
                                                </div>
                                            </div>
                                            <div className='flex flex-col border border-[#d9d9d9] md:w-[97%] w-[93%] rounded-[4px] mx-4 mr-6 p-3 mb-6'>
                                                <div>
                                                    <h4 className='text-[14px] font-medium'>Bank Statement</h4>
                                                </div>
                                                <div>
                                                    <label htmlFor="dropzone-file">
                                                        {/* <div className='flex space-x-2 border border-[#d9d9d9] mt-2 p-2 rounded-[5px] w-[108px] hover:bg-gray-100'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16h-2Zm-5 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" /></svg>
                                                            <p className='text-[14px]'>Add file</p>
                                                        </div> */}
                                                        <input accept="image/x-png,image/gif,image/jpeg" type="file" name="bank_statement" onChange={changeHandler2} />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='flex flex-col border border-[#d9d9d9] md:w-[97%] w-[93%] rounded-[4px] mx-4 mr-6 p-3 mb-6'>
                                                <div>
                                                    <h4 className='text-[14px] font-medium'>Passport Page</h4>
                                                </div>
                                                <div>
                                                    <label htmlFor="dropzone-file">
                                                        {/* <div className='flex space-x-2 border border-[#d9d9d9] mt-2 p-2 rounded-[5px] w-[108px] hover:bg-gray-100'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16h-2Zm-5 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" /></svg>
                                                            <p className='text-[14px]'>Add file</p>
                                                        </div> */}
                                                        <input accept="image/x-png,image/gif,image/jpeg" type="file" name="passport_page" onChange={changeHandler22} />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='px-4 mt-5 flex justify-center'>
                                                <button
                                                    type="submit"
                                                    className=" text-white bg-[#1DB459] hover:bg-[#05401C] focus:ring-4 font-medium rounded-full text-sm px-10 py-2.5 mr-2 mb-2"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div >
            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default DependantInfo