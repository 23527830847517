import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface FullName {
  Username: string;
  amount: any;
  referral_id: any;
}
function DashHeader(props: FullName) {
  

  return (
    <>
      <div className="w-full lg:w-[80%] max-w-5xl bg-[#05401C] border border-gray-200 rounded-2xl shadow md:mt-10 md:ml-10  relative">
        <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl px-8 lg:px-7 pt-8 pb-8 mx-auto lg:gap-20 ">
          <div className="mr-auto place-self-center text-white">

            { props?.Username == 'DeewonEdu' ?
            <h3>Hi ,{props?.Username}!  (Ref_id : {props?.referral_id} <CopyToClipboard text={`www.afriproedu.com/sign-up?ref=${props?.referral_id}`}
            onCopy={() => toast.success('Copied to Clipboard')}>
            <button>📋</button>
          </CopyToClipboard>
            &nbsp;) </h3>
            :

            <h3>Hi ,{props?.Username}! </h3>
            }
            <h3>(Ref_id : {props?.referral_id} <CopyToClipboard text={`www.afriproedu.com/sign-up?ref=${props?.referral_id}`}
              onCopy={() => toast.success('Copied to Clipboard')}>
              <button>📋</button>
            </CopyToClipboard>
              &nbsp;) </h3>
            <p className="text-xs text-[#B3B3B3] mb-5">Here is how you are faring currently </p>
          </div>
          <div className="mr-auto place-self-center text-white ">
            <h3 className=''>Your Available Balance:</h3>
            <h1 className="text-3xl">{props?.amount}</h1>
          </div>
        </div>

        <div className=" lg:py-8 p-2">

          <h1 className="text-white" style={{ fontSize: '32px' }}>You have not started any<br /> lesson yet !</h1>
          <div className='md:mt-16 mt-5 flex md:justify-between md:flex-row flex-col gap-7'>
            <p className='text-white'>Please complete your profile , deposit funds, make payment for a language <br/>learning course and start your online language learning. </p>
            <div className='md:mt-0 mt-8'>
              <Link to="/english-available-programs" className=" text-[#666666] bg-[#ffffff]  font-medium rounded-lg text-sm px-2 py-2.5">
                Get Started
              </Link>

            </div>

          </div>


        </div>

      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default DashHeader