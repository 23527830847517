import React from 'react'
import { NavLink } from "react-router-dom";

function ProfileNav() {
  return (
    <>
    <div className="text-sm font-medium text-center text-[#000]  md:px-14">
            <div className="flex flex-wrap -mb-px">
            {/* <NavLink
                to="/"
                className=" inline-block p-4  hover:text-gray-600  "
                style={({ isActive }) =>
                  isActive ? { color: '#48B774' } : { color: '#000' }
                }
              >
                Account Info
              </NavLink> */}

              <NavLink
                to="/user-profile"
                className=" inline-block p-4  hover:text-gray-600  "
                style={({ isActive }) =>
                  isActive ? { color: '#48B774' } : { color: '#000' }
                }
              >
                Personal Info
              </NavLink>
              {/* <NavLink
                to="/edu-history"
                className=" inline-block p-4  hover:text-gray-600  "
                style={({ isActive }) =>
                  isActive ? { color: '#48B774' } : { color: '#000' }
                }
              >
                Educational History
              </NavLink> */}

              {/* <NavLink
                to="/exam-score"
                className=" inline-block p-4  hover:text-gray-600  "
                style={({ isActive }) =>
                  isActive ? { color: '#48B774' } : { color: '#000' }
                }
              >
                Exam Scores
              </NavLink> */}

              {/* <NavLink
                to="/"
                className=" inline-block p-4  hover:text-gray-600  "
                style={({ isActive }) =>
                  isActive ? { color: '#48B774' } : { color: '#000' }
                }
              >
                Background Info
              </NavLink> */}

              <NavLink
                to="/upload-document"
                className=" inline-block p-4  hover:text-gray-600  "
                style={({ isActive }) =>
                  isActive ? { color: '#48B774' } : { color: '#000' }
                }
              >
                Documents 
              </NavLink>

              <NavLink
                to="/dependant-information"
                className=" inline-block p-4  hover:text-gray-600  "
                style={({ isActive }) =>
                  isActive ? { color: '#48B774' } : { color: '#000' }
                }
              >
                Dependant Info 
              </NavLink>

            </div>
          </div >

          <div className='md:px-14'>
            <NavLink
              to="/dashboard"
              className=" flex space-x-3 py-2 pl-3 pr-4 text-sm text-[#48B774] tracking-wider font-normal leading-5 lg:hover:bg-transparent lg:border-0  lg:hover:text-[#48B774] lg:pb-5 "

            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#48B774" d="m10.875 19.3l-6.6-6.6q-.15-.15-.213-.325Q4 12.2 4 12t.062-.375q.063-.175.213-.325l6.6-6.6q.275-.275.687-.288q.413-.012.713.288q.3.275.313.687q.012.413-.288.713L7.4 11h11.175q.425 0 .713.287q.287.288.287.713t-.287.712Q19 13 18.575 13H7.4l4.9 4.9q.275.275.288.7q.012.425-.288.7q-.275.3-.7.3q-.425 0-.725-.3Z" /></svg>
              Go back
            </NavLink>
          </div>
    </>
  )
}

export default ProfileNav