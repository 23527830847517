import React from "react";
import { NavLink } from "react-router-dom";
// @ts-ignore
import Carousel from "react-grid-carousel";
import Navbar from "../Navbar";
import vocabg from "../../img/voca-bg.svg";
import salarybg from "../../img/salary-bg.svg";
// import vocational from "../../img/vocational.png";
import vocational from "../../img/revamp-vocal-bg.png";
import Footer from "../Footer";

function VocationalSch() {
  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section
        style={{
          backgroundImage: `url(${vocational})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="bg-white body-font font-poppins md:pt-10 pt-10"
      >
        <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl px-8 lg:px-14 pt-20 mx-auto lg:gap-20 ">
          <div className="mr-auto place-self-center pb-8">
            <h1 className="max-w-2xl md:mb-2 text-3xl text-[#000000] font-semibold leading-10 md:text-3xl xl:text-4xl">
              Vocational School
            </h1>
            <h1 className="max-w-2xl mb-4 text-3xl text-[#000000]  font-semibold leading-10 md:text-3xl xl:text-4xl">
              Program Overview
            </h1>
            <div className="lg:px-0">
              <p className="max-w-2xl mb-2 mt-6 font-light text-[#838383]   text-sm leading-relaxed">
                {/* Vocational education and training equips you with vital skills for working life. VET is good for you if you just finished your basic education or you’re a working adult. Finland and takes three years to complete. */}
                Vocational education and training (VET) equips you with
                essential skills for the working world.
                <br />
                VET is suitable for those who have just completed their basic
                education or for working adults.
                <br />
                In Finland, VET programs typically take three years to complete.
              </p>
              {/* <div className='mt-5'>
                                <div className="flex space-x-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">All Teachers must have at least a Master’s Degree.</h3>
                                </div>
                                <div className="flex space-x-2 mt-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">The academic year is generally divided into 5 study periods.</h3>
                                </div>
                                <div className="flex space-x-3 mt-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">All Teachers must have at least a Master’s Degree.</h3>
                                </div>
                                <div className="flex space-x-3 mt-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">The academic year is generally divided into 5 study periods.</h3>
                                </div>
                            </div> */}

              {/* <p className="max-w-2xl mb-2 font-light text-[#000000]   text-sm leading-relaxed">
        All Teachers must have at least a Master’s Degree.
        The academic year is generally divided into 5 study periods.
        After each period, a test is given to the students on the subjects studied during that period
    </p>

    <p className="max-w-2xl  font-light text-[#000000]   text-sm leading-relaxed">
        Some High Schools offer special activities as part of the studies such as: Floorball, Horse Riding, Ice-Hockey, Basketball, Esports, Music, Medicine, etc.!
    </p> */}
            </div>
          </div>
          <div className="hidden md:block mt-6 lg:mt-0   px-6">
            <img src="/images/revamp-vocal-banner.png" alt="vocational" />
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      {/* <section className="bg-[#FBFBFB] pb-5 body-font font-poppins">
                <div className="  lg:px-14 pt-5 pb-3 mx-auto  relative" >
                    <div className="flex justify-center md:mt-20 px-8">
                        <video className="md:w-[80%] h-auto max-w-full border-[5px] border-[#48B774]" controls>
                            <source src="/images/finnish-vocational.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>

                    </div>
                </div>
            </section> */}
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-[#000000] pb-5 body-font font-poppins">
        <div className="  lg:px-14 pt-5 pb-3 mx-auto  relative">
          <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl md:mt-20 px-8">
            <div>
              <video
                className="md:w-[70%] h-auto max-w-full border-[5px] border-[#ffffff] rounded-[15px]"
                controls
              >
                <source src="/images/finnish-vocational.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="mt-5">
              <h1 className="text-white font-semibold md:text-[32px] text-[28px] whitespace-nowrap leading-10">
                Vocational School
              </h1>
              <h1 className="text-white font-semibold md:text-[32px] text-[28px] whitespace-nowrap leading-10">
                Program Overview
              </h1>
              {/* <p className='text-white mt-6 text-[14px] font-normal'>All High Schools in Finland are of equal quality no matter the size or location. They use the same equipment and same teaching methodology. </p> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-[#D7F5DC] ">
        <div className="py-5 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-md text-center mb-8  p-6">
            <h2 className=" md:text-3xl text-2xl tracking-tight font-extrabold text-gray-900 ">
              Academic Timeline
            </h2>
            <div className="mt-5">
              <span className="bg-[#1DB459] text-white text-base font-medium inline-flex items-center px-8 py-2 rounded-full mr-2 ">
                <svg
                  aria-hidden="true"
                  className="w-3 h-3 mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                2-4 years
              </span>
            </div>
            <div className="text-center mt-3">
              <small className="text-[#838383] text-base font-medium">
                120-180 Credits
              </small>
            </div>
          </div>
          <div className="flex justify-center">
            <img src="/images/timeline.svg" alt="timeline" />
          </div>
          <div className="flex justify-center mt-5 ">
            <div className="border border-[#1DB459] px-5 py-2">
              <p className="text-sm">
                Around 145 ects compulsory studies, 35 ects of selective studies
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      {/* <section className="body-font font-poppins md:pt-10 pt-5 pb-16">
                <div className="mx-auto max-w-screen-md text-center  p-6">
                    <div>
                        <h2 className=" md:text-3xl text-2xl tracking-tight font-extrabold text-gray-900 ">
                            Vocational Schools in our <br /> <span className='text-[#1DB459]'>Program</span>
                        </h2>
                        <p className='mt-3 text-[#838383]'> And many more schools for you to browse when applying to the program.</p>
                    </div>

                </div>
                <div className='max-w-screen-xl px-8 lg:px-14 pb-8 mx-auto lg:gap-8 xl:gap-12 md:py-5'>
                    <div className='grid grid-cols-2 gap-8 md:grid-cols-4'>
                        <img src="/images/vuoksi.png" alt='' />
                        <img src="/images/step-edu.png" alt='' />
                        <img src="/images/riv.png" alt='' />
                        <img src="/images/lappia.png" alt='' />
                    </div>
                </div>
                <div className=''>
                    <Carousel cols={4} rows={1} gap={10} loop showDots>
                        <Carousel.Item>
                            <img width="100%" src="/images/slider1.jpg" alt='' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img width="100%" src="/images/slider2.jpg" alt='' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img width="100%" src="/images/slider3.jpg" alt=''/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img width="100%" src="/images/slider4.jpg" alt='' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img width="100%" src="/images/slider1.jpg" alt='' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img width="100%" src="/images/slider2.jpg" alt=''/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img width="100%" src="/images/slider3.jpg" alt='' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img width="100%" src="/images/slider4.jpg" alt='' />
                        </Carousel.Item>
                       
                    </Carousel>
                </div>
            </section> */}
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      {/* <section className=" md:mb-10  pb-5 body-font font-poppins ">
                <div
                    style={{
                        backgroundImage: `url(${vocabg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className="  lg:px-14 md:pt-20 pt-10 pb-24 mx-auto justify-center  flex flex-col lg:flex-row lg:space-x-10  relative"
                >
                    <div className='md:hidden block px-8'>
                        <h1 className='text-2xl font-bold text-white'>Vocational School <br /> Process</h1>

                        <div className=" px-6 mt-5  flex justify-center space-x-3">

                            <img src="/images/vocal-timeline1.png" alt="hero" className="" />
                            <img src="/images/vocal-timeline1.png" alt="hero" className="" />
                        </div>
                    </div>
                    <div className="hidden md:block px-8 ">

                        <img src="/images/vocal-timeline.svg" alt="hero" className="" />
                    </div>

                    <div className='text-white mt-5 px-8'>
                        <h1 className='hidden md:block text-4xl font-bold'>Vocational School <br /> Process</h1>
                        <p className='text-sm mt-5 mb-3'>The application process starts with an interview with the Finest Future council, <br />who will evaluate candidates’ English proficiency and soft skills</p>
                        <p className='text-sm mt-5 mb-3'>Students succeeding in the interview are eligible for the program. <br /> They will continue preparing their study-abroad profile, beginning with the Finnish <br />language course by Finest Future Finnish Language Program.</p>
                        <p className='text-sm mt-5 mb-3'>Chat with our customer service on Whatsapp for more information about the timeline.</p>
                        <p className='text-sm mt-5 mb-3'>+358408187876 ( 09:00 – 16:00 EET )</p>
                        
                    </div>


                </div>
            </section> */}
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="body-font font-poppins pb-16">
        <div className="hidden md:block">
          <div className="mx-auto max-w-screen-md text-center mb-8  p-6">
            <h2 className=" text-3xl tracking-tight font-extrabold text-black ">
              The Finnish <span className="text-[#48B774]">Language</span>
            </h2>
            <h2 className=" text-3xl tracking-tight font-extrabold text-[#48B774] ">
              Program <span className="text-black">Learning Process</span>
            </h2>
          </div>

          <div className="flex justify-center mt-5">
            <img src="/images/highscprocess.svg" alt="friends" />
          </div>
        </div>

        <div className="block md:hidden px-8 text-center pt-5">
          <h2 className=" text-2xl tracking-tight font-extrabold text-black ">
            The <span className="text-[#48B774]">Language</span>
          </h2>
          <h2 className=" text-2xl tracking-tight font-extrabold text-[#48B774] ">
            Program <span className="text-black">Learning Process</span>
          </h2>
          <div className="flex justify-center mt-5">
            <img src="/images/highscprocess-mobile.png" alt="friends" />
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-[#F7F7FF] body-font font-poppins md:pt-10 pt-6  pb-10">
        <div className="mx-auto text-center mt-6">
          <h1 className="hidden md:block text-[#000000] text-3xl  px-6 font-bold">
            Vocational Programs Offered
          </h1>
          <h1 className="block md:hidden text-[#000000] text-2xl px-6 font-bold">
            Vocational Programs
            <br /> Offered
          </h1>
        </div>
        {/* <div className='hidden md:block'>
                    <div className=' lg:px-14 pt-20 pb-24 mx-auto justify-center  flex flex-col lg:flex-row lg:space-x-10'>
                        <div className=''>
                            <div className='flex justify-center'>
                                <img src="/images/vocal-img1.svg" alt="friends" />
                            </div>

                            <div className='bg-[#1DB459] rounded-md mt-3 mx-20 lg:mx-0'>
                                <p className='text-center text-white py-2'>IT / Computer <br />
                                    Specialist</p>
                            </div>
                        </div>

                        <div className='mt-5 lg:mt-0'>
                            <div className='flex justify-center'>
                                <img src="/images/vocal-img2.svg" alt="friends" />
                            </div>
                            <div className='bg-[#1DB459] rounded-md mt-3 mx-20 lg:mx-0'>
                                <p className='text-center text-white py-2'>Practical Nurse </p>
                            </div>
                        </div>

                        <div className='mt-5 lg:mt-0'>
                            <div className='flex justify-center'>
                                <img src="/images/vocal-img3.svg" alt="friends" />
                            </div>
                            <div className='bg-[#1DB459] rounded-md mt-3  mx-20 lg:mx-0'>
                                <p className='text-center text-white py-2'>Mechanical Fitter, <br />
                                    Machinist, Plate-Welder
                                </p>
                            </div>
                        </div>

                        <div className='mt-5 lg:mt-0'>
                            <div className='flex justify-center'>
                                <img src="/images/vocal-img4.svg" alt="friends" />
                            </div>
                            <div className='bg-[#1DB459] rounded-md mt-3 mx-20 lg:mx-0'>
                                <p className='text-center text-white py-2'>Restaurant & <br />
                                    Catering Services
                                </p>
                            </div>
                        </div>

                        <div className='mt-5 lg:mt-0'>
                            <div className='flex justify-center'>
                                <img src="/images/vocal-img5.svg" alt="friends" />
                            </div>
                            <div className='bg-[#1DB459] rounded-md mt-3 mx-20 lg:mx-0'>
                                <p className='text-center text-white py-2'>Site Facilities <br />Operative
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}

        <div className=" md:block hidden px-6  pt-6">
          <Carousel cols={5} rows={1} gap={2} loop>
            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img1.svg" alt="friends" />
                  </div>

                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] flex justify-center rounded-md mt-3 w-[65%]">
                      <p className="text-center text-white py-2">
                        IT / Computer <br />
                        Specialist
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img2.svg" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Practical Nurse{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img3.svg" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Mechanical Fitter
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  {/* <div className=''> */}
                  <img src="/images/vocal-img4.svg" alt="friends" />
                  {/* </div> */}
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[90%]">
                      <p className="text-center text-white py-2">
                        Restaurant & <br />
                        Catering Services
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img5.svg" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Site Facilities <br />
                        Operative
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img6.png" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">Machinist</p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img7.png" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Plate Welder
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img8.png" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Entrepreneurship in Agriculture
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img9.png" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Truck driving
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img10.png" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Early Childhood Education
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img11.png" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Care Assistance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        <div className="block md:hidden px-6  pt-6">
          <Carousel cols={2} rows={1} gap={3} loop>
            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img1.svg" alt="friends" />
                  </div>

                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] flex justify-center rounded-md mt-3 w-[65%]">
                      <p className="text-center text-white py-2">
                        IT / Computer <br />
                        Specialist
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img2.svg" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Practical Nurse{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img3.svg" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Mechanical Fitter
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  {/* <div className=''> */}
                  <img src="/images/vocal-img4.svg" alt="friends" />
                  {/* </div> */}
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[90%]">
                      <p className="text-center text-white py-2">
                        Restaurant & <br />
                        Catering Services
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img5.svg" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Site Facilities <br />
                        Operative
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img6.png" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">Machinist</p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img7.png" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Plate Welder
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img8.png" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Entrepreneurship in Agriculture
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img9.png" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Truck driving
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img10.png" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Early Childhood Education
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="flex justify-center">
                <div className="">
                  <div className="">
                    <img src="/images/vocal-img11.png" alt="friends" />
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-[#1DB459] rounded-md mt-3 w-[70%]">
                      <p className="text-center text-white py-2">
                        Care Assistance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className=" body-font font-poppins">
        <div
          style={{
            backgroundImage: `url(${salarybg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="  lg:px-14 md:pt-20 pb-24   relative"
        >
          <div className="mx-auto max-w-screen-md text-center mb-8  p-6">
            <h2 className=" md:text-3xl text-2xl tracking-tight font-extrabold text-white ">
              Salary of <span className="text-[#48B774]">Vocational </span>
            </h2>
            <h2 className=" md:text-3xl text-2xl tracking-tight font-extrabold text-[#48B774] ">
              Programs <span className="text-white">we offer</span>
            </h2>
          </div>

          <div className="hidden md:block">
            <div className="space-y-8 lg:grid lg:grid-cols-5 xl:gap-4  lg:space-y-0 lg:py-6 p-6">
              <div className="flex flex-col   max-w-lg    rounded-lg border border-[#ffffff] shadow ">
                <div className="pl-4 py-5 px-4 text-[#ffffff]">
                  <div className="border border-[#ffffff] rounded-md p-1 inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="42.96"
                      height="48"
                      viewBox="0 0 48 48"
                    >
                      <mask id="ipSChefHat0">
                        <g
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="4"
                        >
                          <path fill="#fff" d="M12 34h24v8H12z" />
                          <path d="M29 34V20m-7 14v-8m-10-1v9h24v-9s5-3 5-8s-4-7-9-7c0-2-3-6-8-6s-8 4-8 6c-4 0-9 2-9 7s5 8 5 8Z" />
                        </g>
                      </mask>
                      <path
                        fill="white"
                        d="M0 0h48v48H0z"
                        mask="url(#ipSChefHat0)"
                      />
                    </svg>
                  </div>
                  <h3 className="text-lg font-bold">Restaurant &</h3>
                  <h3 className="text-lg font-bold">Catering Services</h3>
                  <ul className="list-disc list-inside text-xs">
                    <li>Restaurant Industry</li>
                    <li>Customer Service</li>
                    <li>Catering Service</li>
                    <li>Gastronomy</li>
                  </ul>

                  <p className="text-xs mt-4">
                    A person working as a Waiter / Waitress in Finland typically
                    earns around 1,420 EUR per month. Salaries range from 740
                    EUR (lowest) to 2,170 EUR (highest). This is the average
                    monthly salary including housing, transport, and other
                    benefits.
                  </p>
                </div>
              </div>
              <div className="flex flex-col   max-w-lg  rounded-lg border border-[#ffffff] shadow mt-4 lg:mt-0">
                <div className="pl-4 py-5 px-4 text-[#ffffff]">
                  <div className="border border-[#ffffff] rounded-md p-1 inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="39.18"
                      height="41"
                      viewBox="0 0 32 32"
                    >
                      <path
                        fill="white"
                        d="M10.156 4L10 4.813l-1 5.625V14h.094a7.032 7.032 0 0 0 3 4.813C8.527 20.343 6 23.883 6 28h2a7.988 7.988 0 0 1 3-6.25v.656l.281.313l4 4l.719.687l.719-.687l4-4l.281-.313v-.656A7.988 7.988 0 0 1 24 28h2c0-4.117-2.527-7.656-6.094-9.188a7.032 7.032 0 0 0 3-4.812H23v-3.563l-1-5.624L21.844 4zm1.688 2h8.312L21 10.781V12H11v-1.219zM15 7v1h-1v2h1v1h2v-1h1V8h-1V7zm-3.906 7h9.812A5.001 5.001 0 0 1 16 18a5.001 5.001 0 0 1-4.906-4zM16 20a8.05 8.05 0 0 1 3 .563v1l-3 3l-3-3v-1A8.05 8.05 0 0 1 16 20z"
                      />
                    </svg>
                  </div>
                  <h3 className="text-lg font-bold">Practical Nurse</h3>
                  <h5 className="text-xs">Nursing</h5>

                  <p className="text-xs mt-4">
                    Salary range after graduation: an average 2200€ per month
                    and the possibility to earn more if you take extra shifts
                  </p>
                </div>
              </div>

              <div className="flex flex-col max-w-lg  rounded-lg border border-[#ffffff] shadow mt-4 lg:mt-0">
                <div className="pl-4 py-5 px-4 text-[#ffffff]">
                  <div className="border border-[#ffffff] rounded-md p-1 inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34.78"
                      height="38"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="white"
                        d="M21 16v-3c0-1.11-.89-2-2-2h-6V8h2V2H9v6h2v3H5c-1.11 0-2 .89-2 2v3H1v6h6v-6H5v-3h6v3H9v6h6v-6h-2v-3h6v3h-2v6h6v-6h-2M11 4h2v2h-2V4M5 20H3v-2h2v2m8 0h-2v-2h2v2m8 0h-2v-2h2v2Z"
                      />
                    </svg>
                  </div>
                  <h3 className="text-lg font-bold">Site Facilities</h3>
                  <h3 className="text-lg font-bold">Operative</h3>

                  <ul className="list-disc list-inside text-xs">
                    <li>Property Maintenance</li>
                    <li>Domestic Services</li>
                    <li>Facilities Services </li>
                  </ul>

                  <p className="text-xs mt-4">
                    A person working in Cleaning and Property Services in
                    Finland typically earns around 1,700 EUR per month. Salaries
                    range from 1,220 EUR (lowest average) to 2,580 EUR (highest
                    average, the actual maximum salary is higher). This is the
                    average monthly salary including housing, transport, and
                    other benefits. Salaries vary drastically between different
                    Cleaning and Property Services careers
                  </p>
                </div>
              </div>

              <div className="flex flex-col max-w-lg   rounded-lg border border-[#ffffff] shadow mt-4 lg:mt-0">
                <div className="pl-4 py-5 px-4 text-[#ffffff]">
                  <div className="border border-[#ffffff] rounded-md p-1 inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="39.38"
                      height="44"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="white"
                        d="M1.05 21v-2.8q0-.825.425-1.55t1.175-1.1q1.275-.65 2.875-1.1T9.05 14q1.925 0 3.525.45t2.875 1.1q.75.375 1.175 1.1t.425 1.55V21h-16Zm2-2h12v-.8q0-.275-.138-.5t-.362-.35q-.9-.45-2.313-.9T9.05 16q-1.775 0-3.188.45t-2.312.9q-.225.125-.362.35t-.138.5v.8Zm6-6q-1.65 0-2.825-1.175T5.05 9H4.8q-.225 0-.362-.138T4.3 8.5q0-.225.138-.363T4.8 8h.25q0-1.125.55-2.025T7.05 4.55v.95q0 .225.138.363T7.55 6q.225 0 .363-.138T8.05 5.5V4.15q.225-.075.475-.113T9.05 4q.275 0 .525.038t.475.112V5.5q0 .225.138.363T10.55 6q.225 0 .363-.138t.137-.362v-.95q.9.525 1.45 1.425T13.05 8h.25q.225 0 .363.138t.137.362q0 .225-.138.363T13.3 9h-.25q0 1.65-1.175 2.825T9.05 13Zm0-2q.825 0 1.413-.588T11.05 9h-4q0 .825.588 1.413T9.05 11Zm7.5 4l-.15-.75q-.15-.05-.287-.113t-.263-.187l-.7.25l-.5-.9l.55-.5v-.6l-.55-.5l.5-.9l.7.25q.1-.1.25-.175t.3-.125l.15-.75h1l.15.75q.15.05.3.125t.25.175l.7-.25l.5.9l-.55.5v.6l.55.5l-.5.9l-.7-.25q-.125.125-.263.188t-.287.112l-.15.75h-1Zm.5-1.75q.3 0 .525-.225t.225-.525q0-.3-.225-.525t-.525-.225q-.3 0-.525.225t-.225.525q0 .3.225.525t.525.225Zm1.8-3.25l-.2-1.05q-.225-.075-.412-.188T17.9 8.5l-1.05.35l-.7-1.2l.85-.75q-.05-.125-.05-.2v-.4q0-.075.05-.2l-.85-.75l.7-1.2l1.05.35q.15-.15.338-.263t.412-.187l.2-1.05h1.4l.2 1.05q.225.075.413.188t.337.262l1.05-.35l.7 1.2l-.85.75q.05.125.05.2v.4q0 .075-.05.2l.85.75l-.7 1.2l-1.05-.35q-.15.15-.337.263t-.413.187l-.2 1.05h-1.4Zm.7-2.25q.525 0 .888-.363T20.8 6.5q0-.525-.363-.888t-.887-.362q-.525 0-.888.363T18.3 6.5q0 .525.363.888t.887.362ZM3.05 19h12h-12Z"
                      />
                    </svg>
                  </div>
                  <h3 className="text-lg font-bold">Mechanical Fitter,</h3>
                  <h3 className="text-lg font-bold">Machinist, Plate-</h3>
                  <h3 className="text-lg font-bold">Welder</h3>
                  <ul className="list-disc list-inside text-xs">
                    <li>Mechanical Engineering</li>
                    <li>Production Engineering</li>
                    <li>Fabrication</li>
                    <li>Installation</li>
                  </ul>

                  <p className="text-xs mt-4">
                    A person working as a Mechanical Engineer in Finland
                    typically earns around 3,500 EUR per month. Salaries range
                    from 2,100 EUR (lowest) to 6,690 EUR (highest). This is the
                    average monthly salary including housing, transport, and
                    other benefits. Mechanical Engineer salaries vary
                    drastically based on experience, skills, gender, or location
                  </p>
                </div>
              </div>

              <div className="flex flex-col max-w-lg   rounded-lg border border-[#ffffff] shadow mt-4 lg:mt-0">
                <div className="pl-4 py-5 px-4 text-[#ffffff]">
                  <div className="border border-[#ffffff] rounded-md p-1 inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="38"
                      height="38"
                      viewBox="-2 -3.5 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M1 .565h5a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-14a1 1 0 0 1 1-1zm0 11v2h2v-2H1zm9-8h8a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2zm2 11h4a1 1 0 0 1 0 2h-4a1 1 0 0 1 0-2z"
                      />
                    </svg>
                  </div>
                  <h3 className="text-lg font-bold">IT & Computer </h3>
                  <h3 className="text-lg font-bold">Specialist</h3>
                  <ul className="list-disc list-inside text-xs">
                    <li>Software Engineer</li>
                    <li>Hardware Repair</li>
                  </ul>

                  <p className="text-xs mt-4">
                    A person working as an IT Specialist or computer operator in
                    Finland typically earns around 3,807 EUR per month. Salaries
                    range from 3,807 EUR (lowest) to 5,402 EUR (highest). This
                    is the average monthly salary including housing, transport,
                    and other benefits.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="block md:hidden px-6">
            <Carousel cols={2} rows={1} gap={20} loop>
              <Carousel.Item>
                <div className="flex flex-col   max-w-lg    rounded-lg border border-[#ffffff] shadow ">
                  <div className="pl-4 py-5 px-4 text-[#ffffff]">
                    <div className="border border-[#ffffff] rounded-md p-1 inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42.96"
                        height="48"
                        viewBox="0 0 48 48"
                      >
                        <mask id="ipSChefHat0">
                          <g
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="4"
                          >
                            <path fill="#fff" d="M12 34h24v8H12z" />
                            <path d="M29 34V20m-7 14v-8m-10-1v9h24v-9s5-3 5-8s-4-7-9-7c0-2-3-6-8-6s-8 4-8 6c-4 0-9 2-9 7s5 8 5 8Z" />
                          </g>
                        </mask>
                        <path
                          fill="white"
                          d="M0 0h48v48H0z"
                          mask="url(#ipSChefHat0)"
                        />
                      </svg>
                    </div>
                    <h3 className="text-lg font-bold">Restaurant &</h3>
                    <h3 className="text-lg font-bold">Catering Services</h3>
                    <ul className="list-disc list-inside text-xs">
                      <li>Restaurant Industry</li>
                      <li>Customer Service</li>
                      <li>Catering Service</li>
                      <li>Gastronomy</li>
                    </ul>

                    <p className="text-xs mt-4">
                      A person working as a Waiter / Waitress in Finland
                      typically earns around 1,420 EUR per month. Salaries range
                      from 740 EUR (lowest) to 2,170 EUR (highest). This is the
                      average monthly salary including housing, transport, and
                      other benefits.
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex flex-col   max-w-lg  rounded-lg border border-[#ffffff] shadow">
                  <div className="pl-4 py-5 px-4 text-[#ffffff]">
                    <div className="border border-[#ffffff] rounded-md p-1 inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="39.18"
                        height="41"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="white"
                          d="M10.156 4L10 4.813l-1 5.625V14h.094a7.032 7.032 0 0 0 3 4.813C8.527 20.343 6 23.883 6 28h2a7.988 7.988 0 0 1 3-6.25v.656l.281.313l4 4l.719.687l.719-.687l4-4l.281-.313v-.656A7.988 7.988 0 0 1 24 28h2c0-4.117-2.527-7.656-6.094-9.188a7.032 7.032 0 0 0 3-4.812H23v-3.563l-1-5.624L21.844 4zm1.688 2h8.312L21 10.781V12H11v-1.219zM15 7v1h-1v2h1v1h2v-1h1V8h-1V7zm-3.906 7h9.812A5.001 5.001 0 0 1 16 18a5.001 5.001 0 0 1-4.906-4zM16 20a8.05 8.05 0 0 1 3 .563v1l-3 3l-3-3v-1A8.05 8.05 0 0 1 16 20z"
                        />
                      </svg>
                    </div>
                    <h3 className="text-lg font-bold">Practical Nurse</h3>
                    <h5 className="text-xs">Nursing</h5>

                    <p className="text-xs mt-4">
                      Salary range after graduation: 3000 - 4500 EUR/month,
                      depends on overtime work, night shifts, and weekends
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex flex-col max-w-lg  rounded-lg border border-[#ffffff] shadow">
                  <div className="pl-4 py-5 px-4 text-[#ffffff]">
                    <div className="border border-[#ffffff] rounded-md p-1 inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34.78"
                        height="38"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="white"
                          d="M21 16v-3c0-1.11-.89-2-2-2h-6V8h2V2H9v6h2v3H5c-1.11 0-2 .89-2 2v3H1v6h6v-6H5v-3h6v3H9v6h6v-6h-2v-3h6v3h-2v6h6v-6h-2M11 4h2v2h-2V4M5 20H3v-2h2v2m8 0h-2v-2h2v2m8 0h-2v-2h2v2Z"
                        />
                      </svg>
                    </div>
                    <h3 className="text-lg font-bold">Site Facilities</h3>
                    <h3 className="text-lg font-bold">Operative</h3>

                    <ul className="list-disc list-inside text-xs">
                      <li>Property Maintenance</li>
                      <li>Domestic Services</li>
                      <li>Facilities Services </li>
                    </ul>

                    <p className="text-xs mt-4">
                      A person working in Cleaning and Property Services in
                      Finland typically earns around 1,700 EUR per month.
                      Salaries range from 1,220 EUR (lowest average) to 2,580
                      EUR (highest average, the actual maximum salary is
                      higher). This is the average monthly salary including
                      housing, transport, and other benefits. Salaries vary
                      drastically between different Cleaning and Property
                      Services careers
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex flex-col max-w-lg   rounded-lg border border-[#ffffff] shadow">
                  <div className="pl-4 py-5 px-4 text-[#ffffff]">
                    <div className="border border-[#ffffff] rounded-md p-1 inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="39.38"
                        height="44"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="white"
                          d="M1.05 21v-2.8q0-.825.425-1.55t1.175-1.1q1.275-.65 2.875-1.1T9.05 14q1.925 0 3.525.45t2.875 1.1q.75.375 1.175 1.1t.425 1.55V21h-16Zm2-2h12v-.8q0-.275-.138-.5t-.362-.35q-.9-.45-2.313-.9T9.05 16q-1.775 0-3.188.45t-2.312.9q-.225.125-.362.35t-.138.5v.8Zm6-6q-1.65 0-2.825-1.175T5.05 9H4.8q-.225 0-.362-.138T4.3 8.5q0-.225.138-.363T4.8 8h.25q0-1.125.55-2.025T7.05 4.55v.95q0 .225.138.363T7.55 6q.225 0 .363-.138T8.05 5.5V4.15q.225-.075.475-.113T9.05 4q.275 0 .525.038t.475.112V5.5q0 .225.138.363T10.55 6q.225 0 .363-.138t.137-.362v-.95q.9.525 1.45 1.425T13.05 8h.25q.225 0 .363.138t.137.362q0 .225-.138.363T13.3 9h-.25q0 1.65-1.175 2.825T9.05 13Zm0-2q.825 0 1.413-.588T11.05 9h-4q0 .825.588 1.413T9.05 11Zm7.5 4l-.15-.75q-.15-.05-.287-.113t-.263-.187l-.7.25l-.5-.9l.55-.5v-.6l-.55-.5l.5-.9l.7.25q.1-.1.25-.175t.3-.125l.15-.75h1l.15.75q.15.05.3.125t.25.175l.7-.25l.5.9l-.55.5v.6l.55.5l-.5.9l-.7-.25q-.125.125-.263.188t-.287.112l-.15.75h-1Zm.5-1.75q.3 0 .525-.225t.225-.525q0-.3-.225-.525t-.525-.225q-.3 0-.525.225t-.225.525q0 .3.225.525t.525.225Zm1.8-3.25l-.2-1.05q-.225-.075-.412-.188T17.9 8.5l-1.05.35l-.7-1.2l.85-.75q-.05-.125-.05-.2v-.4q0-.075.05-.2l-.85-.75l.7-1.2l1.05.35q.15-.15.338-.263t.412-.187l.2-1.05h1.4l.2 1.05q.225.075.413.188t.337.262l1.05-.35l.7 1.2l-.85.75q.05.125.05.2v.4q0 .075-.05.2l.85.75l-.7 1.2l-1.05-.35q-.15.15-.337.263t-.413.187l-.2 1.05h-1.4Zm.7-2.25q.525 0 .888-.363T20.8 6.5q0-.525-.363-.888t-.887-.362q-.525 0-.888.363T18.3 6.5q0 .525.363.888t.887.362ZM3.05 19h12h-12Z"
                        />
                      </svg>
                    </div>
                    <h3 className="text-lg font-bold">Mechanical Fitter,</h3>
                    <h3 className="text-lg font-bold">Machinist, Plate-</h3>
                    <h3 className="text-lg font-bold">Welder</h3>
                    <ul className="list-disc list-inside text-xs">
                      <li>Mechanical Engineering</li>
                      <li>Production Engineering</li>
                      <li>Fabrication</li>
                      <li>Installation</li>
                    </ul>

                    <p className="text-xs mt-4">
                      A person working as a Mechanical Engineer in Finland
                      typically earns around 3,500 EUR per month. Salaries range
                      from 2,100 EUR (lowest) to 6,690 EUR (highest). This is
                      the average monthly salary including housing, transport,
                      and other benefits. Mechanical Engineer salaries vary
                      drastically based on experience, skills, gender, or
                      location
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex flex-col max-w-lg   rounded-lg border border-[#ffffff] shadow">
                  <div className="pl-4 py-5 px-4 text-[#ffffff]">
                    <div className="border border-[#ffffff] rounded-md p-1 inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="38"
                        height="38"
                        viewBox="-2 -3.5 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M1 .565h5a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-14a1 1 0 0 1 1-1zm0 11v2h2v-2H1zm9-8h8a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2zm2 11h4a1 1 0 0 1 0 2h-4a1 1 0 0 1 0-2z"
                        />
                      </svg>
                    </div>
                    <h3 className="text-lg font-bold">IT & Computer </h3>
                    <h3 className="text-lg font-bold">Specialist</h3>
                    <ul className="list-disc list-inside text-xs">
                      <li>Software Engineer</li>
                      <li>Hardware Repair</li>
                    </ul>

                    <p className="text-xs mt-4">
                      A person working as an IT Specialist or computer operator
                      in Finland typically earns around 3,807 EUR per month.
                      Salaries range from 3,807 EUR (lowest) to 5,402 EUR
                      (highest). This is the average monthly salary including
                      housing, transport, and other benefits.
                    </p>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      <Footer />
    </>
  );
}

export default VocationalSch;
