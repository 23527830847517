import React from "react";
import { NavLink } from "react-router-dom";
import teamsbg from "../../../img/teams-bg.png"

function TermsAndCon() {

    const [toggle, setToggle] = React.useState(true);
    return (
        <>
            <section className="bg-[#FBFBFB] body-font font-poppins">
                <div
                    style={{
                        backgroundImage: `url(${teamsbg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        
                        // maxHeight:"100vh"
                    }}
                    className="lg:px-14 lg:pt-28 pt-20 pb-28 mx-auto  relative h-full"
                >
                    <div className="flex justify-center">
                        <img src="./images/logo.svg" className="lg:w-[30%]" alt="Afripro Logo" />
                    </div>
                    <div className=" pr-4 pl-5 pb-8 pt-8 bg-[#FBFBFB] rounded-lg shadow max-w-sm lg:mx-auto place-self-center mx-5">
                        <h1 className="text-gray-500 text-2xl text-center mb-2">Before you continue</h1>
                        <h3 className="text-center text-xs mb-5">It is important to know the following:</h3>
                        <div>
                        <div className="flex items-start mb-3">
                                {/* <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-green-600 rounded" /> */}
                                <label htmlFor="default-checkbox" className="ml-2 text-[15px] font-[700] text-gray-900 ">Finnish pathway:</label>
                            </div>
                            <div className="flex items-start mb-3">
                                {/* <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-green-600 rounded" /> */}
                                <label htmlFor="default-checkbox" className="ml-2 text-xs font-medium text-gray-900 ">You are learning the Finnish Language Online 100%.</label>
                            </div>
                            <div className="flex items-start mb-3">
                                {/* <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-green-600 rounded" /> */}
                                <label htmlFor="default-checkbox" className="ml-2 text-xs font-medium text-gray-900 ">The language program is 10-12 months.</label>
                            </div>
                            <div className="flex items-start mb-3">
                                {/* <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-green-600 rounded" /> */}
                                <label htmlFor="default-checkbox" className="ml-2 text-xs font-medium text-gray-900 ">You are studying vocational school.</label>
                            </div>
                           
                           

                            <div className="flex items-start mb-3">
                                {/* <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-green-600 rounded" /> */}
                                <label htmlFor="default-checkbox" className="ml-2 text-[15px] font-[700] text-gray-900 ">English pathway:</label>
                            </div>

                            <div className="flex items-start mb-3">
                                {/* <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-green-600 rounded" /> */}
                                <label htmlFor="default-checkbox" className="ml-2 text-xs font-medium text-gray-900 ">You have the opportunity to study in Finland using our English pathway program.</label>
                            </div>
                            <div className="flex items-start mb-3">
                                {/* <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-green-600 rounded" /> */}
                                <label htmlFor="default-checkbox" className="ml-2 text-xs font-medium text-gray-900 ">Application fee is non-refundable</label>
                            </div>
                        </div>
                        {/* Divider */}
                        <hr className="mt-6 w-full" />
                        <form>
                            <div className="flex justify-center items-center mb-3 mt-4">
                                <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-green-600 rounded" onClick={()=>setToggle(!toggle)} required />
                                <label htmlFor="default-checkbox" className="ml-2 text-xs font-medium text-gray-900 ">I have read this and I understand </label>
                            </div>


                            <div className="flex justify-center">
                            <NavLink to="/sign-up">
                                <button
                                    type="button"
                                    disabled={toggle}
                                    className={"py-3 px-6 text-xs font-medium "+ (!toggle ? "text-white bg-[#1DB459] " : " text-black bg-gray-400 ")+"rounded-md border border-white "}
                                >
                                    Agree and Continue
                                </button>
                                </NavLink>
                            </div>
                        </form>
                    </div>

                </div>
            </section>
        </>
    )
}
export default TermsAndCon;