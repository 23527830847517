import React from 'react'


function PaymentCard() {
    return (
        <>
            <div className='md:py-10 md:px-16'>
                <div className="flex flex-wrap mt-4 md:px-8 pl-3 pr-4">
                    <div className="w-full md:w-8/12 mb-12 xl:mb-0 px-4">
                        <div className='pb-5'>
                            <h2 className='text-black font-bold text-base'>Payment details</h2>
                        </div>
                        <form>
                            <div className="flex flex-wrap mt-5">
                                <div className="w-full lg:w-12/12 ">
                                    <div className="relative w-full mb-6">
                                        <label
                                            className="block text-[#404D61] text-sm font-medium mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Name on Card
                                        </label>
                                        <input
                                            type="text"
                                            className="border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                                            placeholder=""
                                        />
                                    </div>
                                </div>

                                <div className="w-full lg:w-12/12 ">
                                    <div className="relative w-full mb-6">
                                        <label
                                            className="block text-[#404D61] text-sm font-medium mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Credit Card Number
                                        </label>
                                        <input
                                            type="number"
                                            className="border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#B3B3B3]  ease-linear transition-all duration-150"
                                            placeholder="xxxx xxxx xxxx xxxx"
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 pr-4">
                                    <div className="relative w-full mb-6">
                                        <label
                                            className="block text-[#404D61] text-sm font-medium mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Expiry Date
                                        </label>
                                        <input
                                            type="text"
                                            className="  border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#B3B3B3] ease-linear transition-all duration-150"
                                            placeholder="mm / yy"
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-6">
                                        <label
                                            className="block text-[#404D61] text-sm font-medium mb-2"
                                            htmlFor="grid-password"
                                        >
                                            CVV
                                        </label>
                                        <input
                                            type="number"
                                            className="  border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#B3B3B3] ease-linear transition-all duration-150"
                                            placeholder="xxx"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-[#D9D9D9] border-[#B3B3B3] rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-3 text-sm font-medium text-gray-900 ">I’ve a promo code</label>
                                </div>
                            </div>

                            <div className='pr-16 pt-5'>
                                <div className='flex justify-between my-4'>
                                    <h3 className=' font-medium text-base'>Subtotal</h3>
                                    <h2 className=' font-bold text-base'>$50</h2>
                                </div>
                                <div className='flex justify-between my-4'>
                                    <h3 className=' font-medium text-base'>Platform Fee</h3>
                                    <h2 className=' font-bold text-base'>$50</h2>
                                </div>
                                <hr className="h-px my-2 bg-[#E8E8E8] border-0" />
                                <div className='flex justify-between'>
                                    <h3 className=' font-medium text-base'>Total Amount</h3>
                                    <h2 className=' font-bold text-base'>$50</h2>
                                </div>
                            </div>

                            <div className=' mt-8 '>
                                <button
                                    type="submit"
                                    className="w-full text-white bg-[#48B774] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-4 mr-2 mb-2"
                                >
                                   Make Payment
                                </button>
                            </div>

                        </form>
                    </div>
                    <div className="w-full md:w-4/12 px-4 relative">
                    <div className="  bg-gradient-to-b from-black to-[#034D20] rounded-xl shadow  ">
                    <div className=" pl-5 py-20 text-center">
                        <div className='flex justify-center my-5'>
                        <img src="/images/Dashboard/logo.svg" alt="hero" />
                        </div>
                        <h3 className='text-white font-semibold text-xl'>Secure your Future the <br/> right way today </h3>
                    </div>
                    </div>
                    <div className="  bg-white rounded-xl shadow mx-8 absolute top-60">
                    <div className=" px-5 py-8 text-center">
                        <h4 className='text-sm font-medium'>Type of payment info wil be displayed here 
Installmental or whatever it is </h4>
                    </div>
                    </div>
                    <div className="  bg-[#F5F5F5] rounded-xl shadow  ">
                    <div className=" px-5 py-20 ">
                        <h3 className=' font-semibold text-black text-base'>Please make a choice</h3>
                        <div className='mt-4'>
                                <div className="flex items-center mb-4 ">
                                    <input id="default-checkbox" type="checkbox" value="" checked className="w-4 h-4 accent-green-600 text-white bg-[#D9D9D9] border-[#B3B3B3] rounded-full" />
                                    <label htmlFor="default-checkbox" className="ml-3 text-xs text-[#666666] font-normal">One Time Payment of <span className='font-semibold'>€5,500</span> .</label>
                                </div>
                                <div className="flex items-center mb-4 mt-4">
                                    <input id="default-checkbox" type="checkbox" value="" checked className="w-4 h-4 accent-green-600 text-white bg-[#D9D9D9] border-[#B3B3B3] rounded-full" />
                                    <label htmlFor="default-checkbox" className="ml-3 text-xs text-[#666666] font-normal">Installment payment of <span className='font-semibold'>€1375</span> and subsequent payment of <span className='font-semibold'>€4,125</span></label>
                                </div>
                            </div>
                            <div>
                                <h5 className='text-xs pl-6 text-[#666666] font-normal'>4 Months installment : <span className='font-semibold'>€1,031.25</span> every month</h5>
                                <h5 className='text-xs pl-6 text-[#666666] font-normal'>5 Months installment :  <span className='font-semibold'>€825</span> every month</h5>
                            </div>
                    </div>
                    
                    </div>

                    </div>

                </div>
            </div>
        </>
    )
}
export default PaymentCard