import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { DashboardApi } from "../../apis/DashboardApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AxiosResponse } from "axios";


// components

// import TableDropdown from "components/Dropdowns/TableDropdown.js";

interface datas {
  data: any;
}

export default function Whishlist(data: datas) {


  const [wishlist, setWishList] = React.useState<any>([]);


  React.useEffect(() => {
    DashboardApi.getWishlist().then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setWishList(response?.data?.data)
        }
      }
    )
  }, []);

console?.log(wishlist)

  const UpdateUser = React.useCallback(
    (id: any) => {
      DashboardApi.deleteWishlist(id).then(
        (response: any) => {
          if (response?.data) {
            toast.success(response?.data?.message);

            DashboardApi.getWishlist().then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  console?.log(response?.data?.data)
                  setWishList(response?.data?.data)

                }
              }
            )
            // navigate('/wallet');
            // dispatch(login({ email: userLoginData?.email, token: userLoginData?.token, id: userLoginData?.id, name: response.data.data.name,data:response.data.data}))
          }
        }
      ).catch((err) => {
        if (err?.response?.status == 422) {
          toast.error('Form not rightly filled. Kindly Cross check.');
        } else {
          toast.error('Some error occured.Please try again');
        }


      }).finally(() => {

      });
    },
    [wishlist]
  );

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 border border-gray-200 break-words w-full mb-6 mt-3 md:mt-0 shadow-lg md:rounded rounded-[15px] " +
          "bg-white"
        }
      >
        {/* <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">

          </div>
        </div> */}
        <div className="block w-full overflow-x-auto px-4 py-6">
          <p className="mt-1 ml-2" style={{ fontWeight: 'bold' }}>My Wishlist</p>
          <div className="mt-3 overflow-auto h-40">

            {
              wishlist?.map(
                (datas: any, index: any) => (

                  <div className="flex justify-between my-3 border border-[#D9D9D9] rounded-[10px] p-2 cursor-pointer">
                   {datas?.course_name == 'resturant-and-catering'?
                   <NavLink to={`/vocational-course-details/resturant-and-catering-services`}>
                      <div className="flex justify-start gap-4">
                        <div className="h-[46px] w-[96px] bg-[#D9D9D9] rounded-[10px]"></div>
                        <div className=" ">
                          <h3 className="text-[12px] font-semibold text-[#1DB459]">{datas?.course_name}</h3>
                          <h3 className="text-[12px] font-medium text-[#000000] mt-1">${datas?.course_price}</h3>
                          {/* <p className="text-[10px] text-[#B3B3B3] mt-1">Brief info about the course goes here it could be anything the idea is to fill up this space sho get ? no be like say anything extra dey the middle....................... </p> */}
                        </div>
                      </div>
                    </NavLink>
                  :
                  <NavLink to={`/vocational-course-details/${datas?.course_id}`}>
                  <div className="flex justify-start gap-4">
                    <div className="h-[46px] w-[96px] bg-[#D9D9D9] rounded-[10px]"></div>
                    <div className=" ">
                      <h3 className="text-[12px] font-semibold text-[#1DB459]">{datas?.course_name}</h3>
                      <h3 className="text-[12px] font-medium text-[#000000] mt-1">${datas?.course_price}</h3>
                      {/* <p className="text-[10px] text-[#B3B3B3] mt-1">Brief info about the course goes here it could be anything the idea is to fill up this space sho get ? no be like say anything extra dey the middle....................... </p> */}
                    </div>
                  </div>
                </NavLink>
                  }
                   

                    <span className="mt-2 cursor-pointer mr-2" onClick={() => UpdateUser(datas?.id)}>X</span>

                  </div>

                ))
            }

          </div>



        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}

Whishlist.defaultProps = {
  color: "light",
};

Whishlist.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
