import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import CardSettings from './CardSettings'
import UserNavbar from "../Navbars/UserNavbar"
import ProfileNav from './ProfileNav'


function PersonalInfo() {
    return (
        <>
<Sidebar/>
<div className="relative md:ml-64 bg-blueGray-100">
<UserNavbar title="Profile" />
{/* Profile header */}
<div className='md:py-16'>
<ProfileNav/>
     <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl md:px-16 pl-3 pr-4">
            <div>
              <h1 className='text-[#404D61] text-2xl'>Profile</h1>
              <p className='text-[#757D8A] text-sm mt-2'>Enter the required information below to register. You can change it anytime you want.</p>
            </div>
            <div className='mr-16 mt-5 md:mt-0'>
              <h1 className='text-center text-[#404D61] text-lg'>Profile Photo</h1>
              <div className="relative flex justify-center mt-3">
                <img className="w-full rounded-full" src="/images/Dashboard/Userpic.svg" alt="" />
                <span className="bottom-0 md:right-1 right-16 absolute    rounded-full">
                  <img className="w-full rounded-full" src="/images/Dashboard/Edit.svg" alt="" />
                </span>
              </div>
            </div>
          </div>

          
          {/* Personal info */}
          <div>
            {/* <CardSettings /> */}
            <CardSettings/>
          </div>
</div>

</div>
     
        </>
    )
}

export default PersonalInfo