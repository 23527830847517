import React from "react";

import Navbar from "./../Navbar";
import image from "../../img/waitlist.svg";


function JoinWaitList() {
    return (
        <>
        <Navbar/>
        {/* <!-- Start block --> */}   
      <section className="bg-[#FBFBFB] body-font font-poppins text-center lg:text-left pt-28">
        <div className="text-center">
        <h3 className="mt-5 text-[#1DB459] font-bold">Join Our Waitlist</h3>
        </div>
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "",
          }}
          className="lg:px-14 pt-10 pb-24 mx-auto relative "
        >
            
            <div className=" mx-auto">
                
                <h1 className="text-center text-4xl font-bold">Our Edtech Platform</h1>
                <h1 className="text-center text-4xl font-bold">is <span className="text-[#48B774]">launching</span> soon.</h1>
                <p className="mt-4 text-center text-[#858585]">Register now and get <span className="text-[#48B774] font-bold">10%</span> waiting list discount offer.</p>
            </div>
            <div className="mt-36 text-center">
                <h3 className="font-bold text-base">Join Timothy, Ope, Angela and 2,123 others on the waitlist.</h3>
            </div>
            <div className="lg:block inline-block mt-10">
            <form className="flex justify-center items-center">
                <div className="lg:w-[30%]">
                  <input
                    type="email"
                    id="simple-search"
                    className="bg-gray-50 border border-[#D9D9D9] text-[#333333] text-xs w-full rounded-md block pl-3 p-3.5 "
                    placeholder="Enter your mail"
                    
                    required
                  />
                  
                </div>
                <div className="">
                <button
                    type="submit"
                    className="py-3 px-6 ml-5 text-xs font-medium text-white bg-[#48B774] rounded-md border border-[#48B774] hover:bg-[#05401C] "
                  >
                    Request Access
                  </button>
                </div>
                
              </form>
            </div>
        </div>
        </section>
        {/* <!-- End block --> */}  
        </>
    );
}

export default JoinWaitList;