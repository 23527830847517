import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar'
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import hero from "../../img/new1.png";
import { AuthApis } from '../../apis/authApis';
import { DashboardApi } from '../../apis/DashboardApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function WinterCampForm() {


    const [userData, setUserdata] = React.useState({
        'full_name': "",
        'student_nickname': "",
        'email': "",
        'phone_number': "",
        'date_of_birth': "",
        'you_are': "",
        'student_eng_level': "",
        'city_and_country': "",
        'arrival_date': "",
        'leaving_date': "",
        'student_citizenship': "",
        'passport_number': "",
        'home_address': "",
        'estimated_process_time_for_visa': "",
        'food_allergies': "",
    });

    const navigate = useNavigate();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUserdata({ ...userData, [name]: value });
    };

    const handleSubmit = React.useCallback(
        (e: any) => {
            // setLoader(true)
            e.preventDefault();
            const userData2 = new FormData();
            userData2.append('full_name', userData?.full_name);
            userData2.append('date_of_birth', userData?.date_of_birth);
            userData2.append('student_nickname', userData?.student_nickname);
            userData2.append('date_of_birth', userData?.date_of_birth);
            userData2.append('arrival_date', userData?.arrival_date);
            userData2.append('leaving_date', userData?.leaving_date);
            userData2.append('phone_number', userData?.phone_number);
            userData2.append('email', userData?.email);
            userData2.append('home_address', userData?.home_address);
            userData2.append('food_allergies', userData?.food_allergies);
            userData2.append('city_and_country', userData?.city_and_country);
            userData2.append('you_are', userData?.you_are);
            userData2.append('passport_number', userData?.passport_number);
            userData2.append('student_citizenship', userData?.student_citizenship);
            userData2.append('student_eng_level', userData?.student_eng_level);
            userData2.append('estimated_process_time_for_visa', userData?.estimated_process_time_for_visa);


            AuthApis.createWinterProgram(userData2).then(
                (response: any) => {
                    if (response?.data) {
                        toast.success(response?.data?.message);
                        console?.log(response?.data)
                        // setLoader(false)

                        // setRecall('response?.data?.message')
                        // navigate('/wallet');
                        // dispatch(login({ email: userLoginData?.email, token: userLoginData?.token, id: userLoginData?.id, name: response.data.data.name,data:response.data.data}))
                    }
                }
            ).catch((err) => {
                if (err?.response?.status == 422) {
                    toast.error('Form not rightly filled. Kindly Cross check.');
                } else {
                    toast.error('Some error occured.Please try again');
                }


            }).finally(() => {

            });
        },
        [userData]
    );
    return (
        <>
            <Navbar />

            {/* Start Section */}
            <section className='body-font font-poppins md:pt-20 relative'>
                <div

                    className='  pt-20 md:pt-0  flex justify-center items-center'
                >
                    <div className='w-full'>
                        <img src="/images/winter-application-form2.jpeg" alt="hero" className=' border-[#1DB459] border-b-8 object-fit' />
                    </div>

                </div>
            </section>
            {/* End Section */}

            {/* Start Section */}
            <div className='bg-[#FBFBFB] sm:px-16 px-6 md:flex md:justify-center items-start pt-10 pb-8' >
                <div className='xl:max-w-[1200px] w-full mx-auto'>
                    <div className=''>
                        <p className='md:text-[16px] text-[14px]'>This form is for the registration of students who would like to join the Winter Camp in Finland in 2024 please reach out to us via<br /> WhatsApp or place direct call to +2347088914669 , or via email at <a href="mailto:hello@afriproedu.com" className='text-[#1DB459] underline underline-offset-2'>hello@afriproedu.com</a></p>
                        <p className='md:text-[16px] text-[14px] mt-[12px]'> The Winter Camp is a program designed to offer international students the opportunity to explore Finland - “The Country of Happiness" - up close and see if this is the right country for them to study in the near future.
                        </p>

                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='bg-white mt-[70px] p-6 border border-[#C4C4C4] rounded-[20px]'>
                            <div className='flex justify-end md:px-4 mb-8'>
                                <h5 className='md:text-[16px] text-[14px]'><span className='text-[#EE1D52] font-bold'>*</span>indicates a required field</h5>
                            </div>
                            {/* <span className='bg-[#1DB459] text-white md:text-[20px] text-[16px] font-semibold md:ml-4 mb-[40px] mt-[10px] pl-3 pr-12 py-3 rounded-[5px]'>Personal Information</span> */}
                            <div className='grid grid-cols-1 gap-3 md:grid-cols-2 mt-[40px]'>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Student's Full Name<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <p className="mb-2 text-[12px]">Capitalize the first letters of each name. E.g: John Pattison (EN) - Nguyen Minh Triet (VN)</p>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Full Name"
                                        name="full_name"
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Student's Email<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <p className="mb-2 text-[12px]">Student will use this email to receive notifications about their registration</p>
                                    <input
                                        type="email"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Email"
                                        name="email"
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Student's Nickname<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <p className="mb-2 text-[12px]">The name that the student would like to use in daily communication</p>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="student_nickname"
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Phone Number<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <p className="mb-2 text-[12px]">With country code</p>
                                    <input
                                        type="number"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Phone No"
                                        name="phone_number"

                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Date of Birth<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="date"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="MM/DD/YYYY"
                                        name="date_of_birth"
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label
                                        htmlFor="you_are"
                                        className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                    >
                                        You are...<span className='text-[#EE1D52] font-bold'> *</span>
                                    </label>
                                    <select
                                        id="you_are"
                                        name="you_are"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                    >
                                        <option selected></option>
                                        <option value="student">Student</option>
                                        {/* <option value="male">Finest Future's Agency</option> */}
                                        <option value="parent">Parent</option>
                                        {/* <option value="male">Finest Future</option> */}
                                    </select>
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label
                                        htmlFor="student_eng_level"
                                        className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                    >
                                        What is the Student's English language level?<span className='text-[#EE1D52] font-bold'> *</span>
                                    </label>
                                    <select
                                        id="student_eng_level"
                                        name="student_eng_level"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                    >
                                        <option selected></option>
                                        <option value="female">They can understand enough to follow instructions in English</option>
                                        <option value="male">They can speak enough to express their needs in English</option>
                                        <option value="male">They can hold conversations with ease in English</option>

                                    </select>
                                </div>

                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        The city + country where student will apply for the visa<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="city_and_country"
                                        required
                                    />
                                </div>

                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        On which date does the student plan to ARRIVE in Finland?<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <p className="mb-2 text-[12px]">The Winter Camp will start on 25.03.2024.</p>
                                    <input
                                        type="date"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="MM/DD/YYYY"
                                        name="arrival_date"
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        On which date does the student plan to LEAVE Finland?<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <p className="mb-2 text-[12px]">The Winter Camp will end on 03.04.2024.</p>
                                    <input
                                        type="date"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="MM/DD/YYYY"
                                        name="leaving_date"
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Country of student's citizenship / passport that student will use for travelling to Finland<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="student_citizenship"
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Student's Passport Number<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="passport_number"
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Home Address in current location<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <p className="mb-2 text-[12px]">Full address: Apartment number, Building number, Street name, street number, city, postal code, country.</p>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="home_address"
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Estimated processing time of your tourist visa in the country where you apply?<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <p className="mb-2 text-[12px]">Example: 1 week, 2 weeks, 1 month, etc. Write "I don't know" if you don't have an estimate.</p>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="estimated_process_time_for_visa"

                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Food allergies, special diet requirements, food wishes<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <p className="mb-2 text-[12px]">Write NO if you don't have any special details about food preferences</p>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="food_allergies"

                                        required
                                    />
                                </div>

                            </div>





                            {/* <div className='mt-[20px] md:px-4'>
                                <h3 className='text-[14px] font-semibold'>For more information about how we use your data, please read our <NavLink to={"/terms-condition"} className='text-[#1DB459] underline underline-offset-2'>privacy notice.</NavLink></h3>
                            </div> */}

                            <div className='md:px-4 mt-[50px] flex justify-center'>
                                <button
                                    type="submit"
                                    className=" text-white bg-[#1DB459] hover:bg-[#05401C] rounded-full font-medium text-sm px-6 py-3 mr-2 mb-2"

                                >
                                    Submit
                                </button>
                            </div>


                        </div>


                    </form>


                </div>


            </div>
            {/* End Section */}

            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default WinterCampForm