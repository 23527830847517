import React from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import { SvgElement, icontypesEnum } from '../../assets/svgElement'
import { useParams } from 'react-router-dom';
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ViewUserDetails() {

  const params: any = useParams();
  let userId: string = params?.id;
  const [userData, setUserData] = React.useState<any>([]);

  const [program, setProgram] = React.useState<any>('');

  React.useEffect(() => {
    AdminApis.getUserDetailsById(userId).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserData(response?.data?.data)
        }
      }
    );

  }, []);

  const UpdateUser = React.useCallback(
    (e: any) => {
      e.preventDefault();
      const userData2 = new FormData();
      userData2.append('programs_type', (program !== '') ? program : userData?.programs_type);
      userData2.append('id', userData?.id);
      AdminApis.updateProgram(userData2).then(
        (response: any) => {
          if (response?.data) {
            toast.success("Updated Successfully");
            // console?.log(response?.data?.data)
            // navigate('/wallet');
            // dispatch(login({ email: userLoginData?.email, token: userLoginData?.token, id: userLoginData?.id, name: response.data.data.name,data:response.data.data}))
          }
        }
      ).catch((err) => {
        if (err?.response?.status == 422) {
          // toast.error('Form not rightly filled. Kindly Cross check.');
        } else {
          // toast.error('Some error occured.Please try again');
        }


      }).finally(() => {

      });
    },
    [program]
  );



  return (
    <>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />

        <div className='bg-[rgb(212,250,227)] lg:mt-4 lg:px-16 px-8 pt-10 pb-10'>

          <div className='flex justify-end   '>
            <div className='bg-[#E21E32] px-2 py-2 rounded '>
              <SvgElement type={icontypesEnum.DELETETWO} />
            </div>
          </div>

          <div className='flex justify-start md:flex-row flex-col'>
            <div className='pr-10 max-w-sm'>
              {/* <SvgElement type={icontypesEnum.DEFAULTIMAGE} /> */}
              <img src={userData?.profile_picture} alt="profile" className="rounded-full w-[80px] h-[80px]" />
            </div>
            <div>

              <div className='flex justify-between '>
                <h3 className='font-bold text-xl capitalize'>{userData?.name + ' ' + userData?.last_name}</h3>

              </div>

              <div className="flex flex-col lg:flex-row lg:space-x-4 mt-5">

                <div className='flex space-x-2'>
                  <SvgElement type={icontypesEnum.LOCATION} />
                  <h4>{userData?.address}</h4>
                </div>

                <div className='flex space-x-2 mt-3 lg:mt-0'>
                  <SvgElement type={icontypesEnum.PHONE} />
                  <h4>{userData?.phone_number}</h4>
                </div>
                <div className='flex space-x-2 mt-3 lg:mt-0'>
                  <SvgElement type={icontypesEnum.MAIL} />
                  <h4>{userData?.email}</h4>
                </div>
              </div>






              <div className='flex flex-col lg:flex-row lg:space-x-5 mt-6'>
                <div >
                  <button
                    type="button"
                    className="ml-5 text-[#ffffff] bg-[#1DB459]  font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2"
                      fill="currentColor" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M20 17h2v2H2v-2h2v-7a8 8 0 1 1 16 0v7Zm-2 0v-7a6 6 0 0 0-12 0v7h12Zm-9 4h6v2H9v-2Z" /></svg>


                    Follow

                  </button>
                </div>

                <div className='mt-3 lg:mt-0'>
                  <button
                    type="button"
                    className="ml-5 text-[#1DB459] border border-[#1DB459]  font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    Restrict Account

                  </button>
                </div>

                <div className='mt-3 lg:mt-0'>
                  <button
                    type="button"
                    className="ml-5 text-[#000000] bg-[#D9D9D9]  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                  >
                    View Loan Details

                  </button>
                </div>

                <div className='flex justify-end mt-10' >
                  <h3>Member ID: {userData?.user_id}</h3>
                </div>

              </div>

            </div>
          </div>

        </div>

        {/* <div>
 

<div className='rounded-lg divide-x divide-gray-200 flex justify-center w-[50%] border border-[#1DB459]'>
  <div className='px-4 py-3'>Education</div>
  <div className='px-4 py-3'>Document</div>
  <div className='px-4 py-3'>Prefrence</div>
</div>


          </div> */}

        <div className='lg:px-16 px-8 pt-10 pb-6'>
          <h3 className='font-bold text-xl'>Interested-in-Course</h3>
          <div className='rounded-lg border border-[#D9D9D9] lg:w-[50%] py-3 pl-4 mt-2'>
            <h3 className=' font-medium capitalize'>{userData?.programs_type}</h3>
          </div>
          <form onSubmit={UpdateUser}>
            <div className="w-full lg:w-6/12 px-4 md:px-1 mt-5">
              <div className="relative w-full mb-6">
                <label
                  htmlFor="gender"
                  className="block text-[#404D61] text-sm  font-medium mb-2 "
                >
                  Programs Type ({userData?.programs_type})
                </label>
                <select
                  id="gender"
                  name="programs_type"
                  onChange={(e) => { setProgram(e.target.value) }}
                  className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg  block w-full px-2.5 py-3"
                >
                  <option selected>{userData?.programs_type}</option>
                  <option value="study in finnish">study in Finnish</option>
                  <option value="study in english">study in English</option>
                
                </select>
              </div>

              <div className='px-4 mt-5'>
                <button
                  type="submit"
                  className=" text-white bg-[#1DB459] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                >
                  Update
                </button>
              </div>
            </div>
          </form>


        </div>

      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default ViewUserDetails