import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react'
import React, { Fragment, useState } from 'react'

const CareerEduFaq = () => {
    const [open, setOpen] = useState(1);
    const [openTestTab, setOpenTestTab] = useState(1);
  
    const handleOpen = (value: any) => {
      setOpen(open === value ? 0 : value);
    };
  return (
    <>

    {/* <!-- Start block --> */}
    <section className="bg-[#FFFFFF] body-font font-poppins pt-10">
      <div className="mx-auto flex justify-center text-center">
        <p className="hidden md:block text-[#000000] max-w-lg  md:text-3xl px-6 font-semibold text-center">
          Frequently Asked <span className="text-[#48B774]">Questions on CAREER EDU</span>
        </p>
        <p className="block md:hidden text-[#000000]   text-2xl px-6 font-semibold text-center">
          Frequently Asked <span className="text-[#48B774]"><br/>Questions on CAREER EDU</span>
        </p>
      </div>
      
      <div className="flex justify-center max-w-screen-xl  px-8 lg:px-14 pt-5 md:pt-16 pb-8 mx-auto  ">
     


        <div className=" lg:mb-20 max-w-3xl">


          {/* <div className={openTestTab === 1 ? "block" : "hidden"}> */}
            <Fragment>
              <Accordion open={open === 1} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                <AccordionHeader onClick={() => handleOpen(1)} className='border border-white text-base font-medium text-[#170F49]'>
                What is CAREER EDU?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                CAREER EDU is an innovative online program that combines Finnish language learning with vocational education. It equips you with the skills and qualifications to pursue further education and career opportunities in Finland and Europe.
                </AccordionBody>
              </Accordion>

              <Accordion open={open === 2} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                <AccordionHeader onClick={() => handleOpen(2)} className='border border-white text-base font-medium text-[#170F49]'>
                Is this program right for me?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  <h4>This program is ideal for individuals who:</h4>
                  <ol className="space-y-1 list-disc list-inside">
                    <li>Want to learn Finnish and gain a vocational qualification.</li>
                    <li>Aim to study in Finland or Europe.</li>
                    <li>Seek career advancement in Europe.</li>
                    <li>Prefer a flexible online learning format.</li>
                 
                  </ol>        
                </AccordionBody>
              </Accordion>

              <Accordion open={open === 3} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                <AccordionHeader onClick={() => handleOpen(3)} className='border border-white text-base font-medium text-[#170F49]'>
                What are the benefits of participating in this program?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  <ol className="space-y-1 list-disc list-inside">
                    <li>Mastering Finnish language skills.</li>
                    <li>Earning a recognized Further Vocational Qualification Education (FVQE) certificate.</li>
                    <li>Eligibility to apply to various educational institutions across Finland and Europe.</li>
                    <li>Potential to study for free in Finnish or with reduced program duration in English.</li>
                    <li>Career guidance and support.</li>
                    <li>Access to immediate work opportunities upon arrival in Finland (depending on your field).</li>
                 
                  </ol>         
                </AccordionBody>
       
              </Accordion>

              <Accordion open={open === 4} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                <AccordionHeader onClick={() => handleOpen(4)} className='border border-white text-base font-medium text-[#170F49]'>
                How long is the program?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                The program duration is 9 months.
                </AccordionBody>
              </Accordion>

              <Accordion open={open === 5} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                <AccordionHeader onClick={() => handleOpen(5)} className='border border-white text-base font-medium text-[#170F49]'>
                What is the weekly time commitment?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                The program requires a weekly commitment of 9 hours for online sessions and assignments.
                </AccordionBody>
              </Accordion>

              <Accordion open={open === 6} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                <AccordionHeader onClick={() => handleOpen(6)} className='border border-white text-base font-medium text-[#170F49]'>
                What format does the program follow?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                The program is delivered online with convenient 75-minute sessions held Monday to Thursday.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 7} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                <AccordionHeader onClick={() => handleOpen(7)} className='border border-white text-base font-medium text-[#170F49]'>
                What is the learning approach?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                CAREER EDU offers a unique blend of Finnish language instruction and vocational education, allowing you to gain practical skills simultaneously.

                </AccordionBody>
              </Accordion>
              <Accordion open={open === 8} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                <AccordionHeader onClick={() => handleOpen(8)} className='border border-white text-base font-medium text-[#170F49]'>
                What qualification will I receive?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                Upon successful completion, you will receive a Certificate in Further Vocational Qualification Education (FVQE).
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 9} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                <AccordionHeader onClick={() => handleOpen(9)} className='border border-white text-base font-medium text-[#170F49]'>
                Where can I use this qualification?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                The FVQE certificate allows you to apply for admission to vocational schools, Universities of Applied Sciences, and universities across Finland and Europe.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 10} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                <AccordionHeader onClick={() => handleOpen(10)} className='border border-white text-base font-medium text-[#170F49]'>
                Can I study for free?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                Yes, you can choose to study for free in Finnish at universities in Finland and Europe. Alternatively, you can opt for English-language programs with reduced program duration (6-12 months) but with tuition fees.
                </AccordionBody>
              </Accordion>

              <Accordion open={open === 11} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                <AccordionHeader onClick={() => handleOpen(11)} className='border border-white text-base font-medium text-[#170F49]'>
                How much does the program cost?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                The total program fee is €9000, with a convenient payment plan of 5 installments.
                </AccordionBody>
              </Accordion>

              <Accordion open={open === 12} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                <AccordionHeader onClick={() => handleOpen(12)} className='border border-white text-base font-medium text-[#170F49]'>
                What level of Finnish will I achieve by the end of the program?

                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                The program aims to equip you with Finnish language proficiency at the A2.2 level on the Common European Framework of Reference for Languages (CEFR).
                </AccordionBody>
              </Accordion>

              <Accordion open={open === 13} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                <AccordionHeader onClick={() => handleOpen(13)} className='border border-white text-base font-medium text-[#170F49]'>
                Is there any support for learning Finnish?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                Yes, the program includes career guidance to help you reach the A2.2 proficiency level in Finnish.
                </AccordionBody>
              </Accordion>
            </Fragment>
          {/* </div> */}


      
        </div>
      </div>
    </section>
    
    {/* <!-- End block --> */}

      </>
  )
}

export default CareerEduFaq
