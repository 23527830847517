import React, { useState } from 'react'
import Navbar from '../../Navbar'
import blogbg from "../../../img/blog.png";
import Footer from '../../Footer';
import { NavLink } from 'react-router-dom';
// @ts-ignore
import AnchorLink from "react-anchor-link-smooth-scroll";



function StudingInFinland() {


    return (
        <>
            <Navbar />
            <section className=" body-font font-poppins pt-16" >
                <div
                    style={{
                        backgroundImage: `url(${blogbg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className=" lg:px-14 pt-10 pb-24 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center text-white pt-20">
                        <h1 className="md:text-5xl text-3xl font-bold">
                            Studying in Finland: Ultimate <br />Guide For African Students
                        </h1>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-[#FBFBFB] body-font font-poppins">
                <div className='bg-white lg:mx-28 pb-10 lg:px-10 px-8 pt-10'>
                    <h3 className='text-base text-[#000000] font-normal'>Do you know that Finland’s education system now makes studying in Finland easier than ever for African students? Yes! Whether you’re considering running your post-graduate or undergraduate programme in Finland, the country has the leading education structure in the world to accommodate you.
                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-6'>
                        There’s no other reason why more than 14,000 African students are currently studying in Finland. You could be the next to gain admission into any of Finland’s leading universities. Keep reading this article to find out how!
                    </h3>

                    <h2 className="font-bold text-2xl mt-5">How Studying In Finland Works</h2>
                    <ul className="space-y-1 text-black list-disc list-inside pl-4 mt-1">

                        <AnchorLink href='#higher_education_in_finland'>
                            <li>
                                Overview of Higher Education in Finland
                            </li>
                        </AnchorLink>

                        <AnchorLink href='#facts_about_finland'>
                            <li>
                                Facts about Finland
                            </li>
                        </AnchorLink>
                        <AnchorLink href="#education_system_in_finland">
                            <li>
                                The Education System in Finland
                            </li>
                        </AnchorLink>
                        <AnchorLink href="#living_in_finland">
                            <li>
                                Living in Finland As an African Student
                            </li>
                        </AnchorLink>
                        <AnchorLink href="#culture_and_the_lang_in_finland">
                            <li>
                                Culture and The Language in Finland
                            </li>
                        </AnchorLink>
                        <AnchorLink href="#student_application_process">
                            <li>
                                Student Application Process
                            </li>
                        </AnchorLink>
                        <AnchorLink href="#actual_cost_of_studying_in_finland">
                            <li>
                                Actual Cost of Studying in Finland as an African Student
                            </li>
                        </AnchorLink>
                        <AnchorLink href="#list_of_top_uni_in_finland">
                            <li>
                                List of Top Universities in Finland
                            </li>
                        </AnchorLink>
                        <AnchorLink href="#how_does_afripro_come_in_this_picture">
                            <li>
                                How does AfriProEdu Come In This Picture?
                            </li>
                        </AnchorLink>

                        <AnchorLink href="#conclusion">
                            <li>
                                Conclusion
                            </li>
                        </AnchorLink>

                    </ul>
                    <div id='higher_education_in_finland' className='mt-10'>
                        <h1 className='font-bold text-2xl mt-6'>Overview of Higher Education in Finland</h1>
                        <h3 className='text-base text-[#000000] font-normal mt-6'>
                            To understand how studying in Finland works, know the overview of higher education in the country.
                        </h3>
                        <h3 className='text-base text-[#000000] font-normal mt-4'>
                            Higher education in Finland is categorised under two similar sectors: universities and universities of applied sciences. Narrow your selection to Universities of applied sciences if your course of study falls to any life needs.
                        </h3>
                        <h3 className='text-base text-[#000000] font-normal mt-4'>
                            However, consider the second category, Universities, if your course entails scientific research and its education of it. For Universities of applied sciences, choose one out of the 23 of them.
                        </h3>
                    </div>

                    <div id='facts_about_finland'>
                        <h1 className='font-bold text-2xl mt-6'>Facts about Finland</h1>
                        <h3 className='text-base text-[#000000] font-normal mt-6'>
                            To get the best out of studying in Finland, understand some basic facts about the country.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-4'>
                            Finland continues to list on the UN’s World Happiness Report. Hence, it is the world’s happiest country. The ranking is based on the country’s generosity, life expectancy, economic strength, freedom, and so on.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-4'>
                            Finland is not overpopulated—<span className=' font-medium'>about 5.5 million</span>—and it houses the world’s biggest coffee drinkers. If you love coffee, Finland is a place to be. The county has more than 187888 lakes. </h3>
                    </div>

                    <div id='education_system_in_finland'>
                        <h1 className='font-bold text-2xl mt-6'>The Education System in Finland</h1>
                        <h3 className='text-base text-[#000000] font-normal mt-5'>
                            To make your studying in Finland full of fun, know how the country’s education system runs. Finland’s education system is divided into different categories:</h3>
                        <ul className="space-y-1 text-black list-disc list-inside pl-4 mt-1">
                            <li>
                                Early childhood education
                            </li>
                            <li>
                                Pre-primary education
                            </li>
                            <li>
                                Comprehensive school education—compulsory and ends at the age of 18
                            </li>
                            <li>
                                Vocational upper secondary school education
                            </li>
                            <li>
                                Post-secondary school education
                            </li>
                            <li>
                                Adult education, and higher education system.
                            </li>
                        </ul>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>Read: <NavLink to="/vocational-school" className="font-medium text-[#48B774]">Vocational School in Finland: Definitive Insight for Africans</NavLink></h3>
                        <h3 className='text-base text-[#000000] font-normal mt-4'>
                            Just as mentioned earlier, the higher education system has two parallel categories: universities and universities of applied sciences. Both “Universities” and “Universities of applied sciences” award Bachelor’s and Master’s degrees.</h3>
                    </div>

                    <div id='living_in_finland'>
                        <h1 className='font-bold text-2xl mt-6'>Living in Finland As an African Student</h1>
                        <h3 className='text-base text-[#000000] font-normal mt-6'>
                            To really understand how studying in Finland works, know what it means to live in the country as a student. Life is good in Finland. All of Finland’s universities run a student guilds system. Irrespective of your course of study, you enjoy a student guild and are incorporated into an organisation.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            The goal is to ensure you’re not alone but get all the help that you need.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            Participate in career-oriented activities to enhance your professionalism and development. Enjoy camps, excursions, and other interesting activities from different guilds.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            Feel free to enjoy your stay and study to the fullest. Get coffee at extremely discounted prices.</h3>
                    </div>

                    <div id='culture_and_the_lang_in_finland'>
                        <h1 className='font-bold text-2xl mt-6'>Culture and The Language in Finland</h1>
                        <h3 className='text-base text-[#000000] font-normal mt-6'>
                            Studying in Finland is fun is you know the country’s culture and language. Swedish is the official language of Finland. However, only 6% of Finns speak Swedish.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            The majority of the population that lives southwest of the country speaks Finnish. In the north of Finland—up to 2,000 people—the Sami language is paramount.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            Finns are Egalitarians. They use gender-neutral words. Also, in humility and modesty, they don’t exaggerate or overrate their accomplishments. Finns talk in moderate tones and do not believe in calling attention to oneself.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            They are serial communicators who listen first to the speaker, allow the speaker to finish, and then make their contribution. Cutting someone while speaking in Finland is extremely rude. Don’t do it!</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            When Finns meet, they shake hands, smile, and maintain eye contact. Greet the wife first when you greet married couples and repeat your first and surname when shaking hands.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            Don’t visit a Finnish home without a gift—flowers, wine, etc. Note, it is rude to give flowers in even numbers and avoid white and yellow flowers because they use them in funerals.</h3>
                    </div>

                    <div id='student_application_process'>
                        <h1 className='font-bold text-2xl mt-6'>Student Application Process</h1>
                        <h3 className='text-base text-[#000000] font-normal mt-6'>
                            To study in Finland, know the student application process. Find the best programme for you—use this <NavLink to="http://www.studyinfo.fi/konfo/en" className="font-medium text-[#48B774]">tool</NavLink> to find the best programme you want. Alternatively, check your aspiring universities’ <NavLink to="https://www.studyinfinland.fi/universities-list-view" className="font-medium text-[#48B774]">information portal</NavLink> for available courses.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            Look out for the admission information. Find facts like the application process and deadlines, eligibility criteria, entrance exam—where applicable, and more. See a full list of the universities’ admission info <NavLink to="https://www.studyinfinland.fi/universities-list-view" className="font-medium text-[#48B774]">here.</NavLink></h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            Then, apply to programs and scholarships. Follow up with your preferred university to know when to apply. Wait for the university you apply to send you an admission notice. The letter contains further steps to see to study in Finland.</h3>
                    </div>

                    <div id='actual_cost_of_studying_in_finland'>
                        <h1 className='font-bold text-2xl mt-6'>Actual Cost of Studying in Finland as an African Student</h1>
                        <h3 className='text-base text-[#000000] font-normal mt-6'>
                            To explore everything about studying in Finland, know the actual cost of studying in the country. Education is free for EU/EEA in Finland. For African and other international students, the fees are anything between 1500 euros to 25000 euros per year. Explore scholarships and discount opportunities for international students.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            For accommodation, pay less living in the university’s student halls of residence. If you stay off the student halls of residence, budget 450 to 1200 euros for a self-apartment and 350 to 450 euros for a shared apartment.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            The cost of food is a factor in the city you live in. Restaurant meals range between €10 to €12. Budget at least €200 to €250 on food per month.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            Use the converter below to get the idea in Naira.</h3>
                        <NavLink to={"https://www.ngnrates.com/"} className="text-sm text-[#48B774] mt-3">Daily Naira Rates on NgnRates.com</NavLink>
                    </div>

                    <div id='list_of_top_uni_in_finland'>
                        <h1 className='font-bold text-2xl mt-6'>List of Top Universities in Finland</h1>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            To get the best studying in Finland, you need to actually know the top universities in the country.</h3>
                        <div className='flex justify-center mt-8'>
                            <div className="relative overflow-x-auto">
                                <table className="w-full text-sm text-left text-gray-500 border-1 border-white rounded-md">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                S/N
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                University
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                QS Ranking 2022
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                The Ranking 2022
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                ARWU Ranking 2021
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="bg-white border-b">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                1
                                            </th>
                                            <td className="px-6 py-4">
                                                University of Helsinki
                                            </td>
                                            <td className="px-6 py-4">
                                                104
                                            </td>
                                            <td className="px-6 py-4">
                                                101
                                            </td>
                                            <td className="px-6 py-4">
                                                82
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b ">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                2
                                            </th>
                                            <td className="px-6 py-4">
                                                Alto University
                                            </td>
                                            <td className="px-6 py-4">
                                                112
                                            </td>
                                            <td className="px-6 py-4">
                                                201
                                            </td>
                                            <td className="px-6 py-4">
                                                301
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b ">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                3
                                            </th>
                                            <td className="px-6 py-4">
                                                University of Turku
                                            </td>
                                            <td className="px-6 py-4">
                                                295
                                            </td>
                                            <td className="px-6 py-4">
                                                351
                                            </td>
                                            <td className="px-6 py-4">
                                                301
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b ">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                4
                                            </th>
                                            <td className="px-6 py-4">
                                                University of jyväskylä
                                            </td>
                                            <td className="px-6 py-4">
                                                358
                                            </td>
                                            <td className="px-6 py-4">
                                                352
                                            </td>
                                            <td className="px-6 py-4">
                                                701
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b ">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                5
                                            </th>
                                            <td className="px-6 py-4">
                                                University of Oulu
                                            </td>
                                            <td className="px-6 py-4">
                                                377
                                            </td>
                                            <td className="px-6 py-4">
                                                252
                                            </td>
                                            <td className="px-6 py-4">
                                                401
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b ">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                6
                                            </th>
                                            <td className="px-6 py-4">
                                                Lappeenranta University of Technology
                                            </td>
                                            <td className="px-6 py-4">
                                                414
                                            </td>
                                            <td className="px-6 py-4">
                                                252
                                            </td>
                                            <td className="px-6 py-4">
                                                -
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b ">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                7
                                            </th>
                                            <td className="px-6 py-4">
                                                Tampere University
                                            </td>
                                            <td className="px-6 py-4">
                                                414
                                            </td>
                                            <td className="px-6 py-4">
                                                251
                                            </td>
                                            <td className="px-6 py-4">
                                                501
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b ">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                8
                                            </th>
                                            <td className="px-6 py-4">
                                                Åbo Akademi University
                                            </td>
                                            <td className="px-6 py-4">
                                                602
                                            </td>
                                            <td className="px-6 py-4">
                                                401
                                            </td>
                                            <td className="px-6 py-4">
                                                -
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b ">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                9
                                            </th>
                                            <td className="px-6 py-4">
                                                University of Vaasa
                                            </td>
                                            <td className="px-6 py-4">
                                                -
                                            </td>
                                            <td className="px-6 py-4">
                                                -
                                            </td>
                                            <td className="px-6 py-4">
                                                -
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                    <div id='how_does_afripro_come_in_this_picture'>
                        <h1 className='font-bold text-2xl mt-8'>How does AfriProEdu Come In This Picture?</h1>
                        {/* <h3 className='text-base text-[#000000] font-normal mt-5'>
                            Studying at the High School in the excellent education of Finland is the dream of so many African international students who are seeking an authentically high-quality education of the world.
                        </h3> */}
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            Their desirable dream will come to reality with the <NavLink to="/high-school" className="font-medium text-[#48B774]">Finland Program</NavLink> by Finest Future. Here is where they start the academic journey to Finland from an early age.
                        </h3>
                        {/* <h3 className='text-base text-[#000000] font-normal mt-2'>
                            The program brings all students opportunities to study high school abroad in Finland, home of 380 high schools with equally top quality. Many of them offer a particular pathway to universities in Finland, which rank among top-world universities.
                        </h3> */}
                    </div>

                    <div id="conclusion">
                        <h1 className='font-bold text-2xl mt-6'>Conclusion
                        </h1>
                        <h3 className='text-base text-[#000000] font-normal mt-6'>
                            Finland is home to international students who are curious about studying in Finland. All you have to do is to know the overview of higher education in Finland, facts about Finland, and the country’s education system.
                        </h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>Find out about living in Finland as a student and get familiar with the country’s culture, etiquette, and language.</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>Then apply and estimate the actual cost of studying in Finland as an African Student. Apply to a university and try it out.</h3>
                    </div>
                </div>

            </section>
            {/* <!-- End block --> */}

            <Footer />
        </>
    )
}

export default StudingInFinland