import React from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar';
import UserNavbar from '../../Navbars/UserNavbar'
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";
import animationData from "../../assets/message.json"

function Message() {
    return (
        <>
            <AdminSidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar />

                <div className=" lg:w-12/12 px-4 md:pt-16 py-4">
                    <div className="flex justify-center">
                        <Lottie animationData={animationData} className='md:w-[30%] w-[70%]' />

                    </div>

                    <div className='flex justify-center'>
                        <div>
                        <h3 className='text-black font-semibold text-lg'>No Messages in your Inbox Yet</h3>
                        <p className='text-[#666666] text-sm mt-2'>You have not received anything yet</p>
                        </div>  
                    </div>

                </div>
            </div>
        </>
    );
}

export default Message;