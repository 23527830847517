import React, { Fragment, useState } from "react";
import { NavLink, useNavigate, } from "react-router-dom";
import hero from "../../../img/new-course-bg.png";
import herosmall from "../../../img/new-course-small.png";
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from '../../Navbars/UserNavbar'

function ResturantAndCatering() {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    return (
        <>
            <Sidebar />

            <div className="relative md:ml-64 bg-blueGray-100">

                <UserNavbar title="Available Programs" />
                <div className='md:py-3'>
                    {/* <!-- Start block --> */}
                    <section className='hidden md:block  relative'>
                        <div
                            style={{
                                backgroundImage: `url(${hero})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: 'center center'
                            }}
                            className='md:bg-cover relative bg-contain  md:mt-0 sm:px-16 md:px-14 px-6 md:pb-20 md:pt-20 pt-2  flex items-start'
                        >
                            <div className=" text-white md:pt-40  md:pb-5 pb-5">
                            </div>
                            <div className="flex space-x-3">

                                <div className="text-white text-[24px] font-medium cursor-pointer" onClick={() => navigate(-1)}>← </div>
                                <h3 className="text-white text-[24px] font-medium">Vocational Courses</h3>
                            </div>


                            <img src="/images/logo192-bg.png" alt="location" className=' absolute bottom-[-50px]  md:left-28 left-5 shadow-lg md:h-40' />


                        </div>
                    </section>
                    {/* <!-- End block --> */}

                    {/* <!-- Mobile section --> */}
                    {/* <!-- Start block --> */}
                    <section className='block md:hidden  relative'>
                        <div
                            style={{
                                backgroundImage: `url(${herosmall})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: 'center center'
                            }}
                            className='md:bg-cover relative bg-contain  md:mt-0 sm:px-16 md:px-14 px-6 md:pb-20 md:pt-20 pt-2  flex items-start'
                        >
                            <div className=" text-white md:pt-40 pt-64 md:pb-5 pb-5">

                            </div>
                            <div className="flex space-x-3 mt-6">

                                <div className="text-white text-[20px] font-medium cursor-pointer" onClick={() => navigate(-1)}>← </div>
                                <h3 className="text-white text-[20px] font-medium">Vocational Courses</h3>
                            </div>


                            {/* <img src={schoolData?.logo_url} alt={schoolData?.logo} className='h-[130px] absolute bottom-[-70px] rounded-[10px] md:left-28 left-5 shadow-lg  mb-5' /> */}
                            <img src="/images/logo192.png" alt="location" className=' absolute bottom-[-35px] md:left-28 left-5 shadow-lg h-20' />

                        </div>
                    </section>
                    {/* <!-- Start block --> */}

                    {/* <!-- Start block --> */}
                    <div className=' sm:px-16 px-6 md:flex md:justify-center items-start md:pt-5 pt-10' >
                        <div className='xl:max-w-[1200px] w-full mx-auto'>
                            <div className='flex md:flex-row flex-col sm:py-16 py-6 relative'>
                                <div className='basis-8/12'>
                                    <div className=" text-[#000]  md:pb-5 pb-5">
                                        <h1 className="text-[24px] md:text-[43px] text-[#1DB459] font-[700]">
                                            Resturant and Catering Services
                                        </h1>

                                        <h1 className="md:text-[24px] text-[16px] font-[500]">
                                            Lappeenranta, Finland
                                        </h1>


                                    </div>

                                    <div className='flex md:hidden justify-between  mb-3 gap-2 mt-[10px]'>

                                        <button type="button"
                                            onClick={onOpenModal}
                                            className=" bg-[#1DB459]   text-[#FFFFFF] rounded-full md:text-[18px] text-[16px] px-6 min-w-full  py-3 shadow-md">Register</button>

                                        {/* <NavLink to={`/school-details/${schoolData?.id}`} className=" w-full pr-3">
                                    <button type="button" className="  bg-[#56D9F5] font-[500] text-[#001F26] rounded-full text-[14px] px-6 min-w-full  py-3 shadow-md">About School</button>
                                </NavLink> */}

                                    </div>

                                    <hr className='pb-3 md:hidden' />

                                    <div className='grid md:grid-cols-3 grid-cols-2 md:space-x-5 pb-5 '>
                                        <div className='bg-[#F0F5FE] font-medium mr-4 md:px-2 px-5 md:py-2 py-1 rounded shadow'>
                                            <div className='md:text-[30px] text-[18px] text-[#4D4D4D] font-bold text-center'>€ 11,500</div>
                                            <div className='md:text-[16px] text-[12px] text-[#4D4D4D] text-center'>Per Academic Year</div>
                                        </div>
                                        <div className='bg-[#F0F5FE] font-medium mr-2 px-6  py-1 rounded shadow'>
                                            <h3 className='md:text-[30px] text-[18px] text-[#4D4D4D] font-bold text-center capitalize'>1 Year</h3>
                                            <h5 className='md:text-[16px] text-[12px] text-[#4D4D4D] text-center'>Duration</h5>
                                        </div>

                                        <div className='md:flex md:flex-col md:px-1 hidden'>

                                            <button type="button"
                                                onClick={onOpenModal}
                                                className=" bg-[#1DB459]  text-[#FFFFFF] rounded-full text-[16px]  px-6 min-w-full  py-1.5 shadow-md">Register</button>

                                            {/* <NavLink to={`/school-details/${schoolData?.id}`} className="mt-2 w-full">
                                        <button type="button" className="bg-[white] text-[#001F26] border border-[#1DB459] font-[500] rounded-full  text-[16px] px-6 min-w-full  py-1.5 shadow-md">About School</button>
                                    </NavLink> */}

                                        </div>
                                    </div>
                                    <hr className='pt-3 hidden md:block' />

                                    {/* Key Information Mobile */}
                                    <div className='block md:hidden mt-10 md:mt-0'>
                                        <div className='bg-white p-3 rounded flex-col border border-[#D9D9D9]'>
                                            <div className='flex-1 flex justify-center items-center'>
                                                <img src="/images/cook.jpeg" alt="hero" className='w-full' />
                                            </div>
                                            <div
                                                className="text-white mt-1 bg-[#1DB459] text-center w-full font-medium rounded-[4px] text-sm  py-2.5">
                                                Key Information
                                            </div>
                                            <div className='flex justify-between pt-2 mt-1 mb-2 bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>On-Campus language training starts  </h3>
                                                <h3 className='text-[12px]'>8th Jan. 2024 – 5th Apr. 2024</h3>
                                            </div>
                                            {/* <hr className="h-px mb-2 mt-[10px] bg-[#F0F4F9] border-0" /> */}
                                            <div className='flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>Vocational training on campus  </h3>
                                                <h3 className='text-[12px]'>15th Apr. 2024-max. 19th Jun. 2025</h3>
                                            </div>
                                            {/* <hr className="h-px mb-2 mt-[10px] bg-[#F0F4F9] border-0" /> */}
                                            <div className='flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>Last entry date for applications  </h3>
                                                <h3 className='text-[12px]'>11th Dec. 2023</h3>
                                            </div>
                                            {/* <hr className="h-px mb-2 mt-[10px] bg-[#F0F4F9] border-0" /> */}
                                            <div className='flex justify-between pt-2 mb-2 bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>Last possible date for interviews  </h3>
                                                <h3 className='text-[12px] capitalize'>12th-14th Dec. 2023</h3>
                                            </div>
                                            {/* <hr className="h-px mb-2 mt-[10px] bg-[#F0F4F9] border-0" /> */}
                                            <div className='flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>Offers sent by latest  </h3>
                                                <h3 className='text-[12px] capitalize'>15th-19th Dec. 2023</h3>
                                            </div>
                                            <div className='flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>Due date for all payments  </h3>
                                                <h3 className='text-[12px] capitalize'>20th Dec. 2023</h3>
                                            </div>
                                            {/* <hr className="h-px mb-2 mt-[10px] bg-[#F0F4F9] border-0" /> */}
                                            <div className='flex justify-between pt-2  bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>Language </h3>
                                                <h3 className='text-[12px]'>English</h3>
                                            </div>

                                            <div className='mt-[10px]'>

                                                <div className=''>
                                                    <button
                                                        type="submit"
                                                        onClick={onOpenModal}
                                                        className="text-white bg-[#1DB459] w-full font-medium rounded-[4px] text-sm  py-2.5">
                                                        Register Now
                                                    </button>

                                                </div>

                                                <div className='mt-[10px] mb-3'>
                                                    <div className=''>
                                                        <div className='text-[20px] font-medium text-[#000F2F]'>Need help? </div>
                                                        <NavLink to="/contact-us">
                                                            <div className=' text-[#1DB459] font-[400] text-[14px] cursor-pointer'>contact us for more information</div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='md:mt-3 mt-10'>
                                        <h1 className=' font-[700] text-[24px]'>Composition of the qualification</h1>
                                        <p className='pb-[10px] pt-[8px] font-normal text-[14px] text-[#4D4D4D]'>
                                            The scope of the Vocational Qualification in Restaurant and Catering Services is 180 competence
                                            points. The qualification is composed of vocational units (145 competence points) and common units
                                            (35 competence points).
                                        </p>
                                        <p className='pb-[10px] pt-[8px] font-normal text-[14px] text-[#4D4D4D]'>
                                            The qualification contains a compulsory unit, or working in catering services (20 competence points),
                                            two compulsory units in each competence area (65 competence points in total) and optional units
                                            amounting to 60 competence points.
                                        </p>
                                        <h3 className='font-bold text-[14px] capitalize'>The vocational qualification in Restaurant and Catering Services has two competence areas and
                                            qualification titles:</h3>
                                        <ul className="max-w-md space-y-1 text-[#4D4D4D] list-disc list-outside pt-[8px] font-normal text-[14px] ml-4">
                                            <li>
                                                Competence area of customer service (waiter/waitress) and
                                            </li>
                                            <li>
                                                competence area of food services (cook).
                                            </li>

                                        </ul>
                                        <p className='pb-[10px] pt-[8px] font-normal text-[14px] text-[#4D4D4D]'>
                                            The students may specialise in serving or cooking tasks in this vocational secondary qualification.
                                        </p>

                                        <h3 className='font-bold text-[14px] capitalize'>A waiter/waitress who has completed the qualification knows how to:</h3>
                                        <ul className=" space-y-1 text-[#4D4D4D] list-disc list-outside pt-[8px] font-normal text-[14px] ml-4">
                                            <li>
                                                Work in customer service tasks at restaurants with different business ideas or missions or in
                                                public sector units
                                            </li>
                                            <li>
                                                Tidy customer facilities and display products to be sold
                                            </li>
                                            <li>
                                                Present, sell, and serve food and beverages.
                                            </li>


                                        </ul>
                                        <h3 className='font-bold text-[14px] mt-4 capitalize'>A cook who has completed the qualification knows how to:
                                        </h3>
                                        <ul className=" space-y-1 text-[#4D4D4D] list-disc list-outside pt-[8px] font-normal text-[14px] ml-4">
                                            <li>
                                                Work in food preparation tasks at restaurants with different business ideas or missions or in
                                                public sector units
                                            </li>
                                            <li>
                                                Prepare tasty, nutritious, and wholesome food, taking special diets into account
                                            </li>
                                            <li>
                                                Set out food as individual plated dishes or dishes for a large group of customers.
                                            </li>
                                            <li>
                                                competence area of food services (cook).
                                            </li>

                                        </ul>

                                        <h1 className=' font-bold text-[20px] pt-[24px] capitalize'>Vocational skills and competences required for completion of the qualification</h1>
                                        <h3 className='font-bold text-[14px] capitalize mt-5'>Qualification holders are able to:</h3>
                                        <ul className=" space-y-1 text-[#4D4D4D] list-disc list-outside pt-[8px] font-normal text-[14px] ml-4">
                                            <li>
                                                Plan and carry out food preparation or customer service tasks and work in sales in the catering
                                                sector
                                            </li>
                                            <li>
                                                Serve Finnish and foreign customers in a customer-oriented manner
                                            </li>
                                            <li>
                                                Use professional vocabulary in foreign languages, and draw on their cultural knowledge

                                            </li>
                                            <li>
                                                Work in a sales-minded, customer service oriented and quality conscious manner and
                                                profitably, productively, and responsibly as well as operate in compliance with hygiene
                                                requirements, and sustainable operating practices.
                                            </li>

                                        </ul>

                                        <h1 className=' font-semibold text-[20px] pt-[24px] capitalize'>Range of occupations accessible to the holder of the certificate</h1>

                                        <p className='pb-[10px] pt-[8px] font-normal text-[14px] text-[#4D4D4D]'>
                                            A <span className='font-bold'>waiter/waitress</span> who has completed the competence area of customer service works at
                                            restaurants, staff canteens, pubs, night clubs, transport stations, cafés, fast food, catering, or tourism
                                            service companies or various institutions.
                                        </p>
                                        <p className='pb-[10px] pt-[8px] font-normal text-[14px] text-[#4D4D4D]'>
                                            A <span className='font-bold'>cook</span> who has completed the competence area of food services works at restaurants or in the
                                            catering sector, staff canteens, cafés, catering companies, freight and passenger ships as well as
                                            institutional kitchens.
                                        </p>



                                        <h1 className=' font-bold text-[24px] pt-[24px] capitalize'> Work Tasks that can be executed</h1>

                                        <p className='pb-[10px] pt-[8px] font-normal text-[14px] text-[#4D4D4D]'>
                                            A cook who has completed the competence area of food services works at restaurants or in the catering sector, staff canteens, cafés, catering companies, freight, and passenger ships as well as institutional kitchens.
                                        </p>
                                        <p className='pb-[10px] pt-[8px] font-normal text-[14px] text-[#4D4D4D]'>
                                            <span className='text-[#000000] font-bold'>Included:</span>  Tuition on campus, online and in the workplace, all learning materials, special education, guidance
                                        </p>
                                        <p className='pb-[10px] pt-[8px] font-normal text-[14px] text-[#4D4D4D]'>
                                            <span className='text-[#000000] font-bold'>Not included:</span>   Travel, accommodation, insurance (required), landing services, laptop (required)
                                        </p>


                                        <h1 className=' font-bold text-[24px]  pt-[10px]'>Acceptance Process</h1>
                                        <ul className="max-w-md space-y-1 text-[#4D4D4D] list-disc list-outside pt-[8px] font-normal text-[14px] ml-4">
                                            <li>
                                                Present or previous diploma or certificate in restaurant education
                                            </li>
                                            <li>
                                                Present or previous work contract as a chef
                                            </li>
                                            <li>
                                                Motivational letter
                                            </li>
                                            <li>
                                                No exams ONLY interview
                                            </li>
                                        </ul>
                                        {/* <p className=' pt-[8px] font-normal text-[14px] text-[#4D4D4D]' dangerouslySetInnerHTML={{ __html: courseDetails?.career }}>
                                </p> */}


                                    </div>

                                </div>

                                {/* Key Information Desktop */}
                                <div className='md:block hidden relative basis-4/12 md:ml-[100px]'>

                                    <div className='mt-10 md:mt-0 sticky top-0 left-0'>
                                        <div className='bg-white p-3 rounded flex-col border border-[#D9D9D9]'>
                                            <div className='flex-1 flex justify-center items-center'>
                                                <img src="/images/cook.jpeg" alt="hero" className='w-full' />
                                            </div>
                                            <div
                                                className="text-white mt-1 bg-[#1DB459] text-center w-full font-medium rounded-[4px] text-sm  py-2.5">
                                                Key Information
                                            </div>
                                            <div className='flex justify-between pt-2 mt-1 mb-2 bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>On-Campus language training starts  </h3>
                                                <h3 className='text-[12px]'>8th Jan. 2024 – 5th Apr. 2024</h3>
                                            </div>
                                            {/* <hr className="h-px mb-2 mt-[10px] bg-[#F0F4F9] border-0" /> */}
                                            <div className='flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>Vocational training on campus  </h3>
                                                <h3 className='text-[12px]'>15th Apr. 2024-max. 19th Jun. 2025</h3>
                                            </div>
                                            {/* <hr className="h-px mb-2 mt-[10px] bg-[#F0F4F9] border-0" /> */}
                                            <div className='flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>Last entry date for applications  </h3>
                                                <h3 className='text-[12px]'>11th Dec. 2023</h3>
                                            </div>
                                            {/* <hr className="h-px mb-2 mt-[10px] bg-[#F0F4F9] border-0" /> */}
                                            <div className='flex justify-between pt-2 mb-2 bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>Last possible date for interviews  </h3>
                                                <h3 className='text-[12px] capitalize'>12th-14th Dec. 2023</h3>
                                            </div>
                                            {/* <hr className="h-px mb-2 mt-[10px] bg-[#F0F4F9] border-0" /> */}
                                            <div className='flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>Offers sent by latest  </h3>
                                                <h3 className='text-[12px] capitalize'>15th-19th Dec. 2023</h3>
                                            </div>
                                            <div className='flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>Due date for all payments  </h3>
                                                <h3 className='text-[12px] capitalize'>20th Dec. 2023</h3>
                                            </div>
                                            {/* <hr className="h-px mb-2 mt-[10px] bg-[#F0F4F9] border-0" /> */}
                                            <div className='flex justify-between pt-2  bg-[#F6FBFA] px-2 pb-1'>
                                                <h3 className='text-[12px]'>Language </h3>
                                                <h3 className='text-[12px]'>English</h3>
                                            </div>

                                            <div className='mt-[10px]'>

                                                <div className=''>
                                                    <button
                                                        type="submit"
                                                        onClick={onOpenModal}
                                                        className="text-white bg-[#1DB459] w-full font-medium rounded-[4px] text-sm  py-2.5">
                                                        Register Now
                                                    </button>

                                                </div>

                                                <div className='mt-[10px] mb-3'>
                                                    <div className=''>
                                                        <div className='text-[20px] font-medium text-[#000F2F]'>Need help? </div>
                                                        <NavLink to="/contact-us">
                                                            <div className=' text-[#1DB459] font-[400] text-[14px] cursor-pointer'>contact us for more information</div>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <Modal open={open} onClose={onCloseModal} center>
                                        <div className='md:max-w-md  body-font font-poppins'>
                                            <div className="flex flex-wrap mt-4">
                                                <h2 className='text-center text-[#1DB459] font-bold md:text-[20px] text-[18px] leading-8'>Restaurant and Catering Vocational Program in English Pathway in Finland, with TUITION FEES</h2>
                                                <p className='text-center text-[#838383] md:text-[14px] text-[12px] mt-4'>This form is prepared for those ready to study Restaurant and Catering in English in Finland paying tuition fees.</p>
                                                <h3 className='md:text-[14px] text-[12px] mt-4 font-semibold text-center text-[#DC143C]'>PLEASE DO NOT FILL IF YOU CANNOT AFFORD TO PAY TUITION FEES</h3>


                                                <h3 className='md:text-[14px] text-[12px] text-black font-light mt-4'><span className='font-bold'>Data privacy:</span>  Please be aware that any information provided will be used  to verify your identity for future referencing and also for your Visa and Residence Permit processing.</h3>
                                                <hr className="h-px mb-8 mt-[20px] bg-[#F0F4F9] border-0" />

                                                <div className='flex mx-auto space-x-4 mt-6'>
                                                    <NavLink to={"/resturant-and-catering-registration-form"}>
                                                        <button
                                                            type="button"

                                                            className=" text-white bg-[#1C8B48] rounded-[5px] font-medium text-sm md:px-5 px-3 md:py-3 py-2.5 mr-2 mb-2"
                                                        >
                                                            Proceed
                                                        </button>
                                                    </NavLink>

                                                    <button
                                                        type="button"
                                                        className=" text-white bg-[#FF0000] rounded-[5px]  font-medium text-sm md:px-5 px-8 md:py-3 py-2.5 mr-2 mb-2"
                                                        onClick={onCloseModal}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>

                                                <hr className="h-px mb-8 mt-[20px] bg-[#F0F4F9] border-0" />
                                                <p className='md:text-[12px] text-[10px] text-center mt-3 text-[#838383]'>By proceeding, you confirm that you have read and agree to the Terms and Conditions outlined above. If you do not agree, please click <span className='font-bold'>"Cancel"</span> to abort the registration process</p>
                                            </div>
                                        </div>

                                    </Modal>

                                    {/* <div className='mt-10 md:mt-[80px]'>
                            <div className='bg-white p-3 rounded flex-col border border-[#D9D9D9]'>
                                <h3 className=" mb-2 text-[20px] font-medium text-[#002147] ">Related Courses</h3>
                                <div className='grid grid-cols-2 md:gap-10 gap-3 md:grid-cols-1 md:mt-[25px] mt-[30px]'>
                                    <div className='flex flex-col md:flex-row md:space-x-3'>
                                        <div> <img src="/images/dev-img.png" alt="hero" className='' /></div>
                                        <div>
                                            <h3 className='text-[#000F2F] font-semibold text-[16px]'>Development</h3>
                                            <h5 className='text-[#949DB1] font-normal text-[14px]'>$54.00</h5>
                                        </div>
                                    </div>
                                    <div className='flex flex-col md:flex-row md:space-x-3'>
                                        <div> <img src="/images/dev-img.png" alt="hero" className='' /></div>
                                        <div>
                                            <h3 className='text-[#000F2F] font-semibold text-[16px]'>Development</h3>
                                            <h5 className='text-[#949DB1] font-normal text-[14px]'>$54.00</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div> */}


                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <!-- End block --> */}
                </div>
            </div>

            {/* <Footer /> */}
        </>
    );
}

export default ResturantAndCatering;
