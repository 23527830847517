import React from 'react'
import Navbar from '../../Navbar'
import blogbg from "../../../img/blog.png";
import Footer from '../../Footer';


function HowToStudy() {
  return (
    <>
      <Navbar />

      {/* <!-- Start block --> */}
      <section className=" body-font font-poppins pt-16" >
        <div
          style={{
            backgroundImage: `url(${blogbg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className=" lg:px-14 pt-10 pb-24 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
        >
          <div className="place-self-center text-center text-white pt-20">
            <h1 className="md:text-5xl text-3xl font-bold">
              How To Study in Finland With<br /> No Tuition Fees

            </h1>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB] body-font font-poppins">
        <div className='bg-white lg:mx-28 pb-10 lg:px-10 px-8 pt-10'>
          <h3 className='text-base text-[#000000] font-normal'>Finland is a country known for its amazing technology, safety, forestry, and air quality, the happiest nation in the world, and has a host of many other amazing things.</h3>
          <h3>Amongst all the qualities of life Finland has to offer is education. According to World Health Organization (WHO ), Finland has got one of the best education in the world ( If not the best ).</h3>
          <h3 className='text-base text-[#000000] font-normal mt-3'>
            Finland has one of the highest-quality education systems in the world, as shown by consistently high rankings in third-party international studies.
          </h3>

          <h3 className='text-base text-[#000000] font-normal mt-3'>
            For example, Finland is ranked #1 by the Economist in their Educating for the future index, while the OECD says Finland is #2 in the world for the highest-performing graduates. Finland has achieved this success despite only spending slightly more per student than the average in OECD countries.
          </h3>
          <h3 className='text-base text-[#000000] font-normal mt-3'>
            Teachers are valued in Finnish society. They are highly educated and trusted to do what is best for every student. Personalized help is common: the Smithsonian notes that nearly 30% of children receive some kind of special help during their first nine years of school.
          </h3>
          <h3 className='text-base text-[#000000] font-normal mt-3'>
            The two main official languages of Finland are Finnish and Swedish but English is also predominantly spoken especially in the Capital regions.
          </h3>
          <h3 className='text-base text-[#000000] font-normal mt-3'>
            You can study in Finland in any of the above-named languages but there is a difference.
          </h3>
          <div className='flex justify-center mt-5'>
            <img src="/images/blog-img.png" alt="location" />
          </div>
          <h1 className='font-bold text-2xl mt-6'>What Does it Cost To Study in Finland <br />
            and The Exceptions?</h1>
          <h3 className='text-base text-[#000000] font-normal mt-5'>
            For an international student, you can study in Vocational schools or the University/University of Applied Sciences in English but almost all schools require tuition fees to study in English. These tuition fees range from 4,000€ – 20,000€ per academic year.
          </h3>
          <h3 className='text-base text-[#000000] font-normal mt-2'>
            Although some schools offer a 50% scholarship or even a full scholarship and the competition to get a full scholarship is high and the chances of getting it are low. For some paying for a 50% scholarship is still a challenge, due to economical situations.
          </h3>
          <h3 className='text-base text-[#000000] font-normal mt-2'>
            However, if an international student studies in the Finnish Language, then it is an automatic NO TUITION FEES either in Vocational school or any University to earn a master’s and Ph.D. because this is the policy/Law given by the Ministry of Education and Culture in Finland.
          </h3>
          <h3 className='text-base text-[#000000] font-normal mt-2'>
            There are various ways an international student living outside Finland could learn the Finnish Language and most of which are online either virtual learning with a Finnish teacher online or via recorded Apps/Platforms. The most required process to gain admission to study for free in Finland entails showing a certificate of the Finnish language proficiency level.
          </h3>
          <h1 className='font-bold text-2xl mt-6'>The Finnish Language Proficiency Level</h1>

          <h2 className="font-bold text-sm mt-1">Starting level 0</h2>
          <ul className="max-w-md space-y-1 text-black list-disc list-inside pl-4 mt-3">
            <li>
              No Finnish language skills
            </li>
          </ul>

          <h2 className="font-bold text-sm mt-5">Basic level (A1.1–A2.2)</h2>
          <ul className="space-y-1 text-black list-disc list-inside pl-4 mt-1">
            <li>
              Basic level begins A1.1 – Language basics.
            </li>
            <li>
              Basic level A1.2 – Language basics
            </li>
            <li>
              Basic level A1.3 – You can use the language in simple non-work-related situations. You can write a little.
            </li>
            <li>
              Basic level A2.1 – You can use the language to run the most common errands.
            </li>
            <li>
              Basic level A2.2 – Your understanding of familiar subjects is reasonably good. You can write in simple language.
            </li>
          </ul>

          <h2 className="font-bold text-sm mt-5">Intermediate level (B1.1–B2.2)</h2>
          <ul className="space-y-1 text-black list-disc list-inside pl-4 mt-1">
            <li>
              Intermediate level begins B1.1 – Moderate language skills in day-to-day work and leisure situations.
            </li>
            <li>
              Intermediate level B1.2 – A fluent grasp of the language in day-to-day work and leisure situations.
            </li>
            <li>
              Intermediate level B2.1 – Basic level of independent language proficiency.
            </li>
            <li>
              Intermediate level B2.2 – Functional level of independent language proficiency.
            </li>
          </ul>

          <h2 className="font-bold text-sm mt-5">Advanced level (C)</h2>
          <ul className="space-y-1 text-black list-disc list-inside pl-4 mt-1">
            <li>
              Advanced level C – Language skills are excellent or comparable to a native speaker. There is also a more specific level classification for C-level courses, C1.1, C1.2, C2.1, or C2.2
            </li>
          </ul>

          <h3 className='text-base text-[#000000] font-normal mt-2'>
            Although some schools require A2.2 language proficiency level to apply to certain courses the most required proficiency level in most schools is B1.1 or B1.2 followed by an entrance exam to validate the language proficiency.
          </h3>

          <h3 className='text-base text-[#000000] font-normal mt-2'>
            The most effective and proven way to learn the Finnish language has been through a platform where a teacher is online teaching through the syllabus, grammar, etc. that enables the student to attain a very good Finnish language command and proficiency.
          </h3>
          <h3 className='text-base text-[#000000] font-normal mt-2'>
            AfriProEdu in partnership with Finest Future has had massive success through this medium with Finnish Language teacher experts teaching international students the Finnish language online for 75 minutes, Mondays to Fridays.
          </h3>
          <h3 className='text-base text-[#000000] font-normal mt-2'>
            Since the inception of Finest Future in 2021, starting with 15 students there have been over 200 students across Asia who have studied the Finnish Language and are now in Finland studying in vocational schools respectively.
          </h3>
          <h3 className='text-base text-[#000000] font-normal mt-2'>
            This process has advanced also to the African continent as well as South America, with students following the same process.
          </h3>
          <h3 className='text-base text-[#000000] font-normal mt-4'>
            In conclusion, international students can study in English in Finland but require paying tuition fees by most academic institutions whereas studying in Finnish is free of charge, with no tuition fees.
          </h3>

          <h3 className='text-base text-[#000000] font-normal mt-2'>
            To qualify to get Free tuition you need to attain a certain Finnish language proficiency level to apply to schools in Finland then followed by an internal examination organized by the school.
          </h3>
          <h3 className='text-base text-[#000000] font-normal mt-2'>
            International students studying in Finnish can study from Vocational school to university, master’s, and Ph.D. for free..
          </h3>

          <h3 className='text-base text-[#000000] font-normal mt-2'>
            For more information, you can reach out to our customer service on WhatsApp +358408187876 or email helllo@afriproedu.com
          </h3>
        </div>

      </section>
      {/* <!-- End block --> */}

      <Footer />
    </>
  )
}

export default HowToStudy;