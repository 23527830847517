import React from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import Lottie from "lottie-react";
import animationData from "../../assets/notification.json";
import { AuthApis } from "../../../apis/authApis";
import { AxiosResponse } from "axios";
import { DashboardApi } from "../../../apis/DashboardApi";
import { ToastContainer, toast } from "react-toastify";
import { MutatingDots } from "react-loader-spinner";

function Degree() {
  const [courseList, setCourseList] = React.useState<any>("");
  const [title, setTitle] = React.useState<any>("");
  const [category, setCategory] = React.useState<any>("degree program");
  const [wishlistText, setWishlistText] = React.useState<any>("");
  const [loader, setLoader] = React.useState<boolean>(true);

  React.useEffect(() => {
    setLoader(true);
    const query: any = {
      title: title,
      category: category,
    };
    AuthApis.getFilteredCourse("", query)
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setLoader(false);
          setCourseList(response?.data?.data);
        }
      })
      .catch(function (error) {
        // handle error
      });
  }, []);

  
  const handleSubmit = (data: any) => {
    // console.log(data);
    const formData = new FormData();
    formData.append("course_name", data?.title);
    formData.append("course_id", data?.id);
    formData.append("course_price", data?.product_cost);

    DashboardApi.addWishlist(formData)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.success) {
            setWishlistText((prev: any) => !prev);
            toast.success("Added to wishlist");
          }
        } else {
          // toast.warn('Invalid Login Credentials');
        }
      })
      .catch(function (error) {
        // handle error
      })
      .finally(() => {
        // toast.error("No Internet Connection");
      });
  };

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar title="Degree" />
        <div className="md:py-16">
          <div className="flex space-x-3 md:pl-10 pl-6 pr-4 mt-5 ">
            <NavLink to={"/english-available-programs"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1z"
                />
              </svg>
            </NavLink>
            <h3 className="font-semibold">Degree Courses</h3>
          </div>
          {/* <div className=" lg:w-12/12 px-4 md:pt-16 py-4">
                    <div className="flex justify-center">
                        <Lottie animationData={animationData} className='md:w-[30%] w-[70%]' />

                    </div>

                    <div className='flex justify-center'>
                        <div>
                        <h3 className='text-[#1DB459] font-semibold text-[40px]'>Coming soon!</h3>
                        
                        </div>  
                    </div>
                    

                </div> */}

          {loader ? (
            <div className="flex justify-center mt-52">
              <MutatingDots
                height="100"
                width="100"
                color="#4fa94d"
                secondaryColor="#4fa94d"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loader}
              />
            </div>
          ) : (
            <div className="grid md:grid-cols-3 md:gap-10 gap-3 grid-cols-1 md:mt-[20px] mt-[40px] md:px-10 px-5">
              {courseList?.data?.length > 0 ? (
                courseList?.data?.map((datas: any, index: any) => (
                  <div className="bg-white p-3 rounded flex-col border border-[#D9D9D9]">
                    <NavLink 
                         to={`/vocational-course-details/${(datas?.title).replace(
                          / /g,
                          "-"
                        )}`}
                    // to={`/vocational-course-details/${datas?.title}`}
                    >
                      <div className="flex-1 flex justify-center items-center">
                        <img
                          className=""
                          src={datas?.image_url}
                          alt={datas?.cover_photo}
                          style={{ width: "384px", height: "188px" }}
                        />
                      </div>
                    </NavLink>
                    
                    <div className="flex justify-between pt-2">
                    <NavLink 
                         to={`/vocational-course-details/${(datas?.title).replace(
                          / /g,
                          "-"
                        )}`}
                    // to={`/vocational-course-details/${datas?.title}`}
                    >
                      <h3 className="text-[#002147] md:text-[15px] text-[12px] font-normal">
                        {datas?.title}
                      </h3>
                      </NavLink>
                      <div
                        className="flex space-x-2"
                        onClick={() => handleSubmit(datas)}
                      >
                        {/* ➜ */}
                        <h3 className="text-[10px] mt-3">
                          {!wishlistText ? "" : ""}
                        </h3>
                        <span
                          onClick={() => setWishlistText((prev: any) => !prev)}
                          className="inline-flex items-center justify-center bg-white hover:bg-[#E7F2F3] h-9 w-9 rounded-full cursor-pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="none"
                              stroke="#1db459"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19.5 12.572L12 20l-7.5-7.428A5 5 0 1 1 12 6.006a5 5 0 1 1 7.5 6.572"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex justify-center mt-4">
                  <span>No Result Found for {category}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Degree;
