import React, { useState } from 'react'
import Navbar from '../Navbar'
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import hero from "../../img/new1.png";
import { AuthApis } from '../../apis/authApis';
import { DashboardApi } from '../../apis/DashboardApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function RegisterCook() {
    const [userData, setUserdata] = React.useState({
        'full_name': "",
        'country': "",
        'email': "",
        'phone_number': "",
        'academic_background': "",
        'profession': "",
        'program_studied': "",
        'who_wiil_pay_for_tution': " ",
        'where_do_you_hear_about_us': ""

    });

    const navigate = useNavigate();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUserdata({ ...userData, [name]: value });
    };

    const [currency, setCurrency] = useState<any>('USD');

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [openPayment, setOpenPayment] = useState(false);
    const onOpenPaymentModal = () => setOpenPayment(true);
    const onClosePaymentModal = () => setOpenPayment(false);

    const [openModalNoti, setOpenModalNoti] = useState(true);
    const onOpenModalNoti = () => setOpenModalNoti(true);
    const onCloseModalNoti = () => {
        navigate(-1)
    }

    const [searchParams, setSearchParams] = useSearchParams();
    const [tutionFee, setTutionFee] = React.useState<any>('');
    const [tutionFeeAndWork, setTutionFeeAndWork] = React.useState<any>('');
    const [previousBackground, setpreviousBackground] = React.useState<any>('');
    const [haveTutionFee, setHaveTutionFee] = React.useState<any>('');

    if (searchParams.get('tx_ref')) {
        // console?.log('ddd')
        if (searchParams.get('status') == 'cancelled') {
            navigate('/')
        }

        AuthApis.outsidePaymentCallback(searchParams.get('tx_ref'), searchParams.get('status')).then(
            (response: any) => {
                if (response?.data) {
                    console?.log(JSON.parse(response?.data?.data)?.data)
                    AuthApis.sendMail(JSON.stringify(JSON.parse(response?.data?.data)?.data)).then(
                        (response2: any) => {
                            if (response2?.data) {
                                navigate('/resturant-and-catering-payment-successful')
                                console?.log(response2?.data);
                            }
                        }
                    )
                    if (response?.data?.success === true) {
                        //navigate(response?.data?.data);
                    }
                } else {
                    // toast.warn('Invalid Login Credentials');
                }
            }
        ).catch(function (error) {
            // handle error
            console.log(error.response.data);
        }).finally(() => {
            // toast.error("No Internet Connection");

        });
    }


    const handleSubmit = (e: any) => {
        e.preventDefault();
        // onCloseModal()
        const formData = new FormData()
        formData.append('full_name', userData?.full_name)
        formData.append('email', userData?.email)
        formData.append('amount', (currency == "USD"?"100":"160000"))
        formData.append('currency', currency)
        formData.append('program', 'resturant-and-catering') 
        formData.append('url', '/resturant-and-catering-registration-form')    
        formData.append('country', userData?.country)
        formData.append('phone_number', userData?.phone_number)
        formData.append('pay_tution_fee_and_work', tutionFeeAndWork)
        formData.append('pay_tution_fee', tutionFee)
        formData.append('previous_background', previousBackground)
        formData.append('have_tution_fee', haveTutionFee)
        formData.append('who_will_pay_for_tuition', userData.who_wiil_pay_for_tution)
        formData.append('where_do_you_hear_about_us', userData?.where_do_you_hear_about_us)
        formData.append('program_studied', userData?.program_studied)
        formData.append('profession', userData?.profession)
        formData.append('academic_background', userData?.academic_background)

        AuthApis.createCookProgram(formData).then(
            (response: any) => {
                if (response?.data) {
                    if (response?.data?.success === true) {
                        if (response?.data?.success === true) {
                            console?.log(response?.data)
                            if (response?.data?.data?.data?.link) {
                                window.location.replace(response?.data?.data?.data?.link);
                            } else if (response?.data?.data?.message) {
                                toast.warn(response?.data?.data?.message);
                            } else {
                                toast.error('Something went wrong!');
                            }
                            // onOpenModal()
                            // onOpenPaymentModal()
    
                        } else {
                            toast.warn('some Field Missing');
                        }
                        // console?.log(response?.data)
                        // toast.success(response?.data?.message);
                        // onOpenModal()
                       

                    } else {
                        toast.warn('some Field Missing');
                    }
                } else {
                    // toast.warn('Invalid Login Credentials');
                }
            }
        ).catch(function (error) {
            // handle error
        })
    }





    return (
        <>
            <Navbar />

            <Modal open={openModalNoti} onClose={onCloseModalNoti} center>
                <div className='md:max-w-md mt-6'>
                    <h1 className='text-2xl font-semibold text-center mt-3'>Restaurant and Catering Closed</h1>
                    <p className='text-[14px] mt-3'>We want to express our sincere gratitude for your interest in our recent cohort. However, we want to inform you that the current cohort has reached its maximum capacity and is now closed.</p>
                    <p className='text-[14px] mt-3'>But the good news is, we are excited to announce that you can register for the next cohort.</p>
                    <p className='text-[14px] mt-3'>We can't wait to have you on board for the next cohort. Thank you for considering our program, and we look forward to seeing you succeed in the upcoming session!</p>
              
                <p className='mt-3  text-[14px]'> <a target="_blank" href='https://chat.whatsapp.com/BDoV2a7Hiw69i3zegiO32W' className='text-[#1DB459] hover:underline font-semibold'>Click here</a> to join our WhatsApp group for daily updates.</p>    
                    <div className='flex mx-auto space-x-4 mt-6'>
                                            
                                        </div>
                </div>

            </Modal>

            {/* Start Section */}
            <section className='body-font font-poppins md:pt-20 relative'>
                <div

                    className='  pt-20 md:pt-0  flex justify-center items-center'
                >
                    <div>
                        <img src="/images/cook-bg.jpg" alt="hero" className=' border-[#1DB459] border-b-8' />
                    </div>

                </div>
            </section>
            {/* End Section */}

            {/* Start Section */}
            <div className='bg-[#FBFBFB] sm:px-16 px-6 md:flex md:justify-center items-start pt-10 pb-8' >
                <div className='xl:max-w-[1200px] w-full mx-auto'>
                    <div className=''>
                        <p className='md:text-[16px] text-[14px]'>This form is prepared for those ready to study Restaurant and Catering in English in Finland paying tuition fees.</p>


                    </div>

                   


                    <form onSubmit={handleSubmit}>
                        <div className='bg-white mt-[70px] p-6 border border-[#C4C4C4] rounded-[20px]'>
                            <div className='flex justify-end md:px-4 mb-8'>
                                <h5 className='md:text-[16px] text-[14px]'><span className='text-[#EE1D52] font-bold'>*</span>indicates a required field</h5>
                            </div>
                            <span className='bg-[#1DB459] text-white md:text-[20px] text-[16px] font-semibold md:ml-4 mb-[40px] mt-[10px] pl-3 pr-12 py-3 rounded-[5px]'>Personal Information</span>
                            <div className='grid grid-cols-1 gap-3 md:grid-cols-2 mt-[40px]'>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Full Name<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Name"
                                        name="full_name"

                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Phone Number<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="number"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Phone No"
                                        name="phone_number"

                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Email<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="email"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Email"
                                        name="email"

                                        required
                                    />
                                </div>

                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Country<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Country"
                                        name="country"
                                    />
                                </div>



                            </div>

                            <div className='my-[30px]'>
                                <span className='bg-[#1DB459] text-white md:text-[20px] text-[16px] font-semibold md:ml-4 mb-[40px]  pl-3 pr-12 py-3 rounded-[5px]'>Other Information</span>
                            </div>
                            <div className=" md:w-[50%] w-full mb-6 md:px-4 mt-[30px]">
                                <label
                                    htmlFor="academic_background"
                                    className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                >
                                    Academic background<span className='text-[#EE1D52] font-bold'> *</span>
                                </label>
                                <select
                                    id="academic_background"
                                    name="academic_background"
                                    onChange={handleChange}
                                    className=" border border-[#D9D9D9] text-[#333333] text-sm py-3  block w-full p-2.5 "
                                >
                                    <option selected></option>
                                    {/* <option value="high school">High School</option> */}
                                    <option value="college of education">College of Education</option>
                                    <option value="university">University</option>
                                    <option value="masters/phd">Masters/PhD</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>
                            <div className=" md:w-[50%] w-full mb-6 md:px-4 mt-[30px]">
                                <label
                                    htmlFor="profession"
                                    className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                >
                                    Profession<span className='text-[#EE1D52] font-bold'> *</span>
                                </label>
                                <select
                                    id="profession"
                                    name="profession"
                                    onChange={handleChange}
                                    className=" border border-[#D9D9D9] text-[#333333] text-sm py-3  block w-full p-2.5 "
                                >
                                    <option selected></option>
                                    <option value="student">Student</option>
                                    <option value="employed">Employed</option>
                                    <option value="unemployed">Unemployed</option>
                                    <option value="entrepreneur/self employed">Entrepreneur/Self employed</option>
                                    <option value="parent/guardian/sponsor">Parent/Guardian/Sponsor</option>
                                </select>
                            </div>
                            <div className='md:px-4 mt-[10px]'>
                                <h3 className='mb-2 text-[14px] font-semibold text-[#0A2E04]'>Do you have previous background in restaurant and catering business or education ? </h3>
                                <div className="flex items-center mb-4">
                                    <input id="default-radio-1" onClick={() => setpreviousBackground('yes')} type="radio" name='default-radio' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">Yes</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-radio-1" onClick={() => setpreviousBackground('no')} type="radio" name='default-radio' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">No</label>
                                </div>
                            </div>
                            <div className=" md:w-[50%] w-full mb-6 md:px-4 mt-[30px]">
                                <label
                                    htmlFor="program_studied"
                                    className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                >
                                    If the answer above  yes, then what ?
                                </label>
                                <select
                                    id="program_studied"
                                    name="program_studied"
                                    onChange={handleChange}
                                    className=" border border-[#D9D9D9] text-[#333333] text-sm py-3  block w-full p-2.5 "
                                >
                                    <option selected></option>
                                    <option value="I studied restaurant and catering">I studied restaurant and catering</option>
                                    <option value="I studied and worked as a chef/water/waitress">I studied and worked as a chef/water/waitress</option>
                                    <option value="I studied hospitality management">I studied hospitality management</option>
                                    <option value="I am a chef/waiter/waitress">I am a chef/waiter/waitress</option>
                                    <option value="None of the above but i want to study it">None of the above but i want to study it</option>
                                </select>
                            </div>

                            <div className='md:px-4 mt-[30px]'>
                                <h3 className='mb-2 text-[14px] font-semibold text-[#0A2E04]'>This is a one year program and it is tuition fees based program . This means you will pay tuition fees<br /> once you get accepted into the school in Finland. Are you interested? <span className='text-[#EE1D52] font-bold'> *</span> </h3>
                                <div className="flex items-center mb-4">
                                    <input required id="default-radio-3" onClick={() => setTutionFee('yes')} type="radio" name='default-radio3' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">Yes</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input required onClick={() => setTutionFee('no')} id="default-radio-3" type="radio" name='default-radio3' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-3" className="ml-2 text-sm font-medium text-gray-900 ">No</label>
                                </div>
                            </div>
                            <div className='md:px-4 mt-[30px]'>
                                <h3 className='mb-2 text-[14px] font-semibold text-[#0A2E04]'>The tuition fee is €11,500 and you are studying and working in a restaurant as a student chef<span className='text-[#EE1D52] font-bold'>*</span> </h3>
                                <div className="flex items-center mb-4">
                                    <input required id="default-radio-10" onClick={() => setTutionFeeAndWork('yes')} type="radio" name='default-radio10' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">Yes</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input required id="default-radio-10" onClick={() => setTutionFeeAndWork('no')} type="radio" name='default-radio10' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-10" className="ml-2 text-sm font-medium text-gray-900 ">No</label>
                                </div>
                            </div>
                            <div className='md:px-4 mt-[30px]'>
                                <h3 className='mb-2 text-[14px] font-semibold text-[#0A2E04]'>The program begins in January 2024. Do you have the funds to pay your tuition fees in December<br /> to start your studies in Finland in January 2024 ?<span className='text-[#EE1D52] font-bold'>*</span> </h3>
                                <div className="flex items-center mb-4">
                                    <input required id="default-radio-2" onClick={() => setHaveTutionFee('yes')} type="radio" name='default-radi2' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">Yes</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input required id="default-radio-2" onClick={() => setHaveTutionFee('no')} type="radio" name='default-radi2' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">No</label>
                                </div>
                            </div>
                            <div className=" md:w-[50%] w-full mb-6 md:px-4 mt-[30px]">
                                <label
                                    htmlFor="who_wiil_pay_for_tution"
                                    className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                >
                                    Who will be paying the tution fee?<span className='text-[#EE1D52] font-bold'> *</span>
                                </label>
                                <select
                                    id="who_wiil_pay_for_tution"
                                    name="who_wiil_pay_for_tution"
                                    required
                                    onChange={handleChange}
                                    className=" border border-[#D9D9D9] text-[#333333] text-sm py-3  block w-full p-2.5 "
                                >
                                    <option selected></option>
                                    <option value="myself">Myself</option>
                                    <option value="parents">Parents</option>
                                    <option value="guardian">Guardian</option>
                                    <option value="sponsor">Sponsor</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div className=" md:w-[50%] w-full mb-6 md:px-4 mt-[30px]">
                                <label
                                    htmlFor="where_do_you_hear_about_us"
                                    className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                >
                                    Where did you read about this survey ?<span className='text-[#EE1D52] font-bold'> *</span>
                                </label>
                                <select
                                    id="where_do_you_hear_about_us"
                                    name="where_do_you_hear_about_us"
                                    required
                                    onChange={handleChange}
                                    className=" border border-[#D9D9D9] text-[#333333] text-sm py-3   block w-full p-2.5 "
                                >
                                    <option selected></option>
                                    <option value="twitter">Twitter</option>
                                    <option value="facebook">Facebook</option>
                                    <option value="instagram">Instagram</option>
                                    <option value="email marketing">Email marketing</option>
                                    <option value="google search">Google Search</option>
                                    <option value="Telegram">Telegram</option>
                                    <option value="solomon">Solomon</option>
                                    <option value="mr toye">Mr Toye</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>






                            {/* <div className='md:px-4 mt-[50px] flex justify-center'>
                                <button
                                    type="submit"
                                    className=" text-white bg-[#1DB459] hover:bg-[#05401C] rounded-full font-medium text-sm px-10 py-3 mr-2 mb-2"

                                >
                                    Submit
                                </button>
                            </div> */}

<div className='md:px-4 mt-[50px] flex justify-center'>
                        <button
                            type="submit"
                            onClick={()=>setCurrency("USD")}
                            className=" text-white bg-[#1DB459] hover:bg-[#05401C] rounded-full font-medium text-[13px] px-5 py-3 mr-2 mb-2"

                        >
                          Pay in Dollars
                        </button>

                        <button
                            type="submit"
                            onClick={()=>setCurrency("NGN")}
                            className=" text-white bg-[#1DB459] hover:bg-[#05401C] rounded-full font-medium text-[13px] px-5 py-3 mr-2 mb-2"

                        >
                            Pay in Naira
                        </button>
                    </div>


                        </div>


                    </form>


                </div>
            </div>
            {/* End Section */}

            <Modal open={openPayment} onClose={onClosePaymentModal} center>
                                <div className='md:max-w-md  body-font font-poppins'>
                                    <div className="flex flex-wrap mt-4">
                                        <h2 className='text-center text-[#1DB459] font-bold md:text-[20px] text-[18px] leading-8'>Restaurant and Catering Vocational Program in English Pathway in Finland, with TUITION FEES</h2>
                                        <p className='text-center text-[#838383] md:text-[14px] text-[12px] mt-4'>This form is prepared for those ready to study Restaurant and Catering in English in Finland paying tuition fees.</p>
                                        <h3 className='md:text-[14px] text-[12px] mt-4 font-semibold text-center text-[#DC143C]'>PLEASE DO NOT FILL IF YOU CANNOT AFFORD TO PAY TUITION FEES</h3>

                                        
                                        <h3 className='md:text-[14px] text-[12px] text-black font-light mt-4'><span className='font-bold'>Data privacy:</span>  Please be aware that any information provided will be used  to verify your identity for future referencing and also for your Visa and Residence Permit processing.</h3>
                                        <hr className="h-px mb-8 mt-[20px] bg-[#F0F4F9] border-0" />
                                        
                                        <div className='flex mx-auto space-x-4 mt-6'>
                                           
                                            <button
                                                type="button"
                                                onClick={onClosePaymentModal}
                                                className=" text-white bg-[#1C8B48] rounded-[5px] font-medium text-sm md:px-5 px-3 md:py-3 py-2.5 mr-2 mb-2"
                                            >
                                               Proceed
                                            </button>
                                          
                                            
                                            <button
                                                type="button"
                                                className=" text-white bg-[#FF0000] rounded-[5px]  font-medium text-sm md:px-5 px-8 md:py-3 py-2.5 mr-2 mb-2"
                                                onClick={() => navigate(-1)}
                                            >
                                                Cancel
                                            </button>
                                           
                                            
                                        </div>
                                        
                                        <hr className="h-px mb-8 mt-[20px] bg-[#F0F4F9] border-0" />
                                        <p className='md:text-[12px] text-[10px] text-center mt-3 text-[#838383]'>By proceeding, you confirm that you have read and agree to the Terms and Conditions outlined above. If you do not agree, please click <span className='font-bold'>"Cancel"</span> to abort the registration process</p>
                                    </div>
                                </div>

                            </Modal>

            <Modal open={open} onClose={onCloseModal} center>
                <div className='md:max-w-md  body-font font-poppins'>
                    <p className='pt-10'>Registration Successful!</p>
                    <div className="flex flex-wrap mt-4">

                        <div className='flex mx-auto space-x-4 mt-6'>
                            <NavLink to={"/course-details/resturant-and-catering-services"}>
                                <button
                                    type="button"

                                    className=" text-white bg-[#1C8B48] rounded-[5px] font-medium text-sm md:px-5 px-3 md:py-3 py-2.5 mr-2 mb-2"
                                >
                                    Done
                                </button>
                            </NavLink>


                        </div>


                    </div>
                </div>

            </Modal>

            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default RegisterCook;