import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthApis } from "../../../apis/authApis";
import { Dispatch } from "redux";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../reducer/loginSlice';
import 'react-toastify/dist/ReactToastify.css';

// @ts-ignore
import { PhoneInput } from "react-contact-number-input";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function Register() {
  const [phone, setPhone] = useState<any>('');
  const [searchParams, setSearchParams] = useSearchParams();
  // console?.log(searchParams.get('ref'));
  const [refId, setRefId] = useState<any>(searchParams.get('ref'));
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPassordError] = useState('');

  // Show password
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const dispatch = useDispatch();
  const [userData, setUserdata] = useState({
    'email': "",
    'f_name': "",
    'l_name': "",
    'phone_number': "",
    'gender': "",
    'referrer_id': '',
    'programs_type': "study in finnish",
    'category':"",
    'password': "",
    'confirm_password': "",
    'role':'user'
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });

    
  // Password strength validation
  if (name === 'password') {
    // const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]{8,}$/;

    const isValidPassword = strongPasswordRegex.test(value);
    if (!isValidPassword) {
      setPassordError('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.');
    } else {
      setPassordError('');
    }
  }

  // Confirm password match validation
  if (name === 'confirm_password') {
    if (value !== userData.password) {
      setPassordError("Password and Confirm Password don't match.");
    } else {
      setPassordError('');
    }
  }

    if (name === 'email') {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
  
      // Update the email error state based on validation result
      setEmailError(isValidEmail ? '' : 'Please enter a valid email address.');
    }
  };

  const navigate = useNavigate();


  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

          // Check if password and confirm password match
  if (userData.password !== userData.confirm_password) {
    toast.error("Password and Confirm Password must match.");
    return; // Prevent form submission if passwords don't match
  }
    const formData = new FormData()
    formData.append('email', userData?.email)
    formData.append('name', userData?.f_name)
    formData.append('last_name', userData?.l_name)
    formData.append('phone_number', phone?.validData?.phoneNumber)
    formData.append('gender', userData?.gender)
    formData.append('role', userData?.role)
    formData.append('category', userData?.category)
    formData.append('programs_type', userData?.programs_type)
    formData.append('referrer', refId ? refId : userData?.referrer_id)
    formData.append('confirm_password', userData?.confirm_password)
    formData.append('password', userData?.password)


    AuthApis.register(formData).then(
      (response) => {
        if (response?.data) {
          console.log(response.data)
          toast.error(response?.data?.message);
          if (response?.data?.success === true) {
            dispatch(login({ email: userData?.email, isVerified: response.data.data?.isVerified, token: response.data.data.token, id: response.data.data.account_id, name: response.data.data.name, data: response.data.data }))
    setLoading(false);
            navigate('/email-verify');
          } else {
            toast.success(response?.data?.message);
    setLoading(false);

          }
        } else {
          setLoading(false);

        }

        // toast.success(response?.data?.message);
      }
    ).catch(function (error) {
      // handle error
      console.log(error.response.data);
      toast.error(error.response.data?.message);
    setLoading(false);

    }).finally(() => {
      // toast.error("No Internet Connection");
      setLoading(false);

    });
  }

  const handleInput = (event:any) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z]*$/; // Regular expression to match alphabetic characters only

    if (!regex.test(value)) {
      event.target.value = value.replace(/[^a-zA-Z]/g, ''); // Remove any non-alphabetic characters
    }
  };

  return (
    <>
      {/* <!-- Start block --> */}
      <section className="bg-white  body-font font-poppins ">
        <NavLink to={"/"}>
          <div className=" flex space-x-2 px-8 pt-8 font-semibold text-[#48B774] cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M21 11H6.83l3.58-3.59L9 6l-6 6l6 6l1.41-1.42L6.83 13H21v-2Z" /></svg>
            <p>Go back </p>
          </div>
        </NavLink>
        <div className="grid  max-w-screen-xl  lg:gap-8 xl:gap-0 lg:grid-cols-12  lg:mt-8">
          {/* grid max-w-screen-xl mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12 */}

          <div className="hidden lg:col-span-6 lg:flex w-full">
            <div className="relative pt-10">
              <img src="/images/sign-up.png" alt="sign-in" className="" />
              <div className="absolute bottom-0 left-0 right-0 px-10 py-14">
                <h3 className="text-3xl text-white font-bold">
                  Make the best investment <br />
                  in your future today....

                </h3>
                <p className="mt-6 text-sm text-gray-300">
                  Embark on that Journey to the worlds happiest nation and see for yourself why Finland is so blessed.

                </p>
              </div>
            </div>
          </div>

          <div className="lg:pl-24 lg:col-span-6 lg:py-4 mt-8 px-2 py-10">
            <h1 className="max-w-2xl text-3xl text-[#48B774] font-semibold leading-10 md:text-3xl xl:text-4xl text-center ">
              Create your account
            </h1>
            <p className="text-xs mt-2 mb-6 text-gray-500 text-center">
              Enter the fields below to get started
            </p>

            <div className=" mt-6">
              <form onSubmit={handleSubmit}>

                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                  <div className="relative flex flex-col min-w-0 break-words w-full  ">
                    <div className="flex-auto   py-10 pt-0">
                      <div className="flex flex-wrap ">

                        <div className="w-full lg:w-6/12 md:pr-4">
                          <div className="relative w-full mb-6 mt-4">
                            <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                              First Name <span className="text-red-700">*</span>
                            </label>
                            <input
                              type="text"
                              className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                              placeholder="Enter First Name"
                              name="f_name"
                              onChange={handleChange}
                              onInput={handleInput}
                              required

                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-6/12 ">
                          <div className="relative w-full mb-6 md:mt-4 mt-0">
                            <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                              Last Name <span className="text-red-700">*</span>
                            </label>
                            <input
                              type="text"
                              className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                              placeholder="Enter Last Name"
                              name="l_name"
                              required
                              onChange={handleChange}
                              onInput={handleInput}
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-6/12 md:pr-4 ">
                          <div className="relative w-full mb-6">
                            <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                              Email Address <span className="text-red-700">*</span>
                            </label>
                            <input
                              type="email"
                              className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                              placeholder="Enter email"
                              name="email"
                              required
                              onChange={handleChange}
                              value={userData.email}
                            />
                           {emailError && <div className="text-red-500 text-sm mt-1">{emailError}</div>}
  </div>
                        </div>

                        <div className="w-full lg:w-6/12 ">
                          <div className="relative w-full mb-6">
                            <label htmlFor="role" className="block mb-2 text-sm font-semibold text-[#0A2E04] ">Register As <span className="text-red-700">*</span></label>
                            <select id="role" required name="role" onChange={handleChange} className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px]  block w-full py-3 px-2.5">
                              <option selected>Choose one</option>
                              <option value="user">User</option>
                              <option value="agent">Agent</option>
                             
                            </select>
                          </div>
                        </div>

                        <div className="w-full lg:w-6/12 md:pr-4">
                          <div className="relative w-full mb-6">
                            <label htmlFor="gender" className="block mb-2 text-sm font-semibold text-[#0A2E04] ">Gender <span className="text-red-700">*</span></label>
                            <select id="gender" required name="gender" onChange={handleChange} className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px]  block w-full py-3 px-2.5">
                              <option selected>Choose a Gender</option>
                              <option value="female">Female</option>
                              <option value="male">Male</option>
                              <option value="other">I prefer not to say</option>
                            </select>
                          </div>
                        </div>

                        

                        <div className="w-full lg:w-6/12 md:pr-4">
                          <div className="relative mb-6">
                            <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                              Phone No <span className="text-red-700">*</span>
                            </label>
                            <PhoneInput
                              style={{ backgroundColor: '#F5F5F5', width:"80%"}}
                              disabled={false}
                              className={"p-3 mr-4"}
                              containerClass={"border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-1 "}
                              countryCode={'ng'}
                              onChange={setPhone}
required
                            />
                            <div className="pl-2 mt-1 text-xs text-red-500">{phone?.validData?.phoneNumber === null ? 'Enter valid phone number *' : ''}</div>

                          </div>
                        </div>
                        <div className="w-full lg:w-12/12 ">
                          <div className="relative w-full mb-6">
                            <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                              Referral Code(Optional)
                            </label>
                            <input
                              type="text"
                              className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                              placeholder="Enter Code"
                              onChange={handleChange}
                              disabled={refId}
                              defaultValue={refId ? refId : ''}
                              name="referrer_id"
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-6/12 md:pr-4">
                          <div className="relative w-full mb-6">
                            <label htmlFor="programs_type" className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                              Pathway <span className="text-red-700">*</span>
                            </label>
                            <select 
                            id="programs_type" 
                            //  value={userData?.programs_type}
                            name="programs_type" 
                            onChange={handleChange}
                            required 
                            className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px]  block w-full py-3 px-2.5">
                              <option selected></option>
                              <option value="study in finnish">Study In Finnish</option>
                              <option  value="study in english">Study In English</option>
                            </select>
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12 ">
                          <div className="relative w-full mb-6">
                            <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                              Category <span className="text-red-700">*</span>
                            </label>
                            <select 
      id="category" 
      name="category" 
      onChange={handleChange} 
      required
      className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full py-3 px-2.5"
    >
      <option value="" selected></option>

      {/* Conditionally render options based on the selected programs_type */}
      {userData?.programs_type === "study in finnish" && (
        <>
          <option value="EASYGOEDU">EASYGOEDU</option>
          <option value="CAREEREDU">CAREEREDU</option>
        </>
      )}

      {userData?.programs_type === "study in english" && (
        <>
          <option value="english_vocational">Vocational</option>
          <option value="degree_program">Degree Program</option>
          <option value="postgraduate">Post Graduate</option>
        </>
      )}
    </select>
                          </div>
                        </div>

                        <div className="w-full lg:w-6/12 md:pr-4">
                          <div className="relative w-full">
                            <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                              Password <span className="text-red-700">*</span>
                            </label>
                            <input
                           type={!showOldPassword ? "password" : "text"}

                              className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                              placeholder="Enter password"
                              onChange={handleChange}
                              name="password"
                              required

                            />
                  <button
                    type="button"
                    onClick={() => setShowOldPassword(() => !showOldPassword)}
                    className={`absolute right-4 top-[43px]`}
                  >
                    {!showOldPassword ? (
                      <AiOutlineEyeInvisible className='' />
                    ) : (
                      <AiOutlineEye className='' />
                    )}
                  </button>
                          </div>
                          {passwordError && (
  <div className="text-red-500 text-xs mt-1">{passwordError}</div>
)}
                        </div>
             
                        <div className="w-full lg:w-6/12">
                          <div className="relative w-full mt-6 md:mt-0">
                            <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                              Confirm Password <span className="text-red-700">*</span>
                            </label>
                            <input
                                               type={!showPassword ? "password" : "text"}

                              className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                              placeholder="Enter password"
                              onChange={handleChange}
                              name="confirm_password"
                              required

                            />
                                <button
                    type="button"
                    onClick={() => setShowPassword(() => !showPassword)}
                    className={`absolute right-4 top-[43px]`}
                  >
                    {!showPassword ? (
                      <AiOutlineEyeInvisible className='' />
                    ) : (
                      <AiOutlineEye className='' />
                    )}
                  </button>
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>
                </div>











                {/* <NavLink to={"/"} className="mt-3 "> */}
                <button
                  type="submit"
                  disabled={loading || emailError || passwordError || phone?.validData?.phoneNumber === null ? true : false}
                  className="w-full text-white bg-[#48B774] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                >
                  {loading ? <LoadingSpinner /> : "Create account"}
                  
                </button>
                {/* </NavLink> */}
                <p className="text-center text-xs font-semibold">
                  Already have an account?{" "}
                  <NavLink to="/sign-in">
                    <span className="text-[#48B774] cursor-pointer font-bold">log in</span>
                  </NavLink>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>


      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

      {/* <!-- End block --> */}
    </>
  )
}

export default Register