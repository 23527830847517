import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
// @ts-ignore
import Carousel from 'react-grid-carousel'
import Navbar from '../Navbar'
import highbg from "../../img/high-bg.svg";
// import high from "../../img/high.png";
import high from "../../img/revamp-highsch-bg.png";
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'
import Footer from '../Footer';
import Lottie from "lottie-react";
import animationData from "../assets/play.json"

function HighSch() {

  const [openTestVid, setOpenTestVid] = useState(0);

  const [openModal, setOpenModal] = useState(false);

  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);


  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section
        style={{
          backgroundImage: `url(${high})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="bg-white body-font font-poppins md:pt-20 pt-10">

        <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl px-8 lg:px-10 pt-20 mx-auto lg:gap-20 ">
          <div className="mr-auto place-self-center pt-10">

            <h1 className="max-w-2xl md:mb-2 text-3xl text-[#000000] font-semibold leading-10 md:text-3xl xl:text-4xl">
              High School

            </h1>
            <h1 className="max-w-2xl mb-4 text-3xl text-[#000000]  font-semibold leading-10 md:text-3xl xl:text-4xl">
              Program Overview
            </h1>
            <div className="lg:px-0">
              <p className="max-w-2xl mb-2 mt-6 font-light text-[#838383]   text-sm leading-relaxed">
                All High Schools in Finland are of equal quality no matter the size or location. They use the same equipment and same teaching methodology.
              </p>
              <div>
                <div className="flex space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                  <h3 className="mt-1 font-normal text-[#838383] text-[14px]">All Teachers must have at least a Master’s Degree.</h3>
                </div>
                <div className="flex space-x-2 mt-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                  <h3 className="mt-1 font-normal text-[#838383] text-[14px]">The academic year is generally divided into 5 study periods.</h3>
                </div>
                <div className="flex space-x-3 mt-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                  <h3 className="mt-1 font-normal text-[#838383] text-[14px]">All Teachers must have at least a Master’s Degree.</h3>
                </div>
                <div className="flex space-x-3 mt-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                  <h3 className="mt-1 font-normal text-[#838383] text-[14px]">The academic year is generally divided into 5 study periods.</h3>
                </div>
              </div>


              {/* <p className="max-w-2xl mb-2 font-light text-[#000000]   text-sm leading-relaxed">
                                All Teachers must have at least a Master’s Degree.
                                The academic year is generally divided into 5 study periods.
                                After each period, a test is given to the students on the subjects studied during that period
                            </p>

                            <p className="max-w-2xl  font-light text-[#000000]   text-sm leading-relaxed">
                                Some High Schools offer special activities as part of the studies such as: Floorball, Horse Riding, Ice-Hockey, Basketball, Esports, Music, Medicine, etc.!
                            </p> */}
            </div>



          </div>
          <div className="hidden md:block mt-6 lg:mt-0 px-6">
            <img src="/images/revamp-highsch-banner.png" alt="location" />
          </div>
        </div>

      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-[#000000] pb-5 body-font font-poppins">
        <div className="  lg:px-14 pt-5 pb-3 mx-auto  relative">
          <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl md:mt-20 px-8">

            <div >
              <video className="md:w-[70%] h-auto max-w-full border-[5px] border-[#ffffff] rounded-[15px]" controls>
                <source src="/images/finland-highsch.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="mt-5">
              <h1 className='text-white font-semibold md:text-[32px] text-[28px]'>High School</h1>
              <h1 className='text-white font-semibold md:text-[32px] text-[28px]'>Program Overview</h1>
              <p className='text-white mt-6 text-[14px] font-normal'>All High Schools in Finland are of equal quality no matter the size or location. They use the same equipment and same teaching methodology. </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-[#D7F5DC] ">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-10 lg:px-6">
          <div className="mx-auto max-w-screen-md text-center mb-8  p-6">
            <h2 className=" md:text-3xl text-2xl tracking-tight font-extrabold text-gray-900 ">
              Academic Timeline
            </h2>
            <div className='mt-5'>
              <span className="bg-[#1DB459] text-white text-base font-medium inline-flex items-center px-8 py-2 rounded-full mr-2 ">
                <svg aria-hidden="true" className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
                2-4 years, average 3 years
              </span>
            </div>
            <div className='text-center mt-3'>
              <small className='text-[#838383]  text-base font-medium'>150 Credits</small>
            </div>

          </div>
          <div className='flex justify-center'>
            <img src="/images/timeline.svg" alt="friends" />
          </div>
          <div className='flex justify-center mt-5'>
            <div className='border border-[#1DB459] px-6 py-2'>
              <p className='text-sm'>60% credits compulsory studies, 40% credits of free-choice studies</p>
            </div>

          </div>

        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="body-font font-poppins md:pt-10 pt-5 pb-16">
        <div className="mx-auto max-w-screen-md text-center mb-8  p-6">
          <h2 className="hidden md:block text-3xl tracking-tight font-extrabold text-gray-900 ">
            <span className='text-[#48B774]'>High School</span> Partners
          </h2>
          <h2 className="block md:hidden text-2xl tracking-tight font-bold text-gray-900 ">
           <span className='text-[#48B774]'>High School</span> Partners
          </h2>
          <p className='mt-3 text-[#838383]'>And many more schools for you to browse when applying to the program.</p>
        </div>
        <div className=''>
          <Carousel cols={4} rows={1} gap={10} loop showDots>
            <Carousel.Item>
              <img width="100%" src="/images/slider1.jpg" />
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src="/images/slider2.jpg" />
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src="/images/slider3.jpg" />
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src="/images/slider4.jpg" />
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src="/images/slider1.jpg" />
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src="/images/slider2.jpg" />
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src="/images/slider3.jpg" />
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src="/images/slider4.jpg" />
            </Carousel.Item>
            {/* ... */}
          </Carousel>
        </div>
      </section>
      {/* <!-- End block --> */}



      {/* <!-- Start block --> */}
      <section className=" md:mb-10   pb-5 body-font font-poppins">
        <div
          style={{
            backgroundImage: `url(${highbg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="  lg:px-14 md:pt-20 pt-10 pb-24 mx-auto justify-center  flex flex-col lg:flex-row lg:space-x-10  relative"
        >
          {/* <div className=" hidden md:block">
                        <img src="/images/highsch-timeline.svg" alt="hero" className="" />
                    </div> */}
          <div className='md:hidden block px-8'>

            <h1 className='text-2xl font-bold text-white'>Registration Information <br />for Finnish Language Program</h1>
            {/* <div className='mt-5'>
                            <img src="/images/highsch-timeline-mobile.png" alt="hero" className="" />
                        </div> */}

          </div>
          <div className='text-white mt-5 px-8'>
            <h1 className='hidden md:block text-4xl font-bold'>Registration Information <br />for Finnish Language Program</h1>
            <h5 className="text-white mt-[8px] md:text-[18px] text-[14px] font-medium">Benefits :</h5>
            <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to our expert language teachers</h5>
            <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to our Finnish Language certificate</h5>
            <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to apply to our partner schools in Finland</h5>
            <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to our language materials</h5>
            <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to our learning portals and entire language learning journey.</h5>
            <h5 className="text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to our visa and documentation processing and support</h5>
            <h5 className="md:w-[450px] text-white mt-[12px] md:text-[15px] text-[13px] font-light">Access to get support to apply for apartments and some basic supports needed on or before arrival to Finland</h5>
            {/* <div>
                            <button
                                type="submit"
                                className="py-2.5 px-5  text-sm font-medium text-black bg-white rounded-lg border border-white "
                            >
                                Apply Now
                            </button>
                        </div> */}
          </div>
          <div className="hidden md:block px-8 mt-5">

            <img src="/images/highsch-pic.svg" alt="hero" className="" />
          </div>

        </div>
      </section>
      {/* <!-- End block --> */}


      {/* <!-- Start block --> */}
      <section className="body-font font-poppins pb-16">
        <div className='hidden md:block'>
          <div className="mx-auto max-w-screen-md text-center mb-8  p-6">
            <h2 className=" text-3xl tracking-tight font-extrabold text-black ">
              The <span className='text-[#48B774]'>Language</span>
            </h2>
            <h2 className=" text-3xl tracking-tight font-extrabold text-[#48B774] ">
              Program <span className='text-black'>Learning Process</span>
            </h2>
          </div>

          <div className='flex justify-center mt-5'>
            <img src="/images/highscprocess.svg" alt="friends" />
          </div>
        </div>

        <div className='block md:hidden px-8 text-center pt-5'>
          <h2 className=" text-2xl tracking-tight font-extrabold text-black ">
            The <span className='text-[#48B774]'>Language</span>
          </h2>
          <h2 className=" text-2xl tracking-tight font-extrabold text-[#48B774] ">
            Program <span className='text-black'>Learning Process</span>
          </h2>
          <div className='flex justify-center mt-5'>
            <img src="/images/highscprocess-mobile.png" alt="friends" />
          </div>
        </div>

      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB] body-font font-poppins md:pt-10 pt-5 pb-16">
        <div className="mx-auto text-center mt-6">
          <h1 className="hidden md:block text-[#000000] text-3xl px-6 font-semibold">
            What <span className='text-[#48B774]'> Our Students</span> Say
          </h1>
          <h1 className="block md:hidden text-[#000000] text-2xl px-6 font-semibold">
            What <span className='text-[#48B774]'> Our<br /> Students</span> Say
          </h1>
        </div>
        <div className="md:hidden flex justify-end text-[#48B774] text-[24px] px-6 mb-4"> ➜</div>
        <div className='max-w-screen-xl px-8 lg:px-14 md:pt-10 pb-8 mx-auto lg:gap-20'>
          <Carousel cols={2} rows={1} gap={30} loop >
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
                {/* <div className="flex justify-end" onClick={() => {onOpenModal();setOpenTestVid(1);}} >
                  <Lottie animationData={animationData} className='md:w-[10%] w-[30%] cursor-pointer' />
                </div> */}
                <div className="flex flex-col lg:flex-row lg:space-x-6">

                  <img src="/images/peter.png" alt="location" className="md:w-[30%] md:h-[30%] mt-3" />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      I recently had a remarkable language experience that I would like to share.  It was a transformative experience that I highly recommend to anyone seeking personal and linguistic growth. A very big thank you to AfriProEdu for helping me with my academic journey to Finland.
                    </p>
                    <h1 className="text-base  font-semibold">Peter</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
                {/* <div className="flex justify-end" onClick={() => {onOpenModal();setOpenTestVid(2);}} >
                  <Lottie animationData={animationData} className='md:w-[10%] w-[30%] cursor-pointer' />
                </div> */}
                <div className="flex flex-col lg:flex-row lg:space-x-6">

                  <img src="/images/matilda.svg" alt="location" className="md:w-[30%] md:h-[30%] mt-3" />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      I’m very happy I decided to apply to learn Finnish language through AfriProedu. I heard about the program from AfriProedu and they have being of huge help to this achievement.They are very legit and safe and I highly recommend them for everyone. Kiitos tosi paljon AfriProedu .
                    </p>
                    <h1 className="text-base  font-semibold">Matilda</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
                {/* <div className="flex justify-end" onClick={() => {onOpenModal();setOpenTestVid(3);}} >
                  <Lottie animationData={animationData} className='md:w-[10%] w-[30%] cursor-pointer' />
                </div> */}
                <div className="flex flex-col lg:flex-row lg:space-x-6">

                  <img src="/images/favour.png" alt="location" className="md:w-[30%] md:h-[30%] mt-3" />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      Applying to learn the Finnish language program through AfriproEdu was a wonderful decision I made. I heard about the program through AfriProEdu, and they have been of great help to this achievement. They are very legit and safe. And I recommend AfriproEdu for everyone. Kiitos AfriproEdu!
                    </p>
                    <h1 className="text-base  font-semibold">Favour</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
                {/* <div className="flex justify-end" onClick={() => {onOpenModal();setOpenTestVid(4);}} >
                  <Lottie animationData={animationData} className='md:w-[10%] w-[30%] cursor-pointer' />
                </div> */}
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                  {/* <img src="/images/osose_icon.svg" alt="location" /> */}
                  <img src="/images/Osose.png" alt="location" className="md:w-[30%] md:h-[30%] mt-3" />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      Learning The Finnish language through AfriProEdu with Finest Future has been a lot more fun and helpful than I could have ever imagined. Not only have I learnt the language and Finnish culture but I’ve learnt about teamwork and social skills in the classes.
                    </p>
                    <h1 className="text-base  font-semibold">Osose</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>

              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
                {/* <div className="flex justify-end" onClick={() => {onOpenModal();setOpenTestVid(5);}}>
                  <Lottie animationData={animationData} className='md:w-[10%] w-[30%]' />
                </div> */}
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                  {/* <img src="/images/ornela_icon.svg" alt="location" /> */}
                  <img src="/images/Ornela.png" alt="location" className="md:w-[30%] md:h-[30%] mt-3" />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      Studying Finnish under AfriProEdu and Finest Future has been an awesome and fascinating program for all foreigners who want to five, stay and work in Finland. Studying Finnish for all this time has been captivating. </p>
                    <h1 className="text-base  font-semibold">Ornela</h1>

                  </div>
                </div>


              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
                {/* <div className="flex justify-end" onClick={() => {onOpenModal();setOpenTestVid(6);}}>
                  <Lottie animationData={animationData} className='md:w-[10%] w-[30%]' />
                </div> */}
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                  {/* <img src="/images/phoebe_icon.svg" alt="location" /> */}
                  <img src="/images/Phoebe.png" alt="location" className="md:w-[30%] md:h-[30%] mt-3" />
                  <div className=" mt-5">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 mt-6 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">

                      AfriProEdu and Finnest future program has highly competent teachers who made learning easy and interesting, as well as provide opportunities for foreign students to achieve their dreams. </p>
                    <h1 className="text-base  font-semibold">Phoebe</h1>

                  </div>
                </div>


              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
                {/* <div className="flex justify-end" onClick={() => {onOpenModal();setOpenTestVid(7);}}>
                  <Lottie animationData={animationData} className='md:w-[10%] w-[30%]' />
                </div> */}
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                  {/* <img src="/images/elodie_icon.svg" alt="location" /> */}
                  <img src="/images/Elodie.png" alt="location" className="md:w-[30%] md:h-[30%] mt-3" />
                  <div className=" mt-5">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 mt-6 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">

                      Learning Finnish has been a great experience so far, thanks to AfriProEdu and Finnest future. Before being part of this program, I wouldn't have never imagined learning a new language. So, I would say that AfriProEdu and Finnest future made me believe in myself. </p>
                    <h1 className="text-base  font-semibold">Elodie</h1>

                  </div>
                </div>


              </div>
            </Carousel.Item>

          </Carousel>
        </div>
        <div className='hidden md:block'>
          <NavLink to="/sign-up" className='flex justify-center'>
            <button
              type="button"
              className="mt-5 text-[#ffffff] bg-[#48B774]  font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center"
            >
              Apply Now
            </button>
          </NavLink>
        </div>

      </section>
      {/* <!-- End block --> */}
      <Modal open={openModal} onClose={onCloseModal} center>
        <div className='md:max-w-2xl body-font font-poppins'>

          <div className={openTestVid === 1 ? "flex flex-wrap mt-8" : "hidden"}>
            <iframe className="w-[50vw] h-full aspect-video " src="https://www.youtube.com/embed/UXOA5W_ndYo?si=9rxKdG_nb7MxbYC5"></iframe>

          </div>

          <div className={openTestVid === 2 ? "flex flex-wrap mt-8" : "hidden"}>
            <iframe className="w-[50vw] h-full aspect-video " src="https://www.youtube.com/embed/L6o5KxTVTz4?si=JP9uqz1nmYRJ124Y"></iframe>
          </div>

        </div>

      </Modal>

      <Footer />


    </>
  )
}

export default HighSch