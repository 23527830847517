import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../Navbar";
import image from "../../../img/Consultation-page.svg";
import imagemobile from "../../../img/consultation-mobile.svg";
import Footer from "../../Footer";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from '../../Navbars/UserNavbar'

function Consultation() {
  return (
    <>
      <Sidebar />

      <div className="relative md:ml-64 bg-blueGray-100">

        <UserNavbar title="Consultation" />
        <div className='md:py-5'>
        <section className="bg-[#FBFBFB] body-font font-poppins  relative">
        <div className="hidden md:block">
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            className="lg:px-14 pt-20 mx-auto flex flex-col lg:flex-row lg:space-x-14 px-6 py-8 lg:py-0 bg-cover bg-opacity-25"
          >
            <div className=" hidden lg:mt-0 lg:ml-36 lg:flex ">
              <img src="/images/consult-img.svg" alt="hero" />
            </div>

            <div className="mr-auto bg-[#48B774] md:bg-inherit text-white mt-24 lg:pl-10">
              <div className="hidden md:block">
                <h1 className=" text-4xl font-semibold">Do you need Clarity?</h1>
                <h1 className=" text-4xl font-semibold">Consult with an expert</h1>
                {/* <h1 className=" text-4xl font-semibold">for $50</h1> */}
              </div>
              

              <p className="md:text-base text-sm mt-5">
                We give you hands-on assistance during registration.
              </p>
              <p className="md:text-base text-sm md:mt-3">
                Proper clarity and answers to every question.
              </p>


              <div className="flex mt-8">
                <NavLink to="https://calendly.com/afripro/" className="">
                  <button
                    type="submit"
                    className="py-2.5 px-5  text-sm font-medium text-[#48B774] bg-white rounded border border-[#48B774] "
                  >
                    Book Consultation
                  </button>
                </NavLink>
                {/* <NavLink to="https://calendly.com/afripro/30min">
                <button
                  type="button"
                  className="ml-5 text-[#48B774] bg-white  font-medium rounded text-sm px-5 py-2.5 border border-[#48B774]"
                >
                  Make Payment

                </button>
              </NavLink> */}
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="block md:hidden pt-5 pb-8 px-6"
            style={{
              backgroundImage: `url(${imagemobile})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}

          >
            <div className="mr-auto bg-[#48B774] md:bg-inherit text-white mt-24 lg:pl-10">
              
              <div className="md:hidden block">
                <h1 className=" text-2xl font-semibold">Do you need Clarity?</h1>
                <h1 className=" text-2xl font-semibold">Consult with an </h1>
                {/* <h1 className=" text-2xl font-semibold">expert for $50</h1> */}
              </div>

              <p className="md:text-base text-sm mt-5">
                We give you hands-on assistance during registration.
              </p>
              <p className="md:text-base text-sm md:mt-3">
                Proper clarity and answers to every question.
              </p>


              <div className="flex mt-8">
                <NavLink to="https://calendly.com/afripro/" className="">
                  <button
                    type="submit"
                    className="py-2.5 px-5  text-sm font-medium text-[#48B774] bg-white rounded border border-[#48B774] "
                  >
                    Book Meeting
                  </button>
                </NavLink>
                {/* <NavLink to="https://calendly.com/afripro/30min">
                <button
                  type="button"
                  className="ml-5 text-[#48B774] bg-white  font-medium rounded text-sm px-5 py-2.5 border border-[#48B774]"
                >
                  Make Payment

                </button>
              </NavLink> */}
              </div>
            </div>
          </div>
        </div>

      </section>
        </div>
      </div>

      {/* <!-- Start block --> */}
     
      {/* <Footer /> */}
    </>
  );
}

export default Consultation;
