import React from 'react'
import { NavLink } from "react-router-dom";
import Sidebar from '../../Sidebar/Sidebar'
import UserNavbar from '../../Navbars/UserNavbar'

function EnglishAvailablePrograms() {

    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar title="Available Programs" />
                <div className='md:py-16'>

                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto md:px-16 mt-5 py-10">
                        <NavLink to={"/vocational-courses"} className="w-full flex justify-center">
                        <div className='md:mt-0'>
                        <img src="/images/Dashboard/program-vocational.png" alt="hero" />
                        </div>
                        </NavLink>
                        
                        <NavLink to={"/degree-courses"} className="w-full flex justify-center">
                        <div className='md:mt-16 mt-8'>
                            <img src="/images/Dashboard/program-degree.png" alt="hero" />
                        </div>
                        </NavLink>

                       <NavLink to={"/post-graduate-courses"} className="w-full flex justify-center">
                       <div className='md:mt-0 mt-8'>
                            <img src="/images/Dashboard/master.png" alt="hero" />
                        </div>
                       </NavLink>
                        
                        
                    </div>

                </div>
            </div>
        </>
    )
}

export default EnglishAvailablePrograms