import React from "react";
import AdminSidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AdminApis } from "../../../apis/adminApi";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const AddFaq = () => {
  const [faqOverview, setFaqOverview] = React.useState<any>("");

  var fontSizeStyle = Quill.import("attributors/style/size");
  fontSizeStyle.whitelist = ["12px", "14px", "20px", "24px"];
  Quill.register(fontSizeStyle, true);

  var toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: fontSizeStyle.whitelist }],
    ["bold", "italic", "underline", "link"], // toggled buttons
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ["clean"], // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const navigate = useNavigate();

  let [userData, setUserData] = React.useState({
    question: "",
    faq_category: "",
  });

  const handleChange = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const createSingleFaq = React.useCallback(
    (e: any) => {
      e.preventDefault();
      const faqData = new FormData();
      faqData.append("question", userData?.question);
      faqData.append("answer", faqOverview);
      faqData.append("faq_category", userData?.faq_category);

      AdminApis.createFaq(faqData)
        .then((response: any) => {
          if (response?.data) {
            // console.log(response?.data?.data)
            toast.success(response?.data?.message);
            navigate("/faq-list");
          }
        })
        .catch((err: any) => {
          if (err?.response?.status === 422) {
            console?.log(err);
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(err?.response?.data?.message);
          }
        });
    },
    [userData, faqOverview]
  );
  return (
    <div>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />
        <div className="lg:pt-10 lg:px-10 px-6 ">
          <div className="flex justify-between">
            <h3 className=" font-bold text-[31px]">Create Faq</h3>
          </div>
        </div>
        <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#D9D9D9] mx-6 lg:mt-4 lg:mx-10 mt-5">
          <form onSubmit={createSingleFaq}>
            <div className="flex md:flex-row flex-col sm:pt-10 py-6 md:px-8 px-4">
              <div className="basis-8/12">
                <div className="mt-[30px]">
                  <h3 className="text-[#1DB459] text-[15px] font-semibold">
                    Basic Information
                  </h3>
                  <div className="w-full lg:w-12/12  pt-4">
                    <div className="relative w-full mb-6">
                      <label
                        className="block text-[#303840] text-sm font-medium md:pl-1 mb-2"
                        htmlFor="grid-password"
                      >
                        Faq Question
                      </label>
                      <input
                        type="text"
                        name="question"
                        onChange={handleChange}
                        className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full lg:w-12/12 md:mb-[60px] mb-[120px]">
                  <div className="mb-[10px] text-[#303840] font-medium">
                    Faq Answer
                  </div>
                  <ReactQuill
                    theme="snow"
                    modules={module}
                    value={faqOverview}
                    onChange={setFaqOverview}
                    placeholder={"Content of the Editor"}
                    className="h-40"
                  />
                </div>
              </div>

              <div className="basis-4/12 md:ml-[66px]">
                <div className="mt-10">
                  <h3 className="text-[#303840] my-3 font-medium">Options</h3>
                  <div className=" flex-col border border-[#CBD6E2] rounded-[10px] px-3">
                    <div>
                      <div className="w-full lg:w-12/12  md:pt-4">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                            htmlFor="grid-password"
                          >
                            Category
                          </label>
                          <select
                            id="faq_category"
                            name="faq_category"
                            onChange={handleChange}
                            className=" border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg  block w-full p-2.5 "
                          >
                            <option selected></option>
                            <option value="general">General</option>
                            <option value="payment">Payment</option>
                            <option value="english pathway">
                              English Pathway
                            </option>
                            <option value="finish pathway">
                              Finish Pathway
                            </option>
                            <option value="before application">
                              Before Application
                            </option>
                            <option value="after application">
                              After Application
                            </option>
                            {/* <option value="master's program">Master's Program</option> */}
                          </select>
                          <span className="text-[12px] font-medium text-[#6E768C]">
                            Select a category.
                          </span>
                        </div>
                      </div>

                      {/* <div className="w-full lg:w-12/12  md:pt-4">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                            htmlFor="grid-password"
                          >
                            Product
                          </label>
                          <select
                            id="category2"
                            name="category2"
                            onChange={handleChange}
                            className=" border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg  block w-full p-2.5 "
                          >
                            <option selected></option>
                            <option value="afriPro Edu">AfriPro Edu</option>
                            <option value="AfriPay">AfriPay</option>
                            <option value="AfriPro Edu Global">
                              AfriPro Edu Global
                            </option>
                          </select>
                          <span className="text-[12px] font-medium text-[#6E768C]">
                            Select a category.
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-4/12 md:px-8 px-6 pb-6">
              <div className=" items-center">
                <button
                  type="submit"
                  className="font-normal text-white bg-[#1DB459] px-6 py-2.5 rounded-[3px]"
                >
                  Create Faq
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddFaq;
