import React from 'react'
import Navbar from '../../Navbar'
import blogbg from "../../../img/blog.png";
import Footer from '../../Footer';
import { NavLink } from 'react-router-dom';

function MidSummerInFinland() {
    return (
        <>
            <Navbar />

            {/* <!-- Start block --> */}
            <section className=" body-font font-poppins pt-16" >
                <div
                    style={{
                        backgroundImage: `url(${blogbg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className=" lg:px-14 pt-10 pb-24 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center text-white pt-20">
                        <h1 className="md:text-5xl text-3xl font-bold">
                            Midsummer in Finland.
                        </h1>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-[#F5F5F5]  body-font font-poppins">
                <div className='bg-white lg:mx-28 pb-10 lg:px-10 px-8 pt-10 '>
                    <div>
                    <div className='flex justify-center mt-5'>
                        <img src="/images/midsummer.jpeg" alt="location" className='md:w-[70%] w-full'/> 
                    </div>
                    <div className='flex justify-center mx-auto md:px-44'>
                    <p className='italic mt-1 text-[#333333]'>Midsummer in Finland</p>
                    </div>
                    
                    </div>
                    <h3 className='text-base text-[#000000] font-normal mt-5'>Midsummer, or Juhannus, is one of the most important and widely celebrated holidays in Finland. It takes place around the summer solstice, which usually falls between June 20 and June 26. In Finland, Midsummer is a public holiday, and many businesses and offices close for the celebration.</h3>
                    <h3 className='text-base text-[#000000] font-normal mt-5'>Midsummer marks the longest day of the year when the sun shines for the longest period, which is especially significant in the northern parts of Finland where the sun doesn't set at all for several weeks during the summer. This natural phenomenon is known as the "midnight sun" or "nightless night."</h3>
                    <h3 className='text-base text-[#000000] font-normal mt-2'>
                    Finns typically celebrate Midsummer by heading to their summer cottages in the countryside, where they can enjoy nature and the company of friends and family. Traditional activities during this time include:
                    </h3>
<ol className="space-y-1 text-[#000000] list-decimal list-inside">
    <li><span className='font-semibold'>Bonfires: </span>Lighting a large bonfire, or "kokko," is a central part of the Midsummer celebration. The bonfire is believed to ward off evil spirits and bring good fortune.</li>
<li><span className='font-semibold'>Sauna: </span>Enjoying a sauna is a quintessential Finnish experience, and many people take the opportunity to relax in a sauna during Midsummer.</li>
<li><span className='font-semibold'>Maypole: </span>In some parts of Finland, people erect a Maypole, or "midsummer pole," which is decorated with flowers and greenery.</li>
<li><span className='font-semibold'>Dancing and music: </span>Traditional Finnish music and dance are often a part of Midsummer celebrations, with people gathering to sing, dance, and enjoy live performances.</li>
<li><span className='font-semibold'>Food and drink: </span>Midsummer is a time for feasting on traditional Finnish dishes such as new potatoes, herring, and grilled sausages, as well as enjoying beer and other beverages.</li>
<li><span className='font-semibold'>Magic and folklore: </span>Midsummer is steeped in mythology and folklore, with many people engaging in rituals and customs for good luck, love, and fertility. For example, young women might pick seven different flowers and place them under their pillow to dream of their future spouse.
</li>
</ol>
                    
                   
                    
                    
                   
                    <div>
                        <h3 className='text-base text-[#000000] font-normal mt-5'>
                        Overall, Midsummer in Finland is a festive and joyous occasion that brings people together to celebrate the beauty of nature and the warmth of the season.

                        </h3>
                       
                    </div>

          
                </div>

            </section>
            {/* <!-- End block --> */}

            <Footer />
        </>
    )
}

export default MidSummerInFinland