import React, { useState } from 'react'
import { Link } from "react-router-dom";
import AdminSidebar from '../../Sidebar/AdminSidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import { SvgElement, icontypesEnum } from '../../assets/svgElement'
import { useSelector, useDispatch } from 'react-redux';
import { AuthApis } from '../../../apis/authApis';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
// @ts-ignore
import { PhoneInput } from 'react-contact-number-input';

function AddSubAdmin() {
  const [phone, setPhone] = useState<any>('');
  const dispatch = useDispatch();
  const [userData, setUserdata] = useState({
    'email': "",
    'f_name': "",
    'l_name': "",
    'phone_number': "",
    'gender': "",
    'role': '',
    'password': "",
    'confirm_password': ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };


  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData()
    formData.append('email', userData?.email)
    formData.append('name', userData?.f_name)
    formData.append('last_name', userData?.l_name)
    formData.append('phone_number', phone?.validData?.phoneNumber)
    formData.append('gender', userData?.gender)
    formData.append('role', userData?.role)
    formData.append('confirm_password', userData?.confirm_password)
    formData.append('password', userData?.password)


    AuthApis.register(formData).then(
      (response) => {
        if (response?.data) {
          if (response?.data?.success === true) {
            toast?.success(response?.data?.message)
          } else {
            toast.error(response?.data?.message);
          }
        } else {

        }

        // toast.success(response?.data?.message);
      }
    ).catch(function (error) {
      // handle error
      console.log(error.response.data);
      toast.error(error.response.data?.message);
    }).finally(() => {
      // toast.error("No Internet Connection");

    });
  }


  return (
    <>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />
        <div className=" shadow-md rounded-lg border-2 border-[#c4c4c4] mx-6 mt-3 lg:mt-16 lg:mx-16">
          <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl  pl-3 pr-4 mt-5">
            <div className="relative flex flex-col min-w-0 break-words w-full  ">
              <div className="flex-auto   py-10 pt-0">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-6">
                        <label
                          className="block text-[#404D61] text-sm font-medium mb-2"
                          htmlFor="grid-password"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          name="f_name"
                          required
                          onChange={handleChange}
                          className="border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-6">
                        <label
                          className="block text-[#404D61] text-sm font-medium mb-2"
                          htmlFor="grid-password"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="l_name"
                          required
                          onChange={handleChange}
                          className="border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                          placeholder=""

                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-6">
                        <label
                          className="block text-[#404D61] text-sm font-medium mb-2"
                          htmlFor="grid-password"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          name="email"
                          required
                          onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                          placeholder=" "

                        />
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block text-[#404D61] text-sm  font-medium mb-2"
                          htmlFor="grid-password"
                        >
                          Phone Number
                        </label>
                        <PhoneInput
                          style={{ backgroundColor: '#F5F5F5' }}
                          disabled={false}
                          containerClass={"border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 "}
                          countryCode={'ng'}
                          onChange={setPhone}
                          placeholder={'Enter Mobile Number'}
                        />
                        <div className="pl-2 mt-1 text-xs text-red-500">{phone?.validData?.phoneNumber === null ? 'Enter valid phone number *' : ''}</div>

                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-6">
                        <label
                          htmlFor="gender"
                          className="block text-[#404D61] text-sm  font-medium mb-2 "
                        >
                          Role
                        </label>
                        <select
                          id="gender"
                          name="role"
                          required
                          onChange={handleChange}
                          className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg  block w-full p-2.5 "
                        >
                          <option selected>Choose a Role</option>
                          <option value="admin">Super Admin</option>
                          <option value="sub admin">Sub Admin</option>
                        </select>
                      </div>
                    </div>


                    <div className="w-full lg:w-6/12 px-4">
                      <div className='relative w-full mb-6'>
                        <label htmlFor="gender" className="block mb-2 text-sm font-medium text-gray-900 ">Gender*</label>
                        <select id="gender" required name="gender" onChange={handleChange} className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg  block w-full p-2.5">
                          <option selected>Choose a Gender</option>
                          <option value="female">Female</option>
                          <option value="male">Male</option>
                          <option value="other">I prefer not to say</option>
                        </select>

                      </div>
                    </div>


                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-6">
                        <label
                          className="block text-[#404D61] text-sm font-medium mb-2"
                          htmlFor="grid-password"
                        >
                          Password
                        </label>
                        <input
                          name='password'
                          type="password"
                          onChange={handleChange}
                          className="  border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-6">
                        <label
                          className="block text-[#404D61] text-sm  font-medium mb-2"
                          htmlFor="grid-password"
                        >
                          Comfirm Password
                          {/* <span className="sr-only"> Last name</span> */}
                        </label>
                        <input
                          type="password"
                          onChange={handleChange}
                          name='confirm_password'
                          className="  border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                        />
                      </div>
                    </div>
                  </div>
                  <div className='px-4 mt-5'>
                    <button
                      type="submit"
                      className=" text-white bg-[#1DB459] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

    </>
  );
}

export default AddSubAdmin;