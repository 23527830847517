import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'

function EnglishCriteria() {
    return (
        <>
            <Navbar />
            <section className='md:pt-20 pt-20'>
                {/* <!-- Start block --> */}
                <div className='bg-[#FBFBFB] sm:px-16 px-4 md:flex md:justify-center items-start pt-10 pb-8'>
                    <div className=' w-full mx-auto'>
                        <div className='md:pr-40 md:pl-40 mx-4 mt-10'>
                            <div>
                                <h1 className='text-center md:text-[42px] text-[20px] font-semibold'>Language requirements</h1>
                                <p className='mt-3'>English language proficiency needs to be met by every applicant and it can be proved only in one of the below listed ways. </p>

                            </div>
                            <div className='bg-[#F0F5FE] mt-8 pt-3 pl-8 pr-6 pb-8'>
                                <h3 className='text-[20px] font-semibold'>Language test certificates</h3>
                                <p className='mt-4'>Depending on the language test in question, the language test certificates may be uploaded to the online application form by the applicant. Please see instructions for each test on this page.</p>
                                <p className='mt-4'>If the language certificate is asked to be submitted directly by the language test provider to the University, these certificates should be submitted by e-mail at <a href="mailto:admissions@afriproedu.com" className='text-[#1DB459] underline underline-offset-2'>admissions@afriproedu.com</a> .</p>
                                <p className='mt-4'>If the language skills required by the University of Vaasa are shown in your educational documents (Degree Certificate or Transcript of Records proving you meet the language requirements), you do not have to submit any additional attachments.</p>
                            </div>

                            <div className='mt-8'>

                                <p className='mt-3'>A Language Certificate issued by your home university or any other institution or organisation is not accepted. </p>
                                <p className='mt-5'>In the rolling admission, all the enclosures need to be submitted to the application form <span className=' font-semibold'>by the time of applying.</span> In case of missing enclosures, the application will be rejected.</p>
                                <p className='mt-5'>In the joint admission, all the enclosures need to be submitted to the application form at the latest within one week after the application deadline: by the 24th January 2024 at 15:00 (UTC+2). In case of missing enclosures, the application will be rejected.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End block --> */}
                {/* <!-- Start block --> */}
                <div className='bg-[#FFFFFF] sm:px-16 px-4 md:flex md:justify-center items-start pt-5 pb-8'>
                    <div className=' w-full mx-auto'>
                        <div className='md:pr-40 md:pl-40 mx-4 mt-5'>
                            <div>
                                <h1 className='text-center md:text-[38px] text-[20px] font-semibold'>Language requirements</h1>
                                <p className='mt-3'>These requirements concern the following Master's programs: </p>

                            </div>
                            <div className='mt-5'>
                                <ul className=" text-base space-y-1  list-disc list-inside ">
                                    <li>
                                    Master's Degree Programme in Finance
                                    </li>
                                    <li>
                                    Master's Degree Programme in International Business
                                    </li>
                                    <li>
                                    Master's Programme in Strategic Business Development
                                    </li>
                                    <li>
                                    Master's Programme in Industrial Management
                                    </li>
                                    <li>
                                    Master's Programme in Industrial Systems Analytics
                                    </li>
                                    <li>
                                    Master's Programme in Smart Energy
                                    </li>
                                    <li>
                                    Master's Programme in Strategic Project Management
                                    </li>
                                   
                                </ul>
                                <p className='mt-5'>For the Master's Programme in Sustainable and Autonomous Systems, please see the requirements in the next section.</p>
                            </div>
                            <div className='mt-6'>
                                <h3 className='md:text-[20px] text-[16px] font-semibold'>Language tests (valid two years from the date of the test)</h3>
                            <div className='mt-5'>
                            <ul className=" text-base space-y-1  list-disc list-outside ">
                                <li>IELTS test results (academic band) with minimum overall band score of 6.0 (each section minimum 5.5). IELTS Online is accepted.</li>
                                <li>TOEFL iBT (internet-based), TOEFL iBT Home Edition or TOEFL iBT Paper Edition minimum overall score 80, MyBest scores are accepted</li>
                                <li>PTE Academic (Pearson Test of English, Academic band) or PTE Academic Online results with minimum overall score of 54</li>
                                <li>National Certificate of Language Proficiency (YKI) in English awarded by the Finnish National Agency for Education: all subtests of the test completed on proficiency level 4 (reading comprehension, writing, listening comprehension, speaking)</li>
                                <li>Cambridge C1 Advanced test (Certificate in Advanced English) with minimum pass grade C </li>
                                <li>Cambridge C2 Proficiency test (Certificate of Proficiency in English) with minimum level C1 </li>
                            </ul>
                            </div>
                            </div>
                            <div className='mt-6'>
                                <h3 className='md:text-[20px] text-[16px] font-semibold'>Higher education in English in countries mentioned below</h3>
                            <div className='mt-5'>
                            <ul className=" text-base space-y-2  list-disc list-outside ">
                                <li>English language course(s) included in a Bachelor’s or Master’s degree conducted in Finnish or Swedish in Finland</li>
                                <li>Bachelor's, Master's, or Doctoral degree conducted in English language in Finland or another EU / EEA country, United Kingdom, Switzerland, United States, Canada, Australia or New Zealand</li>
                                <li>Degrees completed in English on offshore campuses are accepted if the main university is located in one of the countries listed above</li>
                                <li>At least 15 ECTS of studies in English as exchange student in a Higher Education Institution in Finland or another EU / EEA country, United Kingdom, Switzerland, United States, Canada, Australia or New Zealand</li>
                                <li>Bachelor's or Master's degree with English as the major subject (English language/literature/philology)</li>
                            </ul>
                            </div>
                            </div>
                            <div className='mt-6'>
                                <h3 className='md:text-[20px] text-[16px] font-semibold'>Secondary education in English in countries or examinations mentioned below</h3>
                            <div className='mt-5'>
                            <ul className=" text-base space-y-2  list-disc list-outside ">
                            <li>West African Senior School Certificate of Education (WASSCE) English with a minimum level of B3 (West African Education Council (WAEC) Board)</li>
                                <li>Certificate of secondary education completed in English language in Finland or another EU / EEA country, United States, Canada, Australia or New Zealand</li>
                                <li>Certificate of English as the A language with a minimum grade of Magna Cum Laude Approbatur as part of a Finnish Matriculation examination</li>
                                <li>Certificate of English as the A1 language (minimum grade 2) or A2 language (minimum grade 5) as part of an International Baccalaureate examination</li>
                                <li>Certificate of English as the A language (literature/language and literature/language and performance) as part of an International Baccalaureate examination (minimum grade 2)</li>
                                <li>Certificate of English as the LI (L1) language (minimum grade 4) or LII (L2) or LIII (L3) language (minimum grade 7) as a part of European Baccalaureate examination</li>
                                <li>Reifeprüfung (RP) or Deutsches Internationales Abitur (DIA) diploma completed at Deutsche Schule Helsinki: passing grade in the written level A English test; or a minimum grade of 8 in the oral level A English test</li>
                                <li>Indian 12th (XII) Standard English or English Core with a minimum grade of 80% or A2 (CISCE & CBSE boards only)</li>
                                <li>Kenyan Certificate of Secondary Education (KCSE) English with a minimum grade B- (B minus)</li>
                                
                                <li>Cambridge GCE A-level/International A-level or AS-level/International AS-level in English language or English Literature with minimum grade C</li>
                                <li>IGCSE/International GCSE (English as) First Language with a minimum grade of C or 4</li>
                                <li>IGCSE/International GCSE (English as) Second Language with minimum level B or 6 including speaking endorsement (Cambridge, Pearson Edexcel or Oxford exam boards)</li>
                            </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End block --> */}
                {/* <!-- Start block --> */}
                <div className='bg-[#FBFBFB] sm:px-16 px-4 md:flex md:justify-center items-start pt-5 pb-8'>
                    <div className=' w-full mx-auto'>
                        <div className='md:pr-40 md:pl-40 mx-4 mt-5'>
                        <div>
                                <h1 className='text-center md:text-[32px] text-[20px] font-semibold'>Language requirements for the Master's Programme in Sustainable and Autonomous Systems</h1>
                                </div>
                                <div className='mt-5'>
                            <ul className=" text-base space-y-2  list-disc list-outside ">
                                <li>TOEFL test results with minimum score of 92 in iBT (with minimum of 20 in Writing section)</li>
                                <li>IELTS Academic or IELTS Indicator test results 6.5 (with a minimum of 5.5 per each section)</li>
                                <li>PTE Academic (Pearson Test of English) test results with minimum overall score of 62 (with minimum of 52 in Writing Section)</li>
                                <li>C1 Advanced test pass level A, B, C</li>
                                <li>C2 Proficiency test pass level A, B, C or level C1 certificate </li>
                                <li>National Certificate of Language Proficiency by Finnish National Board of Education skill level 5 in the following subtests: reading, writing, listening and speaking</li>
                                <li>English language course(s) included in a Bachelor’s or Master’s degree conducted in Finnish or Swedish in Finland</li>
                                <li>Bachelor´s, Master´s or Doctoral degree in English, the degree must be completed in a university or university of applied sciences in EU/EEA country, Australia, Canada, Great Britain, Ireland, New Zealand or the United States excluding online degrees and offshore campuses</li>
                                <li>20 ECTS credits of studies conducted in English language at a Finnish higher education institution, credits must be completed at a Finnish University or a University of Applied Sciences, courses can include e.g. internships and language courses as long as the language of instruction can be verified to be English, the transcript of records must be clearly stating the courses completed in English</li>
                               
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End block --> */}
                {/* <!-- Start block --> */}
                <div className='bg-[#FFFFFF] sm:px-16 px-4 md:flex md:justify-center items-start pt-5 pb-8'>
                    <div className=' w-full mx-auto'>
                        <div className='md:pr-40 md:pl-40 mx-4 mt-5'>
                        <div>
                                <h1 className='text-center md:text-[32px] text-[20px] font-semibold'>How to submit certain test results</h1>
                                </div>
                                <div>
                                    <h3 className='mt-5 font-semibold text-[20px]'>IELTS</h3>
                                    <p className='mt-5 text-[16px]'>The University accepts language test results uploaded directly to the online application form by the applicant, or in paper format both from the test centre and from the applicant directly. If requesting the IELTS test centre to forward your language test results to the University of Vaasa, please write down University of Vaasa as the receiver of the test result.</p>
                                </div>
                                <div>
                                    <h3 className='mt-5 font-semibold text-[20px]'>TOEFL</h3>
                                    <p className='mt-5 text-[16px]'>When requesting the TOEFL test centre to forward your results to the University of Vaasa, please fill in the University of Vaasa's Institution Code 0647 in the Score Report Recipient field. The testing centre must send the score slip directly to the University or make its electronic equivalent available. Please note that test results sent by the applicant are not accepted.</p>
                                </div>
                                <div>
                                    <h3 className='mt-5 font-semibold text-[20px]'>PTE Academic</h3>
                                    <p className='mt-5 text-[16px]'>Pearson Test of English (PTE) Academic is a paperless test. Once you receive your test score, please send it to the University of Vaasa from your PTE account. Choose Finland as the 'Country', University of Vaasa as the 'Institution' and click 'Search'. Select University of Vaasa and the programme you are applying to. Then confirm your order. After this, University of Vaasa should have access to your test results within 48 hours. Please also submit the PTE result sheet to your application form. </p>
                                </div>
                                <div>
                                    <h3 className='mt-5 font-semibold text-[20px]'>National Certificate of English Language Proficiency by the Finnish National Board of Education</h3>
                                    <p className='mt-5 text-[16px]'>Submit your test results to AfriProEdu together with your application. Your result will be verified from the organiser.</p>
                                </div>
                                <div>
                                    <h3 className='mt-5 font-semibold text-[20px]'>C1/C2</h3>
                                    <p className='mt-5 text-[16px]'>Submit your test results to University of Vaasa together with your application. Your results will be verified from the test organiser. Include your Candidate's ID (three letters and six numbers) and secret code (four numbers), if applicable. If you do not have these codes, you can request them from the testing organisation. If the codes are unavailable, you should ask the test organiser for the Certifying Statement from Cambridge ESOL to be sent directly to the University of Vaasa.</p>
                                </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End block --> */}
                {/* <!-- Start block --> */}
                <div className='bg-[#FBFBFB] sm:px-16 px-4 md:flex md:justify-center items-start pt-5 pb-16'>
                    <div className=' w-full mx-auto'>
                        <div className='md:pr-40 md:pl-40 mx-4 mt-5'>
                        <div>
                                <h1 className='text-center md:text-[32px] text-[20px] font-semibold'>How to submit certain language verification documents of secondary education</h1>
                                </div>
                                <div>
                                    <h3 className='mt-5 font-semibold text-[20px]'>Cambridge English Results</h3>
                                    <p className='mt-5 text-[16px]'>The University can electronically verify all Cambridge AS & A-level, IGCSE and O-level <span className='font-semibold'>results that were awarded within the last five years.</span> Please ensure all parts of your certificate are clear and visible on the certificate copy you submit with your application. </p>
                                </div>
                                <p className='mt-5 text-[16px]'>For any results awarded more than five years ago, we are unable to verify these ourselves. Please liaise with the exam board and/or the school where your exams were taken for a verified set of results to be sent to <a href="mailto:admissions@afriproedu.com" className='text-[#1DB459] underline underline-offset-2'>admissions@afriproedu.com</a> These must reach us within seven days of your application being submitted. The results submitted later than that will not be taken into account.</p>
                                <div>
                                    <h3 className='mt-5 font-semibold text-[20px]'>WASSCE English (WAEC)</h3>
                                    <p className='mt-5 text-[16px]'>Preferred method: please provide your WAEC results checker log-in credentials/details within your application. These include your Examination number, PIN and Serial number. You will be asked to write these details on the application form, if you select that you have the WASSCE results, in order for us to verify your English Language result online. Please note whether you were a private or school candidate for your WASSCE exam.</p>
                                </div>
                                <p className='mt-5 text-[16px]'>Alternatively, please liaise directly with WAEC for your verified result to be sent directly to us by email <a href="mailto:admissions@afriproedu.com" className='text-[#1DB459] underline underline-offset-2'>admissions@afriproedu.com</a>. These must reach us within seven days of your application being submitted. Please ensure all parts of your certificate are clear and visible on the certificate copy you submit with your application. </p>
                                <div>
                                    <h3 className='mt-5 font-semibold text-[20px]'>Indian 12th English (CBSE)</h3>
                                    <p className='mt-5 text-[16px]'>Please provide your Indian 12th results checker log-in credentials/details within your application. These include your Roll Number, School Number and Admit Card ID). You will be asked to write these details on the application form, if you select that you have the Indian 12th results, in order for us to verify your English Language result online.</p>
                                </div>
                              <p className='mt-5 text-[16px]'>  Please ensure all parts of your certificate are clear and visible on the certificate copy you submit with your application.</p>
                              <div>
                                    <h3 className='mt-5 font-semibold text-[20px]'>Indian 12th English (CISCE)</h3>
                                    <p className='mt-5 text-[16px]'>Please provide your Indian 12th results checker log-in credentials/details within your application. These include Course, UID and Index number. You will be asked to write these details on the application form, if you select that you have the Indian 12th results, in order for us to verify your English Language result online.</p>
                                </div>
                              <p className='mt-5 text-[16px]'>  Please ensure all parts of your certificate are clear and visible on the certificate copy you submit with your application.</p>
                              <div>
                                    <h3 className='mt-5 font-semibold text-[20px]'>Kenyan School Certificate English (KSCE)</h3>
                                    <p className='mt-5 text-[16px]'>In order to verify your KSCE English language qualification, please liaise directly with the Kenya National Education Council (KNEC) for verified results to be sent to us by email <a href="mailto:admissions@afriproedu.com" className='text-[#1DB459] underline underline-offset-2'>admissions@afriproedu.com</a> (preferred method).</p>
                                </div>

                                <p className='mt-6 text-[16px]'>Your verified results must reach us <span className='font-semibold'>within seven days</span> of your application. </p>
                       <p className='mt-6 text-[16px] font-semibold'>Should you have credentials which allow us to verify you result online/electronically, please provide these in your application.</p>
                        </div>
                    </div>
                </div>
                {/* <!-- End block --> */}
            </section>

            <Footer/>
        </>
    )
}

export default EnglishCriteria