import React from 'react'

function LatestUpdate() {
  return (
    <div>
        <div className='flex flex-col justify-center items-center mt-16 pb-8'>
              
              {/* <div className="lg:w-[800px] bg-white py-[16px] px-[18px] rounded-[8px] mx-6 mb-4">
                <div className='flex space-x-2'>
                  <h4 className='lg:text-[16px] text-[13px] font-semibold mt-1'>We have just Launched a new Program !!! </h4>
                  <SvgElement type={icontypesEnum.CELEBRATE} />
                </div>

                <h2 className='mt-3 bg-gradient-to-r from-[#01963C] to-[#158FD3] text-transparent bg-clip-text lg:text-[24px] text-[20px] font-bold'>Title of the New Program Appears </h2>
                <div className='flex lg-flex-row lg:justify-between '>
                  <p className='mt-2 lg:text-[12px] text-[10px] text-[#777777]'>Read more information about this course on our website  or contact our customer<br /> service to get more info</p>

                  <div>
                    <button className='border border-[#1696BC] items-center text-[#0174B4] text-[12px] inline-flex rounded-[45px] py-[6px]  px-[24px]'>More Details
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className='ml-2' viewBox="0 0 32 32"><path fill="#0174B4" d="m16 8l-1.43 1.393L20.15 15H8v2h12.15l-5.58 5.573L16 24l8-8z" /><path fill="#0174B4" d="M16 30a14 14 0 1 1 14-14a14.016 14.016 0 0 1-14 14m0-26a12 12 0 1 0 12 12A12.014 12.014 0 0 0 16 4" /></svg></button>
                  </div>

                </div>

              </div>
              <div className="lg:w-[800px] bg-white py-[16px] px-[18px] rounded-[8px] mx-6 mb-4">
                <div className='flex space-x-2'>
                  <h4 className='lg:text-[16px] text-[13px] font-semibold mt-1'>We have just Launched a new Program !!! </h4>
                  <SvgElement type={icontypesEnum.CELEBRATE} />
                </div>

                <h2 className='mt-3 bg-gradient-to-r from-[#01963C] to-[#158FD3] text-transparent bg-clip-text lg:text-[24px] text-[20px] font-bold'>Title of the New Program Appears </h2>
                <div className='flex lg-flex-row lg:justify-between '>
                  <p className='mt-2 lg:text-[12px] text-[10px] text-[#777777]'>Read more information about this course on our website  or contact our customer<br /> service to get more info</p>

                  <div>
                    <button className='border border-[#1696BC] items-center text-[#0174B4] text-[12px] inline-flex rounded-[45px] py-[6px]  px-[24px]'>More Details
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className='ml-2' viewBox="0 0 32 32"><path fill="#0174B4" d="m16 8l-1.43 1.393L20.15 15H8v2h12.15l-5.58 5.573L16 24l8-8z" /><path fill="#0174B4" d="M16 30a14 14 0 1 1 14-14a14.016 14.016 0 0 1-14 14m0-26a12 12 0 1 0 12 12A12.014 12.014 0 0 0 16 4" /></svg></button>
                  </div>

                </div>

              </div> */}
              <div className="lg:w-[625px] bg-white py-[24px] px-[18px] rounded-[8px] mx-6 mb-4">
                <h1 className='text-center items-center  lg:text-[48px] text-[40px] bg-gradient-to-r from-[#01963C] to-[#158FD3] font-modak justify-center text-transparent bg-clip-text'>No New Updates</h1>
                <p className='text-center text-[#777777] text-[14px]'>Please check back later </p>
              </div>
            </div>
    </div>
  )
}

export default LatestUpdate