import React from "react";
import { NavLink } from "react-router-dom";

function EmailVerify() {
  return (
    <>
      {/* <!-- Start block --> */}
      <section className="bg-white  body-font font-poppins ">
        <div className="flex justify-center pt-20">
          {/* grid max-w-screen-xl mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12 */}



          <div className="px-24  mt-30">
            <div className="flex justify-center">
              <img src="/images/afripro.svg" alt="sign-in" />
            </div>
            <h1 className="max-w-2xl mt-6 text-3xl text-[#000000] font-semibold leading-10 md:text-3xl xl:text-4xl text-center ">
             Kindly check your mail  <br />
              for a verification link.
            </h1>


            <div className="p-10">
                  <p className="text-xs text-gray-500 text-center">
                    Didn’t receive the email? Please check your spam folder or <br /> try to resend the email
                  </p>
                </div>
          </div>
        </div>
      </section>

      {/* <!-- End block --> */}
    </>
  );
}

export default EmailVerify;
