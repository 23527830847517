import React from "react";
// @ts-ignore
import Carousel from "react-grid-carousel";
import Navbar from "../Navbar";
import { Link, NavLink } from "react-router-dom";
import SimpleImageSlider from "react-simple-image-slider";
import WFooter from "../WFooter";

function AboutUs() {
  const images = [
    { url: "/images/Image1.jpg" },
    { url: "/images/Image2.jpg" },
    { url: "/images/Image3.jpg" },
    { url: "/images/Image4.jpg" },
    { url: "/images/Image5.jpg" },
    { url: "/images/Image6.jpg" },
  ];
  return (
    <>
      <Navbar />

      {/* <!-- Start block --> */}
      <section className="bg-gradient-to-r from-[#F4FAF5] to-[#FBFBFB] body-font font-poppins md:pt-20 pt-[75px]">
        <div className=" mx-auto text-center md:py-10 pt-4">
          <h1 className="text-[#000000] md:text-[32px] text-[28px] px-6 font-[700]">
            About Us
          </h1>
        </div>
        <div className="hidden md:block mx-auto text-center md:py-4 ">
          <h1 className="text-[#000000] md:text-[48px] text-[32px] px-6 font-[700]">
            Building a bridge connecting African students <br /> and Finnish
            education
          </h1>
        </div>
        {/* <div className="md:hidden bg-[#00461C] py-8 flex justify-center w-full">
          <h1 className="text-[32px] text-[#ffffff] font-[600]">About Us</h1>
        </div> */}
        <div className="md:hidden block px-8 mt-8">
          <img
            src="/images/about1.svg"
            alt="location"
            className="rounded-[10px]"
          />
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl px-8 lg:px-14 md:pt-16 pt-5 pb-8 mx-auto lg:gap-20 ">
          <div className="hidden md:block my-6 lg:mt-0 px-6">
            <img
              src="/images/about1.svg"
              alt="about-us"
              className="rounded-[10px]"
            />
          </div>
          <div className="mr-auto place-self-center mb-10">
            <div className="hidden md:block">
              {/* <h1 className="max-w-2xl text-[20px] text-[#000000] font-semibold md:text-[30px]">We Build bridges<span className="text-[#48B774]"> between</span>  African </h1>
            <h1 className="max-w-2xl text-[20px] text-[#000000] font-semibold  md:text-[30px]">students<span className="text-[#48B774]"> and</span> Finnish education</h1>
             */}
              <h1 className="max-w-2xl text-[20px] text-[#000000] font-semibold md:text-[30px]">
                Who We Are{" "}
              </h1>
            </div>
            <div className="block md:hidden">
              <h1 className="max-w-2xl text-[22px] text-[#000000] font-semibold ">
                We Build bridges{" "}
              </h1>
              <h1 className="max-w-2xl text-[22px] text-[#000000] font-semibold">
                <span className="text-[#48B774]"> connecting</span> African
                students
              </h1>
              <h1 className="max-w-2xl text-[22px] text-[#000000] font-semibold ">
                <span className="text-[#48B774]"> and</span> Finnish education
              </h1>
            </div>

            <div className=" lg:px-0">
              <p className="max-w-2xl mb-2 mt-6 font-light text-[#666666]  text-sm md:leading-relaxed">
                AfriProEdu is an Edtech platform providing educational
                consulting services for African students to study in Finland and
                also empowering African students to learn the Finnish language
                to study in Finland tuition free.
              </p>
              <h3 className="text-[#098A3C] text-[14px] font-bold mt-6">
                AfriProEdu provides two academic pathways:
              </h3>
              <p className="max-w-2xl mb-2 mt-6 font-light text-[#666666]  text-sm md:leading-relaxed">
                <span className="font-bold text-[#000000]">
                  Study in Finnish:
                </span>{" "}
                Embark on an 8-10 month online Finnish language learning
                adventure, followed by the opportunity to pursue tuition-free
                studies in Finland. With this pathway, you have access to the
                best Finnish language teachers in Finland, guiding you
                throughout your Finnish language learning journey.
              </p>
              <p className="max-w-2xl mb-2 mt-6 font-light text-[#666666]  text-sm md:leading-relaxed">
                This pathway is open to categories of students: those 
                {/* aged
                14-17, who can engage in high school programs, and */}
                 individuals
                aged 18 and above who can explore vocational education programs.
              </p>
              <p className="max-w-2xl mb-2 mt-6 font-light text-[#666666]  text-sm md:leading-relaxed">
                <span className="font-bold text-[#000000]">
                  Study in English:
                </span>{" "}
                Opt for English programs, which require tuition fees. The
                process involves a simple English proficiency test to assess
                your English language skills. To secure your spot, you will pay
                tuition fees in Finland while studying .
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-gradient-to-r from-[#F4FAF5] to-[#FBFBFB] body-font font-poppins ">
        <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl px-8 lg:px-14 md:pt-20 pb-10 md:pb-16 mx-auto lg:gap-20 ">
          <div className=" md:my-6 my-3 lg:mt-0   ">
            {/* <img src="/images/about2.svg" alt="location" /> */}
            <h1 className="md:text-[38px] text-[30px] font-semibold mt-5">
              Why Choose us?
            </h1>
            <p className="md:text-[16px] text-[14px] text-[#666666] mt-6">
              AfriProEdu is the best choice for African students who want to
              study in Finland because it offers a unique combination of
              features and benefits that are specifically designed to support
              their success.
            </p>
          </div>
          <div className="grid md:grid-cols-2 gap-10">
            <div className="bg-white px-[20px] pt-[40px] pb-4 rounded-[10px] md:mt-0 mt-10 shadow-md relative">
              {/* <div className="w-[70px] h-[70px] rounded-[7px] bg-white border border-[#1DB459] absolute top-[-35px]"></div> */}
              <h3 className="text-[20px] font-semibold mt-[6px]">
                Tuition-free Education
              </h3>
              <p className="mt-[6px] text-[12px] text-[#777777]">
                AfriProEdu offers students the opportunity to study tuition-free
                in Finland by learning the Finnish language online for 8-10
                months. This is a great option for students who want to save
                money on their education.
              </p>
            </div>
            <div className="bg-white px-[20px] pt-[40px] pb-4 rounded-[10px] md:mt-0 mt-10 shadow-md relative">
              {/* <div className="w-[70px] h-[70px] rounded-[7px] bg-white border border-[#1DB459] absolute top-[-35px]"></div> */}
              <h3 className="text-[20px] font-semibold mt-[6px]">
                High-quality Education
              </h3>
              <p className="mt-[6px] text-[12px] text-[#777777]">
                Finland has one of the best education systems in the world.
                AfriProEdu partners with top universities and institutions in
                Finland to offer high-quality courses and programs.
              </p>
            </div>
            <div className="bg-white px-[20px] pt-[40px] pb-4 rounded-[10px] md:mt-8 mt-10 shadow-md relative">
              {/* <div className="w-[70px] h-[70px] rounded-[7px] bg-white border border-[#1DB459] absolute top-[-35px]"></div> */}
              <h3 className="text-[20px] font-semibold mt-[6px]">
                Support for African Students
              </h3>
              <p className="mt-[6px] text-[12px] text-[#777777]">
                AfriProEdu is specifically designed to support African students
                who want to study in Finland. The platform offers a variety of
                resources and services, such as Finnish language learning
                assistance, visa processing assistance, and job placement
                assistance.
              </p>
            </div>
            <div className="bg-white px-[20px] pt-[40px] pb-4 rounded-[10px] md:mt-8 mt-10 shadow-md relative">
              {/* <div className="w-[70px] h-[70px] rounded-[7px] bg-white border border-[#1DB459] absolute top-[-35px]"></div> */}
              <h3 className="text-[20px] font-semibold mt-[6px]">
                Bright Future
              </h3>
              <p className="mt-[6px] text-[12px] text-[#777777]">
                Studying in Finland can open up a world of opportunities for
                African students. Finland is a global leader in innovation and
                technology, and its graduates are in high demand by employers
                around the world.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-[#F2F7F3] body-font font-poppins pt-5 pb-7">
        <div className="flex w-full justify-center">
          <div>
            <div className=" text-center mb-5 mt-5">
              <h1 className="text-[#0E0E0E] md:text-3xl text-2xl px-6 font-semibold">
                Our Team
              </h1>
              <p className="text-[#777777] text-[14px] font-light mt-3">
                Meet a team that has your best interest at heart.
              </p>
            </div>
            <div className="flex text-center w-full justify-center">
              <div className="lg:max-w-screen-xl px-8 lg:px-14  pb-8   ">
                <Carousel cols={3} rows={1} gap={30} loop>
                  {/* <Carousel.Item>
                    <div className="pt-[200px] relative">
                      <div className=" bg-white  shadow-lg pt-[1px] px-4 pb-4 rounded-[10px]">
                        <div className=" flex justify-center -mt-[100px] rounded-[20px] overflow-hidden">
                          <img
                            src="/images/mat.svg"
                            alt="location"
                            className=""
                          />
                        </div>

                        <div className="flex justify-center">
                          <div className="">
                            <h4 className="text-[#000000] text-center text-[22px] font-medium pb-2">
                              Matthias Adebayo
                            </h4>
                            <div className="flex justify-center">
                              <div className="bg-[#1DB459]  w-[268px] text-center rounded-[5px] py-1 mb-3">
                                <h5 className="text-[#303030] text-[14px]">
                                  Product Manager AfriProEdu
                                </h5>
                              </div>
                            </div>
                            <div className="flex justify-center text-center pb-8">
                              <h5 className="text-[#777777] text-[12px] max-w-[268px]">
                                Senior Product Manager with over a decade of
                                experience in product and web development.
                              </h5>
                            </div>
                            <div className="flex justify-center">
                              <a
                                target="_blank"
                                href="https://www.linkedin.com/in/matthias-tunde-adebayo/"
                                className=" "
                              >
                                <img
                                  src="/images/bi_linkedin.svg"
                                  alt="location"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item> */}
                    <Carousel.Item>
                    <div className="pt-[200px] relative">
                      <div className=" bg-white shadow-lg pt-[1px] px-4 pb-4 rounded-[10px]">
                        <div className=" flex justify-center -mt-[100px] rounded-[20px] overflow-hidden">
                          <img
                            src="/images/steph.svg"
                            alt="location"
                            className=""
                          />
                        </div>

                        <div className="flex justify-center">
                          <div className="">
                            <h4 className="text-[#000000] text-center text-[22px] font-medium pb-2">
                              Stephanie Osayameh
                            </h4>
                            <div className="flex justify-center">
                              <div className="bg-[#1DB459] w-[268px]  text-center rounded-[5px] py-1 mb-3">
                                <h5 className="text-[#303030] text-[14px]">
                                  Chief Operating Officer
                                </h5>
                              </div>
                            </div>
                            <div className="flex justify-center text-center pb-8">
                              <h5 className="text-[#777777] text-[12px] max-w-[260px]">
                                {/* Experienced in overseeing daily operations, optimizing business processes, and driving strategic initiatives.  */}
                                Possess strong leadership skills and a track
                                record of improving operational efficiency and
                                profitability.
                              </h5>
                            </div>
                            <div className="flex justify-center">
                              <a
                                target="_blank"
                                href="https://www.linkedin.com/in/stephanieosayameh/"
                                className=" "
                              >
                                <img
                                  src="/images/bi_linkedin.svg"
                                  alt="location"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>

                  <Carousel.Item>
                    <div className=" pt-[250px] relative">
                      <div className=" bg-white shadow-lg pt-[32px] px-4 pb-4 rounded-[10px]">
                        <div className="flex justify-center -mt-[264px]">
                          <img
                            src="/images/john.svg"
                            alt="location"
                            className=""
                          />
                        </div>

                        <div className="flex justify-center">
                          <div className="">
                            <h4 className="text-[#000000] text-center text-[22px] font-medium pb-2">
                              John Samuel
                            </h4>
                            <div className="flex justify-center">
                              <div className="bg-[#1DB459] w-[268px] text-center rounded-[5px] py-1 mb-3">
                                <h5 className="text-[#303030] text-[14px]">
                                  CEO/Founder AfriProEdu
                                </h5>
                              </div>
                            </div>
                            <div className="flex justify-center text-center pb-8">
                              <h5 className="text-[#777777] text-[12px] max-w-[420px]">
                                Experienced Founder proficient in IT services,
                                with a strong track record in business
                                development, marketing, and sales. Expert in
                                connecting European and African markets.
                              </h5>
                            </div>
                            <div className="flex justify-center">
                              <a
                                target="_blank"
                                href="https://www.linkedin.com/in/john-samuel-b0818720/"
                                className=" "
                              >
                                <img
                                  src="/images/bi_linkedin.svg"
                                  alt="location"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                
                  <Carousel.Item>
                    <div className="pt-[200px] relative">
                      <div className=" bg-white shadow-lg pt-[1px] px-4 pb-4 rounded-[10px]">
                        <div className=" flex justify-center -mt-[100px] rounded-[20px] overflow-hidden">
                          <img
                            src="/images/moses.svg"
                            alt="location"
                            className=""
                          />
                        </div>

                        <div className="flex justify-center">
                          <div className="">
                            <h4 className="text-[#000000] text-center text-[22px] font-medium pb-2">
                              Moses Hammed
                            </h4>
                            <div className="flex justify-center">
                              <div className="bg-[#1DB459] w-[268px]  text-center rounded-[5px] py-1 mb-3">
                                <h5 className="text-[#303030] text-[14px]">
                                  Chief Financial Officer
                                </h5>
                              </div>
                            </div>
                            <div className="flex justify-center text-center pb-8">
                              <h5 className="text-[#777777] text-[12px] max-w-[260px]">
                                {/* Experienced in overseeing daily operations, optimizing business processes, and driving strategic initiatives.  */}
                                An experienced Chief Financial Officer with a proven track record in financial strategy and management.
                              </h5>
                            </div>
                            <div className="flex justify-center">
                              <a
                                target="_blank"
                                href="https://www.linkedin.com/in/moses-hammed-cfa-aca-455b5497/"
                                className=" "
                              >
                                <img
                                  src="/images/bi_linkedin.svg"
                                  alt="location"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                  {/* <Carousel.Item>
                    <div className="pt-[200px] relative">
                      <div className=" bg-white shadow-lg pt-[1px] px-4 pb-4 rounded-[10px]">
                        <div className=" flex justify-center -mt-[100px] rounded-[20px] overflow-hidden">
                          <img
                            src="/images/folu.svg"
                            alt="location"
                            className=""
                          />
                        </div>

                        <div className="flex justify-center">
                          <div className="">
                            <h4 className="text-[#000000] text-center text-[22px] font-medium pb-2">
                              Folorunsho Ojulari
                            </h4>
                            <div className="flex justify-center">
                              <div className="bg-[#1DB459] w-[268px]  text-center rounded-[5px] py-1 mb-3">
                                <h5 className="text-[#303030] text-[14px]">
                                  Business development Manager
                                </h5>
                              </div>
                            </div>
                            <div className="flex justify-center text-center pb-8">
                              <h5 className="text-[#777777] text-[12px] max-w-[260px]">
                                Experienced in driving revenue growth through
                                strategic planning, lead generation, and
                                partnership management.
                              </h5>
                            </div>
                            <div className="flex justify-center">
                              <a
                                target="_blank"
                                href="https://www.linkedin.com/"
                                className=" "
                              >
                                <img
                                  src="/images/bi_linkedin.svg"
                                  alt="location"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item> */}
                </Carousel>
                {/* 
          <Carousel cols={2} rows={1} gap={30} loop>
            <Carousel.Item>
              <div className="flex flex-col lg:flex-row lg:space-x-6 mt-5 border border-white px-4 pt-3 rounded-[15px]">
                <div className="">
                  <img
                    src="/images/team1.svg"
                    alt="location"
                    className="hidden md:block relative"
                  />
                  <div className="flex justify-center">
                    <Link
                      to="https://www.linkedin.com/in/john-samuel-b0818720/"
                      className=" hidden md:block absolute bottom-10"
                    >
                      <img src="/images/linkedinn.png" alt="location" />
                    </Link>
                  </div>
                  <img
                    src="/images/team1.png"
                    alt="location"
                    className="block md:hidden relative"
                  />
                  <div className="flex justify-center">
                    <Link
                      to="https://www.linkedin.com/in/john-samuel-b0818720/"
                      className=" block md:hidden "
                    >
                      <img src="/images/linkedinn.png" alt="location" />
                    </Link>
                  </div>
                </div>

                <div className=" mt-5">
                  <h1 className="md:text-3xl text-2xl text-[#ffffff] font-semibold">
                    John Samuel
                  </h1>
                  <h3 className="hidden md:block mt-3 text-base text-[#ffffff] font-semibold">
                    CEO/ Founder <br />
                    AfriProEdu
                  </h3>
                  <h3 className="block md:hidden mt-1 text-sm text-[#ffffff] font-semibold">
                    CEO/ Founder AfriProEdu
                  </h3>
          
                  <p className="max-w-[550px]  md:mt-3 mt-4 font-extralight text-[13px] text-[#F8F8F8]  lg:mb-8  ">
                    Experienced Founder with a demonstrated history of working
                    in the information technology and services
                    industry.Experienced business development, marketing and
                    sales professional with a demonstrated history of bridging
                    European and African market .
                 </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="flex flex-col lg:flex-row lg:space-x-6 mt-5 border border-white px-4 pt-3 pb-10 rounded-[15px]">
                <div>
                  <img
                    src="/images/team-new.svg"
                    alt="location"
                    className=" rounded-[5px] hidden md:block relative"
                  />
                  <div className="flex justify-center">
                    <Link
                      to="https://www.linkedin.com/in/joshuaawesome/"
                      className="hidden md:block absolute bottom-3"
                    >
                      <img src="/images/linkedinn.png" alt="location" />
                    </Link>
                  </div>
                  <img
                    src="/images/team-nnew.png"
                    alt="location"
                    className=" rounded-[5px]  w-[281px] h-[320px] block md:hidden"
                  />
                  <div className="flex justify-center">
                    <Link
                      to="https://www.linkedin.com/in/joshuaawesome/"
                      className="block md:hidden"
                    >
                      <img src="/images/linkedinn.png" alt="location" />
                    </Link>
                  </div>
                </div>
                <div className=" mt-5">
                  <h1 className="md:text-3xl text-2xl text-[#ffffff] font-semibold">
                    Dr Joshua Awesome
                  </h1>
                  <h3 className="hidden md:block mt-3 text-base text-[#ffffff] font-semibold">
                    Director and Head of
                    <br /> Partnership to Africa
                  </h3>
                  <h3 className="block md:hidden mt-1 text-sm text-[#ffffff] font-semibold">
                    Director and Head of Partnership to Africa
                  </h3>

                  <p className="max-w-2xl mb-6 md:mt-3 mt-4 font-extralight text-[13px] text-[#F8F8F8]  lg:mb-8">
                    Professional with over 20 years in the international
                    education industry.
                  </p>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="flex flex-col lg:flex-row lg:space-x-6 mt-5 border border-white px-4 pt-3 rounded-[15px]">
                <div>
                  <img
                    src="/images/matt-team.svg"
                    alt="location"
                    className=" rounded-[5px] hidden md:block relative"
                  />
                  <div className="flex justify-center">
                    <Link
                      to="https://www.linkedin.com/in/matthias-tunde-adebayo/"
                      className="hidden md:block "
                    >
                      <img src="/images/linkedinn.png" alt="location" />
                    </Link>
                  </div>
                  <img
                    src="/images/matt-team.png"
                    alt="location"
                    className=" rounded-[5px] block md:hidden w-[281px] h-[320px]"
                  />
                  <div className="flex justify-center">
                    <Link
                      to="https://www.linkedin.com/in/matthias-tunde-adebayo/"
                      className="block md:hidden"
                    >
                      <img src="/images/linkedinn.png" alt="location" />
                    </Link>
                  </div>
                </div>
                <div className=" mt-5">
                  <h1 className="md:text-3xl text-2xl text-[#ffffff] font-semibold">
                    Matthias Adebayo
                  </h1>
                  <h3 className="hidden md:block mt-3 text-base text-[#ffffff] font-semibold">
                    Product Lead <br />
                    AfriProEdu
                  </h3>
                  <h3 className="block md:hidden mt-3 text-sm text-[#ffffff] font-semibold">
                    Product Lead AfriProEdu
                  </h3>
                  <p className="max-w-2xl mb-6 md:mt-6 mt-4 font-extralight text-[13px] text-[#F8F8F8]  lg:mb-8">
                    Senior Product Manager with over a decade of experience in
                    product and web development.
                  </p>
                </div>
              </div>
            </Carousel.Item>
          </Carousel> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl px-8 lg:px-14 pb-8 mx-auto lg:gap-20 ">
          <div className="mr-auto place-self-center  ">
            <div className="flex flex-col lg:flex-row lg:space-x-6 mt-5">

              <img src="/images/team2.svg" alt="location" className="w-full" />

              <div className=" mt-5">
                <h1 className="md:text-3xl text-2xl text-[#48B774] font-semibold">Timothy Aigbe</h1>
                <h3 className="hidden md:block mt-3 text-base font-semibold">Product Manager <br />
                  AfriProEdu</h3>
                  <h3 className="block md:hidden mt-1 text-sm font-semibold">Product Manager AfriProEdu</h3>
                <Link to="https://www.linkedin.com/in/aigbe-timothy-mmba-1b256b166/">
                  <img src="/images/linkedin.png" alt="location" />
                </Link>
                <p className="max-w-2xl mb-6 md:mt-6 mt-4 font-normal text-gray-500  lg:mb-8 text-sm">
                  ‘’ Our goal is to help Africa achieve quality education, for it is only through such an endeavour that the continent can truly unlock its potential for development. Education is a fundamental human right and a vital tool for empowerment, growth, and transformation ”.
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-[#ffffff] body-font font-poppins md:pt-3 pt-5 pb-16">
        <div className="mx-auto text-center mt-6">
          <h1 className=" flex-shrink font-semibold text-[#171717] md:text-[38px] text-[32px]">
            Our Gallery
          </h1>
          <p className="hidden md:block text-[#838383]">
            We are empowering African students with the best education system in
            the world <br />
            (the Finnish education) to build a future and a better Africa.
          </p>
          <p className="md:hidden block text-[#838383]">
            We are empowering African students with the best education system in
            the world (the Finnish education) to build a future and a better
            Africa.
          </p>
        </div>
        <div className="max-w-screen-xl px-8 lg:px-14 md:pt-10 grid md:grid-cols-3 grid-cols-1  pb-5 mx-auto lg:gap-20">
          {/* <div className="mt-[50px] md:mt-0 overflow-hidden  cursor-pointer rounded-xl relative group">
          <div
                className="rounded-xl z-50 opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out cursor-pointer absolute from-black/80 to-transparent bg-gradient-to-t inset-x-0 -bottom-2 pt-30 text-white flex items-end"
            >
                <div>
                    <div
                        className="  p-4 space-y-3 text-xl group-hover:opacity-100 group-hover:translate-y-0 translate-y-4 pb-10 transform transition duration-300 ease-in-out"
                    >
                        <div className="font-bold">Jessie Watsica</div>

                        <div className="opacity-60 text-sm ">
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Distinctio dolores error iure, perferendis
                            sequi totam. Ad aliquam aperiam atque deleniti dolor
                            dolorem enim esse et in, inventore itaque, pariatur
                            reprehenderit.
                        </div>
                    </div>
                </div>
            </div>
          <img src="/images/gallery1.jpeg" alt="location" className=" group-hover:scale-110 transition duration-300 ease-in-out"/>
          </div> */}
          <div className="mt-[50px] md:mt-0">
            <img
              src="/images/gallery1.jpeg"
              alt="location"
              className=" group-hover:scale-110 transition duration-300 ease-in-out"
            />
          </div>
          <div className="mt-[30px] md:mt-0">
            <img src="/images/gallery2.jpeg" alt="location" />
          </div>
          <div className="mt-[30px] md:mt-0">
            <img src="/images/gallery3.jpeg" alt="location" />
          </div>
        </div>
        <div className="px-8 lg:px-14 max-w-screen-xl mx-auto flex justify-end">
          <NavLink to={"/gallery"} className="flex cursor-pointer">
            <h3 className="text-[#1DB459] hover:text-[#1C8B48] font-medium">
              See More{" "}
            </h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="#1db459"
                fill-rule="evenodd"
                d="m6.5 17.5l8.25-5.5L6.5 6.5l1-1.5L18 12L7.5 19z"
              />
            </svg>
          </NavLink>
        </div>
      </section>

      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB] body-font font-poppins">
        <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl px-8 lg:px-14 md:pt-16 pt-8 pb-8 mx-auto lg:gap-20 ">
          <div className=" my-6 lg:mt-0 flex justify-center ">
            {/* <img src="/images/about2.svg" alt="location" /> */}
            <div className="md:flex hidden px-6">
              <SimpleImageSlider
                width={400}
                height={330}
                images={images}
                showBullets={false}
                showNavs={false}
                autoPlay={true}
              />
            </div>
            <div className="flex md:hidden px-6">
              <SimpleImageSlider
                width={330}
                height={330}
                images={images}
                showBullets={false}
                showNavs={false}
                autoPlay={true}
              />
            </div>
          </div>

          <div className="mr-auto place-self-center lg:mb-20 lg:px-6">
            <h1 className="max-w-2xl text-2xl text-[#000000] font-semibold leading-10 md:text-3xl xl:text-4xl">
              Our <span className="text-[#48B774]">Gallery</span>
            </h1>
            <p className="max-w-2xl mb-2 md:mt-2 font-semibold text-[#000000]  text-base md:leading-relaxed">
              We go round the globe trying to impact as much lives as we can
            </p>
            <p className="max-w-2xl mt-3 font-light text-[#838383]  text-sm leading-relaxed">
              We are empowering African students with the best education system
              in the world (the Finnish education) to build a future and a
              better Africa.
            </p>
            <p className="max-w-2xl mt-4 font-light text-[#838383]  text-sm leading-relaxed">
              We aim to empower African youths with the opportunities to
              graduate from a Finnish vocational school for FREE! You can
              develop your professional
            </p>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section className="bg-[#000000] body-font font-poppins ">
        <div className="flex  flex-col-reverse lg:flex-row lg:justify-between max-w-screen-xl px-8 lg:px-14 md:pt-20 pt-8 pb-8 mx-auto lg:gap-20 ">
          <div className="mr-auto place-self-center lg:mb-20">
            <h1 className="max-w-2xl md:mb-2 text-2xl text-white font-semibold md:leading-10 md:text-3xl xl:text-4xl">
              Have a question ?
            </h1>
            <h1 className="max-w-2xl md:mb-2 text-2xl text-[#48B774] font-semibold md:leading-10 md:text-3xl xl:text-4xl">
              Our team is happy
            </h1>
            <h1 className="max-w-2xl md:mb-4 text-2xl text-white font-semibold md:leading-10 md:text-3xl xl:text-4xl">
              to assist you
            </h1>
            <div className=" lg:px-0">
              <p className="max-w-2xl  mt-6 mb-6 font-light text-white  text-sm leading-relaxed">
                If you have any questions or would like to to learn more about
                studying in Finland, please don't hesitate to contact us. Our
                team is dedicated to helping you achieve your academic goals and
                providing you with the information you need to make an informed
                decision about your education. Contact us today via email or by
                filling out the contact form on this page.
              </p>
            </div>
            <hr className="hidden md:block h-px my-10 bg-[#D9D9D9] border-0 " />

            <NavLink to="/contact-us" className="mt-6">
              <button
                type="button"
                className="py-2.5 px-6  text-sm font-medium text-[#48B774] bg-white rounded-lg border border-white "
              >
                Contact Us
              </button>
            </NavLink>
          </div>
          <div className=" my-6 lg:mt-0 ">
            <img src="/images/about-fineboy.svg" alt="location" />
          </div>
        </div>
      </section>

      {/* <!-- End block --> */}
      <section>
        <WFooter />
      </section>
    </>
  );
}

export default AboutUs;
