import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar'
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import hero from "../../img/new1.png";
import { AuthApis } from '../../apis/authApis';
import { DashboardApi } from '../../apis/DashboardApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ApplyForm() {
    const [userData, setUserdata] = React.useState({
        'amount': "",
        'first_name': "",
        'last_name': "",
        'gender': "",
        'email': "",
        'phone_number': "",
        'academic_background': "",
        'tution_fee': "",
        'comment': ""
    });

    const navigate = useNavigate();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUserdata({ ...userData, [name]: value });
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const [age, setAge] = React.useState<any>('');
    const [nursingProgram, setNursingProgram] = React.useState<any>('');
    const [passport, setPassport] = React.useState<any>('');
    const [location, setLocation] = React.useState<any>('');
    const [comment, setComment] = React.useState<any>('');

    console?.log(searchParams.get('tx_ref'))

    if (searchParams.get('tx_ref')) {
        // console?.log('ddd')
        if (searchParams.get('status') == 'cancelled') {
            navigate('/practical-nursing-application-form')
        }

        AuthApis.outsidePaymentCallback(searchParams.get('tx_ref'), searchParams.get('status')).then(
            (response: any) => {
                if (response?.data) {
                    console?.log(JSON.parse(response?.data?.data)?.data)
                    AuthApis.sendMail(JSON.stringify(JSON.parse(response?.data?.data)?.data)).then(
                        (response2: any) => {
                            if (response2?.data) {
                                navigate('/practical-nursing-payment-successful')
                                console?.log(response2?.data);
                            }
                        }
                    )
                    if (response?.data?.success === true) {
                        //navigate(response?.data?.data);
                    }
                } else {
                    // toast.warn('Invalid Login Credentials');
                }
            }
        ).catch(function (error) {
            // handle error
            console.log(error.response.data);
        }).finally(() => {
            // toast.error("No Internet Connection");

        });
    }

    const handleVerification = (e: any) => {
        e.preventDefault();
        onOpenModal()

    }

    const handleSubmit = () => {
        // e.preventDefault();
        onCloseModal()
        const formData = new FormData()
        formData.append('amount', '100')
        formData.append('first_name', userData?.first_name)
        formData.append('last_name', userData?.last_name)
        formData.append('email', userData?.email)
        formData.append('gender', userData?.gender)
        formData.append('phone_number', userData?.phone_number)
        formData.append('status', 'st2')
        formData.append('age', age)
        formData.append('nursing_program', nursingProgram)
        formData.append('passport', passport)
        formData.append('location', location)
        formData.append('comment', comment)
        formData.append('tution_fee', userData?.tution_fee)
        formData.append('academic_background', userData?.academic_background)

        AuthApis.makeOutsudePayment(formData).then(
            (response: any) => {
                if (response?.data) {
                    if (response?.data?.success === true) {
                        console?.log(response?.data)
                        if (response?.data?.data?.data?.link) {
                            window.location.replace(response?.data?.data?.data?.link);
                        } else if (response?.data?.data?.message) {
                            toast.warn(response?.data?.data?.message);
                        } else {
                            toast.error('Something went wrong!');
                        }

                    }
                } else {
                    // toast.warn('Invalid Login Credentials');
                }
            }
        ).catch(function (error) {
            // handle error
        }).finally(() => {
            // toast.error("No Internet Connection");

        })
    }


    const handleSubmit2 = () => {
        // e.preventDefault();
        onCloseModal()
        const formData = new FormData()
        formData.append('amount', '160000')
        formData.append('first_name', userData?.first_name)
        formData.append('last_name', userData?.last_name)
        formData.append('email', userData?.email)
        formData.append('gender', userData?.gender)
        formData.append('phone_number', userData?.phone_number)
        formData.append('status', 'st2')
        formData.append('age', age)
        formData.append('nursing_program', nursingProgram)
        formData.append('passport', passport)
        formData.append('location', location)
        formData.append('comment', comment)
        formData.append('tution_fee', userData?.tution_fee)
        formData.append('academic_background', userData?.academic_background)

        AuthApis.makeOutsideNairaPayment(formData).then(
            (response: any) => {
                if (response?.data) {
                    if (response?.data?.success === true) {
                        console?.log(response?.data)
                        if (response?.data?.data?.data?.link) {
                            window.location.replace(response?.data?.data?.data?.link);
                        } else if (response?.data?.data?.message) {
                            toast.warn(response?.data?.data?.message);
                        } else {
                            toast.error('Something went wrong!');
                        }

                    }
                } else {
                    // toast.warn('Invalid Login Credentials');
                }
            }
        ).catch(function (error) {
            // handle error
        }).finally(() => {
            // toast.error("No Internet Connection");

        })
    }



    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [openModalNoti, setOpenModalNoti] = useState(true);
    // const onOpenModalNoti = () => setOpenModalNoti(true);
    // const onCloseModalNoti = () => setOpenModalNoti(false);
    const onCloseModalNoti = () => {
        navigate(-1)
    }


    return (
        <>
            <Navbar />
            <Modal open={openModalNoti} onClose={onCloseModalNoti} center>
                <div className='md:max-w-md mt-6'>
                    <h1 className='text-2xl font-semibold text-center mt-3'>Nursing Application Closed</h1>
                    <p className='text-[14px] mt-3'>We want to express our sincere gratitude for your interest in our recent cohort. However, we want to inform you that the current cohort has reached its maximum capacity and is now closed.</p>
                    <p className='text-[14px] mt-3'>But the good news is, we are excited to announce that you can register for the next cohort.</p>
                    <p className='text-[14px] mt-3'>We can't wait to have you on board for the next cohort. Thank you for considering our program, and we look forward to seeing you succeed in the upcoming session!</p>
              
                <p className='mt-3  text-[14px]'> <a target="_blank" href='https://chat.whatsapp.com/L5MkgQ9vj7zFBHSZflqDe6' className='text-[#1DB459] hover:underline font-semibold'>Click here</a> to join our WhatsApp group for daily updates.</p>    
                    <div className='flex mx-auto space-x-4 mt-6'>
                                            
                                        </div>
                </div>

            </Modal>
            {/* Start Section */}
            <section className='body-font font-poppins md:pt-20 relative'>
                <div

                    className='  pt-20 md:pt-0  flex justify-center items-center'
                >
                    <div>
                        <img src="/images/application-form.jpeg" alt="hero" className=' border-[#1DB459] border-b-8' />
                    </div>

                </div>
            </section>
            {/* End Section */}

            {/* Start Section */}
            <div className='bg-[#FBFBFB] sm:px-16 px-6 md:flex md:justify-center items-start pt-10 pb-8' >
                <div className='xl:max-w-[1200px] w-full mx-auto'>
                    <div className='hidden md:block'>
                        <p className='md:text-[16px] text-[14px]'>Once you've completed the registration process and submitted the registration fee, you will be included in our group<br />
                            and receive an invitation for a Entrance exam. If you prefer quicker assistance and immediate updates, please reach out to us via<br /> WhatsApp or place direct call to +2347088914669 , or via email at <a href="mailto:hello@afriproedu.com" className='text-[#1DB459] underline underline-offset-2'>hello@afriproedu.com</a></p>
                        <p className='md:text-[16px] text-[14px] mt-[12px]'> Please be aware that your registration process initiates only upon receipt of the registration fee and confirmation of payment via a valid receipt.
                        </p>

                    </div>
                    <div className='md:hidden block'>
                        <p className='md:text-[16px] text-[14px]'>Once you've completed the registration process and submitted the registration fee, you will be included in our group
                            and receive an invitation for a Entrance exam. If you prefer quicker assistance and immediate updates, please reach out to us via WhatsApp or place  call to +2347088914669, or via email at hello@afriproedu.com</p>
                        <p className='md:text-[16px] text-[14px] mt-[12px]'> Please be aware that your registration process initiates only upon receipt of the registration fee and confirmation of payment via a valid receipt.
                        </p>

                    </div>
                    <form onSubmit={handleVerification}>
                        <div className='bg-white mt-[70px] p-6 border border-[#C4C4C4] rounded-[20px]'>
                            <div className='flex justify-end md:px-4 mb-8'>
                                <h5 className='md:text-[16px] text-[14px]'><span className='text-[#EE1D52] font-bold'>*</span>indicates a required field</h5>
                            </div>
                            <span className='bg-[#1DB459] text-white md:text-[20px] text-[16px] font-semibold md:ml-4 mb-[40px] mt-[10px] pl-3 pr-12 py-3 rounded-[5px]'>Personal Information</span>
                            <div className='grid grid-cols-1 gap-3 md:grid-cols-2 mt-[40px]'>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        First Name<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Name"
                                        name="first_name"

                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Last Name<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Name"
                                        name="last_name"

                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Other Name (Optional)
                                    </label>
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Name"
                                        name="other_name"
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Phone Number<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="number"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Phone No"
                                        name="phone_number"

                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label
                                        htmlFor="gender"
                                        className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                    >
                                        Gender<span className='text-[#EE1D52] font-bold'> *</span>
                                    </label>
                                    <select
                                        id="gender"
                                        name="gender"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                    >
                                        <option selected></option>
                                        <option value="female">Female</option>
                                        <option value="male">Male</option>

                                    </select>
                                </div>
                                <div className="mb-6 w-full md:px-4">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Email<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="email"
                                        onChange={handleChange}
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Email"
                                        name="email"

                                        required
                                    />
                                </div>

                            </div>

                            <div className='my-[30px]'>
                                <span className='bg-[#1DB459] text-white md:text-[20px] text-[16px] font-semibold md:ml-4 mb-[40px]  pl-3 pr-12 py-3 rounded-[5px]'>Exam Information</span>
                            </div>
                            <div className='md:px-4 mt-[10px]'>
                                <h3 className='mb-2 text-[14px] font-semibold text-[#0A2E04]'>Are you 18 years and above?<span className='text-[#EE1D52] font-bold'>*</span> </h3>
                                <div className="flex items-center mb-4">
                                    <input id="default-radio-1" onClick={() => setAge('yes')} type="radio" name='default-radio' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">Yes</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-radio-1" onClick={() => setAge('no')} type="radio" name='default-radio' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">No</label>
                                </div>
                            </div>
                            <div className=" md:w-[50%] w-full mb-6 md:px-4 mt-[30px]">
                                <label
                                    htmlFor="academic_background"
                                    className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                >
                                    What is your academic background?<span className='text-[#EE1D52] font-bold'> *</span>
                                </label>
                                <select
                                    id="academic_background"
                                    name="academic_background"
                                    onChange={handleChange}
                                    className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                >
                                    <option selected></option>
                                    {/* <option value="high school">High School</option> */}
                                    <option value="college of education">College of Education</option>
                                    <option value="university">University</option>
                                    <option value="masters/phd">Masters/PhD</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>
                            <div className='md:px-4 mt-[30px]'>
                                <h3 className='mb-2 text-[14px] font-semibold text-[#0A2E04]'>Are you aware that the Nursing program is Tuition based and you are to pay a fee of €9000?<span className='text-[#EE1D52] font-bold'> *</span> </h3>
                                <div className="flex items-center mb-4">
                                    <input required id="default-radio-3" onClick={() => setNursingProgram('yes')} type="radio" name='default-radio3' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">Yes</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input required onClick={() => setNursingProgram('no')} id="default-radio-3" type="radio" name='default-radio3' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-3" className="ml-2 text-sm font-medium text-gray-900 ">No</label>
                                </div>
                            </div>

                            <div className=" md:w-[50%] w-full mb-6 md:px-4 mt-[30px]">
                                <label
                                    htmlFor="academic_background"
                                    className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                >
                                    Who is paying the tuition fee of €9000 ?<span className='text-[#EE1D52] font-bold'> *</span>
                                </label>
                                <select
                                    id="academic_background"
                                    name="tution_fee"
                                    required
                                    onChange={handleChange}
                                    className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                >
                                    <option selected></option>
                                    <option value="myself">Myself</option>
                                    <option value="parents">Parents</option>
                                    <option value="sponsor">Sponsor</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>

                            <div className='md:px-4 mt-[30px]'>
                                <h3 className='mb-2 text-[14px] font-semibold text-[#0A2E04]'>Do you have a Valid Passport expiring in a minimum of one year? <span className='text-[#EE1D52] font-bold'>*</span> </h3>
                                <div className="flex items-center mb-4">
                                    <input required id="default-radio-10" onClick={() => setPassport('yes')} type="radio" name='default-radio10' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">Yes</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input required id="default-radio-10" onClick={() => setPassport('no')} type="radio" name='default-radio10' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-10" className="ml-2 text-sm font-medium text-gray-900 ">No</label>
                                </div>
                            </div>
                            <div className='md:px-4 mt-[30px]'>
                                <h3 className='mb-2 text-[14px] font-semibold text-[#0A2E04]'>The application process requires a service fee of $100, which covers visa processing, school application, and documentation. <br />This is a nonrefundable fee to book your slot. Are you willing to proceed ?<span className='text-[#EE1D52] font-bold'>*</span> </h3>
                                <div className="flex items-center mb-4">
                                    <input required id="default-radio-2" onClick={() => setLocation('yes')} type="radio" name='default-radi2' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">Yes</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input required id="default-radio-2" onClick={() => setLocation('no')} type="radio" name='default-radi2' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                                    <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">No</label>
                                </div>
                            </div>
                            <div className='md:px-4 mt-[30px]'>

                                <label htmlFor="comment" className="block mb-2 text-[14px] font-semibold text-[#0A2E04]">Comment</label>
                                <textarea name='comment' id="comment" onChange={(e) => setComment(e.target.value)} rows={4} className="block p-2.5 w-full text-sm text-gray-900 rounded-[4px]  border border-[#D9D9D9]"></textarea>

                            </div>
                            {/* <div className='mt-[20px] md:px-4'>
                                <h3 className='text-[14px] font-semibold'>For more information about how we use your data, please read our <NavLink to={"/terms-condition"} className='text-[#1DB459] underline underline-offset-2'>privacy notice.</NavLink></h3>
                            </div> */}

                            <div className='md:px-4 mt-[50px] flex justify-center'>
                                <button
                                    type="submit"
                                    className=" text-white bg-[#1DB459] hover:bg-[#05401C] rounded-full font-medium text-sm px-6 py-3 mr-2 mb-2"

                                >
                                    Proceed to Payment
                                </button>
                            </div>

                            <Modal open={open} onClose={onCloseModal} center>
                                <div className='md:max-w-md  body-font font-poppins'>
                                    <div className="flex flex-wrap mt-4">
                                        <h2 className='text-center text-[#1DB459] font-bold md:text-[26px] text-[22px]'>Accept Terms and Conditions</h2>
                                        <p className='text-center text-[#838383] md:text-[14px] text-[12px]'>Before you proceed with your payment, please take a moment to review and accept our Terms and Conditions.</p>
                                        <hr className="h-px mb-8 mt-[10px] bg-[#F0F4F9] border-0" />
                                        <h3 className='md:text-[16px] text-[14px] text-black font-light '><span className='font-bold'>Payment:</span>  By making a payment, you agree to pay $100 for the service charge.</h3>
                                        <h3 className='md:text-[16px] text-[14px] text-black font-light mt-4'><span className='font-bold'>Refunds:</span>  The $100 being paid will be a one time  non refundable fee</h3>
                                        <h3 className='md:text-[16px] text-[14px] text-black font-light mt-4'><span className='font-bold'>Data privacy:</span>  Please be aware that any information provided will be used  to verify your identity for future referencing and also for your Visa and Residence Permit processing.</h3>
                                        <hr className="h-px mb-8 mt-[20px] bg-[#F0F4F9] border-0" />
                                        {/* <div className="flex justify-center items-center mb-3 mt-4">
                                            <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-green-600 rounded" required />
                                            <label htmlFor="default-checkbox" className="ml-2 md:text-[14px] text-[12px] font-light text-black "> I accept the Terms and Conditions</label>
                                        </div> */}
                                        <div className='flex mx-auto space-x-4 mt-6'>
                                            <button
                                                type="button"
                                                onClick={handleSubmit}
                                                className=" text-white bg-[#1DB459] rounded-[5px] font-medium text-sm md:px-5 px-3 md:py-3 py-2.5 mr-2 mb-2"
                                            >
                                                Pay in Dollars
                                            </button>
                                            <button
                                                type="button"
                                                className=" text-[#FCF7F7] bg-[#1C8B48] rounded-[5px]  font-medium text-sm md:px-5 px-8 md:py-3 py-2.5 mr-2 mb-2"
                                                onClick={handleSubmit2}
                                            >
                                                Pay in Naira
                                            </button>
                                        </div>
                                        <div className='flex justify-center mx-auto w-full'>
                                            <button
                                                type="button"
                                                className=" text-white bg-[#FF0000] rounded-[5px]  font-medium text-sm md:px-12 px-8 md:py-3 py-2.5 mr-2 mb-2"
                                                onClick={onCloseModal}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <hr className="h-px mb-8 mt-[20px] bg-[#F0F4F9] border-0" />
                                        <p className='md:text-[12px] text-[10px] text-center mt-3 text-[#838383]'>By proceeding, you confirm that you have read and agree to the Terms and Conditions outlined above. If you do not agree, please click <span className='font-bold'>"Cancel"</span> to abort the payment process</p>
                                    </div>
                                </div>

                            </Modal>
                        </div>


                    </form>


                </div>
            </div>
            {/* End Section */}

            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default ApplyForm