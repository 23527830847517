import React from "react";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";

import { useDispatch } from "react-redux";
import CardTable from "../../ReuseableComp/CardTable.js";
import Calendar from "../../ReuseableComp/Calendar";
import { useSelector } from "react-redux";
import { DashboardApi } from "../../../apis/DashboardApi";
import { AxiosResponse } from "axios";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate, NavLink } from 'react-router-dom';
import { login } from "../../../reducer/loginSlice";
import ProgramsHeader from "../../ReuseableComp/ProgramsHeader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lottie from "lottie-react";
import animationData from "../../assets/online-learning.json"
// components

// import CardPageVisits from "../Cards/CardPageVisits";
// import CardSocialTraffic from "../Cards/CardSocialTraffic";
// import AdminNavbar from "../Navbars/AdminNavbar.js";
// import Sidebar from "../Sidebar/Sidebar";
// import HeaderStats from "../Headers/HeaderStats.js";
// import FooterAdmin from "../Footers/FooterAdmin.js";

export default function Programs() {
  var currencyFormatter = require('currency-formatter');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLoginData = useSelector((state: any) => state.data.login.value);
  const [learningDetails, setLearningDetails] = React.useState<any>([]);
  const [checkData, setCheckData] = React.useState<any>([]);


  React.useEffect(() => {
    userLoginData?.token ?
      DashboardApi.getLearningDetails().then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setLearningDetails(response?.data?.data)
            setCheckData(response?.data)
          }

        }
      ).catch(function (error) {
        // handle error
        console.log(error.response.data);

      })
      :

      navigate('/sign-in');
  }, []);

  function isCopied() {
    toast.success("Copied to Clipboard");
  }

  console?.log(learningDetails)


  return (
    <>
      {/* <Sidebar /> */}
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">

        <UserNavbar title="Language Learning" />
        {/* Header */}

        <div className="md:my-10  flex justify-between md:px-14 ">
          <div className="border-r-[#D9D9D9]">
            <div className="">
              <Lottie animationData={animationData} width={400} height={400} />

            </div>

            {checkData ?
              <div className="my-10  ">
                <div className=" px-10 pt-10 pb-10">
                  {/* <p>Class Code</p>
              <p>This is your class code,Copy it from here</p> */}
                  <p className=" font-medium">Copy the class link here to gain access to a classroom</p>
                  <div className="mt-3">
                    <input type="text" id="first_name" defaultValue={learningDetails?.class_link} className="bg-gray-50 border border-gray-300 mb-2 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/3 p-2.5" placeholder="Class code will appear here" disabled />
                    <div className="w-1/3">
                      {/* <CopyToClipboard text={learningDetails?.class_link !== null ? learningDetails?.class_link : ''}
                        onCopy={() => isCopied()}> */}
                        <NavLink to={learningDetails?.class_link !== null ? learningDetails?.class_link : ''} target="_blank" rel="noreferrer">
                        <span
                          style={{ color: '#61A24F', borderColor: '#61A24F' }}
                          className="ring-1 cursor-pointer outline-none ring-green-500 font-xs rounded-lg text-xs px-3 py-1 h-4   text-center "
                        >
                          Click Here
                        </span>
                        </NavLink>
                       
                        {/* <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3 19h17v2H3c-1.1 0-2-.9-2-2V6h2v13zM23 6v9c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2l.01-11c0-1.1.89-2 1.99-2h5l2 2h7c1.1 0 2 .9 2 2zM7 15h14V6h-7.83l-2-2H7v11z"/></svg></span> */}
                      {/* </CopyToClipboard> */}
                    </div>
                  </div>
                </div>

                <div className="px-10">
                  <p>To sign in with a class code:</p>
                  <li>Use an authorized account</li>
                  <li>Use a class with 5-7 numbers and no symbols</li>
                </div>





              </div>

              :
              <p className="m-96">Not Authorized</p>


            }

          </div>


          <div className="my-10 hidden md:block">
            <div className="max-w-sm p-4 border border-[#D9D9D9] rounded-lg">
              <h3 className=" font-semibold text-black text-base">Time spent on learning this week</h3>
              <h5 className=" font-light text-[#1DB459] text-xs">Your total time spent will be shown and cla</h5>
              <img src="/images/Dashboard/learning-chart.png" alt="hero" />
            </div>

            <div className="max-w-sm p-4 border border-[#D9D9D9] rounded-lg mt-5">
            <h3 className=" font-semibold text-black text-base">Recorded Classes</h3>
              <h5 className=" font-light text-[#1DB459] text-xs">Recorded classes will be provided here </h5>
              <div className="mt-2 py-3 flex justify-center">
              <img src="/images/Dashboard/records.svg" alt="hero" />
              </div>
              
            </div>

          </div>

        </div>





        {/* <ProgramsHeader Username={userLoginData?.name} amount={currencyFormatter.format(availableBalance, { code: 'NGN' })} /> */}


        {/* <HeaderStats />  */}
        {/* <div className="flex flex-wrap mt-4">
          <div className="w-full md:w-8/12 mb-12 xl:mb-0 px-4">
            <div className="space-y-2 lg:grid lg:grid-cols-3 sm:gap-5  lg:space-y-0 lg:py-8 p-6 ">

              <div className="  text-gray-900 bg-[#FBFBFB] rounded-lg shadow ">
                <div className="pr-4 pl-3 text-[#838383] my-8">
                  <h3 className=" text-2xl text-black">0</h3>
                  <p className="text-xs mt-4 text-[#838383]">
                    Completed <br />
                    Courses
                  </p>
                </div>
              </div>

              <div className="  text-gray-900 bg-[#FBFBFB] rounded-lg shadow ">
                <div className="pr-4 pl-3 text-[#838383] my-8">
                  <h3 className=" text-2xl text-black">0</h3>
                  <p className="text-xs mt-4 text-[#838383]">
                    Completed <br />
                    Courses
                  </p>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row lg:justify-between  text-gray-900 bg-[#FBFBFB] rounded-lg shadow ">
                <div className="pr-4 pl-3 text-[#838383] my-8">
                  <h3 className=" text-2xl text-black">0</h3>
                  <p className="text-xs mt-4 text-[#838383]">
                    Completed <br />
                    Courses
                  </p>
                </div>
                <div className="place-self-center w-full">
                  <img src="/images/Dashboard/icon-map.svg" alt="hero" />
                </div>
              </div>

            </div>
          </div>

          <div className="w-full md:w-4/12 px-4">
            <Calendar />
          </div>

        </div>
        <div className="flex flex-wrap  mt-4 ">
          <div className="w-full md:w-8/12 mb-12 xl:mb-0 px-4">
            <CardTable color={"white"} />
          </div>
          <div className="w-full md:w-4/12 px-4 pt-10">
            <div className="flex justify-between mx-4">
              <h3 className=" font-bold text-2xl ">Upcoming Classes</h3>
              <h3 className="text-[#666666] text-base mt-1 font-semibold">View all</h3>
            </div> */}


        {/* <div className="flex space-x-3 p-6 mx-4 mt-6  border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
              <img src="/images/Dashboard/img5.svg" alt="hero" />

              <h3 className="mb-2 text-xs font-bold tracking-tight ">
                <span className="text-[#B3B3B3] text-xs ">10:00 - 12:30</span><br />
                <span className="text-[#666666]"> Religions : Interactions,<br />
                  Ordering , Decline. </span></h3>
              <div className=" place-self-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="black" d="m5.5 4.14l-1 1.72L15 12L4.5 18.14l1 1.72L19 12L5.5 4.14Z" /></svg>
              </div>


            </div> */}

        {/* </div>
        </div> */}

      </div>


      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

    </>
  );
}
