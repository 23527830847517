import React, {useState} from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar';
import UserNavbar from '../../Navbars/UserNavbar'
import { Link, NavLink } from 'react-router-dom';
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import configs from '../../../configs';

export const ListOfCourses = () => {
    const [toggle, setToggle] = useState(false)

    const [groupLists, setGroupList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');
    


    React.useEffect(() => {
        const query: any = {
            search: searchText,
        };
        AdminApis.getPartnerCourse('').then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setGroupList(response?.data?.data)
                    console.log(response?.data?.data)
                     // Filter out "Master's Program" category
                // const filteredData = response?.data?.data.filter((course: any) => course.category !== "master\'s program");
                // setGroupList(filteredData);
                }
            }
        ).catch(function (error) {
            // handle error

        })
    }, []);

    // console?.log(groupLists)


    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }
            const query: any = {
                search: searchText,
            };

            AdminApis.getPartnerCourse(value2).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setGroupList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [groupLists, searchText]);



  return (
    <>
     <AdminSidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar />

                <div className="lg:pt-10 lg:px-10 px-6 ">
                    <div className='flex justify-between'>
                        <h3 className=' font-bold text-[#303840] text-[30px]'>Courses</h3>
                        <Link to="/add-course">
                            <button type='button' className="font-normal text-white bg-[#1DB459] px-5 py-2 rounded-md">Add New Course</button>
                        </Link>
                    </div>
                </div>

                <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#c4c4c4] mx-6 lg:mt-6 lg:mx-10 mt-5">
                    <div className="flex md:justify-end justify-start px-6 md:px-8 pt-6">
                        <div className=" lg:ml-auto mr-3">
                            <div className="relative flex w-full ">
                                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                                </div>
                                <input type="text" placeholder='Search Courses...' className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " />
                                {/* <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required /> */}
                            </div>
                        </div>
                    </div>

                    <div className='grid grid-cols-2 md:gap-10 gap-4 md:grid-cols-4 lg:px-5 px-6 pt-5 pb-24 mx-auto lg:grid lg:grid-cols-4 xl:gap-4  lg:space-y-0 lg:py-6 lg:mt-1'>
                    
                    {groupLists?.data?.map(
                        (datas: any, index: any) => (
                            <NavLink to={`/edit-partner-course/${((datas?.id))}`} >
                            <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow p-2 ">  
                                <div>
                                    <img className="" src={datas?.image_url} alt={datas?.cover_photo} style={{maxHeight:"20vh",minWidth:"100%",maxWidth:'100%',minHeight:"20vh"}} />
                                </div>
                                <div className=" pb-2 pt-2"> 

                                    <h5 className="text-[12px] font-medium text-black "> {datas?.title}</h5>
                                    <p className='text-[20px] text-black font-bold'>{datas?.product_cost}</p>
                                </div>
                            </div>
                            </NavLink>
                        )
                    )
                        }
                            
                           
                           
                    </div>


                    <div className='m-4'>
                        {
                            groupLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-green-500 text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }
                    </div>


    
                </div>

            </div>
    </>
  )
}

export default ListOfCourses; 