import React from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar';
import UserNavbar from '../../Navbars/UserNavbar'
import { SvgElement, icontypesEnum } from '../../assets/svgElement'
import { Link, NavLink } from 'react-router-dom';
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';

function RegList() {

  const [lists, setList] = React.useState<any>([]);

  React.useEffect(() => {
    AdminApis.getPaymentData().then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setList(response?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, []);

  console?.log(lists)

  return (
    <>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />



        <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#c4c4c4] mx-6 lg:mt-16 lg:mx-10 mt-5">
          <div className="flex justify-between px-8 py-6">

            <div><h3>Users List</h3></div>
            {/* <div className=" lg:ml-auto mr-3">
              <div className="relative flex w-full ">
                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                </div>
                <input type="text" placeholder='search User...' className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " />
                
              </div>
            </div> */}



          </div>




          <table className="w-full text-sm text-center">
            <thead className="text-[14px] text-black uppercase">
              <tr>

                <th scope="col" className="px-6 py-3">
                  S/N
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>

                <th scope="col" className="px-6 py-3">
                  Phone Number
                </th>

                <th scope="col" className="px-6 py-3">
                  Unique ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Academic background
                </th>
                <th scope="col" className="px-6 py-3">
                  Above 18?
                </th>
                <th scope="col" className="px-6 py-3">
                  Aware of nursing
                </th>
                <th scope="col" className="px-6 py-3">
                  Who is paying
                </th>
                <th scope="col" className="px-6 py-3">
                  Have valid Passport
                </th>

                <th scope="col" className="px-6 py-3">
                  Accept service fee charge?
                </th>
              </tr>
            </thead>
            <tbody>
              {
                lists?.map(
                  (datas: any, index: any) => (
                    <tr className="bg-white">

                      <td className="px-6 py-4">
                        {index + 1}
                      </td>

                      <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{datas?.first_name + ' ' + datas?.last_name}</td>
                      <td className="px-6 py-4">
                        {datas?.email}
                      </td>
                      <td className="px-6 py-4">
                        {datas?.phone_number}
                      </td>

                      <td className="px-6 py-4">
                        {datas?.unique_id}
                      </td>

                      <td className="px-6 py-4">
                        {datas?.academic_background}
                      </td>


                      <td className="px-6 py-4">
                        {datas?.age}
                      </td>

                      <td className="px-6 py-4">
                        {datas?.nursing_program}
                      </td>

                      <td className="px-6 py-4">
                        {datas?.tution_fee}
                      </td>

                      <td className="px-6 py-4">
                        {datas?.passport}
                      </td>

                      <td className="px-6 py-4">
                        Yes
                      </td>





                      {/* <td className="px-6 py-4">
                      <a href="#" className=" font-normal text-white bg-[#1DB459] px-3 py-1 rounded-sm">Paid</a>
                    </td>
                    <td className="px-6 py-4 flex justify-center">
                      <NavLink to={"/view-registered-user"}>
                        <span className=' cursor-pointer'  >
                          <SvgElement type={icontypesEnum.EYE} />
                        </span>
                      </NavLink>
                    </td> */}
                    </tr>
                  ))}

            </tbody>

          </table>


        </div>

      </div>
    </>
  )
}

export default RegList