import React from "react";
import { useSelector } from "react-redux";
import { DashboardApi } from "../../apis/DashboardApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../reducer/loginSlice";
//@ts-ignore
import EllipsisText from 'react-ellipsis-text';


// components

export default function CardSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLoginData = useSelector((state: any) => state.data.login.value);

  // console?.log(userLoginData)
  const [userDetails, setuserDetails] = React.useState<any>([]);

  React.useEffect(() => {
    DashboardApi.getUserDetails().then(
      (response: any) => {
        if (response?.data) {
          setuserDetails(response?.data?.data?.user_details)
        }
      }
    );

  }, []);

  // console.log(userDetails)
  const [fileName2, setFileName2] = React.useState("No selected file");
  const [coverPhotoName, SetCoverPhotoName] = React.useState<any>("No selected file")

  const [selectedPics2, setSelectedPics2] = React.useState('No selected file');
  const changeHandler22 = (event: any) => {
    // console?.log(2)
    const target = event.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    setFileName2(event.target.files[0].name)
    SetCoverPhotoName(event.target.files[0].name)
    if (target.files && target.files[0]) {
      if (size > 2) {
        if (targetName == 'image1') {
          target.value = ''
          toast.warn('File too large');
        }
      }
      if (size <= 2) {
        if (targetName == 'image1') {
          setSelectedPics2(event.target.files[0]);
        }
      }
    }
  };

  const [user_data, set_user_data] = React.useState<any>(userDetails);


  let [userDatas, setUserDatas] = React.useState({
    email: "", name: "",
    phone_number: "", address: "",
    electricity_type: "", water: "",
    d_o_b: "", place_of_birth: "",
    programs_type: "", gender: "",
    profile_picture: "", interested_course: ''
  });

  const handleChange = (e: any) => {
    setUserDatas({ ...userDatas, [e.target.name]: e.target.value });
  }


  const UpdateUser = React.useCallback(
    (e: any) => {
      e.preventDefault();
      const userData = new FormData();
      userData.append('address', (userDatas?.address !== '') ? userDatas?.address : userDetails?.address);
      userData.append('d_o_b', (userDatas?.d_o_b !== '') ? userDatas?.d_o_b : userDetails?.d_o_b);
      userData.append('gender', (userDatas?.gender !== '') ? userDatas?.d_o_b : userDetails?.gender);
      userData.append('name', (userDatas?.name !== '') ? userDatas?.name : userDetails?.name);
      userData.append('email', userDetails?.email);
      userData.append('id', userDetails?.id);
      userData.append('interested_course', (userDatas?.interested_course !== '') ? userDetails?.interested_course : userDetails?.interested_course);
      userData.append('phone_number', (userDatas?.phone_number !== '') ? userDatas?.phone_number : userDetails?.phone_number);
      userData.append('place_of_birth', (userDatas?.place_of_birth !== '') ? userDatas?.place_of_birth : userDetails?.place_of_birth);
      userData.append('profile_picture', selectedPics2);
      userData.append('programs_type', (userDatas?.programs_type !== '') ? userDatas?.programs_type : userDetails?.programs_type);

      DashboardApi.updateUser(userData).then(
        (response: any) => {
          if (response?.data) {
            toast.success(response?.data?.message);
            console?.log(response?.data?.data)
            // navigate('/wallet');
            // dispatch(login({ email: userLoginData?.email, token: userLoginData?.token, id: userLoginData?.id, name: response.data.data.name,data:response.data.data}))
          }
        }
      ).catch((err) => {
        if (err?.response?.status == 422) {
          toast.error('Form not rightly filled. Kindly Cross check.');
        } else {
          toast.error('Some error occured.Please try again');
        }


      }).finally(() => {

      });
    },
    [userDatas, user_data, userDetails, selectedPics2]
  );

  // console?.log(user_data)
  return (
    <>
      <form onSubmit={UpdateUser}>

        <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl md:px-16 pl-3 pr-4">
          <div className="hidden md:block">
            <h1 className='text-[#404D61] text-2xl'>Profile </h1>
            <p className='text-[#757D8A] text-sm mt-2'>Enter the required information below to register. You can change it anytime you want.</p>
          </div>
        
        </div>

        <div className="flex flex-col-reverse lg:flex-row lg:justify-between max-w-screen-xl  md:pl-3 md:pr-4 mt-5">
          <div className="w-full lg:w-8/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full  ">
              <div className="flex-auto px-4  py-10 pt-0">

                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-4 md:mt-0 mt-5">
                    <div className="relative w-full mb-6">
                      <label
                        className="block text-[#404D61] text-sm font-medium mb-2"
                        htmlFor="grid-password"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        onChange={handleChange}
                        name="email"
                        disabled
                        className="border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                        placeholder=""
                        defaultValue={userDetails?.email}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-6">
                      <label
                        className="block text-[#404D61] text-sm font-medium mb-2"
                        htmlFor="grid-password"
                      >
                        Full name
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="  border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                        placeholder=""
                        onChange={handleChange}
                        defaultValue={userDetails?.name}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block text-[#404D61] text-sm  font-medium mb-2"
                        htmlFor="grid-password"
                      >
                        Phone Number
                      </label>
                      <input
                        type="number"
                        name="phone_number"
                        className="border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                        placeholder=" "
                        onChange={handleChange}
                        defaultValue={userDetails?.phone_number}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-6">
                      <label
                        className="block text-[#404D61] text-sm font-medium mb-2"
                        htmlFor="grid-password"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        name="address"
                        onChange={handleChange}
                        defaultValue={userDetails?.address}
                        className="border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-6">
                      <label
                        className="block text-[#404D61] text-sm font-medium mb-2"
                        htmlFor="grid-password"
                      >
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        name="d_o_b"
                        onChange={handleChange}
                        defaultValue={userDetails?.d_o_b}
                        className="  border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full md:mb-6">
                      <label
                        className="block text-[#404D61] text-sm  font-medium mb-2"
                        htmlFor="grid-password"
                      >
                        Place of Birth
                        {/* <span className="sr-only"> Last name</span> */}
                      </label>
                      <input
                        type="text"
                        name="place_of_birth"
                        onChange={handleChange}
                        defaultValue={userDetails?.place_of_birth}
                        className="  border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>

                  <div className='px-4 mt-5'>
                  <button
                    type="submit"
                    className=" text-white bg-[#1DB459] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                  >
                    Save
                  </button>
                </div>

                </div>

              </div>
            </div>
          </div>


          <div className='mr-16 mt-5 md:mt-0 lg:w-4/12'>
            <h1 className='text-center text-[#404D61] text-lg'>Profile Photo</h1>
            <div className="  flex justify-center mt-3">

              <label className=" cursor-pointer relative">
                <p className="mb-2 text-sm text-[#6C757D] font-medium ">{selectedPics2 === "No selected file" ? " " : <EllipsisText text={coverPhotoName} length={"15"} tail={`...`} />}</p>

                {(userDetails?.profile_picture == 'No selected file' || userDetails?.profile_picture == 'null') ?
                  <img className="lg:w-full rounded" style={{ height: '60px', width: '70px' }} src="/images/Dashboard/camera.png" alt="" />
                  :
                  <img className="   w-32 h-32 rounded-full" src={userDetails?.profile_picture} alt={userDetails?.profile_picture}   />
                }

                <span className="bottom-0 md:-right-1 -right-1  absolute  rounded-full">
                  <input id="dropzone-file" name='image1' onChange={changeHandler22} type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
                  <img className="w-full rounded-full" src="/images/Dashboard/Edit.svg" alt="" />
                </span>

              </label>


            </div>
            <h3 className="text-center text-[#404D61] mt-4 capitalize">Gender : {userDetails?.gender}</h3>
          </div>
          
        </div>
      </form>


      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}
