import React from 'react'
import Navbar from '../Navbar'
import blogbg from "../../img/blog.png";
import Footer from '../Footer';
import { NavLink } from 'react-router-dom';
import { AuthApis } from '../../apis/authApis';
import { AxiosResponse } from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { MutatingDots } from 'react-loader-spinner'

function AfriProAndFinest() {

    const [groupLists, setGroupList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');
    const [loader, setLoader] = React.useState<boolean>(true);

    const params: any = useParams();
    let id: string = params?.id;

    React.useEffect(() => {
        setLoader(true)
        AuthApis.getSIngleBlog(id).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setGroupList(response?.data?.data)
                    setLoader(false)
                    // console?.log(response?.data?.data)

                }
            }
        ).catch(function (error) {
            // handle error

        })
    }, []);


    return (
        <>
            <Navbar />

            {/* <!-- Start block --> */}
            <section className=" body-font font-poppins pt-16" >
                <div
                    style={{
                        backgroundImage: `url(${blogbg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className=" lg:px-14 pt-10 pb-24 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center text-white pt-20">
                        <h1 className="md:text-5xl text-3xl font-bold">
                            {groupLists?.article_title}
                        </h1>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-[#F5F5F5]  body-font font-poppins">

                {
                    loader ?
                    <div className='flex justify-center my-40'>
                    <MutatingDots
                        height="100"
                        width="100"
                        color="#4fa94d"
                        secondaryColor='#4fa94d'
                        radius='12.5'
                        ariaLabel="mutating-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={loader}
                    />
                </div>

                :
                <div className='bg-white lg:mx-28 pb-10 lg:px-10 px-8 pt-10 '>
                <div>
                    <div className='flex justify-center mt-5'>
                        {/* <img src={groupLists?.article_image_url} alt={groupLists?.article_image} className=' md:max-w-[20vw]' /> */}
                    </div>
                    <div className='flex justify-center mx-auto md:px-44'>
                        {/* <p className='italic mt-1 text-[#333333]' dangerouslySetInnerHTML={{__html: groupLists?.blog_summary}}></p> */}
                    </div>

                </div>
                <h3 className='text-base text-[#000000] font-normal mt-5' dangerouslySetInnerHTML={{ __html: groupLists?.article_body }}></h3>

            </div>
                }

              
              

            </section>
            {/* <!-- End block --> */}

            <Footer />
        </>
    )
}

export default AfriProAndFinest