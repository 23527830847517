import React from 'react'
import { Link } from "react-router-dom";
import AdminSidebar from '../../Sidebar/AdminSidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import { SvgElement, icontypesEnum } from '../../assets/svgElement'
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import { CSVLink, CSVDownload } from "react-csv";

function MasterProgramList() {
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userLists, setUserList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');
    const [csvData, setCsvData] = React.useState<any>([]);


    React.useEffect(() => {

        const query: any = {
            search: searchText,
        };
        AdminApis.getMasterStudent('', query).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setUserList(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })

        AdminApis.getMasters().then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setCsvData(response?.data?.data)
                }
            }
        ).catch(function (error) {
            console.log(error.response.data);
        })


    }, []);



    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }
            const query: any = {
                search: searchText,
            };

            AdminApis.getMasterStudent(value2, query).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setUserList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [userLists, searchText]);


    return (
        <>
            <AdminSidebar />
            <div className="relative md:ml-64 bg-blueGray-100">

                <UserNavbar />

                <div className="relative shadow-md rounded-lg border-2 border-[#c4c4c4] mx-6 lg:mt-10 lg:mx-4 px-3 mt-5">
                    <div className="flex justify-between px-8 py-6">
                        <h3>Master Registration</h3>
                        <div className='flex justify-end'>
                            <div className=" lg:ml-auto mr-3 flex justify-end">
                                <div className="relative flex w-full flex-wrap items-stretch">
                                    <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                                    </div>
                                    <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                                </div>

                                <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#1DB459] px-3 py-0.5 rounded-md"}>Search</button> </div>

                                <div className='ml-4 mt-1 cursor-pointer' onClick={() => { }}>
                                    <CSVLink data={csvData} filename={"MasterList.csv"} target="_blank">

                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18 7H6V3H18V7ZM18 12.5C18.2833 12.5 18.5207 12.404 18.712 12.212C18.904 12.0207 19 11.7833 19 11.5C19 11.2167 18.904 10.979 18.712 10.787C18.5207 10.5957 18.2833 10.5 18 10.5C17.7167 10.5 17.4793 10.5957 17.288 10.787C17.096 10.979 17 11.2167 17 11.5C17 11.7833 17.096 12.0207 17.288 12.212C17.4793 12.404 17.7167 12.5 18 12.5ZM16 19V15H8V19H16ZM18 21H6V17H2V11C2 10.15 2.29167 9.43767 2.875 8.863C3.45833 8.28767 4.16667 8 5 8H19C19.85 8 20.5627 8.28767 21.138 8.863C21.7127 9.43767 22 10.15 22 11V17H18V21Z" fill="#9DA4AA" />
                                        </svg>

                                    </CSVLink>

                                </div>

                            </div>

                            {/* <div className='mt-1'>Filter </div> */}
                        </div>

                    </div>










                    <div className='overflow-x-auto'>
                        <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 font-light">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        S/N
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Nationality
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Perosnal Id code
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Email
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Phone Number
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Country Of Residence
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Address
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Postal Code
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Country And City
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Native Language
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Year Of Completion
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Degree
                                    </th>



                                    <th scope="col" className="px-6 py-3">
                                        Degree Level
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Institution Attended
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Country of Institution
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Year of Completion
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Name of Degree
                                    </th> 

                                    <th scope="col" className="px-6 py-3">
                                       Address Of Institution
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                       Country Of Institution
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Email of Institution
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Major and Minor Subject
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Thesis
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Grading Scale of Institution
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        GPA of your Degree
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Language Of Instruction
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Additional Uni Studies
                                    </th>


                                    <th scope="col" className="px-6 py-3">
                                        Expected Date of Graduation
                                    </th>


                                    <th scope="col" className="px-6 py-3">
                                        Graduation Requiement
                                    </th>


                                    <th scope="col" className="px-6 py-3">
                                        Graduated?
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    userLists?.data?.filter((data: any) => data?.role !== 'admin')?.map(
                                        (datas: any, index: any) => (
                                            <tr className="bg-white  ">

                                                <td className="px-6 py-4">
                                                    {index + 1}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.first_name + ' ' + datas?.last_name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.nationality}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.personal_id_code}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.email}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.phone_number}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.country_of_residence}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.address}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.postal_code}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.city_and_country}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.native_lang}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.year_of_completion}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.degree}
                                                </td>


                                                <td className="px-6 py-4">
                                                    {datas?.degree_level}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.higher_education_institution}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.country_of_completion}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.year_of_completion_for_higher_edu}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.name_of_degree}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.name_and_postal_address_of_institution}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.country_of_institution}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.email_and_website_of_institution}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.major_and_minor_subject_in_your_degree}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.thesis_and_final_title}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.grading_scale_of_institution}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.gpa_of_your_degree}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.lang_of_instruction_in_uni}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.additional_uni_studies}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.expected_date_of_graduation}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.what_required_for_you_to_graduate}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.graduated}
                                                </td>

                                               

                                            </tr>
                                        )
                                    )
                                }

                            </tbody>
                        </table>
                    </div>

                    <div className='m-4'>
                        {
                            userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-green-500 text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }

                    </div>
                </div>
            </div>

            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />

        </>
    )
}

export default MasterProgramList