import React from 'react'
import Navbar from '../../Navbar'
import blogbg from "../../../img/blog.png";
import Footer from '../../Footer';
import { NavLink } from 'react-router-dom';

function AfriProAndFinest() {
    return (
        <>
            <Navbar />

            {/* <!-- Start block --> */}
            <section className=" body-font font-poppins pt-16" >
                <div
                    style={{
                        backgroundImage: `url(${blogbg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className=" lg:px-14 pt-10 pb-24 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center text-white pt-20">
                        <h1 className="md:text-5xl text-3xl font-bold">
                            AfriPro and Finest Future, African Tour to Promote Finnish Language Program to African Students to access Free education in Finland.
                        </h1>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-[#F5F5F5]  body-font font-poppins">
                <div className='bg-white lg:mx-28 pb-10 lg:px-10 px-8 pt-10 '>
                    <div>
                    <div className='flex justify-center mt-5'>
                        <img src="/images/blog-img1.png" alt="location" className='md:w-[70%] w-full'/> 
                    </div>
                    <div className='flex justify-center mx-auto md:px-44'>
                    <p className='italic mt-1 text-[#333333]'>Peter Haufiku ( Founder EPSA27) , Deputy minister of Education,Arts and Culture, Hon. Faustina Namutenya Caley, Peter Vesterbacka ( Slush founder, former Mighty Eagle at Rovio entertainment), Minister of Education, Arts and Culture Hon. Ester Anna Nghipondoka, John Samuel (Founder and CEO AfriProEdu) with Tuomas Tiilikainen (CO-Founder Finest future) and Azyelo</p>
                    </div>
                    
                    </div>
                    <h3 className='text-base text-[#000000] font-normal mt-5'>AfriProEdu is a sister company to AfriPro. AfriPro is a digital platform designed to empower African footballers to be monitored and scouted by top teams across Europe. </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-2'>
                        With AfriProEdu, we are able to provide educational services that enable students to study Finnish online in their respective countries for 6-7 months.
                    </h3>

                    <h3 className='text-base text-[#000000] font-normal mt-2'>
                        AfriProEdu is an educational consulting service that partnered with Finest Future providing a Finnish language program for students to study without tuition fees in Finland.
                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-2'>
                        Finest Future is an organization based in Finland designed to provide Finnish language programs to international students.

                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-2'>
                        AfriProEdu is a partner to Finest Future providing these educational services to Africa.
                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-2'>
                        AfriProEdu invited Finest Future representatives ( Mr. Peter Vesterbacka the chairman of Finest Future and creator of Angry birds and Slush together with Mr. Tuomas Tiillikainen the COO and Co-Founder of Finest Future) to an African tour to promote the Finnish language program and create awareness about the Finnish Education.

                    </h3>
                    <div>
                        {/* image */}
                    </div>
                    <div>
                        <h3 className='text-base text-[#000000] font-normal mt-5'>
                            AfriProEdu and Finest Future visited the ambassador of Finland to Namibia Leena Viljanen to discuss more opportunities and strengthen the 152 years the bilateral relationship between Finland and Namibia via education and we also visited the minister of Lower Education in Namibia, Honourable Ester Anna Nghipondoka who was excited about our projects and now we have an ongoing project between Finest Future + AfriProEdu + Namibian Ministry of Education.

                        </h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            The visit to Nigeria, was a seminar done with various individuals, startup founders, and organizations like Ambitious Nigeria ( a civil organization connecting Nordic and African youths to collaboratively solve the SDGs) , and create awareness of studying the Finnish language and also, starting a business in Finland.

                        </h3>
                    </div>
                    <div>
                        {/* image */}
                    </div>
                    <div>
                        <h3 className='text-base text-[#000000] font-normal mt-5'>
                            Finnish Education is regarded to be the best in the world.  Finland is also regarded as one of the safest countries to travel to in the world. Finland is one of the most technologically advanced countries in the world. Finland can boast of the cleanest air in the world, the Greenest country in the world, and the most Socially and gender-equal country in the world. The list goes on and on.

                        </h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            Finest Future began the pilot phase last year 2021 with 15  students from Vietnam and Uzbekistan who studied the Finnish language for 6 months, and all students got school placements, and students visas and all are now studying .

                        </h3>
                    </div>

                    <div>
                    <div className='flex justify-center mt-8'>
                        <img src="/images/blog-img2.png" alt="location" className='md:w-[70%] w-full'/> 
                    </div>
                    <div className='flex justify-center mx-auto md:px-44'>
                    {/* <p className='italic mt-1 text-[#333333]'>Cross-section of attendees at the event held at DHPS high school in Windhoek, Namibia
</p> */}
                    </div>
                    
                    </div>

                    <div>
                    <h3 className='text-base text-[#000000] font-normal mt-5'>
                    With the Finest Future model, we provide pathways to free education in Finland up to University and master’s level through vocational schools.

                        </h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            In Finland, students can study vocational schools. The former is more academical and after studies goes straight to University while the latter is more practical in their field of studies and after studies can decide to go and work or move on to a degree course in the University.

                        </h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            Students who have finished secondary school or University are not exempted because with vocational studies you can have access to studying for free in your field of choice.
                        </h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            At the moment we have 10 students from Nigeria, Cameroon, and Tanzania combined who are currently studying the Finnish language and this tour is to create more awareness about the immense benefits and opportunities available for students to work and study in Finland.</h3>
                    </div>
                    <div>
                    <div className='flex justify-center mt-8'>
                        <img src="/images/blog-img3.png" alt="location" className='md:w-[70%] w-full'/> 
                    </div>
                    <div className='flex justify-center mx-auto md:px-44'>
                    <p className='italic mt-1 text-[#333333]'>Cross-section of attendees at the event held at The Bunker, Lekki phase one, Lagos Nigeria
</p>
                    </div>
                    
                    </div>
                    <div>
                        <h3 className='text-base text-[#000000] font-normal mt-5'>
                            In Finland, students are eligible to work while they study and can work 25 hours a week ( unlike full time workers who work 40 hours a week )
                            Students however can work 40 hours and more during holidays.
                        </h3>
                        <h3 className='text-base text-[#000000] font-medium mt-2'>
                            Average salaries in Finland is 10€/hour.
                        </h3>

                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            These opportunities do come at a cost which are charged either one time or part payment flexible payment method and more information about this costs can be inquired through the sales team of AfriProEdu +358408187876 ( WhatsApp).</h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            Students can start immediately after first payment and the language duration is 6 months, 100% online. After 6 months, students will do the Finest Future language proficiency exam which in turn they get the certificate to apply to schools in Finland.</h3>
                    </div>

                    <div>
                    <div className='flex justify-center mt-8'>
                        <img src="/images/blog-img4.png" alt="location" className='md:w-[70%] w-full'/> 
                    </div>
                    <div className='flex justify-center mx-auto md:px-44'>
                    <p className='italic mt-1 text-[#333333]'>Press Conference with AIMS, Windhoek Namibia
</p>
                    </div>
                    
                    </div>

                    <div>
                        <h3 className='text-base text-[#000000] font-normal mt-5'>
                            At the moment,  Finest Future has got over 20 partner in Finland and 4 vocational schools with various branches across Finland.

                        </h3>
                        <h3 className='text-base text-[#000000] font-medium mt-2'>
                            AfriProEdu supports students with all applications to partner schools, documentation,  visa application processes, etc.
                        </h3>

                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            The program also includes integration programs into the Finnish culture, people, laws, policies, and environments. This aids the fast integration into the Finnish system when our students arrive in Finland.
                        </h3>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            For more information visit our website<NavLink to="http://afriproedu.com/" className="text-[#48B774] font-medium">www.afriproedu.com</NavLink>  or call our sales team on WhatsApp at +358408187876</h3>
                    </div>







                </div>

            </section>
            {/* <!-- End block --> */}

            <Footer />
        </>
    )
}

export default AfriProAndFinest