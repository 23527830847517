import React from 'react'
import { Link } from "react-router-dom";
import AdminSidebar from '../../Sidebar/AdminSidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import { SvgElement, icontypesEnum } from '../../assets/svgElement'
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { NavLink, useNavigate } from "react-router-dom";
function SubAdmins() {

  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);
  React.useEffect(() => {
    AdminApis.getAllSubAdmins('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserList(response?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, []);

  // const paginator = React.useCallback(
  //   (value: any) => {
  //     //   setLoader(true);
  //     let value2 = '';
  //     if (value !== null) {
  //       value2 = value;
  //     } else {
  //       value2 = ''
  //     }

  //     AdminApis.getAllUsers(value2,'').then(
  //       (response: AxiosResponse<any>) => {
  //         if (response?.data) {
  //           setUserList(response?.data?.data)
  //         }
  //       }
  //     ).catch(function (error) {
  //       console.log(error.response.data);
  //     })

  //   }, [userLists]);

  const userDetailsPage = React.useCallback(
    (value: any) => {
      console?.log('d')
      navigate(`/subadmin-details/${value}`);
    }, [])


  return (
    <>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100">

        <UserNavbar />

        <div className='lg:mt-10 lg:mx-16 mx-6 flex justify-end'>
          <Link to="/add-admin">
            <button type="button" className="text-white bg-[#1DB459] font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 ">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z" /></svg>
              Add Admin
            </button>
          </Link>

        </div>
        <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#c4c4c4] mx-6 mt-3 lg:mt-8 lg:mx-16">
          <div className="flex justify-between px-8 py-6">
            <h3>Sub Admins</h3>

          </div>
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 font-light">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Role

                </th>
                <th scope="col" className="px-6 py-3">
                  Joined
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {
                userLists?.data?.map(
                  (datas: any, index: any) => (
                    <tr className="bg-white" key={index}>
                      <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                        <div className="flex space-x-3">
                          <div>
                            <h3 className=" text-sm">{datas?.name} {datas?.last_name}</h3>
                            <p className=" text-xs text-[#647380]">{datas?.email}</p>

                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        {datas?.role}
                      </td>
                      <td className="px-6 py-4">
                        {datas?.created_at}
                      </td>
                     
                      <td className="px-6 py-4">
                        <a href="#" className=" font-normal text-white bg-[#1DB459] px-3 py-1 rounded-sm">{datas?.status}</a>
                      </td>
                      
                      <td className="px-6 py-4">
                        <div className='flex space-x-3'>
                          <span style={{ cursor: 'pointer' }} onClick={() => userDetailsPage(datas?.id)}>
                            <SvgElement type={icontypesEnum.EYE} />
                          </span>

                          <div className='mt-1'>
                            <SvgElement type={icontypesEnum.DELETE} />
                          </div>

                        </div>
                      </td>
                    </tr>
                  )
                )
              }




              {/* <tr className="bg-white  ">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                  <div className="flex space-x-3">
                    <div>
                      <img src="/images/Dashboard/Userpic1.svg" alt="hero" />
                    </div>
                    <div>
                      <h3 className=" text-sm">John Samuel</h3>
                      <p className=" text-xs text-[#647380]">john@gmail.com</p>

                    </div>
                  </div>
                </th>
                <td className="px-6 py-4">
                  <a href="#" className=" font-normal text-white bg-[#1DB459] px-3 py-1 rounded-sm">Active</a>
                </td>
                <td className="px-6 py-4">
                  29 Jan 2023
                </td>

                <td className="px-6 py-4">
                  CEO and Founder
                </td>

                <td className="px-6 py-4">
                  <div className='flex space-x-3'>
                    <SvgElement type={icontypesEnum.EDIT} />
                    <div className='mt-1'>
                      <SvgElement type={icontypesEnum.DELETE} />
                    </div>

                  </div>
                </td>
              </tr>
              <tr className=" bg-gray-50 ">

                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                  <div className="flex space-x-3">
                    <div>
                      <img src="/images/Dashboard/Userpic1.svg" alt="hero" />
                    </div>
                    <div>
                      <h3 className=" text-sm">Timothy Samuel</h3>
                      <p className=" text-xs">timothy@gmail.com</p>

                    </div>
                  </div>
                </th>
                <td className="px-6 py-4">
                  <a href="#" className=" font-normal text-white bg-[#E21E32] px-3 py-1 rounded-sm">Inactive</a>
                </td>

                <td className="px-6 py-4">
                  29 July 2023
                </td>
                <td className="px-6 py-4">
                  Sub Admin
                </td>

                <td className="px-6 py-4">
                  <div className='flex space-x-3'>
                    <SvgElement type={icontypesEnum.EDIT} />
                    <div className='mt-1'>
                      <SvgElement type={icontypesEnum.DELETE} />
                    </div>

                  </div>
                </td>
              </tr> */}



            </tbody>
          </table>
        </div>
      </div>



    </>
  );
}

export default SubAdmins;