import React from 'react'
import { NavLink } from "react-router-dom";
import Sidebar from '../../Sidebar/Sidebar'
import UserNavbar from '../../Navbars/UserNavbar'

function TravelDocuments() {
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar title="Travel Documents" />

                <div className='md:py-16'>
                    <form>
                        <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto pl-3 pr-4 mt-5">
                            <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                <div className="flex-auto md:px-4  py-10 pt-0">
                                    <div className="flex flex-wrap">
                                        <div className="w-full lg:w-12/12 px-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#0A2E04] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Full Name
                                                </label>
                                                <input
                                                    type="text"

                                                    name="full_name"

                                                    className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                                                    placeholder="Enter Name"

                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-6/12 px-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#0A2E04] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Post Code
                                                </label>
                                                <input
                                                    type="text"

                                                    name="post_code"

                                                    className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                                                    placeholder="Enter Post Code"

                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-6/12 px-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#0A2E04] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Phone Number
                                                </label>
                                                <input
                                                    type="number"

                                                    name="phone_number"

                                                    className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                                                    placeholder="Enter Phone Number"

                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-12/12 px-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#0A2E04] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Email
                                                </label>
                                                <input
                                                    type="email"

                                                    name="email"

                                                    className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                                                    placeholder="Enter Email"

                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-12/12 px-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#0A2E04] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Home address
                                                </label>
                                                <input
                                                    type="text"

                                                    name="home_address"

                                                    className="border border-[#D9D9D9] text-[#333333] text-sm rounded-[4px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                                                    placeholder="Enter Your Address"

                                                />
                                            </div>
                                        </div>

                                        <div className='flex flex-col border border-[#d9d9d9] w-full rounded-[4px] mx-4 p-3 mb-6'>
                                            <div>
                                                <h4 className='text-[14px] font-medium'>Notarized copy of Birth certificate</h4>
                                            </div>
                                            <div>
                                                <label htmlFor="dropzone-file">
                                                    <div className='flex space-x-2 border border-[#d9d9d9] mt-2 p-2 rounded-[5px] w-[108px] hover:bg-gray-100'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16h-2Zm-5 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" /></svg>
                                                        <p className='text-[14px]'>Add file</p>
                                                    </div>
                                                    <input id="dropzone-file" type="file" className="hidden" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className='flex flex-col border border-[#d9d9d9] w-full rounded-[4px] mx-4 p-3 mb-6'>
                                            <div>
                                                <h4 className='text-[14px] font-medium'>Notarized copy of school certificate</h4>
                                            </div>
                                            <div>
                                                <label htmlFor="dropzone-file">
                                                    <div className='flex space-x-2 border border-[#d9d9d9] mt-2 p-2 rounded-[5px] w-[108px] hover:bg-gray-100'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16h-2Zm-5 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" /></svg>
                                                        <p className='text-[14px]'>Add file</p>
                                                    </div>
                                                    <input id="dropzone-file" type="file" className="hidden" />
                                                </label>
                                            </div>
                                        </div>

                                        <div className='flex flex-col border border-[#d9d9d9] w-full rounded-[4px] mx-4 p-3 mb-6'>
                                            <div>
                                                <h4 className='text-[14px] font-medium'>Notarized copy of Marriage Certificate</h4>
                                            </div>
                                            <div>
                                                <label htmlFor="dropzone-file">
                                                    <div className='flex space-x-2 border border-[#d9d9d9] mt-2 p-2 rounded-[5px] w-[108px] hover:bg-gray-100'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16h-2Zm-5 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" /></svg>
                                                        <p className='text-[14px]'>Add file</p>
                                                    </div>
                                                    <input id="dropzone-file" type="file" className="hidden" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className='flex flex-col border border-[#d9d9d9] w-full rounded-[4px] mx-4 p-3 mb-6'>
                                            <div>
                                                <h4 className='text-[14px] font-medium'>Bank statement of Student</h4>
                                            </div>
                                            <div>
                                                <label htmlFor="dropzone-file">
                                                    <div className='flex space-x-2 border border-[#d9d9d9] mt-2 p-2 rounded-[5px] w-[108px] hover:bg-gray-100'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16h-2Zm-5 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" /></svg>
                                                        <p className='text-[14px]'>Add file</p>
                                                    </div>
                                                    <input id="dropzone-file" type="file" className="hidden" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className='flex flex-col border border-[#d9d9d9] w-full rounded-[4px] mx-4 p-3 mb-6'>
                                            <div>
                                                <h4 className='text-[14px] font-medium'>Passport Data page</h4>
                                            </div>
                                            <div>
                                                <label htmlFor="dropzone-file">
                                                    <div className='flex space-x-2 border border-[#d9d9d9] mt-2 p-2 rounded-[5px] w-[108px] hover:bg-gray-100'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16h-2Zm-5 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" /></svg>
                                                        <p className='text-[14px]'>Add file</p>
                                                    </div>
                                                    <input id="dropzone-file" type="file" className="hidden" />
                                                </label>
                                            </div>
                                        </div>

                                        <NavLink to={"/dependant-information"} className="w-full  lg:w-6/12 md:px-8 px-6 pb-6">
                                            <div className='flex justify-center items-center'>
                                                <button type='submit' className="font-normal text-white bg-[#1DB459] px-6 py-2.5 rounded-full w-full">Next</button>
                                            </div>
                                        </NavLink>












                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default TravelDocuments