import React, { useState, useMemo } from 'react'
import Navbar from '../Navbar'
import {  useNavigate, useParams } from "react-router-dom";
import { AuthApis } from '../../apis/authApis';
import { ToastContainer, toast } from 'react-toastify';
import { MutatingDots } from 'react-loader-spinner';
import "react-responsive-modal/styles.css";
import { Modal } from 'react-responsive-modal'


function MastersApplication() {
    const params: any = useParams();
    let details: string = params?.masters;

    // console.log(details)
    const programs = details.split(",");
    const [userData, setUserdata] = React.useState({
        'study_program_one': "",
        'study_program_two': "none",
        'first_name': "",
        'last_name': "",
        'other_name': "",
        'nationality': "",
        'personal_id_code': "",
        'email': "",
        'phone_number': "",
        'country_of_residence': "",
        'address': "",
        'postal_code': "",
        'city_and_country': "",
        'native_lang': "",
        'year_of_completion': "",
        'degree': "",
        'degree_level': "",
        'higher_education_institution': "",
        'country_of_completion': "",
        'year_of_completion_for_higher_edu': "",
        'name_of_degree': "",
        'name_and_postal_address_of_institution': "",
        'country_of_institution': "",
        'email_and_website_of_institution': "",
        'major_and_minor_subject_in_your_degree': "",
        'thesis_and_final_title': "",
        'grading_scale_of_institution': "",
        'gpa_of_your_degree': "",
        'lang_of_instruction_in_uni': "",
        'additional_uni_studies': "",
        'expected_date_of_graduation': "",
        'what_required_for_you_to_graduate': "",

    });

    const [open, setOpen] = useState(true);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const navigate = useNavigate();
    const [openPayment, setOpenPayment] = useState(false);
    const onOpenPaymentModal = () => setOpenPayment(true);
    const onClosePaymentModal = () => setOpenPayment(false);

    let [graduated, setGraduated] = React.useState<any>('');
    let [tuition, setTuition] = React.useState<any>('');
    let [availableDoc, setAvailableDoc] = React.useState<any>('');
    let [applyScholarship, setApplyScolarship] = React.useState<any>('');
    let [acceptTerms, setAcceptTerms] = React.useState<any>('');
    let [permission, setPermission] = React.useState<any>('');
    let [degreeCert, setDegreeCert] = React.useState<any>('No selected file');
    let [transcript, setTranscript] = React.useState<any>('No selected file');
    let [languageProficiency, setLanguageProficiency] = React.useState<any>('No selected file');
    let [passport, setPassport] = React.useState<any>('No selected file');
    let [examTuitionFee, setExamTuitionFee] = React.useState<any>('No selected file');
    let [otherDocs, setOthersDocs] = React.useState<any>('No selected file');

    const [loader, setLoader] = React.useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUserdata({ ...userData, [name]: value });
    };

    const [openModalNoti, setOpenModalNoti] = useState(true);
    // const onOpenModalNoti = () => setOpenModalNoti(true);
    // const onCloseModalNoti = () => setOpenModalNoti(false);
    const onCloseModalNoti = () => {
        navigate(-1)
    }

    const degCert = (event: any) => {
        const target = event.target
        let size = (target.files[0].size / 1048576.0)

        if (size > 1) {
            if (target.name == 'degree_cert') {
                target.value = ''
                console.log('error')
            }
        }
        if (size <= 1) {
            if (target.name == 'degree_cert') {
                setDegreeCert(event.target.files[0]);
            }
        }

    };



    const transcripts = (event: any) => {
        const target = event.target
        let size = (target.files[0].size / 1048576.0)

        if (size > 1) {
            if (target.name == 'copy_of_transcript') {
                target.value = ''
                console.log('error')
            }
        }
        if (size <= 1) {
            if (target.name == 'copy_of_transcript') {
                setTranscript(event.target.files[0]);
            }

        }
    };


    const langProficiency = (event: any) => {
        const target = event.target
        let size = (target.files[0].size / 1048576.0)

        if (size > 1) {
            if (target.name == 'proof_of_lang') {
                target.value = ''
                console.log('error')
            }
        }
        if (size <= 1) {
            if (target.name == 'proof_of_lang') {
                setLanguageProficiency(event.target.files[0]);
            }
        }

    };



    const passpt = (event: any) => {
        const target = event.target
        let size = (target.files[0].size / 1048576.0)

        if (size > 1) {
            if (target.name == 'copy_of_passport') {
                target.value = ''
                console.log('error')
            }
        }
        if (size <= 1) {
            if (target.name == 'copy_of_passport') {
                setPassport(event.target.files[0]);
            }

        }
    };



    const tuiFee = (event: any) => {
        const target = event.target
        let size = (target.files[0].size / 1048576.0)

        if (size > 1) {
            if (target.name == 'copy_of_permit_card') {
                target.value = ''
                console.log('error')
            }
        }
        if (size <= 1) {
            if (target.name == 'copy_of_permit_card') {
                setExamTuitionFee(event.target.files[0]);
            }
        }

    };



    const otherDoc = (event: any) => {

        const target = event.target
        let size = (target.files[0].size / 1048576.0)
        // console?.log(size)
        if (size > 1) {
            if (target.name == 'other_document') {
                target.value = ''
                console.log('error')
            }
        }
        if (size <= 1) {
            if (target.name == 'other_document') {
                setOthersDocs(event.target.files[0]);
            }
        }

    };

    console?.log(otherDocs)


    const handleSubmit = React.useCallback(
        (e: any) => {
            setLoader(true)
            e.preventDefault();
            const userData2 = new FormData();
            userData2.append('study_program_one', userData?.study_program_one);
            userData2.append('study_program_two', userData?.study_program_two);
            userData2.append('first_name', userData?.first_name);
            userData2.append('last_name', userData?.last_name);
            userData2.append('other_name', userData?.other_name);
            userData2.append('nationality', userData?.nationality);
            userData2.append('personal_id_code', 'null');
            userData2.append('email', userData?.email);
            userData2.append('phone_number', userData?.phone_number);
            userData2.append('country_of_residence', userData?.country_of_residence);
            userData2.append('address', userData?.address);
            userData2.append('postal_code', userData?.postal_code);
            userData2.append('city_and_country', userData?.city_and_country);
            userData2.append('native_lang', userData?.native_lang);
            userData2.append('year_of_completion', userData?.year_of_completion);
            userData2.append('degree', userData?.degree);
            userData2.append('degree_level', userData?.degree_level);
            userData2.append('higher_education_institution', userData?.higher_education_institution);
            userData2.append('country_of_completion', userData?.country_of_completion);
            userData2.append('year_of_completion_for_higher_edu', userData?.year_of_completion_for_higher_edu);
            userData2.append('name_of_degree', userData?.name_of_degree);
            userData2.append('name_and_postal_address_of_institution', userData?.name_and_postal_address_of_institution);
            userData2.append('country_of_institution', userData?.country_of_institution);
            userData2.append('email_and_website_of_institution', userData?.email_and_website_of_institution);
            userData2.append('major_and_minor_subject_in_your_degree', userData?.major_and_minor_subject_in_your_degree);
            userData2.append('thesis_and_final_title', userData?.thesis_and_final_title);
            userData2.append('grading_scale_of_institution', userData?.grading_scale_of_institution);
            userData2.append('gpa_of_your_degree', userData?.gpa_of_your_degree);
            userData2.append('lang_of_instruction_in_uni', userData?.lang_of_instruction_in_uni);
            userData2.append('additional_uni_studies', userData?.additional_uni_studies);
            userData2.append('expected_date_of_graduation', userData?.expected_date_of_graduation);
            userData2.append('what_required_for_you_to_graduate', userData?.what_required_for_you_to_graduate);
            userData2.append('graduated', graduated);
            userData2.append('available_doc', availableDoc);
            userData2.append('apply_for_scholarship', applyScholarship);
            userData2.append('accept_terms', acceptTerms);
            userData2.append('permission', permission);
            userData2.append('degree_certificate', degreeCert);
            userData2.append('transcript', transcript);
            userData2.append('language_proficiency', languageProficiency);
            userData2.append('passport', passport);
            userData2.append('exam_tuition_fee', examTuitionFee);
            userData2.append('other_docs', otherDocs);

            AuthApis.createMasterCourse(userData2).then(
                (response: any) => {
                    if (response?.data) {
                        toast.success(response?.data?.message);
                        console?.log(response?.data)
                        setLoader(false)
                        onOpenPaymentModal()
                        // setRecall('response?.data?.message')
                        // navigate('/wallet');
                        // dispatch(login({ email: userLoginData?.email, token: userLoginData?.token, id: userLoginData?.id, name: response.data.data.name,data:response.data.data}))
                    }
                }
            ).catch((err) => {
                if (err?.response?.status == 422) {
                    toast.error('Form not rightly filled. Kindly Cross check.');
                } else {
                    toast.error('Some error occured.Please try again');
                }


            }).finally(() => {

            });
        },
        [passport, userData, otherDocs, examTuitionFee, loader, languageProficiency, transcript, degreeCert, permission, acceptTerms, applyScholarship, availableDoc, graduated]
    );


    const makeNairaPayment = () => {
        // e.preventDefault();
        onClosePaymentModal()
        const formData = new FormData()
        formData.append('amount', '160000')
        formData.append('first_name', userData?.first_name)
        formData.append('last_name', userData?.last_name)
        formData.append('email', userData?.email)
        formData.append('currency', 'NGN')
        AuthApis.makeCoursePayment(formData).then(
            (response: any) => {
                if (response?.data) {
                    if (response?.data?.success === true) {
                        console?.log(response?.data)
                        if (response?.data?.data?.data?.link) {
                            window.location.replace(response?.data?.data?.data?.link);
                        } else if (response?.data?.data?.message) {
                            toast.warn(response?.data?.data?.message);
                        } else {
                            toast.error('Something went wrong!');
                        }

                    }
                } else {
                    // toast.warn('Invalid Login Credentials');
                }
            }
        ).catch(function (error) {
            // handle error
        }).finally(() => {
            // toast.error("No Internet Connection");

        })
    }


    const makeDollarPayment = () => {
        // e.preventDefault();
        onClosePaymentModal()
        const formData = new FormData()
        formData.append('amount', '100')
        formData.append('first_name', userData?.first_name)
        formData.append('last_name', userData?.last_name)
        formData.append('email', userData?.email)
        formData.append('currency', 'USD')
        AuthApis.makeCoursePayment(formData).then(
            (response: any) => {
                if (response?.data) {
                    if (response?.data?.success === true) {
                        console?.log(response?.data)
                        if (response?.data?.data?.data?.link) {
                            window.location.replace(response?.data?.data?.data?.link);
                        } else if (response?.data?.data?.message) {
                            toast.warn(response?.data?.data?.message);
                        } else {
                            toast.error('Something went wrong!');
                        }

                    }
                } else {
                    // toast.warn('Invalid Login Credentials');
                }
            }
        ).catch(function (error) {
            // handle error
        }).finally(() => {
            // toast.error("No Internet Connection");

        })
    }



    return (
        <>
            <Navbar />
            {/* <Modal open={openModalNoti} onClose={onCloseModalNoti} center>
                <div className='md:max-w-md mt-6'>
                    <h1 className='text-2xl font-semibold text-center mt-3'>Masters Application Closed</h1>
                    <p className='text-[14px] mt-3'>We want to express our sincere gratitude for your interest in our recent cohort. However, we want to inform you that the current cohort has reached its maximum capacity and is now closed.</p>
                    <p className='text-[14px] mt-3'>But the good news is, we are excited to announce that you can register for the next cohort.</p>
                    <p className='text-[14px] mt-3'>We can't wait to have you on board for the next cohort. Thank you for considering our program, and we look forward to seeing you succeed in the upcoming session!</p>
              
                <p className='mt-3  text-[14px]'> <a target="_blank" href='https://chat.whatsapp.com/IRXlgGnZpBAGqFvlYlAxe7' className='text-[#1DB459] hover:underline font-semibold'>Click here</a> to join our WhatsApp group for daily updates.</p>    
                    <div className='flex mx-auto space-x-4 mt-6'>                                           
                                        </div>
                </div>
            </Modal> */}
            {/* <!-- Start block --> */}
            <section className='md:pt-20 pt-20'>
                <div className='bg-[#FBFBFB] sm:px-16 px-6 md:flex md:justify-center items-start pt-10 pb-8'>
                    <div className=' w-full mx-auto'>
                        <h1 className='md:text-[30px] text-[20px] font-semibold'>AfriProEdu, 2024 Early Bird Masters Program Rolling-Intake Admission Process</h1>
                        <div className='flex-col justify-center text-center mt-4'>
                            <h3>Welcome to apply to the Master's programs at the University of Vaasa!</h3>
                            <p>Please read these instructions carefully before filling in the application form:</p>

                        </div>
                        <div className='md:pr-60 md:pl-60 mx-4 mt-10'>
                            <ul className=" space-y-1 text-gray-500 list-disc list-outside">
                                <li>By filling in this application you can apply to admission to the Master's programs
                                    at the University of Vaasa through the rolling admission. Please choose the programs
                                    applied for in section "Application options". You can select up to two programs.</li>
                                <li>The rolling admission is open only for applicants who have completed or will complete their previous degree in a university or polytechnic outside of Finland. Applicants who fulfill the admission criteria are accepted in the order they apply. The application period is open until the 2 January 2024 at 3 PM (UTC+2) if the intake is not full before.</li>

                                <li>The application form cannot be saved as a draft. You are required to answer at least mandatory questions before you can submit the application form. In addition, you must submit all required attachments by the time of applying. In case of missing attachments, the application will be rejected, so please make sure that you submit your application only after you have checked that all required documents are attached to the application.</li>
                                <li>You can only submit one (1) application form. If you submit several application forms, only the first one will be taken into consideration and all others will be deleted. University will not inform applicants about deleted applications.
                                </li>
                                <li>You will receive a confirmation of your submitted application to the email you have given on the application form. An admission decision is made two weeks after the application has arrived. NB! Due to the high number of applications in the Early bird admission round, the processing time of applications is longer than usual.
                                </li>
                                <li>Admission results will be communicated to you by email. Please also follow your e-mail spam mail folder. The applicant must accept the study place within 7 days and pay the possible tuition fee by the 29 February 2024.
                                </li>
                                <h3 className='mt-5 font-medium'>Good luck with your application!</h3>

                            </ul>
                        </div>
                        <div className='md:pr-40 md:pl-40 mx-4 mt-10'>
                            <form onSubmit={handleSubmit}>
                                <hr />
                                <h3 className='mt-5 mb-6 font-medium text-[20px]'>APPLICATION OPTIONS</h3>
                                <div className="mb-6 w-full">
                                    <label
                                        htmlFor="study_program_one"
                                        className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                    >
                                        Add Study Program 1<span className='text-[#EE1D52] font-bold'> *</span>
                                    </label>
                                    <select
  id="study_program_one"
  name="study_program_one"
  onChange={handleChange}
  required
  className="border border-[#D9D9D9] text-[#333333] text-sm block w-full p-2.5"
>
  <option selected></option>
  {programs.map((program: string, index: number) => (
    <option key={index} value={program}>
      {program} 
    </option>
  ))}
</select>
                                    {/* <select
                                        id="study_program_one"
                                        name="study_program_one"
                                        onChange={handleChange}
                                        required
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                    >
                                        <option selected></option>
                                        <option value="finance">Master's Degree Programme in Finance (2 years) €12,000</option>
                                        <option value="international business">Master's Degree Programme in International Business (2 years) €12,000</option>
                                        <option value="strategic business development">Master's Programme in Strategic Business Development (2 years) €12,000</option>
                                        <option value="industrial management">Industrial Management, MSc in Economics and Business Administration (2 years) €10,000</option>
                                        <option value="smart grids">Smart Grids, MSc in Technology (2 years) €10,000</option>
                                        <option value="industrial system analytics">Industrial Systems Analytics, MSc in Technology (2 years) €10,000</option>
                                        <option value="strategic project management">Strategic Project Management, MSc in Technology (2 years) €10,000</option>
                                        <option value="sustainable and autonomous systems">Sustainable and Autonomous Systems, MSc in Technology (2 years) €10,000</option>

                                    </select> */}
                                </div>
                                {/* <div className="mb-6 w-full">
                                    <label
                                        htmlFor="study_program_two"
                                        className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                    >
                                        Add Study Program 2<span className='text-[#EE1D52] font-bold'> *</span>
                                    </label>
                                    <select
                                        id="study_program_two"
                                        name="study_program_two"
                                        onChange={handleChange}
                                        required
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                    >
                                        <option selected></option>
                                        <option value="finance">Master's Degree Programme in Finance (2 years) €12,000</option>
                                        <option value="international business">Master's Degree Programme in International Business (2 years) €12,000</option>
                                        <option value="strategic business development">Master's Programme in Strategic Business Development (2 years) €12,000</option>
                                        <option value="industrial management">Industrial Management, MSc in Economics and Business Administration (2 years) €10,000</option>
                                        <option value="smart grids">Smart Grids, MSc in Technology (2 years) €10,000</option>
                                        <option value="industrial system analytics">Industrial Systems Analytics, MSc in Technology (2 years) €10,000</option>
                                        <option value="strategic project management">Strategic Project Management, MSc in Technology (2 years) €10,000</option>
                                        <option value="sustainable and autonomous systems">Sustainable and Autonomous Systems, MSc in Technology (2 years) €10,000</option>
                                    </select>
                                </div> */}

                                <hr />
                                <h3 className='mt-5 mb-6 font-medium text-[20px]'>PERSONAL INFORMATION</h3>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        First Name<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Name"
                                        name="first_name"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Last Name<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Name"
                                        name="last_name"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Other Name
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder="Enter Name"
                                        name="other_name"
                                        onChange={handleChange}
                                        // required
                                    />
                                </div>
                                <div className="mb-6 w-full">
                                    <label
                                        htmlFor="nationality"
                                        className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                    >
                                        Nationality<span className='text-[#EE1D52] font-bold'> *</span>
                                    </label>

                                    <select
                                        id="nationality"
                                        name="nationality"
                                        onChange={handleChange}
                                        required
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                    >
                                        <option selected></option>
                                        <option value="Afghanistan">Afghanistan</option>
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Anguilla">Anguilla</option>
                                        <option value="Antartica">Antarctica</option>
                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Aruba">Aruba</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bermuda">Bermuda</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Bouvet Island">Bouvet Island</option>
                                        <option value="Brazil">Brazil</option>
                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                                        <option value="Bulgaria">Bulgaria</option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Central African Republic">Central African Republic</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China</option>
                                        <option value="Christmas Island">Christmas Island</option>
                                        <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo">Congo</option>
                                        <option value="Congo">Congo, the Democratic Republic of the</option>
                                        <option value="Cook Islands">Cook Islands</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                        <option value="Croatia">Croatia (Hrvatska)</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="East Timor">East Timor</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                        <option value="Faroe Islands">Faroe Islands</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="France Metropolitan">France, Metropolitan</option>
                                        <option value="French Guiana">French Guiana</option>
                                        <option value="French Polynesia">French Polynesia</option>
                                        <option value="French Southern Territories">French Southern Territories</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Gibraltar">Gibraltar</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Greenland">Greenland</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guadeloupe">Guadeloupe</option>
                                        <option value="Guam">Guam</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                        <option value="Holy See">Holy See (Vatican City State)</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="India">India</option>
                                        <option value="Indonesia">Indonesia</option>
                                        <option value="Iran">Iran (Islamic Republic of)</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                        <option value="Korea">Korea, Republic of</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Lao">Lao People's Democratic Republic</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon" selected>Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Macau">Macau</option>
                                        <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Martinique">Martinique</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mayotte">Mayotte</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Micronesia">Micronesia, Federated States of</option>
                                        <option value="Moldova">Moldova, Republic of</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montserrat">Montserrat</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>
                                        <option value="Namibia">Namibia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                        <option value="New Caledonia">New Caledonia</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria">Nigeria</option>
                                        <option value="Niue">Niue</option>
                                        <option value="Norfolk Island">Norfolk Island</option>
                                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Philippines">Philippines</option>
                                        <option value="Pitcairn">Pitcairn</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Reunion">Reunion</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russia">Russian Federation</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                        <option value="Saint LUCIA">Saint LUCIA</option>
                                        <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                        <option value="Span">Spain</option>
                                        <option value="SriLanka">Sri Lanka</option>
                                        <option value="St. Helena">St. Helena</option>
                                        <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                        <option value="Swaziland">Swaziland</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syria">Syrian Arab Republic</option>
                                        <option value="Taiwan">Taiwan, Province of China</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania">Tanzania, United Republic of</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tokelau">Tokelau</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="United States">United States</option>
                                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                        <option value="Uruguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Viet Nam</option>
                                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                        <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                        <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                        <option value="Western Sahara">Western Sahara</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Serbia">Serbia</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                </div>
                                {/* <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Personal Identity Code<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="personal_id_code"
                                        onChange={handleChange}
                                        required
                                    />
                                </div> */}
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        E-mail Address<span className='text-[#EE1D52] font-bold'>*</span>
                                        <p className='text-xs mt-1'>(Please ensure that the given email address is typed correctly and is your personal address. Important messages will be sent to this email address.)</p>
                                    </label>
                                    <input
                                        type="email"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="email"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Mobile Phone Number<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="number"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="phone_number"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full">
                                    <label
                                        htmlFor="country_of_residence"
                                        className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                    >
                                        Country of Residence<span className='text-[#EE1D52] font-bold'> *</span>
                                    </label>

                                    <select
                                        id="country_of_residence"
                                        name="country_of_residence"
                                        onChange={handleChange}
                                        required
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                    >
                                        <option selected></option>
                                        <option value="Afghanistan">Afghanistan</option>
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Anguilla">Anguilla</option>
                                        <option value="Antartica">Antarctica</option>
                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Aruba">Aruba</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bermuda">Bermuda</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Bouvet Island">Bouvet Island</option>
                                        <option value="Brazil">Brazil</option>
                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                                        <option value="Bulgaria">Bulgaria</option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Central African Republic">Central African Republic</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China</option>
                                        <option value="Christmas Island">Christmas Island</option>
                                        <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo">Congo</option>
                                        <option value="Congo">Congo, the Democratic Republic of the</option>
                                        <option value="Cook Islands">Cook Islands</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                        <option value="Croatia">Croatia (Hrvatska)</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="East Timor">East Timor</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                        <option value="Faroe Islands">Faroe Islands</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="France Metropolitan">France, Metropolitan</option>
                                        <option value="French Guiana">French Guiana</option>
                                        <option value="French Polynesia">French Polynesia</option>
                                        <option value="French Southern Territories">French Southern Territories</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Gibraltar">Gibraltar</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Greenland">Greenland</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guadeloupe">Guadeloupe</option>
                                        <option value="Guam">Guam</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                        <option value="Holy See">Holy See (Vatican City State)</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="India">India</option>
                                        <option value="Indonesia">Indonesia</option>
                                        <option value="Iran">Iran (Islamic Republic of)</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                        <option value="Korea">Korea, Republic of</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Lao">Lao People's Democratic Republic</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon" >Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Macau">Macau</option>
                                        <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Martinique">Martinique</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mayotte">Mayotte</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Micronesia">Micronesia, Federated States of</option>
                                        <option value="Moldova">Moldova, Republic of</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montserrat">Montserrat</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>
                                        <option value="Namibia">Namibia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                        <option value="New Caledonia">New Caledonia</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria" selected>Nigeria</option>
                                        <option value="Niue">Niue</option>
                                        <option value="Norfolk Island">Norfolk Island</option>
                                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Philippines">Philippines</option>
                                        <option value="Pitcairn">Pitcairn</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Reunion">Reunion</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russia">Russian Federation</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                        <option value="Saint LUCIA">Saint LUCIA</option>
                                        <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                        <option value="Span">Spain</option>
                                        <option value="SriLanka">Sri Lanka</option>
                                        <option value="St. Helena">St. Helena</option>
                                        <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                        <option value="Swaziland">Swaziland</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syria">Syrian Arab Republic</option>
                                        <option value="Taiwan">Taiwan, Province of China</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania">Tanzania, United Republic of</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tokelau">Tokelau</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="United States">United States</option>
                                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                        <option value="Uruguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Viet Nam</option>
                                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                        <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                        <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                        <option value="Western Sahara">Western Sahara</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Serbia">Serbia</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Address<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="address"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                {/* <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Postal Code 
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="postal_code"
                                        onChange={handleChange}
                                        // required
                                    />
                                </div> */}
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        City and Country<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="city_and_country"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full">
                                    <label
                                        htmlFor="native_lang"
                                        className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                    >
                                        Native Language<span className='text-[#EE1D52] font-bold'> *</span>
                                    </label>

                                    <select
                                        id="native_lang"
                                        name="native_lang"
                                        onChange={handleChange}
                                        required
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                    >
                                        <option value="" selected>Select...</option>
                                        <option value="Afrikaans">Afrikaans</option>
                                        <option value="Albanian">Albanian</option>
                                        <option value="Arabic">Arabic</option>
                                        <option value="Armenian">Armenian</option>
                                        <option value="Basque">Basque</option>
                                        <option value="Bengali">Bengali</option>
                                        <option value="Bulgarian">Bulgarian</option>
                                        <option value="Catalan">Catalan</option>
                                        <option value="Cambodian">Cambodian</option>
                                        <option value="Chinese (Mandarin)">Chinese (Mandarin)</option>
                                        <option value="Croatian">Croatian</option>
                                        <option value="Czech">Czech</option>
                                        <option value="Danish">Danish</option>
                                        <option value="Dutch">Dutch</option>
                                        <option value="English">English</option>
                                        <option value="Estonian">Estonian</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finnish">Finnish</option>
                                        <option value="French">French</option>
                                        <option value="Georgian">Georgian</option>
                                        <option value="German">German</option>
                                        <option value="Greek">Greek</option>
                                        <option value="Gujarati">Gujarati</option>
                                        <option value="Hebrew">Hebrew</option>
                                        <option value="Hindi">Hindi</option>
                                        <option value="Hungarian">Hungarian</option>
                                        <option value="Icelandic">Icelandic</option>
                                        <option value="Indonesian">Indonesian</option>
                                        <option value="Irish">Irish</option>
                                        <option value="Italian">Italian</option>
                                        <option value="Japanese">Japanese</option>
                                        <option value="Javanese">Javanese</option>
                                        <option value="Korean">Korean</option>
                                        <option value="Latin">Latin</option>
                                        <option value="Latvian">Latvian</option>
                                        <option value="Lithuanian">Lithuanian</option>
                                        <option value="Macedonian">Macedonian</option>
                                        <option value="Malay">Malay</option>
                                        <option value="Malayalam">Malayalam</option>
                                        <option value="Maltese">Maltese</option>
                                        <option value="Maori">Maori</option>
                                        <option value="Marathi">Marathi</option>
                                        <option value="Mongolian">Mongolian</option>
                                        <option value="Nepali">Nepali</option>
                                        <option value="Norwegian">Norwegian</option>
                                        <option value="Persian">Persian</option>
                                        <option value="Polish">Polish</option>
                                        <option value="Portuguese">Portuguese</option>
                                        <option value="Punjabi">Punjabi</option>
                                        <option value="Quechua">Quechua</option>
                                        <option value="Romanian">Romanian</option>
                                        <option value="Russian">Russian</option>
                                        <option value="Samoan">Samoan</option>
                                        <option value="Serbian">Serbian</option>
                                        <option value="Slovak">Slovak</option>
                                        <option value="Slovenian">Slovenian</option>
                                        <option value="Spanish">Spanish</option>
                                        <option value="Swahili">Swahili</option>
                                        <option value="Swedish ">Swedish </option>
                                        <option value="Tamil">Tamil</option>
                                        <option value="Tatar">Tatar</option>
                                        <option value="Telugu">Telugu</option>
                                        <option value="Thai">Thai</option>
                                        <option value="Tibetan">Tibetan</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Turkish">Turkish</option>
                                        <option value="Ukrainian">Ukrainian</option>
                                        <option value="Urdu">Urdu</option>
                                        <option value="Uzbek">Uzbek</option>
                                        <option value="Vietnamese">Vietnamese</option>
                                        <option value="Welsh">Welsh</option>
                                        <option value="Xhosa">Xhosa</option>
                                    </select>
                                </div>
                                <hr />
                                <h3 className='mt-5 mb-6 font-medium text-[20px]'>YOUR EDUCATIONAL BACKGROUND</h3>
                                <p className='text-xs mt-1'>Fill in the education that you have completed or will complete during the admission process.</p>
                                <div className='border border-[#d9d9d9] p-3'>
                                    <h3>Bachelor’s/Master’s/Doctoral degree completed outside Finland</h3>
                                    <div className="mb-6 w-full ">
                                        <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                            Year of Completion<span className='text-[#EE1D52] font-bold'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                            placeholder=""
                                            name="year_of_completion"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-6 w-full ">
                                        <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                            Degree<span className='text-[#EE1D52] font-bold'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                            placeholder=""
                                            name="degree"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-6 w-full">
                                        <label
                                            htmlFor="degree_level"
                                            className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                        >
                                            Degree Level<span className='text-[#EE1D52] font-bold'> *</span>
                                        </label>

                                        <select
                                            id="degree_level"
                                            name="degree_level"
                                            onChange={handleChange}
                                            required
                                            className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                        >
                                            <option selected></option>
                                            <option value="lower university degree">Lower University Degree (Bachelor's)</option>
                                            <option value="polytechnic/uas bachelor degree">Polytechnic/UAS Bachelor's Degree</option>
                                            <option value="higher university degree">Higher University Degree (Master's)</option>
                                            <option value="polytechnic/uas master degree">Polytechnic/UAS Master's Degree</option>
                                            <option value="Licentiate">Licentiate</option>

                                        </select>
                                    </div>
                                    <div className="mb-6 w-full ">
                                        <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                            Higher Education Institution<span className='text-[#EE1D52] font-bold'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                            placeholder=""
                                            name="higher_education_institution"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-6 w-full">
                                        <label
                                            htmlFor="country_of_completion"
                                            className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                        >
                                            Country of Completion<span className='text-[#EE1D52] font-bold'> *</span>
                                        </label>

                                        <select
                                            id="country_of_completion"
                                            name="country_of_completion"
                                            onChange={handleChange}
                                            required
                                            className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                        >
                                            <option selected></option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antartica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo">Congo, the Democratic Republic of the</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                            <option value="Croatia">Croatia (Hrvatska)</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="East Timor">East Timor</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="France Metropolitan">France, Metropolitan</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories">French Southern Territories</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                            <option value="Holy See">Holy See (Vatican City State)</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran">Iran (Islamic Republic of)</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                            <option value="Korea">Korea, Republic of</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao">Lao People's Democratic Republic</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macau">Macau</option>
                                            <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia">Micronesia, Federated States of</option>
                                            <option value="Moldova">Moldova, Republic of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria" selected>Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russia">Russian Federation</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint LUCIA">Saint LUCIA</option>
                                            <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                            <option value="Span">Spain</option>
                                            <option value="SriLanka">Sri Lanka</option>
                                            <option value="St. Helena">St. Helena</option>
                                            <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syria">Syrian Arab Republic</option>
                                            <option value="Taiwan">Taiwan, Province of China</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania">Tanzania, United Republic of</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Viet Nam</option>
                                            <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                            <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                            <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='border border-[#d9d9d9] p-3 mt-3 mb-5'>
                                    <h3>Other eligibility for higher education</h3>
                                    <div className="mb-6 w-full ">
                                        <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                            Year of Completion
                                        </label>
                                        <input
                                            type="text"
                                            className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                            placeholder=""
                                            name="year_of_completion_for_higher_edu"
                                            onChange={handleChange}
                                            // required
                                        />
                                    </div>
                                    <div className="mb-6 w-full">
                                        <label
                                            htmlFor=""
                                            className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                        >
                                            Academic background
                                        </label>

                                        <select
                                            id="name_of_degree"
                                            name="name_of_degree"
                                            onChange={handleChange}
                                            // required
                                            className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                        >
                                            <option selected></option>
                                            {/* <option value="high school">High school</option> */}
                                            <option value="college of education">College of education</option>
                                            <option value="university">University</option>
                                            <option value="masters/phd">Masters/PHD</option>
                                            <option value="others">others</option>

                                        </select>
                                    </div>
                                    <div className="mb-6 w-full">
                                        <label
                                            htmlFor=""
                                            className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                        >
                                            Profession
                                        </label>

                                        <select
                                            id="additional_uni_studies"
                                            name="additional_uni_studies"
                                            onChange={handleChange}
                                            // required
                                            className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                        >
                                            <option selected></option>
                                            <option value="student">Student</option>
                                            <option value="employed">Employed</option>
                                            <option value="unemployed">Unemployed</option>
                                            <option value="Entrepreneur/Self employed">Entrepreneur/Self employed</option>
                                            <option value="parent">parent</option>

                                        </select>
                                    </div>
                                    
                                </div>

                                <hr />
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04] mt-4">
                                    Do you have any work experience in the program you chose above?<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <div className='flex md:space-x-3 space-y-4 md:space-y-0 md:justify-between md:flex-row flex-col '>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="scholarship-1" type="radio" value="" name="scholarship" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setGraduated('Yes')} />
                                        <label htmlFor="scholarship-1" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">Yes</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="scholarship-2" type="radio" value="" name="scholarship" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setGraduated('No')} />
                                        <label htmlFor="scholarship-2" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">No</label>
                                    </div>
                                    </div>      
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04] mt-4">
                                    This is a tuition fees based program . This means you will pay tuition fees once you get accepted into the school in Finland. Are you interested ?<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <div className='flex md:space-x-3 space-y-4 md:space-y-0 md:justify-between md:flex-row flex-col '>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="tuition-1" type="radio" value="" name="tuition" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setTuition('Yes')} />
                                        <label htmlFor="tuition-1" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">Yes</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="tuition-2" type="radio" value="" name="tuition" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setTuition('No')} />
                                        <label htmlFor="tuition-2" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">No</label>
                                    </div>
                                    </div>      
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04] mt-4">
                                    If you however wish to apply for the full scholarship, you must meet the following requirements?<span className='text-[#EE1D52] font-bold'>*</span>
                                    
                                    </label>
                                    <p className="mb-2 ">Click the link and check if you have the required GPA and answer Yes or No .</p>
                                    <a href='https://afriproedu.com/eligibility-criteria' target='_blank' className=' text-[#1DB459] underline underline-offset-2'>Check country specific for GPA grades to qualify for full scholarship</a>
                                    <div className='flex md:space-x-3 space-y-4 md:space-y-0 md:justify-between md:flex-row flex-col mt-3'>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="scholarship-requirement-1" type="radio" value="" name="tuition" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setApplyScolarship('Yes')} />
                                        <label htmlFor="scholarship-requirement-1" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">Yes</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="scholarship-requirement-2" type="radio" value="" name="tuition" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setApplyScolarship('No')} />
                                        <label htmlFor="scholarship-requirement-2" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">No</label>
                                    </div>
                                    </div>      
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04] mt-4">
                                    If your answer above is NO , then no worries, you can join the Early bird to get some discount in your tuition fees. Are you still interested to apply to get reduced tuition fees ?<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <div className='flex md:space-x-3 space-y-4 md:space-y-0 md:justify-between md:flex-row flex-col '>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="early-bird-tuition-1" type="radio" value="" name="tuition" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAvailableDoc('Yes')} />
                                        <label htmlFor="early-bird-tuition-1" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">Yes</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="early-bird-tuition-2" type="radio" value="" name="tuition" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAvailableDoc('No')} />
                                        <label htmlFor="early-bird-tuition-2" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">No</label>
                                    </div>
                                    </div>      
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04] mt-4">
                                    Do you have IELTS test results (academic band) with minimum overall band score of 6.0 (each section minimum 5.5) or WAEC with English B3 score ?<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="IELTS-test-1" type="radio" value="" name="tuition" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAcceptTerms('I have IELTS band score 6.0 and Above')} />
                                        <label htmlFor="IELTS-test-1" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">I have IELTS band score 6.0 and Above</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="IELTS-test-2" type="radio" value="" name="tuition" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAcceptTerms('I have IELTS band score below 6.0')} />
                                        <label htmlFor="IELTS-test-2" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">I have IELTS band score below 6.0</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="IELTS-test-3" type="radio" value="" name="tuition" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAcceptTerms('I have B3 in English in WAEC')} />
                                        <label htmlFor="IELTS-test-3" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">I have B3 in English in WAEC</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="IELTS-test-4" type="radio" value="" name="tuition" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAcceptTerms('I have other English proficiency test results')} />
                                        <label htmlFor="IELTS-test-4" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">I have other English proficiency test results</label>
                                    </div>
                                     
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04] mt-4">
                                    The masters program you are applying for, do you have Prior studies in core areas and a first degree in the University in your home country ?<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <div className='flex md:space-x-3 space-y-4 md:space-y-0 md:justify-between md:flex-row flex-col '>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="prior-studies-1" type="radio" value="" name="tuition" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setPermission('Yes')} />
                                        <label htmlFor="prior-studies-1" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">Yes</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="prior-studies-2" type="radio" value="" name="tuition" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setPermission('No')} />
                                        <label htmlFor="prior-studies-2" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">No</label>
                                    </div>
                                    </div>      
                                </div>
                                {/* <h3 className='mt-5 mb-6 font-medium text-[20px]'>DEGREE USED AS BASIS FOR ELIGIBILITY</h3>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Name of the degree awarded to you in original language and in English (for example Bachelor's in Business Administration)<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="name_of_degree"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Have you graduated<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <div className='flex md:space-x-3 space-y-4 md:space-y-0 md:justify-between md:flex-row flex-col '>
                                        <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                            <input id="graduated-yes" type="radio" value="" name="graduated" onClick={() => setGraduated('yes')} className="w-4 h-4 accent-green-600 bg-gray-100 border-gray-300 " />
                                            <label htmlFor="graduated-yes" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">Yes</label>
                                        </div>
                                        <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                            <input id="graduated-no" type="radio" value="" name="graduated" onClick={() => setGraduated('no')} className="w-4 h-4 accent-green-600 bg-gray-100 border-gray-300 " />
                                            <label htmlFor="graduated-no" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='border border-[#d9d9d9] p-3 mb-5'>
                                    <div className="mb-6 w-full ">
                                        <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                            Expected date of graduation<span className='text-[#EE1D52] font-bold'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                            placeholder=""
                                            name="expected_date_of_graduation"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-6 w-full ">
                                        <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                            Please explain briefly what more is required for you to graduate (the amount of missing credits/courses/thesis)<span className='text-[#EE1D52] font-bold'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                            placeholder=""
                                            name="what_required_for_you_to_graduate"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Name and postal address of the institution<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="name_and_postal_address_of_institution"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full">
                                    <label
                                        htmlFor="country_of_institution"
                                        className="block text-[#0A2E04] text-[14px] font-semibold mb-2 "
                                    >
                                        Country where the Institution is Located<span className='text-[#EE1D52] font-bold'> *</span>
                                    </label>

                                    <select
                                        id="country_of_institution"
                                        name="country_of_institution"
                                        onChange={handleChange}
                                        required
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm   block w-full p-2.5 "
                                    >
                                        <option value="Afghanistan">Afghanistan</option>
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Anguilla">Anguilla</option>
                                        <option value="Antartica">Antarctica</option>
                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Aruba">Aruba</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bermuda">Bermuda</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Bouvet Island">Bouvet Island</option>
                                        <option value="Brazil">Brazil</option>
                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                                        <option value="Bulgaria">Bulgaria</option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Central African Republic">Central African Republic</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China</option>
                                        <option value="Christmas Island">Christmas Island</option>
                                        <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo">Congo</option>
                                        <option value="Congo">Congo, the Democratic Republic of the</option>
                                        <option value="Cook Islands">Cook Islands</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                        <option value="Croatia">Croatia (Hrvatska)</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="East Timor">East Timor</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                        <option value="Faroe Islands">Faroe Islands</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="France Metropolitan">France, Metropolitan</option>
                                        <option value="French Guiana">French Guiana</option>
                                        <option value="French Polynesia">French Polynesia</option>
                                        <option value="French Southern Territories">French Southern Territories</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Gibraltar">Gibraltar</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Greenland">Greenland</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guadeloupe">Guadeloupe</option>
                                        <option value="Guam">Guam</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                        <option value="Holy See">Holy See (Vatican City State)</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="India">India</option>
                                        <option value="Indonesia">Indonesia</option>
                                        <option value="Iran">Iran (Islamic Republic of)</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                        <option value="Korea">Korea, Republic of</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Lao">Lao People's Democratic Republic</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon" >Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Macau">Macau</option>
                                        <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Martinique">Martinique</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mayotte">Mayotte</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Micronesia">Micronesia, Federated States of</option>
                                        <option value="Moldova">Moldova, Republic of</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montserrat">Montserrat</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>
                                        <option value="Namibia">Namibia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                        <option value="New Caledonia">New Caledonia</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria" selected>Nigeria</option>
                                        <option value="Niue">Niue</option>
                                        <option value="Norfolk Island">Norfolk Island</option>
                                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Philippines">Philippines</option>
                                        <option value="Pitcairn">Pitcairn</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Reunion">Reunion</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russia">Russian Federation</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                        <option value="Saint LUCIA">Saint LUCIA</option>
                                        <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                        <option value="Span">Spain</option>
                                        <option value="SriLanka">Sri Lanka</option>
                                        <option value="St. Helena">St. Helena</option>
                                        <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                        <option value="Swaziland">Swaziland</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syria">Syrian Arab Republic</option>
                                        <option value="Taiwan">Taiwan, Province of China</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania">Tanzania, United Republic of</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tokelau">Tokelau</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="United States">United States</option>
                                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                        <option value="Uruguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Viet Nam</option>
                                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                        <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                        <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                        <option value="Western Sahara">Western Sahara</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Serbia">Serbia</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Email Address and Website of the Institution<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="email"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="email_and_website_of_institution"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Major and Minor Subject(s) in your Degree (if applicable)<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="major_and_minor_subject_in_your_degree"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Your Thesis / final report title or subject(s), grade and number of study credits (ECTS or equivalent)<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="thesis_and_final_title"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Grading Scale used at the Institution (ECTS or equivalent)<span className='text-[#EE1D52] font-bold'>*</span>
                                        <p className='text-xs mt-1'>Please describe the grading scale used at the institution at the time of study. The description should include the grades in relation to each other and clearly depict the highest and lowest grades, as well as which grades are passing grades and which are fail.</p>
                                    </label>

                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="grading_scale_of_institution"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        GPA (grade point average) of your Degree<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="gpa_of_your_degree"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Language(s) of Instruction in University Education<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="lang_of_instruction_in_uni"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Additional university studies which you consider relevant in the admission process (e.g. exchange studies or open university studies inside or outside Finland)<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                                        placeholder=""
                                        name="additional_uni_studies"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <hr />
                                <h3 className='mt-5 mb-6 font-medium text-[20px]'>TUITION FEES</h3>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Are you required to pay tuition fees? <span className='text-[#EE1D52] font-bold'>*</span>
                                        <p className='text-xs mt-1'>The fee will not be charged from persons who are citizens of the European Union (EU), European Economic Area (EEA) or Switzerland, or are residing in Finland on a permanent basis and have a continuous residence permit in Finland (P type or A type issued for purposes other than studies) or have an EU Blue Card.</p>
                                        <p className='text-xs mt-1'>Please note that it is the applicants responsibility to provide the University with a proof of being exempted from paying tuition fees (for example a residence permit card).</p>
                                    </label>
                                    <div className='flex md:space-x-3 space-y-4 md:space-y-0 md:justify-between md:flex-row flex-col '>
                                        <div className="flex items-center w-full px-4 border border-gray-200 rounded ">
                                            <input id="tuition-fee-1" type="radio" value="" name="tuition-fee" onClick={() => setTuition('i am not required to pay')} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " />
                                            <label htmlFor="tuition-fee-1" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">I am not required to pay tuition fees</label>
                                        </div>
                                        <div className="flex items-center w-full px-4 border border-gray-200 rounded">
                                            <input id="tuition-fee-1" type="radio" value="" name="tuition-fee" onClick={() => setTuition('i am required to pay')} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300" />
                                            <label htmlFor="tuition-fee-1" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">I am required to pay tuition fees</label>
                                        </div>
                                    </div>

                                </div>


                                <div className={"mb-6 w-full " + (tuition == 'i am not required to pay' ? '' : ' hidden')}>
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        I have the following document <span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="document-1" type="radio" value="" name="document" onClick={() => setAvailableDoc('Passport or identity card to indicate the citizenship of EU/EEA/Switzerland')} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " />
                                        <label htmlFor="document-1" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">Passport or identity card to indicate the citizenship of EU/EEA/Switzerland</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="document-2" type="radio" value="" name="document" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAvailableDoc('EU Blue Card in Finland')} />
                                        <label htmlFor="document-2" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">EU Blue Card in Finland</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="document-3" type="radio" value="" name="document" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAvailableDoc('EU Family Member\'s Residence Card in Finland')} />
                                        <label htmlFor="document-3" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">EU Family Member's Residence Card in Finland</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="document-4" type="radio" value="" name="document" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAvailableDoc('Continuous residence permit card in Finland, Type A permit (issued for purposes other than studies)')} />
                                        <label htmlFor="document-4" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">Continuous residence permit card in Finland, Type A permit (issued for purposes other than studies)</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="document-5" type="radio" value="" name="document" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAvailableDoc('EU residence permit for third-country citizens with long-term residence permit card in Finland (Type P-EU)')} />
                                        <label htmlFor="document-5" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">EU residence permit for third-country citizens with long-term residence permit card in Finland (Type P-EU)</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="document-6" type="radio" value="" name="document" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAvailableDoc('Permanent residence permit card in Finland, Type P permit')} />
                                        <label htmlFor="document-6" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">Permanent residence permit card in Finland, Type P permit</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="document-7" type="radio" value="" name="document" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAvailableDoc('Brexit residence permit card, Type SEU-sopimuksen 50 artikla = Right of residence under the withdrawal agreement, or P SEU-sopimuksen 50 artikla = Right of permanent residence under the withdrawal agreement')} />
                                        <label htmlFor="document-7" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">Brexit residence permit card, Type SEU-sopimuksen 50 artikla = Right of residence under the withdrawal agreement, or P SEU-sopimuksen 50 artikla = Right of permanent residence under the withdrawal agreement</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="document-8" type="radio" value="" name="document" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setAvailableDoc('Proof from the Finnish Immigration Services that I have registered, or applied to register, my EU right to residence in Finland (UK-citizens)')} />
                                        <label htmlFor="document-8" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">Proof from the Finnish Immigration Services that I have registered, or applied to register, my EU right to residence in Finland (UK-citizens)</label>
                                    </div>
                                </div>


                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        University of Vaasa offers scholarships for students who are required to pay tuition fees. Do you wish to apply for a scholarship?<span className='text-[#EE1D52] font-bold'>*</span>
                                        <p className='text-xs mt-1'>The scholarships will be awarded together with the admission. There is no separate application form for scholarships.</p>
                                    </label>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="scholarship-1" type="radio" value="" name="scholarship" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setApplyScolarship('I am exempted from paying tuition fees (I can\'t apply for a scholarship)')} />
                                        <label htmlFor="scholarship-1" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">I am exempted from paying tuition fees (I can't apply for a scholarship)</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="scholarship-2" type="radio" value="" name="scholarship" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setApplyScolarship('I have to pay a tuition fee and I wish to apply for a scholarship')} />
                                        <label htmlFor="scholarship-2" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">I have to pay a tuition fee and I wish to apply for a scholarship</label>
                                    </div>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="scholarship-3" type="radio" value="" name="scholarship" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " onClick={() => setApplyScolarship('I have to pay a tuition fee but I don\'t wish to apply for a scholarship')} />
                                        <label htmlFor="scholarship-3" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">I have to pay a tuition fee but I don't wish to apply for a scholarship</label>
                                    </div>
                                </div>

                                <hr />
                                <h3 className='mt-5 mb-6 font-medium text-[20px]'>ATTACHMENTS</h3>
                                <h3 className='mt-5 mb-2 font-medium text-[20px]'>IMPORTANT INFORMATION ABOUT SUBMITTING YOUR DOCUMENTS</h3>
                                <p className='text-xs mb-6'>You must submit all required attachments by the time of applying despite of the date that has been announced next to the attachment request. Please make sure that you submit your application only after you have checked that all required documents are attached to the application. In case of missing attachments, the application will be rejected.</p>
                                <div className="mb-6 w-full ">
                                    <label
                                        className="block mb-2 text-sm font-semibold text-[#0A2E04]"
                                        htmlFor="degree_cert">
                                        Officially Certified copy of Degree Certificate(s) if Graduated<span className='text-[#EE1D52] font-bold'>*</span>

                                    </label>
                                    <p className='text-xs mt-1 mb-2'>The required educational documents (degree certificate, transcript of records) must be submitted in the original language of your previous institution. If the original language is not Finnish, Swedish or English, please submit also translations of the documents into one of these languages. All translations of the required documents are to be made by an official translator and must bear the signature and stamp of the translator.
                                    </p>
                                    <p className='text-xs mt-1 mb-2'>  You need to submit officially certified copies of your degree certificate and transcript of records. This is with a stamp, date and signature for example by the institution which issued the original document or a notary public. Copies certified as correct by two individuals not representing an official body are not accepted.
                                    </p>
                                    <p className='text-xs mt-1 mb-2'>   If you have not graduated by the time you apply, the degree needs to be completed by 11 July 2024, and evidence of the completed degree needs to be presented to the University of Vaasa by 11 July 2024 at 15:00 (UTC+3).
                                    </p>
                                    <p className='text-xs mt-1 mb-2'>   If you have already graduated, you must submit the attachment at the same time when submitting the application despite of the date that has been announced next to the attachment request. In case of missing attachment, the application will be rejected.</p>
                                    <input accept="image/x-png,image/gif,image/jpeg" className="block w-full text-lg  border border-[#D9D9D9] cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#48B774] file:text-white ease-linear transition-all duration-150 "
                                        id="degree_cert"
                                        onChange={degCert}
                                        name='degree_cert'
                                        type="file" />
                                    <span className='mt-1 text-xs'>Maximum file size is 1.0 MB</span>
                                </div>
                                <div className="mb-6 w-full ">
                                    <label
                                        className="block mb-2 text-sm font-semibold text-[#0A2E04]"
                                        htmlFor="copy_of_transcript">
                                        Officially certified copy of Transcript(s) of records (an explanation of the grading system used is mandatory if it is not clearly stated in the Transcript)<span className='text-[#EE1D52] font-bold'>*</span>

                                    </label>
                                    <p className='text-xs mt-1 mb-2'>The required educational documents (degree certificate, transcript of records) must be submitted in the original language of your previous institution. If the original language is not Finnish, Swedish or English, please submit also translations of the documents into one of these languages. All translations of the required documents are to be made by an official translator and must bear the signature and stamp of the translator.
                                    </p>
                                    <p className='text-xs mt-1 mb-2'>   If you have not graduated by the time you apply, you need to submit an officially certified copy of your latest transcript of records. The degree needs to be completed by 11 July 2024, and evidence of the completed degree needs to be presented to the University of Vaasa by 11 July 2024 at 15:00 (UTC+3).
                                    </p>
                                    <p className='text-xs mt-1 mb-2'>    You must submit the attachment at the same time when submitting the application despite of the date that has been announced next to the attachment request. In case of missing attachment, the application will be rejected.</p>
                                    <input accept="image/x-png,image/gif,image/jpeg" className="block w-full text-lg  border border-[#D9D9D9] cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#48B774] file:text-white ease-linear transition-all duration-150 "
                                        id="copy_of_transcript"
                                        onChange={transcripts}
                                        name='copy_of_transcript'
                                        type="file" />
                                    <span className='mt-1 text-xs'>Maximum file size is 1.0 MB</span>
                                </div>
                                <div className="mb-6 w-full ">
                                    <label
                                        className="block mb-2 text-sm font-semibold text-[#0A2E04]"
                                        htmlFor="proof_of_lang">
                                        Proof of Language Proficiency<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <p className='text-xs mt-1 mb-2'>You must submit the attachment at the same time when submitting the application despite of the date that has been announced next to the attachment request. In case of missing attachment, the application will be rejected.
                                    </p>

                                    <input accept="image/x-png,image/gif,image/jpeg" className="block w-full text-lg  border border-[#D9D9D9] cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#48B774] file:text-white ease-linear transition-all duration-150 "
                                        id="proof_of_lang"
                                        onChange={langProficiency}
                                        name='proof_of_lang'
                                        type="file" />
                                    <span className='mt-1 text-xs'>Maximum file size is 1.0 MB</span>
                                </div>
                                <div className="mb-6 w-full ">
                                    <label
                                        className="block mb-2 text-sm font-semibold text-[#0A2E04]"
                                        htmlFor="copy_of_passport">
                                        Copy of passport's information page / official ID card<span className='text-[#EE1D52] font-bold'>*</span>
                                    </label>
                                    <p className='text-xs mt-1 mb-2'>You must submit the attachment at the same time when submitting the application despite of the date that has been announced next to the attachment request. In case of missing attachment, the application will be rejected.
                                    </p>

                                    <input accept="image/x-png,image/gif,image/jpeg" className="block w-full text-lg  border border-[#D9D9D9] cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#48B774] file:text-white ease-linear transition-all duration-150 "
                                        id="copy_of_passport"
                                        onChange={passpt}
                                        name='copy_of_passport'
                                        type="file" />
                                    <span className='mt-1 text-xs'>Maximum file size is 1.0 MB</span>
                                </div>
                                <div className="mb-6 w-full ">
                                    <label
                                        className="block mb-2 text-sm font-semibold text-[#0A2E04]"
                                        htmlFor="copy_of_permit_card">
                                        Document to prove your exemption from tuition fees (e.g. copy of your residence permit card to Finland)
                                    </label>
                                    <p className='text-xs mt-1 mb-2'>Students are required to pay tuition fees unless they meet the set criteria and provide documentation of it. It is the student's responsibility to provide the University with the necessary documentation. Please leave this field empty if you do not have any documents that would grant you exemption from tuition fees, e.g. a residence permit card to Finland.
                                    </p>

                                    <input accept="image/x-png,image/gif,image/jpeg" onChange={tuiFee} className="block w-full text-lg  border border-[#D9D9D9] cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#48B774] file:text-white ease-linear transition-all duration-150 "
                                        id="copy_of_permit_card"
                                        name='copy_of_permit_card'
                                        type="file" />
                                    <span className='mt-1 text-xs'>Maximum file size is 1.0 MB</span>
                                </div>
                                <div className="mb-6 w-full ">
                                    <label
                                        className="block mb-2 text-sm font-semibold text-[#0A2E04]"
                                        htmlFor="other_document">
                                        Other documents
                                    </label>
                                    <p className='text-xs mt-1 mb-2'>You may also submit other documents supporting your application. These may include, for example, diplomas of other studies completed in higher education institution level.
                                    </p>

                                    <input accept="image/x-png,image/gif,image/jpeg" onChange={otherDoc} className="block w-full text-lg  border border-[#D9D9D9] cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#48B774] file:text-white ease-linear transition-all duration-150 "
                                        id="other_document"
                                        name='other_document'
                                        type="file" />
                                    <span className='mt-1 text-xs'>Maximum file size is 1.0 MB</span>
                                </div>
                                <hr />
                                <h3 className='mt-5 mb-6 font-medium text-[20px]'>PERMISSIONS</h3>
                                <p className='text-xs mt-1 mb-2'>Please check all the information you have given in the application before you submit the application.
                                </p>

                                <p className='text-xs mt-1 mb-2'>The information given on the application will be used for student admission and selection purposes. In addition to this information, data stored on the National Data Register for Basic Education, General Upper Secondary Education and Vocational Education, data received from the Finnish Matriculation Examination Board and data regarding degrees and grades received from higher education institutes as well as personal data received from the Finnish Population Information System will be used in student admission and selection. The processing of personal data is based on the Act on the National Registers of Education Records, Qualifications and Degrees (884/2017).</p>
                                <p className='text-xs mt-1 mb-2'>After the student admission and selection process your data is transferred to the higher education institute to which you have received the right to study. Your data can be transferred to other officials as well as for research purposes. Data in the data registry are kept for five years, starting from the decision of student admission. After this, the data will be transferred to permanent storage, according to the decision made by the National Archives of Finland. Data concerning receiving the right to study are kept forever.</p>
                                <p className='text-xs mt-1 mb-2'>You have the right to demand rectification of false or inaccurate data as well as the right to demand the limitation of processing the data. Additionally, you have the right to file a complaint to a data protection supervisor.</p>
                                <p className='text-xs mt-1 mb-2'>You will receive a confirmation of your application to your email.</p>

                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        Consent for electronic communication<span className='text-[#EE1D52] font-bold'>*</span>
                                        <p className='text-xs mt-1'>By filling in this electronic application form you also give your consent that communication regarding student admissions can be carried out only by email. If you do not agree to these terms, please contact the admissions services.</p>
                                    </label>
                                    <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                        <input id="agreement" onClick={() => setAcceptTerms('I agree on the terms of online/electronic service')} type="radio" value="" name="agreement" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " />
                                        <label htmlFor="agreement" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">I agree on the terms of online/electronic service</label>
                                    </div>

                                </div>
                                <div className="mb-6 w-full ">
                                    <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                                        My contact information can be given to third parties for the purpose of direct education marketing<span className='text-[#EE1D52] font-bold'>*</span>
                                        <p className='text-xs mt-1'>By granting permission to direct educational marketing you can receive advertisements from education institutions.</p>
                                    </label>
                                    <div className='flex md:space-x-3 space-y-4 md:space-y-0 md:justify-between md:flex-row flex-col '>
                                        <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                            <input id="permission-yes" onClick={() => setPermission('yes')} type="radio" value="" name="permission" className="w-4 h-4 accent-green-600 bg-gray-100 border-gray-300 " />
                                            <label htmlFor="permission-yes" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">Yes</label>
                                        </div>
                                        <div className="flex items-center w-full px-4 mb-1 border border-gray-200 rounded ">
                                            <input id="permission-no" type="radio" onClick={() => setPermission('no')} value="" name="permission" className="w-4 h-4 accent-green-600 bg-gray-100 border-gray-300 " />
                                            <label htmlFor="permission-no" className="w-full py-4 ml-3 text-sm font-medium text-gray-900  ">No</label>
                                        </div>
                                    </div>

                                </div> */}
                                <div className='flex justify-start mt-5'>
                                    <button
                                        type="submit"
                                        className=" text-white bg-[#1DB459] hover:bg-[#05401C] focus:ring-4 font-medium text-[16px] px-6 py-2.5 mr-2 mb-2"
                                    >
                                        Submit Application
                                    </button>

                                    <div className='flex justify-center'>
                                        <MutatingDots
                                            height="70"
                                            width="100"
                                            color="#4fa94d"
                                            secondaryColor='#4fa94d'
                                            radius='9.5'
                                            ariaLabel="mutating-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={loader}
                                        />
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>






                <Modal open={open} onClose={() => null} center>
                    <div className='md:max-w-md  body-font font-poppins'>
                        <div className="flex flex-wrap mt-4">
                            <h2 className='text-center text-[#1DB459] font-bold md:text-[26px] text-[22px]'>Accept Terms and Conditions</h2>
                            <p className='text-center text-[#838383] md:text-[14px] text-[12px]'>Before you proceed , please take a moment to review and accept our Terms and Conditions.</p>
                            <hr className="h-px mb-8 mt-[10px] bg-[#F0F4F9] border-0" />
                            <h3 className='md:text-[16px] text-[14px] text-black font-light '><span className='font-bold'>Payment:</span>  By proceeding, you agree to pay $100 for the Registration fee.</h3>
                            <h3 className='md:text-[16px] text-[14px] text-black font-light mt-4'><span className='font-bold'>Refunds:</span>  The $100 being paid will be a one time  non refundable fee</h3>
                            <h3 className='md:text-[16px] text-[14px] text-black font-light mt-4'><span className='font-bold'>Data privacy:</span>  Please be aware that any information provided will be used  to verify your identity for future referencing and also for your Visa and Residence Permit processing.</h3>
                            <hr className="h-px mb-8 mt-[20px] bg-[#F0F4F9] border-0" />
                            {/* <div className="flex justify-center items-center mb-3 mt-4">
                                            <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-green-600 rounded" required />
                                            <label htmlFor="default-checkbox" className="ml-2 md:text-[14px] text-[12px] font-light text-black "> I accept the Terms and Conditions</label>
                                        </div> */}
                            <div className='flex mx-auto space-x-4 mt-6'>
                                <button
                                    type="button"
                                    onClick={onCloseModal}
                                    className=" text-white bg-[#1DB459] rounded-[5px] font-medium text-sm md:px-5 px-3 md:py-3 py-2.5 mr-2 mb-2"
                                >
                                    Proceed
                                </button>

                            </div>

                        </div>
                    </div>

                </Modal>


                <Modal open={openPayment} onClose={onClosePaymentModal} center>
                    <div className='md:max-w-md  body-font font-poppins'>
                        <div className="flex flex-wrap mt-4">
                            <h2 className='text-center text-[#1DB459] font-bold md:text-[26px] text-[22px]'>Accept Terms and Conditions</h2>
                            <p className='text-center text-[#838383] md:text-[14px] text-[12px]'>Before you proceed , please take a moment to review and accept our Terms and Conditions.</p>
                            <hr className="h-px mb-8 mt-[10px] bg-[#F0F4F9] border-0" />
                            <h3 className='md:text-[16px] text-[14px] text-black font-light '><span className='font-bold'>Payment:</span>  By proceeding, you agree to pay $100 for the Registration fee.</h3>
                            <h3 className='md:text-[16px] text-[14px] text-black font-light mt-4'><span className='font-bold'>Refunds:</span>  The $100 being paid will be a one time  non refundable fee</h3>
                            <h3 className='md:text-[16px] text-[14px] text-black font-light mt-4'><span className='font-bold'>Data privacy:</span>  Please be aware that any information provided will be used  to verify your identity for future referencing and also for your Visa and Residence Permit processing.</h3>
                            <hr className="h-px mb-8 mt-[20px] bg-[#F0F4F9] border-0" />
                            {/* <div className="flex justify-center items-center mb-3 mt-4">
                                            <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-green-600 rounded" required />
                                            <label htmlFor="default-checkbox" className="ml-2 md:text-[14px] text-[12px] font-light text-black "> I accept the Terms and Conditions</label>
                                        </div> */}
                            <div className='flex mx-auto space-x-4 mt-6'>
                                <button
                                    type="button"
                                    onClick={makeDollarPayment}
                                    className=" text-white bg-[#1DB459] rounded-[5px] font-medium text-sm md:px-5 px-3 md:py-3 py-2.5 mr-2 mb-2"
                                >
                                    Pay in Dollars
                                </button>
                                <button
                                    type="button"
                                    className=" text-[#FCF7F7] bg-[#1C8B48] rounded-[5px]  font-medium text-sm md:px-5 px-8 md:py-3 py-2.5 mr-2 mb-2"
                                    onClick={makeNairaPayment}
                                >
                                    Pay in Naira
                                </button>
                            </div>
                           
                        </div>
                    </div>

                </Modal>

            </section>
            {/* <!-- Start block --> */}

            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    )
}

export default MastersApplication