import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Refferal() {

  const userLoginData = useSelector((state:any) => state?.data?.login?.value);

  return (
    <div>
        <div className='flex flex-col justify-center items-center mt-16 pb-8'>
              <div className='flex lg:flex-row flex-col justify-between lg:gap-6 gap-3'>
                <div className='lg:w-[528px] bg-white rounded-[20px] px-[26px] py-[29px] relative'>
                  <h3 className='text-[16px] font-bold'>Invite a student via Email</h3>
                  <div>
                    <form>
                      <div className="relative w-full mb-4 mt-4">
                        <input
                          type="email"
                          className=" bg-[#E3E3E3]/[0.25]  text-[#333333] text-[12px] rounded-[5px] block w-full p-3 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                          placeholder="Enter Email Address"
                          name="email"
                          required
                        />
                      </div>
                      <div className='flex justify-end'>
                        <button className='text-white bg-[#1DB459] text-[14px] rounded-[30px] px-[41px] py-[10px]'>Invite</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='lg:w-[528px] bg-white rounded-[20px] px-[26px] py-[29px] relative'>
                  <h3 className='text-[16px] font-bold'>Share through social media</h3>
                  <div className='text-[12px] bg-[#1DB459]/[0.05] text-[#098A3C] rounded-[5px] text-center p-3 mt-4'>
                  <h3>(Ref_id : {`www.afriproedu.com/sign-up?ref=${userLoginData?.data?.user_data?.referral_id}`} <CopyToClipboard text={`www.afriproedu.com/sign-up?ref=${userLoginData?.data?.user_data?.referral_id}`}
              onCopy={() => toast.success('Copied to Clipboard')}>
              <button>📋</button>
            </CopyToClipboard>
              &nbsp;) </h3>
                  </div>
                </div>
              </div>
              <div className='flex lg:flex-row flex-col justify-between lg:gap-6 gap-3 mt-[20px] mx-6'>
                <div className='lg:w-[528px] bg-white rounded-[20px] px-[26px] py-[29px] relative'>
                  <h3 className='text-[16px] font-bold'>Your Rewards</h3>
                  <div className='mt-3'>
                    <h1 className='text-[32px] font-medium text-[#1DB459]'>$0</h1>
                    <h5 className='text-[12px] text-[#838383]'>Your pending Rewards </h5>
                  </div>
                  <hr className='mt-4 h-[0.5px]' />
                  <div className='mt-4'>
                    <h1 className='text-[32px] font-medium '>$0</h1>
                    <h5 className='text-[12px] text-[#838383]'>Your total amount you have earned by reffering people to AfriProEdu</h5>

                  </div>

                  <div className='text-[12px] bg-[#1DB459]/[0.05] text-[#098A3C] rounded-[5px] text-center p-3 mt-4'>
                    Please add a Payment method to receive your rewards
                  </div>
                  <div className='flex justify-start mt-3'>
                    <button className='text-white bg-[#1DB459] rounded-[30px] px-[41px] py-[10px] text-[14px]'>Claim Reward</button>
                  </div>
                </div>
                {/* <div className='lg:w-[528px] bg-white rounded-[20px] px-[26px] py-[29px] relative'>
                  <h3 className='text-[16px] font-bold'>Agency Terms of Service</h3>
                  <div className='overflow-x-auto mt-4'>
                    <div className='border border-[#D9D9D9] rounded-[10px] h-[249px] overflow-auto px-2 py-4'>
                      <p className='text-[12px]'>Lorem ipsum dolor sit amet consectetur. Quam feugiat turpis convallis sed in natoque. Vulputate tempor faucibus pharetra mattis mauris congue turpis. In enim ut risus vitae. Massa diam congue vitae viverra gravida posuere eget. Sed amet sed viverra id. Vulputate at tristique condimentum nec at neque tellus arcu odio. Consectetur pellentesque fusce nullam vitae nisl non condimentum lorem</p>
                      <ul className=' list-outside list-decimal space-y-1 text-[12px] pl-3 mt-4'>
                        <li>Lorem ipsum dolor sit amet consectetur. Quam feugiat turpis convallis sed in natoque. </li>
                     <li>Lorem ipsum dolor sit amet consectetur. Quam feugiat turpis convallis sed in natoque.</li>
                     <li>Lorem ipsum dolor sit amet consectetur. Quam feugiat turpis convallis sed in natoque.</li>
                     <li>Lorem ipsum dolor sit amet consectetur. Quam feugiat turpis convallis sed in natoque.</li>
                      </ul>
                    </div>
                  </div>
                  <div className='flex justify-start mt-3'>
                    <button className='text-[#1DB459] border border-[#E2E8F0] rounded-[30px] px-[41px] py-[10px] text-[12px]'>Terms of Service </button>
                  </div>
                </div> */}
              </div>
            </div>
            <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </div>

  )
}

export default Refferal