import React from 'react'
import { Link } from "react-router-dom";
import AdminSidebar from '../../Sidebar/AdminSidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import { SvgElement, icontypesEnum } from '../../assets/svgElement'
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { NavLink, useNavigate } from "react-router-dom";

function Payments() {
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const [lists, setList] = React.useState<any>([]);
    React.useEffect(() => {
        AdminApis.getPaymentDetails('').then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setList(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })
    }, []);

   



    const paginator = React.useCallback(
        (value: any) => {
        //   setLoader(true);
          let value2 = '';
          if (value !== null) {
            value2 = value;
          } else {
            value2 = ''
          }
         
          AdminApis.getPaymentDetails(value2).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setList(response?.data?.data)
              }
            }
          ).catch(function (error) {
            console.log(error.response.data);
          })
    
        }, [lists]);


        const userDetailsPage = React.useCallback(
            (value: any) => {
                console?.log('d')
                navigate(`/user-details/${value}`);
            },[])


            console?.log(lists?.data)



    return (
        <>
            <AdminSidebar />
            <div className="relative md:ml-64 bg-blueGray-100">

                <UserNavbar />


                <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#c4c4c4] mx-6 lg:mt-16 lg:mx-16">
                    <div className="flex justify-between px-8 py-6">
                        <h3>Users Payments</h3>
                        <div>Filter</div>
                    </div>
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 font-light">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    User Id
                                </th>
                                <th scope="col" className="px-6 py-3">
                                   Account Balance
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount Spent
                                </th>

                                <th scope="col" className="px-6 py-3">
                                    last Transaction Date
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {
                                lists?.data?.map(
                                    (datas: any, index: any) => (
                                        <tr className="bg-white  ">

                                            {/* <td>
                                               {index} 
                                            </td> */}
                                            <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                <div className="flex space-x-3">
                                                    {/* <div>
                                                        <img src="/images/Dashboard/Userpic1.svg" alt="hero" />
                                                    </div> */}
                                                    <div>
                                                        <h3 className=" text-sm">{datas?.name} {datas?.user_id}</h3>
                                                       

                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4">
                                                {datas?.account_balance}
                                            </td>
                                            <td className="px-6 py-4">
                                                {datas?.amount_spent}
                                            </td>
                                            <td className="px-6 py-4">
                                                {datas?.updated_at}
                                            </td>
                                            {/* <td className="px-6 py-4">
                                                <a href="#" className=" font-normal text-white bg-[#1DB459] px-3 py-1 rounded-sm">{datas?.status}</a>
                                            </td>
                                            <td className="px-6 py-4">
                                                {datas?.programs_type}
                                            </td>
                                            <td className="px-6 py-4">
                                                <div className='flex space-x-3'>
                                                    <span style={{cursor:'pointer'}} onClick={()=> userDetailsPage(datas?.id)}>
                                                    <SvgElement type={icontypesEnum.EYE} />
                                                    </span>
                                                    
                                                    <div className='mt-1'>
                                                        <SvgElement type={icontypesEnum.DELETE} />
                                                    </div>

                                                </div>
                                            </td> */}
                                        </tr>
                                    )
                                )
                            }

                        </tbody>
                    </table>

                    <div className='m-4'>
                        {
                            lists?.links?.filter(((item:any, idx:any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={()=>paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) :(datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) :datas?.label )} disabled={datas?.active} className={'mx-1 py-1 px-2 '+ (datas?.active == false ?'bg-gray-300 text-black ' :'bg-green-500 text-white')}>
                                            {datas?.label =='&laquo; Previous' ? '< Previous':(datas?.label === 'Next &raquo;') ? 'Next  >' :datas?.label}
                                    </button>
                                )
                            )
                        }
                        

                        {/* {
                           
                            userLists?.links?.map(
                                (datas: any, index: any) => (
                                    datas?.label === 'Next &raquo;' ?
                                    <button disabled={!datas?.active} key={index} className='mx-1 py-1 px-2 bg-green-500 text-white '>
                                        <a href={datas?.url}>
                                            {datas?.label =='&laquo; Previous' ? '< Previous':(datas?.label === 'Next &raquo;') ? 'Next  >' :datas?.label}
                                        </a>
                                    </button>

                                    :
                                    ''
                                )
                            )
                           
                        } */}



                    </div>
                </div>
            </div>



        </>
    )
}

export default Payments