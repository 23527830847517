import React,{useState} from 'react'
// @ts-ignore
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Sidebar from '../Sidebar/Sidebar'
import UserNavbar from "../Navbars/UserNavbar"
import ProfileNav from './ProfileNav'

function ExamScore() {
    const [startDate, setStartDate] = useState(new Date());
   
  return (
    <>
    <Sidebar/>
    <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar title="Profile"/>
        <div className='md:py-16'>
            <ProfileNav/>
            

            <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl  pl-3 pr-4 mt-5">
            <div className="w-full lg:w-10/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full  ">
            <div className="flex-auto px-4  py-10 pt-0">
              <form>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-6">
                      <label
                        htmlFor="gender"
                        className="block text-[#404D61] text-sm  font-medium mb-2 "
                      >
                        Exam Types
                      </label>
                      <select
                        id="gender"
                        name="gender"
                        className=" border border-[#D9D9D9] text-[#333333] text-sm rounded-lg  block w-full p-2.5 "
                      >
                        <option selected></option>
                        {/* <option value="female">High school</option> */}
                        <option value="male">Undergraduate</option>
                      </select>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-6">
                  <label
                        className="block text-[#404D61] text-sm font-medium mb-2"
                        htmlFor="grid-password"
                      >
                        Exam Date*
                      </label>
                  <DatePicker
                  className="  border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150" 
                   selected={startDate} onChange={(date:any) => setStartDate(date)} 
                   
                   placeholderText="mm/dd/yyyy" />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-6">
                      <label
                        className="block text-[#404D61] text-sm font-medium mb-2"
                        htmlFor="grid-password"
                      >
                        Reading*
                      </label>
                      <input
                        type="text"
                        className="  border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block text-[#404D61] text-sm  font-medium mb-2"
                        htmlFor="grid-password"
                      >
                        Listening*
                      </label>
                      <input
                        type="text"
                        className="border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-6">
                      <label
                        className="block text-[#404D61] text-sm font-medium mb-2"
                        htmlFor="grid-password"
                      >
                        Speaking*
                      </label>
                      <input
                        type="text"
                        className="  border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-6">
                      <label
                        className="block text-[#404D61] text-sm font-medium mb-2"
                        htmlFor="grid-password"
                      >
                        Writing*
                      </label>
                      <input
                        type="text"
                        className="  border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-3 placeholder-[#5A6474] ease-linear transition-all duration-150"
                        placeholder=""
                      />
                    </div>
                  </div>
                  
                  
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-6">
                      <label
                        className="block text-[#404D61] text-sm font-medium mb-2"
                        htmlFor="grid-password"
                      >
                        Upload Result
                      </label>
                      
                      <input className="block w-full text-lg text-gray-900 border border-[#D9D9D9] rounded-lg cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#D9D9D9] ease-linear transition-all duration-150" id="large_size" type="file"/>
                    </div>
                  </div>
                </div>

                <div className='px-4 mt-5'>
                <button
                    type="submit"
                    className=" text-white bg-[#1DB459] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                  >
                    Save
                  </button>
                </div>
                
              </form>
            </div>
          </div>
            </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ExamScore