import React from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar';
import UserNavbar from '../../Navbars/UserNavbar'
import { SvgElement, icontypesEnum } from '../../assets/svgElement'
import { Link, NavLink } from 'react-router-dom';
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import configs from "../../../configs";

function BlogList() {
    const [groupLists, setGroupList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');


    React.useEffect(() => {
        const query: any = {
            search: searchText,
        };
        AdminApis.getBlogs('').then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setGroupList(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })
    }, []);



    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }
            const query: any = {
                search: searchText,
            };

            AdminApis.getBlogs(value2).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setGroupList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [groupLists, searchText]);




    return (
        <>
            <AdminSidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar />

                <div className="lg:pt-10 lg:px-10 px-6 ">
                    <div className='flex justify-between'>
                        <h3 className=' font-medium text-lg'>Blogs</h3>
                        <Link to="/add-blog">
                            <button type='button' className="font-normal text-white bg-[#1DB459] px-5 py-2 rounded-md">Add New Blog</button>
                        </Link>
                    </div>
                </div>

                <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#c4c4c4] mx-6 lg:mt-6 lg:mx-10 mt-5">
                    <div className="flex justify-end px-8 pt-6">


                        <div className=" lg:ml-auto mr-3">
                            <div className="relative flex w-full ">
                                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                                </div>
                                <input type="text" placeholder='search Blog...' className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " />
                                {/* <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required /> */}
                            </div>
                        </div>
                    </div>

                    <div className=' lg:px-5 px-8 pt-1 pb-24 mx-auto  space-y-8 lg:grid lg:grid-cols-3 xl:gap-4  lg:space-y-0 lg:py-6 lg:mt-1'>
                    {groupLists?.data?.map(
                        (datas: any, index: any) => (
                            <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow">
                                <div className='flex justify-between p-4'>
                                    <div className="flex space-x-2 ">
                                        <div>
                                        <img src="/images/Dashboard/Userpic1.svg" alt="hero" />
                                        </div>
                                        <div>
                                            <h1 className=" text-md">{datas?.author_name}</h1>
                                            <p className=" text-xs">{datas?.date_posted}</p>

                                        </div>
                                    </div>
                                    <div>
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input type="checkbox" checked value="" className="sr-only peer" />
                                            <div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-green-600"></div>

                                        </label>
                                    </div>
                                </div>

                                <NavLink  to={`/edit-blog/${datas?.id}`}>
                                <div>
                                    <img className=" px-3" src={datas?.article_image_url} alt={datas?.article_image} />
                                </div>
                                <div className="px-4 pb-5 pt-2"> 

                                    <h5 className="text-lg font-semibold text-gray-900 ">{datas?.article_title} </h5>
                                    {/* <p className='text-xs text-[#838383]' dangerouslySetInnerHTML={{__html: datas?.article_body}}></p> */}
                                </div>
                                </NavLink>
                            </div>
                        )
                    )
                    }
                    </div>


                    {/* <div className='m-4'>
                        {
                            userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-green-500 text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }
                    </div> */}
                </div>

            </div>
        </>
    );
}

export default BlogList;