import React from "react";
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";

import DashHeader from "../../ReuseableComp/DashHeader";
import { useDispatch } from "react-redux";
import Calendar from "../../ReuseableComp/Calendar";
import { useSelector } from "react-redux";
import { DashboardApi } from "../../../apis/DashboardApi";
import { AxiosResponse } from "axios";
import { useNavigate } from 'react-router-dom';
import { login } from "../../../reducer/loginSlice";
import EmptyAssignment from "../../ReuseableComp/EmptyAssignment";


// components

// import CardPageVisits from "../Cards/CardPageVisits";
// import CardSocialTraffic from "../Cards/CardSocialTraffic";
// import AdminNavbar from "../Navbars/AdminNavbar.js";
// import Sidebar from "../Sidebar/Sidebar";
// import HeaderStats from "../Headers/HeaderStats.js";
// import FooterAdmin from "../Footers/FooterAdmin.js";

export default function Dashboard() {
  var currencyFormatter = require('currency-formatter');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLoginData = useSelector((state: any) => state.data.login.value);

  const [availableBalance, setAvailableBalance] = React.useState(0);

  React.useEffect(() => {
    userLoginData?.token ?
      DashboardApi.getUserDetails().then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            DashboardApi.getWalletDetails().then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  setAvailableBalance(response?.data?.data?.account_balance)
                  //  dispatch(login({email:email,token:response.data.data.token,id:response.data.data.account_id}))
                  // setRoomData(response?.data)
                  // device(roomData?.deviceId, roomData?.buildingId, roomData?.levelId)
                }
              }
            )
            // console.log(response?.data)
            //  dispatch(login({email:email,token:response.data.data.token,id:response.data.data.account_id}))
            // setRoomData(response?.data)
            // device(roomData?.deviceId, roomData?.buildingId, roomData?.levelId)
          }
          else {
            dispatch(login([]))
             navigate('/sign-in');
          }
        }
      ).catch(function (error) {
        // handle error
        console.log(error.response.data);

      })
      :

      navigate('/sign-in');
  }, []);


  return (
    <>
      {/* <Sidebar /> */}
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">

        <UserNavbar title="Dashboard" />
        {/* Header */}

        <DashHeader Username={userLoginData?.name} referral_id={userLoginData?.data?.user_data?.referral_id} amount={currencyFormatter.format(availableBalance, { code: 'USD' })} />


        {/* <HeaderStats />  */}
        <div className="flex flex-wrap mt-4">
          <div className="w-full md:w-8/12 mb-12 xl:mb-0 px-4">
            <div className="space-y-2 lg:grid lg:grid-cols-3 sm:gap-5  lg:space-y-0 lg:py-8 p-6 ">

              <div className="  text-gray-900 bg-[#FBFBFB] rounded-lg shadow ">
                <div className="pr-4 pl-3 text-[#838383] my-8">
                  <h3 className=" text-2xl text-black">0</h3>
                  <p className="text-xs mt-4 text-[#838383]">
                    Completed <br />
                    Courses
                  </p>
                </div>
              </div>

              <div className="  text-gray-900 bg-[#FBFBFB] rounded-lg shadow ">
                <div className="pr-4 pl-3 text-[#838383] my-8">
                  <h3 className=" text-2xl text-black">0</h3>
                  <p className="text-xs mt-4 text-[#838383]">
                    Completed <br />
                    Courses
                  </p>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row lg:justify-between  text-gray-900 bg-[#FBFBFB] rounded-lg shadow ">
                <div className="pr-4 pl-3 text-[#838383] my-8">
                  <h3 className=" text-2xl text-black">0</h3>
                  <p className="text-xs mt-4 text-[#838383]">
                    Completed <br />
                    Courses
                  </p>
                </div>
                <div className="place-self-center w-full">
                  <img src="/images/Dashboard/icon-map.svg" alt="hero" />
                </div>
              </div>

            </div>
          </div>

          <div className="w-full md:w-4/12 px-4">
            <Calendar />

            
          </div>

        </div>
        <div className="flex flex-wrap  mt-4 ">
          <div className="w-full md:w-8/12 mb-12 xl:mb-0 px-4">
            <EmptyAssignment color={"white"} />
          </div>
          <div className="w-full md:w-4/12 px-4">
            {/* <div className="flex justify-between mx-4">
              <h3 className=" font-bold text-2xl ">Upcoming Classes</h3>
              <h3 className="text-[#666666] text-base mt-1 font-semibold">View all</h3>
            </div> */}
            <div className="flex space-x-3 p-6 mx-4 mt-6  border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
              <div className="block w-full overflow-x-auto">
                <p className="ml-2" style={{ fontWeight: 'bold' }}>Upcoming Classes</p>

                <div className="mt-4">
                  <div className="flex justify-center mt-10">
                    <img src="/images/upcoming-classes.png" alt="hero" />
                  </div>

                  <div className="flex justify-center mt-7">
                    <p style={{ fontSize: '14px', fontWeight: 'bold', color: 'rgba(102, 102, 102, 0.8)' }}> No Upcoming class yet!</p>
                  </div>

                  <div className="flex justify-center mt-1">
                    <p style={{ fontSize: '12px', fontWeight: 'bold', color: '#B3B3B3' }}> You have no pending classes to attend  </p>
                  </div>
                </div>


              </div>

            </div>

          </div>
        </div>

      </div>


    </>
  );
}
