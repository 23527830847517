import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import { NavLink } from 'react-router-dom'
import Navbar from '../Navbar'
import EasyGoEduFaq from '../EasyGoEduFaq'
import CareerEduFaq from './CareerEduFaq'
import { useSelector } from 'react-redux'

const CareerEdu = () => {

  const [isLogin, setIsLogin] = useState(false);


  const userLoginData = useSelector((state:any) => state.data.login.value);

  React.useEffect(() => {
    if (!userLoginData.token) {
      setIsLogin(false)
    }

    // console?.log(userLoginData)

    if (userLoginData.token) {
      setIsLogin(true)
    }
    //  (userLoginData.token ?
    //   ''
    //   :
    //     navigate('/sign-in'));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when the component mounts
  }, []);
  return (
    <>
    <Navbar />
    {/* <!-- Start block --> */}
    <section className=" body-font font-poppins md:pt-10 pt-20">
      <div className="grid lg:grid-cols-12 max-w-screen-xl mx-auto px-8   lg:gap-8 xl:gap-0 lg:pt-16 pt-20">
        <div className="mr-auto place-self-center lg:col-span-7">
        <div className="ml-[86px] pb-1 lg:block hidden">
            <div className="bg-[#FFBA40] h-3 w-3 rounded-full"></div>
          </div>
          <h1 className="lg:max-w-3xl mb-4 lg:text-[40px] text-[35px] text-[#000] font-[700] md:leading-[52px] leading-[48px] tracking-normal  ">
          CAREEREDU
          </h1>
          {/* <div className=" -mt-8  lg:flex justify-end mr-[150px]  hidden">
            <div className="bg-[#EE1D52] h-6 w-6 rounded-full"></div>
          </div> */}
          <p className=" max-w-[640px] lg:pr-[30px]  mb-6 mt-3 font-normal text-[#777777] lg:mb-8 lg:text-[22px] md:leading-[30px] text-sm ">
          Elevate Your Learning Journey with our Finnish Language Program
          </p>

          <div className="flex justify-between">
            {/* {isLogin ? <a  href="https://wa.me/2349047248430?text=Hello%20I%20would%20like%20to%20speak%20to%20a%20consultant"  rel="noopener noreferrer" target="_blank">
              <button
                type="submit"
                className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
              >
                Get Started
              </button>
              </a> :  */}
                <NavLink to={"/contact-us"} className="">
                <button
                  type="submit"
                  className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
                >
                  Get Started
                </button>
              </NavLink>
              {/* } */}
          
            <div className="flex flex-col">
              <div className=" mb-4  lg:flex justify-end mr-[140px]  hidden">
                <div className="bg-[#0174B4] h-3 w-3 rounded-full"></div>
              </div>
            </div>
          </div>
          <div className=" mb-4  lg:flex justify-center -mt-[30px] mr-[200px]  hidden">
              <div className="bg-[#1DB459] h-5 w-5 rounded-full"></div>
            </div>
        </div>
        <div className=" my-6 lg:mt-0 lg:col-span-5 lg:flex w-full">
          <img src="/images/careeredu.png" alt="hero" />
        </div>
      </div>
    </section>
    {/* <!-- End block --> */}

   {/* <!-- Start block --> */}
   <section className=" body-font font-poppins md:pt-10 pt-10">
        <div className=" px-8 lg:px-14  ">
          <h1 className=" mb-4 lg:text-[48px] text-[35px] text-[#000] font-[700] md:leading-[52px] leading-[48px] tracking-normal  ">
          Benefits of CAREEREDU
          </h1>

          <div className=" space-y-8 grid lg:grid-cols-4 md:grid-cols-2 xl:gap-4 gap-3 lg:space-y-0 lg:py-6 lg:mt-6 ">
           <div className=" lg:mt-0 mt-10">
              <div className="px-6 py-[15px] h-[200px] rounded-[10px]  bg-[#EEFFF5]">
              <div className="flex ">
                  {/* <div>
                    <img src="/images/easygoedu4.png" alt="hero" />
                  </div> */}
              <div className="flex flex-col gap-3">
             <div className="flex gap-2 items-center">
              <div className="bg-[#D7F5DC]  rounded-md w-[25px] h-[25px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#1DB459"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                  <p className="font-normal list-disc text-sm max-w-[300px] text-[#000000]">
                  Access to language learning with expert Finnish language teachers.

                    </p>
                    </div>
                    <div className="flex gap-2 items-center">
              <div className="bg-[#D7F5DC]  rounded-md w-[25px] h-[25px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#1DB459"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                    <p className="font-normal  text-sm max-w-[320px] text-[#000000]">
                    Access to language learning materials.

                    </p>
                    </div>

                    <div className="flex gap-2 items-center">
              <div className="bg-[#D7F5DC]  rounded-md w-[25px] h-[25px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#1DB459"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                    <p className="font-normal  text-sm max-w-[300px] text-[#000000]">
                    Access to video learning materials.

                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" lg:mt-0 mt-10">
              <div className="px-6 py-[15px] h-[200px] rounded-[10px]  bg-[#F48120]/[15%]">
               <div className="flex gap-[10px]">
                  {/* <div>
                    <img src="/images/easygoedu5.png" alt="hero" />
                  </div> */}
                   <div className="flex flex-col gap-3">
                  
                   <div className="flex gap-2 items-center">
              <div className="bg-[#F4812026]  rounded-md w-[25px] h-[25px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#cc6918e5"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                    <p className="font-normal max-w-[320px] text-sm  text-[#000000]">
                 
                    Access to our team for daily support and many more services.

                    </p>
                    </div>

                    <div className="flex gap-2 items-center">
              <div className="bg-[#F4812026]  rounded-md w-[25px] h-[25px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#cc6918e5"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                    <p className="font-normal max-w-[320px] text-sm text-[#000000]">
                    Access to support system from our Finnish teachers.
                    </p>
                    </div>

                    <div className="flex gap-2 items-center">
              <div className="bg-[#F4812026]  rounded-md w-[25px] h-[25px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#cc6918e5"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                    <p className="font-normal max-w-[320px] text-sm  text-[#000000]">
                    Access to school application assistance.

                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" lg:mt-0 mt-10">
              <div className="px-6 py-[15px] h-[200px] rounded-[10px]  bg-[#C24B9A]/[15%]">
            <div className="flex gap-[10px]">
                  {/* <div>
                    <img src="/images/easygoedu6.png" alt="hero" />
                  </div> */}
             <div className="flex flex-col gap-3">
                   
             <div className="flex gap-2 items-center">
              <div className="bg-[#e0b6d2]  rounded-md w-[25px] h-[25px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#C24B9A"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                    <p className="font-normal max-w-[320px] text-sm  text-[#000000]">
                    Guidance for the residence permit process.

                    </p>
                    </div>

                    <div className="flex gap-2 items-center">
              <div className="bg-[#e0b6d2]  rounded-md w-[25px] h-[25px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#C24B9A"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                    <p className="font-normal max-w-[320px] text-sm  text-[#000000]">
                    Access to counseling and career opportunities.

                    </p>
                    </div>

                    <div className="flex gap-2 items-center">
              <div className="bg-[#e0b6d2]  rounded-md w-[25px] h-[25px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#C24B9A"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                    <p className="font-normal max-w-[320px] text-sm text-[#000000]">
                    Support in accommodation search

                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" lg:mt-0 mt-10">
              <div className="px-6 py-[15px] h-[200px] rounded-[10px]  bg-[#0174B4]/[15%]">
          <div className="flex gap-[10px]">

              <div className="flex flex-col gap-3"> 
          <div className="flex gap-2 items-center">
              <div className="bg-[#97cdeb]  rounded-md w-[25px] h-[25px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#0174B4"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                    <p className="font-normal max-w-[320px] text-sm  text-[#000000]">
                    Access to once-a-month 45-minute speaking practice with a Finnish citizen.

                    </p>
                  </div>

                  <div className="flex gap-2 items-center">
              <div className="bg-[#97cdeb]  rounded-md w-[25px] h-[25px] inline-flex items-center justify-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#0174B4"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"
                    />
                  </svg>
                </div>
                    <p className="font-normal max-w-[320px] text-sm  text-[#000000]">
                    Access to language certificate.

                    </p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}
      
    {/* <!-- Start block --> */}
    <section className=" body-font font-poppins md:pt-10 pt-10">
      <div className="grid lg:grid-cols-12 max-w-screen-xl mx-auto px-8 lg:px-14  pb-8  lg:gap-8 xl:gap-0">
        <div className="mr-auto place-self-center hidden lg:flex lg:col-span-7">
          <img src="/images/careeredu2.png" alt="hero" />
        </div>
        <div className="mr-auto place-self-center lg:col-span-5">
          <h1 className="lg:max-w-3xl mb-4 lg:text-[40px] text-[35px] text-[#1DB459] font-[700] md:leading-[52px] leading-[48px] tracking-normal">
          A Comprehensive <span className="text-black">Learning Experience</span>
          </h1>
          <p className=" mt-3 mb-4 font-normal text-[#777777] lg:text-base text-sm">
          Our innovative online program seamlessly blends Finnish language learning with vocational education. With expert instructors and immersive experiences, you will be mastering the nuances of the Finnish language with ease and convenience.
          </p>
          {/* {isLogin ? <a  href="https://wa.me/2349047248430?text=Hello%20I%20would%20like%20to%20speak%20to%20a%20consultant"  rel="noopener noreferrer" target="_blank">
              <button
                type="submit"
                className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
              >
                Get Started
              </button>
              </a> :  */}
                <NavLink to={"/contact-us"} className="">
                <button
                  type="submit"
                  className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
                >
                  Get Started
                </button>
              </NavLink>
              {/* } */}
        </div>
        <div className="mr-auto place-self-center lg:hidden flex lg:col-span-7">
          <img src="/images/careeredu2.png" alt="hero" />
        </div>
      </div>
    </section>
    {/* <!-- End block --> */}

    {/* <!-- Start block --> */}
    <section className=" body-font font-poppins md:pt-10 pt-10">
      <div className="grid lg:grid-cols-12 max-w-screen-xl mx-auto  px-8 lg:px-14  pb-8  lg:gap-8 xl:gap-0 ">
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1 className="lg:max-w-xl mb-4 lg:text-[48px] text-[35px]  text-black font-[700] md:leading-[52px] leading-[48px] tracking-normal">
          Unlock <span className="text-[#1DB459]">Unlimited</span> Opportunities
        
          </h1>

          <p className=" max-w-[610px] lg:pr-[30px]  mb-4 mt-3 font-normal text-[#777777] lg:mb-4 lg:text-base text-sm ">
          Learn Finnish online for 9 months and earn a certificate in Further Vocational Qualification Education (FVQE). With this certification, you can apply to vocational schools, Universities of Applied Sciences, and universities in Finland, as well as other institutions across Europe. Choose to study for free in Finnish, or opt for English tuition with a reduced study duration of just 6-12 months.

          </p>
          {/* {isLogin ? <a  href="https://wa.me/2349047248430?text=Hello%20I%20would%20like%20to%20speak%20to%20a%20consultant"  rel="noopener noreferrer" target="_blank">
              <button
                type="submit"
                className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
              >
                Get Started
              </button>
              </a> :  */}
                <NavLink to={"/contact-us"} className="">
                <button
                  type="submit"
                  className="bg-gradient-to-r from-[#01963C] to-[#158FD3] py-[16px] px-[40px]  text-sm font-medium text-white rounded-[40px] "
                >
                  Get Started
                </button>
              </NavLink>
              {/* } */}
        </div>
        <div className=" my-6 lg:mt-0 lg:col-span-5 lg:flex w-full">
          <img src="/images/careeredu3.png" alt="hero" />
        </div>
      </div>
    </section>
    {/* <!-- End block --> */}


    {/* <!-- Start block --> */}
    {/* <section className="bg-[#F7F8FA] body-font font-poppins md:pt-10 pt-10">
    <h3 className='text-[35px] text-center font-[700] lg:text-[40px] text-[#000000] mb-10 '>Program <span className="text-[#1DB459]">Highlights</span></h3>
    <div className="grid lg:grid-cols-12 max-w-screen-xl mx-auto  px-8 lg:px-14   lg:gap-8 xl:gap-0 ">
        <div className="mr-auto place-self-center lg:col-span-7">
            <div className='flex flex-col gap-2 pb-10'>

         
        <div className='flex items-center gap-3'>
        <img src="/images/check.png" alt="hero" />
<h3 className='font-[500] text-[24px]'>9-Month Online Language Course</h3>
        </div>

        <div className='flex items-center gap-3'>
        <img src="/images/check.png" alt="hero" />
<h3 className='font-[500] text-[24px]'>Certificate in Further Vocational Qualification Education (FVQE)</h3>
        </div>

        <div className='flex items-center gap-3'>
        <img src="/images/check.png" alt="hero" />
<h3 className='font-[500] text-[24px]'>Eligibility to Study in Vocational Schools, Universities of Applied Sciences, and Universities Across Finland and Europe</h3>
        </div>

        <div className='flex items-center gap-3 '>
        <img src="/images/check.png" alt="hero" />
<h3 className='font-[500] text-[24px]'>Eligibility to Apply to All Universities Inside the EU</h3>
        </div>

        <div className='flex items-center gap-3'>
        <img src="/images/check.png" alt="hero" />
<h3 className='font-[500] text-[24px]'>Option to Study for Free in Finnish or in English with Reduced Study Duration (6-12 Months)</h3>
        </div>

        <div className='flex items-center gap-3'>
        <img src="/images/check.png" alt="hero" />
<h3 className='font-[500] text-[24px]'>9 Hours Weekly + Assignments</h3>
        </div>

        <div className='flex items-center gap-3'>
        <img src="/images/check.png" alt="hero" />
<h3 className='font-[500] text-[24px]'>Immediate Work Opportunities Upon Arrival in Finland</h3>
        </div>

        <div className='flex items-center gap-3'>
        <img src="/images/check.png" alt="hero" />
<h3 className='font-[500] text-[24px]'>Innocamp: Innovative Learning Experiences</h3>
        </div>

        <div className='flex items-center gap-3'>
        <img src="/images/check.png" alt="hero" />
<h3 className='font-[500] text-[24px]'>Career Guidance to Reach A2.2 Proficiency Level</h3>
        </div>
        </div>
        </div>
        <div className="  lg:mt-[90px] lg:col-span-5 lg:flex w-full">
          <img src="/images/careerPerson.svg" alt="hero" />
        </div>
      </div>
    </section> */}
    {/* <!-- End block --> */}


    {/* <!-- Start block --> */}
    <section className="">
      <CareerEduFaq />
    </section>

    {/* <!-- End block --> */}
    <Footer />
  </>
  )
}

export default CareerEdu
