import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import AdminSidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import { useNavigate } from "react-router-dom";
import { AdminApis } from "../../../apis/adminApi";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import Modal from "react-awesome-modal";

const EditFaq = () => {
  const [faqOverview, setFaqOverview] = React.useState<any>("");
  const [question, setQuestion] = React.useState<any>("");
  const [faqLists, setFaqList] = React.useState<any>([]);
  const [faqCategory, setFaqCategory] = React.useState<any>("");

  const params: any = useParams();
  let faqQuestionId: string = params?.faqId;

  var fontSizeStyle = Quill.import("attributors/style/size");
  fontSizeStyle.whitelist = ["12px", "14px", "20px", "24px"];
  Quill.register(fontSizeStyle, true);

  var toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: fontSizeStyle.whitelist }],
    [{ font: [5, 6, 7, 8, 9, 10, 11, 12, 131, 4, 16, 19, 23] }],
    ["bold", "italic", "underline", "link"], // toggled buttons
    ["blockquote", "code-block"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ["clean"], // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const navigate = useNavigate();
  let [userData, setUserData] = React.useState({
    question: "",
    faq_category: "",
  });
  React.useEffect(() => {
    AdminApis.getAllFaq()
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          const faqData = response?.data?.data;
          setFaqList(faqData);

          // Find the FAQ object with matching ID
          const faq = faqData.find(
            (faq: any) => faq.id === parseInt(faqQuestionId)
          );
          console.log(faq);
          // If FAQ is found, pre-fill form fields
          if (faq) {
            setUserData({
              question: faq.question,
              faq_category: faq.faq_category,
            });
            setFaqCategory(faq.faq_category);
            setFaqOverview(faq.answer);
          }
        }
      })
      .catch(function (error) {
        // handle error
      });
  }, [faqQuestionId]);

  let [visible, setVisible] = React.useState(false);
  function toggleModal() {
    setVisible(!visible);
  }
  // console.log(userData?.question)
  // console.log(faqQuestionId)
  const deleteCourse = React.useCallback((e: any) => {
    AdminApis.deleteFaq(faqQuestionId)
      .then((response: any) => {
        if (response?.data) {
          // console.log(response?.data?.data)
          toast.success(response?.data?.message);
          setVisible(false);
          navigate("/faq-list");
        }
      })
      .catch((err: any) => {
        if (err?.response?.status == 422) {
          //   toast.error('Form not rightly filled. Kindly Cross check.');
        } else {
          //   toast.error('Some error occured.Please try again');
        }
      });
  }, []);

  const handleChange = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  console.log(userData?.question);
  console.log(faqOverview);
  console.log(userData?.faq_category);

  const updateSingleFaq = React.useCallback(
    (e: any) => {
      e.preventDefault();
      const data = {
        question: userData?.question,
        answer: faqOverview,
        faq_category: userData?.faq_category,
        id: faqQuestionId
      };
      // const faqData = new FormData();
      // faqData.append("question", userData?.question);
      // faqData.append("answer", faqOverview);
      // faqData.append("faq_category", userData?.faq_category); // Corrected key name to 'faq_category'
      // faqData.append("id", faqQuestionId);

      AdminApis.updateFaq(faqQuestionId, data) // Pass faqData to the updateFaq method
        .then((response: any) => {
          if (response?.data) {
            console.log(response?.data?.data);
            toast.success(response?.data?.message);
            navigate("/faq-list");
          }
        })
        .catch((err: any) => {
          if (err?.response?.status === 422) {
            console?.log(err);
            toast.error(err?.response?.data?.message);
          } else {
            console?.log(err);
            toast.error(err?.response?.data?.message);
          }
        });
    },
    [userData, faqOverview, faqQuestionId]
  );

  return (
    <div>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />
        <div className="lg:pt-10 lg:px-10 px-6 ">
          <div className="flex justify-between">
            <h3 className=" font-bold text-[31px]">Edit Faq</h3>
            <span>
              {" "}
              <button
                type="button"
                onClick={toggleModal}
                className="text-white bg-[#b41d1d] px-3 py-1 rounded-md"
              >
                {" "}
                Delete
              </button>
            </span>
          </div>
        </div>
        <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#D9D9D9] mx-6 lg:mt-4 lg:mx-10 mt-5">
          <form onSubmit={updateSingleFaq}>
            <div className="flex md:flex-row flex-col sm:pt-10 py-6 md:px-8 px-4">
              <div className="basis-8/12">
                <div className="mt-[30px]">
                  <h3 className="text-[#1DB459] text-[15px] font-semibold">
                    Basic Information
                  </h3>
                  <div className="w-full lg:w-12/12  pt-4">
                    <div className="relative w-full mb-6">
                      <label
                        className="block text-[#303840] text-sm font-medium md:pl-1 mb-2"
                        htmlFor="grid-password"
                      >
                        Faq Question
                      </label>
                      <input
                        type="text"
                        name="question"
                        defaultValue={userData?.question}
                        onChange={handleChange}
                        className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full lg:w-12/12 md:mb-[60px] mb-[120px]">
                  <div className="mb-[10px] text-[#303840] font-medium">
                    Faq Answer
                  </div>
                  <ReactQuill
                    theme="snow"
                    modules={module}
                    value={faqOverview}
                    onChange={setFaqOverview}
                    placeholder={"Content of the Editor"}
                    className="h-40"
                  />
                </div>
              </div>

              <div className="basis-4/12 md:ml-[66px]">
                <div className="mt-10">
                  <h3 className="text-[#303840] my-3 font-medium">Options</h3>
                  <div className=" flex-col border border-[#CBD6E2] rounded-[10px] px-3">
                    <div>
                      <div className="w-full lg:w-12/12  md:pt-4">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                            htmlFor="grid-password"
                          >
                            Category
                          </label>
                          <select
                            id="faq_category"
                            name="faq_category"
                            // defaultValue={userData?.faq_category}
                            onChange={handleChange}
                            className=" border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg  block w-full p-2.5 "
                          >
                            <option selected>{userData?.faq_category}</option>
                            <option value="general">General</option>
                            <option value="payment">Payment</option>
                            <option value="english pathway">
                              English Pathway
                            </option>
                            <option value="finish pathway">
                              Finish Pathway
                            </option>
                            <option value="before application">
                              Before Application
                            </option>
                            <option value="after application">
                              After Application
                            </option>
                            {/* <option value="master's program">Master's Program</option> */}
                          </select>
                          <span className="text-[12px] font-medium text-[#6E768C]">
                            Select a category.
                          </span>
                        </div>
                      </div>

                      {/* <div className="w-full lg:w-12/12  md:pt-4">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                            htmlFor="grid-password"
                          >
                            Product
                          </label>
                          <select
                            id="category"
                            name="category"
                            onChange={handleChange}
                            className=" border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg  block w-full p-2.5 "
                          >
                            <option selected></option>
                            <option value="afriPro Edu">AfriPro Edu</option>
                            <option value="AfriPay">AfriPay</option>
                            <option value="AfriPro Edu Global">
                              AfriPro Edu Global
                            </option>
                            <option value="master's program">Master's Program</option>
                          </select>
                          <span className="text-[12px] font-medium text-[#6E768C]">
                            Select a category.
                          </span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-4/12 md:px-8 px-6 pb-6">
              <div className=" items-center">
                <button
                  type="submit"
                  className="font-normal text-white bg-[#1DB459] px-6 py-2.5 rounded-[3px]"
                >
                  Update Faq
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <section>
        <Modal
          visible={visible}
          width="700"
          minHeight="300"
          effect="fadeInUp"
          onClickAway={() => toggleModal}
        >
          <p
            className="flex justify-end mt-2 ml-2 mr-2 cursor-pointer font-bold"
            onClick={toggleModal}
          >
            X
          </p>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-xl rounded-lg mt-2">
            <div className="px-6">
              <div className="">
                <div className="flex justify-center">
                  Are you sure you want to delete this Faq?
                </div>
                <div className="flex justify-center py-10">
                  <button
                    type="button"
                    onClick={deleteCourse}
                    className="text-white bg-[#b41d1d] px-3 py-1 rounded-md "
                  >
                    {" "}
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </section>
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default EditFaq;
