import React from 'react'
import Navbar from '../Navbar'
import { NavLink, useNavigate } from "react-router-dom";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import hero from "../../img/new1.png";
import { AuthApis } from '../../apis/authApis';
import { AxiosResponse } from 'axios';
import configs from '../../configs';
import Footer from '../Footer';

const FindPrograms = () => {
    const [courseList, setCourseList] = React.useState<any>('');
    const [category, setCategory] = React.useState<any>('');
    const [name, setName] = React.useState<any>('');

    React.useEffect(() => {
        const query: any = {
            title: '',
            category: ''
        };
        AuthApis.getFilteredCourse('', query).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setCourseList(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })
    }, []);
    const navigate = useNavigate();

    const viewCourse = React.useCallback(
        () => {
            navigate("/list-of-courses",
                {
                    state:
                    {
                        name: name,
                        category: category
                    }
                });
        },
        [name, category]
    );
    return (
        <>
            <Navbar />

            {/* Start Section */}
            <div className='bg-[#FFFFFF] sm:px-16 px-6 flex justify-center items-start md:pt-20 md:pb-5' >
                <div
                    style={{
                        backgroundImage: `url(${hero})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className='xl:max-w-[1280px] w-full'>
                    <section className='flex md:flex-row  flex-col-reverse sm:py-16'>
                        <div className='flex-1 flex-col justify-center items-start  hidden md:block'>
                            <img src="/images/new-hero.png" alt="hero" />
                        </div>

                        <div className='flex-1 flex   flex-col md:ml-[120px] ml-0 md:mt-[110px] mt-20 relative'>
                            <div className="md:flex flex-row w-full mt-5 md:mt-0">
                                <h1 className="flex-1 font-poppins font-bold md:text-[40px] text-[27px] text-black md:leading-[48px] leading-[38px]">
                                    Move to Finland through <br className="sm:block hidden" /> {" "}
                                    our different Educational
                                    <div
                                        className="md:w-[250px] md:h-[70px] w-[200px] h-[50px] rounded-[50%] 
                flex items-center justify-center 
                 border-[3px] border-[#FCD27C] font-bold">
                                        pathways
                                    </div>
                                </h1>
                            </div>
                            <div className='mt-3'>
                                <p className='font-poppins font-normal text-[#1B212F] md:text-[16px] text-[13px] md:leading-[23.85px] leading-[20px] max-w-[500px] mt-5'>Our programs are designed to prepare you for success in Finnish higher education and give you the skills and knowledge you need to thrive in the Finnish job market.</p>

                            </div>
                            {/* <div className="bg-[#FFFFFF] grid grid-cols-1 md:gap-10 gap-5 md:grid-cols-3 md:pl-2 md:border md:border-[#CECECE] md:py-2 md:px-2 md:rounded-full  mb-2 mt-3">
                                <div className=' relative md:ml-2 md:mt-1'>
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.6836 15.625C16.8711 15.7917 16.8711 15.9688 16.6836 16.1562L15.9961 16.8438C15.8086 17.0312 15.6315 17.0312 15.4648 16.8438L11.6836 13.0625C11.6003 12.9792 11.5586 12.8958 11.5586 12.8125V12.375C10.3294 13.4375 8.91276 13.9688 7.30859 13.9688C5.51693 13.9688 3.98568 13.3333 2.71484 12.0625C1.44401 10.7917 0.808594 9.26042 0.808594 7.46875C0.808594 5.67708 1.44401 4.14583 2.71484 2.875C3.98568 1.60417 5.51693 0.96875 7.30859 0.96875C9.10026 0.96875 10.6315 1.60417 11.9023 2.875C13.1732 4.14583 13.8086 5.67708 13.8086 7.46875C13.8086 9.07292 13.2773 10.4896 12.2148 11.7188H12.6523C12.7565 11.7188 12.8398 11.7604 12.9023 11.8438L16.6836 15.625ZM3.77734 11C3.77734 11 4.02214 11.2448 4.51172 11.7344C5.0013 12.224 5.93359 12.4688 7.30859 12.4688C8.68359 12.4688 9.86068 11.9792 10.8398 11C11.819 10.0208 12.3086 8.84375 12.3086 7.46875C12.3086 6.09375 11.819 4.91667 10.8398 3.9375C9.86068 2.95833 8.68359 2.46875 7.30859 2.46875C5.93359 2.46875 4.75651 2.95833 3.77734 3.9375C2.79818 4.91667 2.30859 6.09375 2.30859 7.46875C2.30859 8.84375 2.79818 10.0208 3.77734 11Z" fill="#707070" />
                                        </svg>

                                    </div>
                                    <input type="text" id="small-input" placeholder='Find Courses' className="block w-full p-3 pl-10 border border-[#D9D9D9] md:border-none font-medium text-black rounded-md text-xs placeholder-[#000000]" />
                                </div>
                                <div className=' relative md:mt-1'>
                                    <select id="countries" className=" text-gray-900 text-sm rounded-[4px] font-medium border border-[#D9D9D9] md:border-none block w-full p-2 pl-4 ">
                                    <option selected>Category</option>
                                    <option value="">All</option>
                                    <option value="high school">High School</option>
                                    <option value="vocational">Vocational</option>
                                    <option value="degree program">Degree Program</option>

                                    </select>
                                </div>
                                <div>
                                    <button type="button" className="text-white bg-[#1DB459]  font-medium rounded-full text-sm px-6 py-3 text-center items-end">
                                        Search Now
                                    </button>
                                </div>
                            </div> */}
                            <div className='mb-3 md:mt-10'>
                                <h3 className='text-[#1B212F] text-[14px] font-medium'><span className='text-[#1DB459]'>#1</span>African Path to Finnish Education</h3>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {/* End Section */}

            {/* Start Section */}
            <div className='bg-[#F1F8F4] sm:px-16 px-6 flex justify-center items-start' >
                <div className='xl:max-w-[1280px] w-full'>
                    <div className='text-center'>
                        <hr className="md:w-[170px] w-[110px] h-1 mx-auto mt-4 mb-1 bg-[#1DB459] border-0 rounded md:mt-10" />
                        <h3 className='md:text-[30px] text-[20px] font-bold text-[#1B212F]'>Our Study Paths </h3>
                    </div>
                    <section className='flex md:flex-row justify-between flex-col gap-7 md:py-16 py-6'>
                        <div className='bg-white flex-1 flex flex-col justify-center items-center rounded-[10px] pt-4 px-6'>
                            <div>
                                <img src="/images/phone-operator.png" alt="hero" />
                            </div>
                            <div className='pt-[25px] text-center'>
                                <h3 className=' md:text-[30px] text-[20px] text-[#1B212F] font-bold leading-[32px]'>Learn in Finnish</h3>
                                <p className=' mt-[20px] font-normal md:text-[16px] text-[#707070] text-xs pb-3 text-center'>The focus of our language programs is to help the
students pass the level B1.1. language test which is a
requirement to come to study in Finland for Free.

                                   </p>
                            </div>
                            <NavLink to={"/study-in-finnish"}
                            className='mb-6 mt-[25px]'>
                                <button type="button" className="text-white bg-[#41C485]  font-medium rounded-full text-sm px-6 py-3 text-center items-center">
                                    See More
                                </button>
                            </NavLink>

                        </div>

                        <div className='bg-white flex-1 flex flex-col justify-center items-center rounded-[10px] pt-4 px-6'>
                            <div>
                                <img src="/images/graduate.png" alt="hero" />
                            </div>
                            <div className='pt-[25px] text-center'>
                                <h3 className=' md:text-[30px] text-[20px] font-bold text-[#1B212F] leading-[32px]'>Learn in English</h3>
                                <p className=' mt-[20px] font-normal md:text-[16px] text-xs pb-3 text-[#707070] text-center'> Expand your horizons by studying in English in Finland with AfriProEdu! Immerse yourself in a world-class education while experiencing the unique Finnish culture.</p>
                            </div>
                            <NavLink to={"/study-in-english"} className='mb-6 mt-[25px]'>
                                <button type="button" className="text-white bg-[#41C485]  font-medium rounded-full text-sm px-6 py-3 text-center items-center">
                                    See More
                                </button>
                            </NavLink>

                        </div>

                    </section>
                </div>
            </div>
            {/* End Section */}

            {/* Start Section */}
            <div className='bg-[#FFFFFF] sm:px-16 px-6 flex justify-center items-start ' >
                <div className='xl:max-w-[1280px] w-full'>
                    <section className='flex md:flex-row flex-col sm:py-16 py-6'>
                        <div className='flex-1 flex-col justify-center items-start  '>
                            <img src="/images/eng-pathway.png" alt="hero" />
                        </div>

                        <div className='flex-1 flex   flex-col md:ml-[120px] ml-0 md:mt-[80px] mt-10 relative'>
                            <div className="md:flex flex-col   w-full">
                                <h3 className=' underline underline-offset-4 md:text-[15px] text-[13px] text-[#1DB459] font-bold'>English Pathway</h3>
                                <h1 className="flex-1 font-poppins font-semibold md:text-[40px] text-[25px] md:mt-[20px] mt-[10px] text-[#1B212F] md:leading-[48px] leading-[38px]">
                                    Why Study in English ?
                                </h1>
                                <p className='md:mt-[20px] mt-[10px] text-[#707070] md:text-[16px] text-sm'>Finnish Educational Institutions offer a wide range of high-quality programs in a variety of fields, many of which are taught in English.</p>
                            </div>
                            <div className='mt-[24px]'>
                                <div className='flex space-x-3'>
                                    <div className="md:w-[25px] md:h-[25px] w-[15px] h-[15px] mt-2 md:mt-0 bg-[#8E66FF] rounded-full"></div>
                                    <h3 className='text-[#1B212F] font-medium md:text-base text-sm'>Access to a world-class education</h3>
                                </div>
                                <div className='flex space-x-3 mt-[20px]'>
                                    <div className="md:w-[25px] md:h-[25px] w-[15px] h-[15px] mt-2 md:mt-0 bg-[#1DB459] rounded-full"></div>
                                    <h3 className='text-[#1B212F] font-medium md:text-base text-sm'>Increased global career opportunities</h3>
                                </div>
                                <div className='flex space-x-3 mt-[20px]'>
                                    <div className="md:w-[25px] md:h-[25px] w-[15px] h-[15px] mt-2 md:mt-0 bg-[#F14D5D] rounded-full"></div>
                                    <h3 className='text-[#1B212F] font-medium md:text-base text-sm'>Affordability</h3>
                                </div>
                                <div className='flex space-x-3 mt-[20px]'>
                                    <div className="md:w-[25px] md:h-[25px] w-[15px] h-[15px] mt-2 md:mt-0 bg-[#8E66FF] rounded-full"></div>
                                    <h3 className='text-[#1B212F] font-medium md:text-base text-sm'>Job opportunities</h3>
                                </div>

                            </div>
                            <NavLink to={"/study-in-english"} className='mb-6 mt-[25px]'>
                                <button type="button" className="text-white bg-[#1DB459]  font-medium rounded-full text-sm px-6 py-3 text-center items-center">
                                    More Details
                                </button>
                            </NavLink>


                        </div>

                    </section>
                </div>
            </div>
            {/* End Section */}

            {/* Start Section */}
            <div className=' sm:px-16 px-6 flex justify-center items-start' >
                <div className='xl:max-w-[1280px] w-full'>

                    <section className='flex md:flex-row flex-col sm:py-16 py-6'>
                        <div className='flex-1 flex justify-center items-start flex-col'>
                            <div className="md:flex flex-col w-full">
                                <h3 className=' underline underline-offset-4 md:text-[15px] text-[13px] text-[#1DB459] font-bold'>Finnish Pathway</h3>
                                <h1 className="flex-1 font-poppins font-semibold md:text-[40px] text-[25px] md:mt-[20px] mt-[10px] text-[#1B212F] md:leading-[48px] leading-[38px]">
                                    Why Study in Finnish ?
                                </h1>
                                <p className='md:mt-[20px] mt-[10px] text-[#707070] md:text-[16px] text-sm max-w-[500px]'>If you are planning to work in Finland after you graduate, speaking Finnish will give you a significant advantage. Many employers in Finland prefer to hire bilingual employees.</p>
                            </div>
                            <div className='mt-[24px]'>
                                <div className='flex space-x-3'>
                                    <div className="md:w-[25px] md:h-[25px] w-[15px] h-[15px] mt-2 md:mt-0 bg-[#8E66FF] rounded-full"></div>
                                    <h3 className='text-[#1B212F] font-medium md:text-base text-sm'>Better job opportunities</h3>
                                </div>
                                <div className='flex space-x-3 mt-[20px]'>
                                    <div className="md:w-[25px] md:h-[25px] w-[15px] h-[15px] mt-2 md:mt-0 bg-[#1DB459] rounded-full"></div>
                                    <h3 className='text-[#1B212F] font-medium md:text-base text-sm'>Easier integration into society</h3>
                                </div>
                                <div className='flex space-x-3 mt-[20px]'>
                                    <div className="md:w-[25px] md:h-[25px] w-[15px] h-[15px] mt-2 md:mt-0 bg-[#F14D5D] rounded-full"></div>
                                    <h3 className='text-[#1B212F] font-medium md:text-base text-sm'>Free tuition</h3>
                                </div>
                                <div className='flex space-x-3 mt-[20px]'>
                                    <div className="md:w-[25px] md:h-[25px] w-[15px] h-[15px] mt-2 md:mt-0 bg-[#8E66FF] rounded-full"></div>
                                    <h3 className='text-[#1B212F] font-medium md:text-base text-sm'>Government scholarships</h3>
                                </div>

                            </div>
                            <NavLink to={"/study-in-finnish"} className='mb-6 mt-[25px]'>
                                <button type="button" className="text-white bg-[#1DB459]  font-medium rounded-full text-sm px-6 py-3 text-center items-center">
                                    More Details
                                </button>
                            </NavLink>

                        </div>
                        <div className='flex-1 flex justify-center items-center'>
                            <img src="/images/fin-pathway.png" alt="hero" />
                        </div>
                    </section>

                </div>
            </div>
            {/* End Section */}

            {/* Start Section */}
            {/* <div className=' sm:px-16 px-6 flex justify-center items-start py-10' >
                <div className='xl:max-w-[1280px] w-full'>
                    <div>
                        <h3 className='text-black font-semibold text-[24px]'>Other Courses</h3>
                    </div>
                    <div className='flex cursor-pointer justify-end space-x-3 md:mt-[50px] mt-[20px]' onClick={viewCourse}>
                        <h3>See all</h3>
                        <div className='mt-0'>
                            ➜
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:gap-10 gap-3 md:grid-cols-3 mt-[10px]'>
                        {
                            courseList?.data?.slice(0, 3)?.map(
                                (datas: any, index: any) => (
                                    <NavLink to={`/course-details/${(datas?.title).replace(
                                 / /g,
                                 "-"
                               )}`}>
                                        <div className='bg-white p-3 rounded flex-col border border-[#D9D9D9]'>
                                            <div className='flex-1 flex justify-center items-center'>
                                            <img className="" src={datas?.image_url} alt={datas?.cover_photo} style={{ width:"370px", height:'188px' }} />
                                                            
                                            </div>
                                            <div className='flex justify-between pt-2'>
                                                <h3 className='text-[#002147] md:text-[15px] text-[12px] font-normal'>{datas?.title}</h3>
                                                <div className='mt-1'>
                                                    ➜
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                )
                            )
                        }
                        

                    </div>
                </div>
            </div> */}
            {/* End Section */}

            {/* Start Section */}
            <Footer/>
            {/* End Section */}
        </>
    )
}

export default FindPrograms