import React from 'react'
import AgentNavbar from "../../Navbars/AgentNavbar";
import { SvgElement, icontypesEnum } from "../../assets/svgElement";


import LatestUpdate from './LatestUpdate';
import StudentsList from './StudentsList';
import MyProfile from './MyProfile';
import Refferal from './Refferal';
import { useSelector } from 'react-redux';
function Welcome() {

  const userLoginData = useSelector((state:any) => state?.data?.login?.value);
  const [openTestTab, setOpenTestTab] = React.useState(1);
  let [welcomeScreen, setWelcomeScreen] = React.useState<any>(1);
  let [screen, setScreen] = React.useState<any>(1);
  return (
    <>
      <AgentNavbar />
      <div className='bg-gradient-to-r from-[#01963C] to-[#158FD3] min-h-screen lg:mt-4 mt-14'>

        {/* <!-- Welcome --> */}
        <div className={screen === 1 ? "block " : "hidden"} >
          <div className='flex justify-center items-center pt-16 pb-8 mx-6'>
            <div className={welcomeScreen === 1 ? 'block' : "hidden"}>
              <div className='border border-[#E2E8F0] rounded-[10px] p-2 '>
                <div className='lg:w-[859px] bg-white rounded-[10px] px-[26px] py-[29px] relative'>
                  <div className=' absolute -left-[210px]'>
                    <img src="/images/Dashboard/agent-icon.png" alt="hero" />
                  </div>
                  <div className=' absolute -bottom-10 -left-[200px]'>
                                <img src="/images/Dashboard/agent-icon2.png" alt="hero" />
                                </div>
                  <div className='flex-col justify-center'>
                    <h3 className='text-center font-bold lg:text-[20px] text-[16px]'>Welcome to AfriproEdu's</h3>
                    <h1 className='text-center items-center  lg:text-[64px] text-[45px] bg-gradient-to-r from-[#01963C] to-[#158FD3] font-modak justify-center text-transparent bg-clip-text'>Agent Portal</h1>
                    <div>
                      <p className='text-[14px] capitalize'>Dear {userLoginData?.data?.user_data?.name},</p>
                    </div>
                    <p className='text-[14px] mt-3'>We are thrilled to welcome you to AfriProEdu's Agent Portal – your exclusive gateway to a wealth of information, updates, and insights into your students' journeys. As an integral part of our network, your dedication to guiding students toward educational opportunities is highly valued, and we are excited to support you every step of the way.</p>
                    <h3 className='text-[14px] mt-3'>Here's what you can expect from the Agent Portal:</h3>
                    <ul className=" space-y-1 text-[14px] mt-3 pl-4 list-disc list-outside ">
                      <li>
                        Real-time Updates: Stay informed about the latest news, program changes, and important announcements directly from AfriProEdu. Our goal is to keep you well-informed so you can provide the best guidance to your students
                      </li>
                      <li>
                        Student Referral Tracking: Easily access a comprehensive list of students who have utilized your referral links. This feature allows you to monitor the progress of your referred students throughout their application process.
                      </li>
                      <li>
                        Referral Rewards: We believe in recognizing your efforts. The portal provides a streamlined process for claiming funds from successful student referrals. Your hard work deserves to be rewarded, and we want to ensure you receive the acknowledgment and compensation you deserve.
                      </li>
                    </ul>

                    <p className='text-[14px] mt-3'> If you encounter any issues or have questions, our dedicated support team is here to assist you.</p>

                    <p className='text-[14px] mt-3'>Thank you for being a valued member of the AfriProEdu family. Your commitment to fostering educational opportunities is making a meaningful impact, and we look forward to supporting your continued success through our Agent Portal.</p>
                    <div className='flex justify-center mt-5'>
                      <button
                        onClick={() => setScreen(2)}
                        className="bg-gradient-to-r from-[#01963C] to-[#158FD3] text-white font-semibold rounded p-1">
                        <span className="flex w-full text-[14px] bg-white text-black rounded-[5px] py-[10px] px-[73px]">
                          Proceed
                        </span>
                      </button>
                    </div>

                  </div>
                </div>
              </div>


            </div>




          </div>
        </div>



        <div className={screen === 2 ? "block " : "hidden"} >

          {/* <!-- Header --> */}

          <div className='flex lg:flex-row flex-col justify-center lg:space-x-6 lg:space-y-0 space-y-3 pt-6 mx-6'>
            
            <div
              onClick={() => setOpenTestTab(1)}
              className={` ${openTestTab === 1 ? "bg-[#ffffff] text-[#1DB459] rounded-full" : "text-[#ffffff]"} py-[10px] px-[30px]  rounded-full border border-[#ffffff] hover:text-[#1DB459] hover:bg-white text-[12px] cursor-pointer`}
            >Latest Update</div>
            <div
              onClick={() => setOpenTestTab(2)}
              className={` ${openTestTab === 2 ? "bg-[#ffffff] text-[#1DB459] rounded-full" : "text-[#ffffff]"} py-[10px] px-[30px]  rounded-full border border-[#ffffff] hover:text-[#1DB459] hover:bg-white text-[12px] cursor-pointer`}
            >My Students</div>
            <div
              onClick={() => setOpenTestTab(3)}
              className={` ${openTestTab === 3 ? "bg-[#ffffff] text-[#1DB459] rounded-full" : "text-[#ffffff]"} py-[10px] px-[30px]  rounded-full border border-[#ffffff] hover:text-[#1DB459] hover:bg-white text-[12px] cursor-pointer`}
            >My Profile</div>
            <div
              onClick={() => setOpenTestTab(4)}
              className={` ${openTestTab === 4 ? "bg-[#ffffff] text-[#1DB459] rounded-full" : "text-[#ffffff]"} py-[10px] px-[30px] rounded-full border border-[#ffffff] hover:text-[#1DB459] hover:bg-white text-[12px] cursor-pointer`}
            >Refferal</div>
          </div>


          {/* <!-- Content --> */}

          <div className={openTestTab === 1 ? 'block' : "hidden"}>
            <LatestUpdate/>

          </div>



          <div className={openTestTab === 2 ? "block " : "hidden"} >
           <StudentsList/>

          </div>

          <div className={openTestTab === 3 ? "block " : "hidden"} >
            <MyProfile/> 
          </div>

          <div className={openTestTab === 4 ? "block " : "hidden"} >
            <Refferal/>
          </div>


        </div>



      </div>
    </>
  )
}

export default Welcome