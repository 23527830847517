import React from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import { icontypesEnum, SvgElement } from '../../assets/svgElement'
import { TbWorld } from 'react-icons/tb'

const AgentDetails = () => {
  return (
    <div>
        <AdminSidebar />
        <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />

        <div className=' lg:mt-4 lg:px-16 px-8 pt-10 pb-10'>

          <div className='flex justify-end   '>
            <div className='bg-[#E21E32] px-2 py-2 rounded '>
              <SvgElement type={icontypesEnum.DELETETWO} />
            </div>
          </div>

          <div className='flex justify-start md:flex-row flex-col border-b-[2px] bo'>
            <div className='pr-10 max-w-sm'>
              <SvgElement type={icontypesEnum.DEFAULTIMAGE} />
              {/* <img src={userData?.profile_picture} alt="profile" className="rounded-full w-[80px] h-[80px]" /> */}
            </div>
            <div>

              <div className='flex justify-between '>
                <h3 className='font-bold text-xl capitalize'>Ijaola Joseph</h3>

              </div>

              <div className="flex flex-col lg:flex-row lg:space-x-4 mt-5">

                <div className='flex space-x-2'>
                  <SvgElement type={icontypesEnum.LOCATION} />
                  <h4>N0 20 jigawa street</h4>
                </div>

                <div className='flex space-x-2 items-center mt-3 lg:mt-0'>
                  <SvgElement type={icontypesEnum.TWITTER} />
                  <h4>ijaijb</h4>
                </div>
                <div className='flex space-x-2 mt-3 lg:mt-0'>
                  <SvgElement type={icontypesEnum.MAIL} />
                  <h4>ijabolu@gmail.com</h4>
                </div>
              </div>






              <div className='flex flex-col lg:flex-row lg:space-x-5 my-6'>
                {/* <div >
                  <button
                    type="button"
                    className="ml-5 text-[#ffffff] bg-[#1DB459]  font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2"
                      fill="currentColor" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M20 17h2v2H2v-2h2v-7a8 8 0 1 1 16 0v7Zm-2 0v-7a6 6 0 0 0-12 0v7h12Zm-9 4h6v2H9v-2Z" /></svg>


                    Follow

                  </button>
                </div> */}

                <div className='mt-3 lg:mt-0'>
                  <button
                    type="button"
                    className="ml-5 text-[#1DB459] border border-[#1DB459]  font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    Restrict Account

                  </button>
                </div>

         

           
              </div>

            </div>
          </div>

<h3 className='text-[20px] font-[500] pt-4 pb-6'>Company Info</h3>

<div className='flex justify-start md:flex-row flex-col border-b-[2px] bo'>
           
            <div>

              <div className='flex justify-between '>
                <h3 className='font-bold text-xl capitalize'>Bright Ventures</h3>

              </div>

              <div className="flex flex-col lg:flex-row lg:space-x-4 mt-5">

                <div className='flex space-x-2'>
                  <SvgElement type={icontypesEnum.PHONE} />
                  <h4>08145363748</h4>
                </div>
              

                {/* <div className='flex space-x-2 mt-3 lg:mt-0'>
                  <SvgElement type={icontypesEnum.TWITTER} />
                  <h4>ijaijb</h4>
                </div> */}
                <div className='flex space-x-2 mt-3 lg:mt-0'>
                  <SvgElement type={icontypesEnum.MAIL} />
                  <h4>ijabolu@gmail.com</h4>
                </div>

                <div className='flex items-center space-x-2'>
                  <TbWorld />
                  <h4>ija.com</h4>
                </div>
              </div>






              <div className='flex flex-col lg:flex-row lg:space-x-5 mt-6'>
           
                

                <div className='flex justify-end mt-10' >
                  <h3>Company ID: 7483902275537</h3>
                </div>

              </div>

            </div>
          </div>
        </div>

        {/* <div>
 

<div className='rounded-lg divide-x divide-gray-200 flex justify-center w-[50%] border border-[#1DB459]'>
  <div className='px-4 py-3'>Education</div>
  <div className='px-4 py-3'>Document</div>
  <div className='px-4 py-3'>Prefrence</div>
</div>


          </div> */}

      </div>
    </div>
  )
}

export default AgentDetails