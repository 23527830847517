import React, { useState } from 'react'
import Navbar from '../Navbar'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import Footer from '../Footer';

function ElegibilityCriteria() {
    const [open, setOpen] = useState(0);

    const handleOpen = (value: any) => {
        setOpen(open === value ? 0 : value);
    };
    return (
        <>
            <Navbar />
            <section className='md:pt-20 pt-20'>
                <div className='bg-[#FBFBFB] sm:px-16 px-4 md:flex md:justify-center items-start pt-10 pb-8'>
                    <div className=' w-full mx-auto'>
                        <div className='md:pr-40 md:pl-40 mx-4 mt-10'>
                            <div>
                                <h1 className='text-center md:text-[42px] text-[20px] font-semibold'>Entry guidance for international qualifications</h1>
                                <p className='mt-3'>This page provides guidance on international entry requirements. This should be considered as the minimum level required to apply and does not act as a guarantee of admission. </p>
                                <p className='mt-3'>All qualifications should be awarded by recognised institutions and the University of Vaasa may assess the local standing of the institution when assessing applications. </p>
                                <p className='mt-3'>If your country and/or qualification is not listed, please feel free to contact us at <a href="mailto:admissions@afriproedu.com" className='text-[#1DB459] underline underline-offset-2'>admissions@afriproedu.com</a> we will be happy to help. </p>
                            </div>
                            <div className='mt-10'>
                                <Accordion open={open === 1} className="  mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(1)} className=' px-4 border border-white text-base font-medium text-white'>
                                        Algeria
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-8  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-8 mt-2'>
                                            <h3>Licence (4 year) / Diplome d'Inginieur d'Etat / Diplôme d'Etudes Supérieures</h3>

                                            <h3 className='flex-1 ml-10'>14/20</h3>
                                        </div>

                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 2} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(2)} className=' px-4 border border-white text-base font-medium text-white'>
                                        Argentina
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-8  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-8 mt-2'>
                                            <h3>Bachelors degree (typically four years)</h3>
                                            <h3 className=''>GPA 4.0/5.0,  70% or 7/10</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 3} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(3)} className=' px-4 border border-white text-base font-medium text-white'>
                                        Australia
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-8  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-8 mt-2'>
                                            <h3>Honours degree </h3>
                                            <h3 className=''>60 %</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-8 mt-2'>
                                            <h3>Ordinary degree </h3>
                                            <h3 className=''>70 %</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 4} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(4)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Azerbaijan
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-8  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-8 mt-2'>
                                            <h3>Bakalavr Diplomu & Diplomu (Specialist Diploma) </h3>
                                            <h3 className=''>GPA 4.0/5.0 or 70%</h3>
                                        </div>
                                        
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 5} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(5)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Bahrain
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-8  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-8 mt-2'>
                                            <h3>Bachelors degree  </h3>
                                            <h3 className=''>GPA 3.0/4.0</h3>
                                        </div>
                                        
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 6} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(6)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Bangladesh
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className='md:w-[550px]'>Applicants to MSc Technology programs: 5 year bachelors degree including 150 credits OR a 5 year bachelors degree in a professional field </h3>
                                            <h3 className=''>55% or GPA 2.75/4.0</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-4'>
                                            <h3 className='md:w-[550px]'>Applicants to MSc Economics and Business Administration programs: 5 year bachelors degree including 150 credits OR a 4 year bachelor degree from a highly ranked/recognised University  </h3>
                                            <h3 className=''>55% or GPA 2.75/4.0</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-4'>
                                            <h3 className='md:w-[550px]'>Alternatively, a two year full time masters following a three year bachelor OR  a one or two year full time masters following a four year bachelor </h3>
                                            <h3 className=''>55% or GPA 2.75/4.0 in both the bachelor and masters</h3>
                                        </div>
                                        
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 7} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(7)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Botswana
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Five year bachelors degree</h3>
                                            <h3 className='md:mr-10'>70 %</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 8} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(8)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Brazil
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bacharel/Licenciado (four years of study)</h3>
                                            <h3 className='md:mr-10'>7.5/10</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 9} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(9)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Brunei
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree (typically four years)</h3>
                                            <h3 className='md:mr-10'>Upper Second or GPA 3.2/4.0</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 10} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(10)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Cambodia
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Masters degree </h3>
                                            <h3 className='md:mr-20'>GPA 3.25/4.0 </h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 11} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(11)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Cameroon
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree or Licence</h3>
                                            <h3 className='md:mr-20'>2:1 / 14 or Bien (GPA 3.4) </h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 12} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(12)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Canada
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree </h3>
                                            <h3 className='md:mr-20'>GPA of 3.0/4.0, or 6.5/9.0, 8.0/12.0, or B+, or 75% </h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 13} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(13)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Chile
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Licenciatura</h3>
                                            <h3 className='md:mr-20'>5.5 (or 5.0 from high standing institutions) </h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 14} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(14)} className=' px-4 border border-white text-base font-medium text-white'>
                                    China
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Four year bachelors degree</h3>
                                            <h3 className='md:mr-20'>70 %</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 15} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(15)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Colombia
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''> Licenciatura or Título</h3>
                                            <h3 className='md:mr-20'>GPA 3.75/5.0</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 16} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(16)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Ecuador
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree (Licenciatura)</h3>
                                            <h3 className='md:mr-20'>8.0/10 (80%), or 14/20</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 17} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(17)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Egypt
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree</h3>
                                            <h3 className='md:mr-20'>GPA 3.0/4.0 or 75% or B</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 18} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(18)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Ethiopia
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Masters degree</h3>
                                            <h3 className='md:mr-20'>GPA 3.5/4.0</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 19} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(19)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Ghana
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree</h3>
                                            <h3 className='md:mr-20'>Upper Second or 60%</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 20} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(20)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Hong Kong
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Four year bachelors degree</h3>
                                            <h3 className='md:mr-20'>GPA 3/4 or GPA 3.2/4.3 or GPA 3.5/4.5</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 21} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(21)} className=' px-4 border border-white text-base font-medium text-white'>
                                    India
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree</h3>
                                            <h3 className='md:mr-20'>First 60%</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>2 year bachelor degree plus a 2 year masters</h3>
                                            <h3 className='md:mr-20'>60% in bachelors and 55% in masters </h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 22} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(22)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Indonesia
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelor’s degree (also known as Sarjana I or S1) of four years duration</h3>
                                            <h3 className='md:mr-20'>GPA 3.0/4.0</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 23} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(23)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Iran
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree (typically four years)</h3>
                                            <h3 className='md:mr-20'>15/20</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 24} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(24)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Iraq
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree (typically four years)</h3>
                                            <h3 className='md:mr-20'>75 %</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 25} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(25)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Japan
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelor's degree (Gakushi)</h3>
                                            <h3 className='md:mr-20'>75% or B or GPA 3.0/4,0</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 26} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(26)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Jordan  (GPA 4.0 scale)
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree</h3>
                                            <h3 className='md:mr-20'>GPA 3.0/4.0 or 70%</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 27} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(27)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Kazakhstan
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bakalavr Degree or Diplom Specialista</h3>
                                            <h3 className='md:mr-20'>GPA 4.0/5.0 or 3.5/4.33 or 3.0/4.0</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 28} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(28)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Kenya
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree</h3>
                                            <h3 className='md:mr-20'>Upper Second or 60% or B</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 29} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(29)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Lebanon (American) 
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Four year bachelors degree</h3>
                                            <h3 className='md:mr-20'>80% or GPA 3.2/4.0</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 30} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(30)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Lebanon (French)
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Licen​​ce</h3>
                                            <h3 className='md:mr-20'>13/20</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 31} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(31)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Lebanon American University of Beirut
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree (typically four years)</h3>
                                            <h3 className='md:mr-20'>GPA 3.2/4.0</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 33} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(33)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Macau
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Licentiatura</h3>
                                            <h3 className='md:mr-20'> 2:1 or GPA 3.0/4.0</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 34} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(34)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Malaysia
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree</h3>
                                            <h3 className='md:mr-20'> 2:1 or GPA 3.0/4.0</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 35} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(35)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Mexico
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Licenciatura or Título</h3>
                                            <h3 className='md:mr-20'>8/10 or 80%</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 36} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(36)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Mongolia
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelor Degree or Specialist Diploma</h3>
                                            <h3 className='md:mr-20'>80% or GPA 3.2</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 37} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(37)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Morocco
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelor/Licence</h3>
                                            <h3 className='md:mr-20'>15/20</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 38} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(38)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Myanmar
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degrees with at least five years in professional subjects</h3>
                                            <h3 className='md:mr-20'>GPA 4.5/5.0</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Masters degrees</h3>
                                            <h3 className='md:mr-20'>GPA 4.0/5.0</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 39} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(39)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Nepal
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>4 year bachelor degree</h3>
                                            <h3 className='md:mr-20'>65 %</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Minimum 2 years bachelor plus 2 year masters</h3>
                                            <h3 className='md:mr-20'>65% in bachelors and 65% in masters </h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 40} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(40)} className=' px-4 border border-white text-base font-medium text-white'>
                                    New Zealand
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Four year bachelors degree with honours</h3>
                                            <h3 className='md:mr-20'>Second Class Division 1 or an overall mark of B+</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Three year ordinary bachelors degree</h3>
                                            <h3 className='md:mr-20'>A-</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 41} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(41)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Nigeria
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'> Scale</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                        <h3 className=''>7 point Scale</h3>
                                            <h3 className=''>Bachelors degree (typically four years)</h3>
                                            <h3 className='md:mr-20'>GPA 4.6/7.0 or Second Class Upper</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                        <h3 className=''>5 point Scale</h3>
                                            <h3 className=''>Bachelors degree (typically four years)</h3>
                                            <h3 className='md:mr-20'>GPA 3.5/5.0 or Second Class Upper</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                        <h3 className=''>4 point Scale</h3>
                                            <h3 className=''>Bachelors degree (typically four years)</h3>
                                            <h3 className='md:mr-20'>GPA 3.0/4.0 or Second Class Upper</h3>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 42} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(42)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Oman
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree</h3>
                                            <h3 className='md:mr-20'>GPA 3.0/4.0 or 70% or GPA 3.5/4.0 (private universities)</h3>
                                        </div>
                                        
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 43} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(43)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Pakistan
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Four year bachelors degree</h3>
                                            <h3 className='md:mr-20'>First Division or 65% </h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className='md:w-[400px]'>2 year bachelor and 2 year masters, or 3 year bachelors and 1 year masters (from selected universities)</h3>
                                            <h3 className='md:mr-20'>First Division or 65% in bachelors and 55% in masters </h3>
                                        </div>
                                        
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 44} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(44)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Peru
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className='md:w-[500px]'>Bachiller (Academic Bachiller from a University - minimum 3 years) - Bachelor Ordinary degree standard (only high grades acceptable)</h3>
                                            <h3 className='md:mr-20'>15/20 </h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-4'>
                                            <h3 className=''>Licenciatura/Licenciado or a professional title (4-5 years) - Bachelor degree standard</h3>
                                            <h3 className='md:mr-20'>13/20 </h3>
                                        </div>
                                        
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 45} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(45)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Russia
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bakalavr Degree or Diplom Specialista</h3>
                                            <h3 className='md:mr-20'>GPA 4.0/5.0</h3>
                                        </div>       
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 46} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(46)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Rwanda
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Maitrise or Bachelor (Honours) degree</h3>
                                            <h3 className='md:mr-20'>2:1, 14/20, (70%)</h3>
                                        </div>       
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 47} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(47)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Saudi Arabia
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                        <h3 className='text-[#170F49] font-medium md:text-base text-sm'> Scale</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                        <h3 className=''>GPA 4.0 scale</h3>
                                            <h3 className=''>Four year bachelors degree</h3>
                                            <h3 className='md:mr-20'>GPA 3.0/4.0</h3>
                                        </div> 
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                        <h3 className=''>GPA 5.0 scale	</h3>
                                            <h3 className=''>Four year bachelors degree</h3>
                                            <h3 className='md:mr-20'>GPA 3.75/5.0</h3>
                                        </div>       
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 48} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(48)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Singapore
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Four year bachelors degree</h3>
                                            <h3 className='md:mr-20'>GPA 4.0/5.0</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Three years bachelor degree</h3>
                                            <h3 className='md:mr-20'>GPA 4.4/5.0</h3>
                                        </div>       
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 49} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(49)} className=' px-4 border border-white text-base font-medium text-white'>
                                    South Africa
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree</h3>
                                            <h3 className='md:mr-20'>2.1 / 70%</h3>
                                        </div>      
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 50} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(50)} className=' px-4 border border-white text-base font-medium text-white'>
                                    South Korea
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                        <h3 className='text-[#170F49] font-medium md:text-base text-sm'> GPA</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                        <h3 className=''>GPA out of 4.5</h3>
                                            <h3 className=''>Bachelor's degree (Haksa)</h3>
                                            <h3 className='md:mr-20'>GPA 3.2/4.5</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                        <h3 className=''>GPA out of 4.3</h3>
                                            <h3 className=''>Bachelor's degree (Haksa)</h3>
                                            <h3 className='md:mr-20'>GPA 3.1/4.3</h3>
                                        </div> 
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                        <h3 className=''>GPA out of 4.0</h3>
                                            <h3 className=''>Bachelor's degree (Haksa)</h3>
                                            <h3 className='md:mr-20'>GPA 3.0/4.0</h3>
                                        </div>      
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 51} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(51)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Sri Lanka
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>General Bachelor Degree (3 Years) or Special/Honours Bachelor Degree (4 years)</h3>
                                            <h3 className='md:mr-20'>60% or B</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 52} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(52)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Taiwan
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Four year bachelors degree</h3>
                                            <h3 className=''>75% (national universities) 78% (prviate institutions)</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 53} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(53)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Tanzania
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree</h3>
                                            <h3 className='md:mr-20'>2:1</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 54} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(54)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Thailand
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Four year bachelor degree</h3>
                                            <h3 className='md:mr-20'>GPA 3.0/4.0</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 55} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(55)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Turkey
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Turkish  Lisans Diplomasi  </h3>
                                            <h3 className='md:mr-20'>GPA 3.0/4.0</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 56} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(56)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Uganda
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree</h3>
                                            <h3 className='md:mr-20'>2:1</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 57} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(57)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Ukraine
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Specialist diploma or bachelors degree </h3>
                                            <h3 className='md:mr-20'>8/10 or GPA 4.0/5.0</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 58} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(58)} className=' px-4 border border-white text-base font-medium text-white'>
                                    United Arab Emirates
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree</h3>
                                            <h3 className='md:mr-20'>GPA 3.0/4.0 or GPA 3.75/5.0 or 80% or B</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 59} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(59)} className=' px-4 border border-white text-base font-medium text-white'>
                                    United Kingdom
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Bachelors degree</h3>
                                            <h3 className='md:mr-20'>2:1</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 60} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(60)} className=' px-4 border border-white text-base font-medium text-white'>
                                    United States of America
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Four year bachelor degree</h3>
                                            <h3 className='md:mr-20'>GPA 3.2/4.0 or B+</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 61} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(61)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Uzbekistan
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Applicants should hold a specialist diploma or bachelor's degree (bakalavr)</h3>
                                            <h3 className='md:mr-20'>80% or GPA 4.0</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 62} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(62)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Venezuela
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Licenciado (Bachelor) or Ingeniero (Engineer)</h3>
                                            <h3 className='md:mr-20'>14/20 or 7/9</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 63} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(63)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Vietnam
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Four-year bachelor degree (Bằng Tốt Nghiệp Đại Học)	</h3>
                                            <h3 className='md:mr-20'>7/10 or GPA 3.0/4.0</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 64} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(64)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Zambia
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Masters degree	</h3>
                                            <h3 className='md:mr-20'>B+ or GPA 3.5/5.0 or 70%</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 65} className=" mb-[2px] bg-[#05401C] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(65)} className=' px-4 border border-white text-base font-medium text-white'>
                                    Zimbabwe
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='bg-[#F6FBFA] flex justify-between md:mx-3  py-3 md:px-4 px-1'>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Qualification required to apply</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Minimum academic grading required to apply</h3>
                                        </div>
                                        <div className='flex justify-between md:mx-3 mt-2'>
                                            <h3 className=''>Four year bachelors degree 	</h3>
                                            <h3 className='md:mr-20'>2:1 or 65%</h3>
                                        </div>
                                              
                                    </AccordionBody>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default ElegibilityCriteria