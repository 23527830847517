import { AxiosGlobal } from "../shared/axios";
import { AxiosPromise } from "axios";
import configs from "../../configs";
import { store } from "../../Components/store/store";

export class DashboardLiveApi extends AxiosGlobal {

    loginUser(data: any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/${configs.apiList.LOGIN}`, data);
    }

    getUserDetails(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/dashboard/get-user-details`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getCallback(reference: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/callback?reference=${reference}`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }  

    verifyResidentPayment(reference: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/verify-resident-payment?reference=${reference}`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }  


    getWalletDetails(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/get-wallet-details`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    addWishlist(data:any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/dashboard/add-wishlist`,data, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getWishlist(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/dashboard/get-wishlist`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getLearningDetails(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/dashboard/get-learning-details`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    updateUser(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/dashboard/update-user`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    dependantInfo(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/dashboard/create-dependant-info`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    updateDocuments(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/dashboard/update-document`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }


    makePayment(amount: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/payment/make-payment`, amount, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 


    makeResidentPayment(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/payment/make-resident-payment`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    payForCourse(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/payment/pay-for-course`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    deleteWishlist(id: any): AxiosPromise<Array<any>> {
        return this.axios.delete(`${configs.context}/dashboard/delete-wishlist/${id}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    //  loginUser(data:any): AxiosPromise<any> {
    //      return this.axios.post(`${configs.context}/login`, data,{
    //         headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":'Bearer 2|X4dvppS3EugstZmfvKwPbf4jBF7Y70OYvKGjdFnQ',"Access-Control-Allow-Origin":"*" },
    //       });
    // }


}