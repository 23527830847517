import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'

function TermsAndCondition() {
  return (
    <>
    <Navbar/>

{/* <section className="hidden bg-[#ffffff] md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-2xl   flex-wrap items-center justify-between relative md:w-72 
 z-10 py-4 px-6 pt-20">
  <div className='pt-20 lg:pl-6 pb-10'>
    <div>
    <h3 className='text-[#1DB459] font-medium text-base cursor-pointer'>1. Introduction</h3>
    </div>
    <div className='mt-4'>
    <h3 className='text-[#1DB459] font-medium text-base cursor-pointer'>2. Program Description</h3>
    </div>
    <div className='mt-4'>
    <h3 className='text-[#1DB459] font-medium cursor-pointer'>3. Language Learning</h3>
    </div>
    <div className='mt-4'>
    <h3 className='text-[#1DB459] font-medium cursor-pointer'>4. Payment</h3>
    </div>
    <div className='mt-4'>
    <h3 className='text-[#1DB459] font-medium cursor-pointer'>5. Loan</h3>
    </div>
    <div className='mt-4'>
    <h3 className='text-[#1DB459] font-medium cursor-pointer'>6. Documents for Visa Application</h3>
    </div>
    <div className='mt-4'>
    <h3 className='text-[#1DB459] font-medium cursor-pointer'>7. Job Placement</h3>
    </div>
    <div className='mt-4'>
    <h3 className='text-[#1DB459] font-medium cursor-pointer'>8. Limitation of Liability</h3>
    </div>
    <div className='mt-4'>
    <h3 className='text-[#1DB459] font-medium cursor-pointer'>9. Force Majeure</h3>
    </div>
    <div className='mt-4'>
    <h3 className='text-[#1DB459] font-medium cursor-pointer'>10. Indemnification</h3>
    </div>
    <div className='mt-4'>
    <h3 className='text-[#1DB459] font-medium cursor-pointer'>11. No Guarantee</h3>
    </div>
    <div className='mt-4'>
    <h3 className='text-[#1DB459] font-medium cursor-pointer'>12. Disclaimer</h3>
    </div>
    <div className='mt-4'>
    <h3 className='text-[#1DB459] font-medium cursor-pointer'>13. Governing Law and Jurisdiction</h3>
    </div>
  </div>
</section> md:ml-64 */}

<section className='relative mx-auto lg:pt-20 pt-10'>
  <div className=' md:pt-16 pt-12 pb-8 text-center px-8'>
    <h1 className='text-black md:text-4xl text-2xl font-bold'>Terms and Conditions </h1>
    <p className='text-black text-sm mt-2 hidden md:block'>By using AfriProEdu's Finnish Language Program online,<br/> you agree to the following terms and conditions: </p>
    <p className='text-black text-sm mt-2 md:hidden block'>By using AfriProEdu's Finnish Language Program online, you agree to the following terms and conditions: </p>
  </div>
  <div className='mt-5 lg:pl-64 lg:pr-64 px-8 pb-10'>
<div>
  <h3 className='text-black font-bold'>1. Introduction</h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'>AfriProEdu is an educational consulting service providing an online Finnish language program to African students to learn Finnish language for 8 months and to have access to study in Finland for free. This agreement outlines the terms and conditions of using AfriProEdu's services. </p>
</div>

<div className='mt-4'>
  <h3 className='text-black font-bold'>2. Program Description</h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'>AfriProEdu's Finnish Language Program provides access to secondary education schools in Finland, for 
    {/* both High School Program and  */}
    Vocational School Program. After completing the program, students can apply to partner schools in Finland, and take an entrance exam to study for free. The program also offers vocational studies that allow students to work in their field after one year of study during apprenticeship in Finland.  </p>
</div>

<div className='mt-4'>
  <h3 className='text-black font-bold'>3. Language Learning</h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'> The language learning is 100% online and requires a laptop with internet access. Learning takes place every day from Monday to Friday for one hour and 15 minutes daily. Assessments are done every two months to test language skills and progress. </p>
</div>

<div className='mt-4'>
  <h3 className='text-black font-bold'>4. Payment</h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'> The service charge for learning the language, getting certificates, admissions to partner schools, support, documentation processing, and visa processing is 5500€. The fees can be paid in installment or in full. All payments are made in Euro. </p>
</div>

<div className='mt-4'>
  <h3 className='text-black font-bold'>5. Loan</h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'>Applicants who cannot pay the service charge in installments can apply for a loan. The loan is only available for Nigerians who are working-class, or have one or more guarantors who are working-class, to cover the service charge fees.  </p>
</div>

<div className='mt-4'>
  <h3 className='text-black font-bold'>6. Documents for Visa Application</h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'>To apply for a visa, applicants need an acceptance letter from school, proof of funds (6720€), a health insurance certificate (300€), a notarized copy of the school leaving certificate, and a notarized copy of the birth certificate.  </p>
</div>

<div className='mt-4'>
  <h3 className='text-black font-bold'>7. Job Placement</h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'> AfriProEdu supports students in getting jobs in Finland. Students can work between 25-30 hours per week, with a monthly salary of 1000€ - 1500€.  </p>
</div>
 

<div className='mt-4'>
  <h3 className='text-black font-bold'>8.  Limitation of Liability: </h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'>AfriProEdu shall not be liable to the student or any third party for any indirect, incidental, special, consequential or punitive damages, including without limitation, lost profits, lost data, Terms of Use 1 or other intangible losses, arising out of or in connection with the Finnish language program, the partner school admissions, the visa processing, or any other related services. AfriProEdu's total liability in connection with the Finnish language program, the partner school admissions, the visa processing, or any other related services, shall not exceed the total amount paid by the student to AfriProEdu. </p>
</div>

<div className='mt-4'>
  <h3 className='text-black font-bold'>9. Force Majeure: </h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'>AfriProEdu shall not be liable for any failure or delay in the performance of its obligations under these Terms and Conditions due to any event or circumstance beyond its reasonable control, including without limitation, acts of God, war, riot, terrorism, fire, flood, earthquake, or other natural disaster, governmental action, labor dispute, or failure of suppliers or subcontractors.  </p>
</div>

<div className='mt-4'>
  <h3 className='text-black font-bold'>10. Indemnification:  </h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'>The student agrees to indemnify and hold AfriProEdu harmless from any and all claims, damages, losses, liabilities, costs and expenses (including reasonable attorneys' fees) arising out of or in connection with the student's participation in the Finnish language program, the partner school admissions, the visa processing, or any other related services.   </p>
</div>

<div className='mt-4'>
  <h3 className='text-black font-bold'>11. No Guarantee:   </h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'>AfriProEdu does not guarantee that the student will be accepted to any partner school or that the student will be granted a visa to Finland. The decision to accept a student to a partner school or to grant a visa is at the discretion of the partner school or the Finnish authorities, respectively.    </p>
</div>

<div className='mt-4'>
  <h3 className='text-black font-bold'>12. Disclaimer:</h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'>The Finnish language program and all related services are provided "as is" without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose. AfriProEdu does not warrant that the Finnish language program or any related services will meet the student's requirements, that the operation of the Finnish language program or any related services will be uninterrupted or error-free, or that any defects in the Finnish language program or any related services will be corrected. </p>
</div>

<div className='mt-4'>
  <h3 className='text-black font-bold'>13. Governing Law and Jurisdiction:    </h3>
  <p className='text-[#8C8C8C] text-sm font-normal mt-3'>These Terms and Conditions shall be governed by and construed in accordance with the laws of Finland. Any dispute arising out of or in connection with these Terms and Conditions shall be submitted to the exclusive jurisdiction of the courts of Finland.     </p>
</div>
  </div>
</section>
    
<section>
<Footer />
</section>

    
    
</>
  )
}

export default TermsAndCondition