import React from 'react'
import { NavLink } from "react-router-dom";
import Sidebar from '../../Sidebar/Sidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import CardSettings from '../../ReuseableComp/CardSettings';
import ProfileNav from '../../ReuseableComp/ProfileNav';
import { useSelector } from "react-redux";
import Dashboard from './Dashboard';
import EnglishDashboard from './EnglishDashboard';

function DashType() {

  const userLoginData = useSelector((state: any) => state.data.login.value);


  return (
    <>
      {userLoginData?.data?.user_data?.programs_type == 'study in finnish' 
        ?
        <Dashboard />
        :
        <EnglishDashboard />
      }

    </>
  )
}

export default DashType