import React from 'react'
import { SvgElement, icontypesEnum } from "../../assets/svgElement";
// @ts-ignore
import { PhoneInput } from "react-contact-number-input";
import { useSelector } from "react-redux";
function Profile() {

  const userLoginData = useSelector((state:any) => state?.data?.login?.value);
  return (
    <div>
      <div className='flex justify-center items-center mt-16 pb-8 mx-6'>
        <div className='lg:w-[697px] bg-white rounded-[20px] px-[26px] py-[29px] relative'>
          <div className="flex justify-center">
            <SvgElement type={icontypesEnum.DEFAULTIMAGE} />
          </div>
          <div className='lg:px-[100px]'>
            <form>

              <div className="relative w-full mb-4 mt-4">
                <label className="block mb-2 text-sm text-[#000000]">
                  First Name
                </label>
                <input
                  type="text"
                  className="border border-[#DADADA] text-[#333333] text-[12px] rounded-[5px] block w-full p-2 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                  placeholder=""
                  defaultValue={userLoginData?.name}
                  name="f_name"
                  required
                />
              </div>
              <div className="relative w-full mb-4 mt-4">
                <label className="block mb-2 text-sm  text-[#000000]">
                  Last Name
                </label>
                <input
                  type="text"
                  className="border border-[#DADADA] text-[#333333] text-[12px] rounded-[5px] block w-full p-2 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                  placeholder=""
                  defaultValue={userLoginData?.name}
                  name="f_name"
                  required
                />
              </div>
              <div className="relative w-full mb-4 mt-4">
                <label className="block mb-2 text-sm  text-[#000000]">
                  Email
                </label>
                <input
                  type="email"
                  className="border border-[#DADADA] text-[#333333] text-[12px] rounded-[5px] block w-full p-2 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                  placeholder=""
                  defaultValue={userLoginData?.email}
                  name="f_name"
                  required
                />
              </div>
              <div className="relative mb-4">
                <label className="block mb-2 text-sm  text-[#000000]">
                  Phone No
                </label>
                <PhoneInput
                  style={{ backgroundColor: '#F5F5F5', width: "80%" }}
                  disabled={false}
                  defaultValue={userLoginData?.data?.user_data?.phone_number}
                  className={"p-2 mr-4"}
                  containerClass={"border border-[#D9D9D9] text-[#333333] text-sm rounded-lg block w-full p-1 "}
                  countryCode={'ng'}


                />

              </div>
              <div className="relative w-full mb-4 mt-4">
                <label className="block mb-2 text-sm  text-[#000000]">
                  Address
                </label>
                <input
                  type="text"
                  className="border border-[#DADADA] text-[#333333] text-[12px] rounded-[5px] block w-full p-2 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                  placeholder=""
                  name="f_name"
                  defaultValue={userLoginData?.data?.user_data?.address}
                  required
                />
              </div>
              <div className="relative w-full mb-4 mt-4">
                <label className="block mb-2 text-sm  text-[#000000]">
                  Refferal Code
                </label>
                <input
                  type="text"
                  className="border border-[#DADADA] text-[#333333] text-[12px] rounded-[5px] block w-full p-2 placeholder-[#5A6474]  ease-linear transition-all duration-150"
                  placeholder=""
                  defaultValue={userLoginData?.data?.user_data?.referral_id}
                  name="f_name"
                  required
                />
              </div>

              <div className='flex justify-center'>
                <button type='button' className='bg-[#00B07B] text-white w-full text-center text-[12px] py-[10px] rounded-[5px]'>Save Changes</button>
              </div>


            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile