import React from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar';
import UserNavbar from '../../Navbars/UserNavbar'
import { SvgElement, icontypesEnum } from '../../assets/svgElement'
import { Link } from 'react-router-dom';
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
function ViewRegUser() {
    return (
        <>
            <AdminSidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar />



                <div className="relative overflow-x-auto shadow-md rounded-lg border border-[#D9D9D9] mx-6 lg:mt-16 lg:mx-10 mt-5">
                    <div className="grid grid-cols-1 md:grid-cols-2  px-8 py-6">

                        <div className='md:mt-8'>

                            <span className='bg-[#1DB459] text-white md:text-[14px] text-[12px] font-semibold  mt-[10px] pl-3 pr-12 py-3'>Personal Information</span>
                            <div className='mt-[30px]'>
                                <div>
                                    <h3 className='text-[16px] font-[500]'>First Name</h3>
                                    <h4 className='text-[#838383] text-[15px]'>Samuel</h4>
                                </div>
                                <div className='mt-[25px]'>
                                    <h3 className='text-[16px] font-[500]'>Last Name</h3>
                                    <h4 className='text-[#838383] text-[15px]'>Omolade</h4>
                                </div>
                                <div className='mt-[25px]'>
                                    <h3 className='text-[16px] font-[500]'>Email</h3>
                                    <h4 className='text-[#838383] text-[15px]'>omoladesamuel@gmail.com</h4>
                                </div>
                                <div className='mt-[25px]'>
                                    <h3 className='text-[16px] font-[500]'>Phone Number</h3>
                                    <h4 className='text-[#838383] text-[15px]'>07065078544</h4>
                                </div>
                                <div className='mt-[25px]'>
                                    <h3 className='text-[16px] font-[500]'>Status</h3>
                                    <span className=" font-normal text-white bg-[#1DB459] px-3 py-1 rounded-sm">Paid</span>
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:block absolute left-1/2 -ml-0.5 w-[7px] rounded h-[700px] bg-[#1DB459]"></div>
                        <div className=" mr-auto md:ml-10 flex-start mr-3 md:mt-8 mt-8">
                            <span className='bg-[#1DB459] text-white md:text-[14px] text-[12px] font-semibold  mt-[10px] pl-3 pr-12 py-3'>Exam Information</span>
                            <div className='mt-[30px]'>
                            <div className=''>
                                    <h3 className='text-[16px] font-[500]'>Are you 18 years and above?</h3>
                                    <h4 className='text-[#838383] text-[15px]'>Yes</h4>
                                </div>
                                <div className='mt-[25px]'>
                                    <h3 className='text-[16px] font-[500]'>What is your academic background?</h3>
                                    <h4 className='text-[#838383] text-[15px]'>University</h4>
                                </div>
                                <div className='mt-[25px]'>
                                    <h3 className='text-[16px] font-[500]'>Are you aware that the Nursing program is Tuition based and you are to pay a fee of €9000?</h3>
                                    <h4 className='text-[#838383] text-[15px]'>Yes</h4>
                                </div>
                                <div className='mt-[25px]'>
                                    <h3 className='text-[16px] font-[500]'>Who is paying the tuition fee of €9000 ?</h3>
                                    <h4 className='text-[#838383] text-[15px]'>Parents</h4>
                                </div>
                                <div className='mt-[25px]'>
                                    <h3 className='text-[16px] font-[500]'>Do you have a Valid Passport expiring in a minimum of one year?</h3>
                                    <h4 className='text-[#838383] text-[15px]'>Yes</h4>
                                </div>
                                <div className='mt-[25px]'>
                                    <h3 className='text-[16px] font-[500]'>The application process requires a service fee of $100, which covers visa processing, school application, and documentation. <br/>This is a nonrefundable fee to book your slot. Are you willing to proceed ?</h3>
                                    <h4 className='text-[#838383] text-[15px]'>Yes</h4>
                                </div>
                                <div className='mt-[25px]'>
                                    <h3 className='text-[16px] font-[500]'>Comment</h3>
                                    <h4 className='text-[#838383] text-[15px]'>Afriproedu</h4>
                                </div>
                            </div>
                            
                        </div>



                    </div>

                </div>

            </div>
        </>
    )
}

export default ViewRegUser;