import React from 'react'
import Navbar from '../Navbar'
function LUTPaymentSuccessful() {
  return (
    <>
    <Navbar />
    {/* Start Section */}
    <section className='body-font font-poppins md:pt-20 relative'>
        <div

            className='pt-20 md:pt-0  flex justify-center items-center'
        >
            <div>
                <img src="/images/degree-program.png" alt="degree-design" className='border-[#1DB459] border-b-8' />
            </div>

        </div>
    </section>
    {/* End Section */}

    {/* Start Section */}
    <div className='bg-[#FBFBFB] sm:px-16 px-6 md:flex md:justify-center items-start pt-10 pb-10' >
        <div className='xl:max-w-[1200px] w-full mx-auto'>
            <div className=''>
                <h3 className='text-[#1DB459] md:text-[36px] text-[28px] font-bold text-center'>Payment Successful </h3>
                <p className='text-center md:text-[16px] text-[14px] mt-3'>Your payment has been confirmed, please reach out to our customer<br/> care line at +234 813 1470 992 to proceed with your registration.</p>
                {/* <h3 className='text-[#1DB459] md:text-[24px] text-[20px] font-bold text-center mt-4'>Note</h3>
                <p className='text-center md:text-[16px] text-[14px]'>Your code will be sent to your mail shortly</p> */}

            </div>
        </div>
    </div>
    
    {/* End Section */}
</>
  )
}

export default LUTPaymentSuccessful