import React, { Fragment, useState } from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
  } from "@material-tailwind/react";




function AsapEduGoFaq() {
    const [open, setOpen] = useState(1);
    const [openTestTab, setOpenTestTab] = useState(1);
  
    const handleOpen = (value: any) => {
      setOpen(open === value ? 0 : value);
    };

    return (
        <>

      {/* <!-- Start block --> */}
      <section className="bg-[#FFFFFF] body-font font-poppins pt-10">
        <div className="mx-auto flex justify-center text-center">
          <p className="hidden md:block max-w-lg text-[#000000] md:text-3xl px-6 font-semibold text-center">
            Frequently Asked <span className="text-[#48B774]">Questions on ASAPEDUGO</span>
          </p>
          <p className="block md:hidden text-[#000000] text-2xl px-6 font-semibold text-center">
            Frequently Asked <span className="text-[#48B774]"><br/>Questions on ASAPEDUGO</span>
          </p>
        </div>
        
        <div className="flex justify-center max-w-screen-xl  px-8 lg:px-14 pt-5 md:pt-16 pb-8 mx-auto  ">
       


          <div className=" lg:mb-20 max-w-3xl">


            {/* <div className={openTestTab === 1 ? "block" : "hidden"}> */}
              <Fragment>
                <Accordion open={open === 1} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(1)} className='border border-white text-base font-medium text-[#170F49]'>
                  What is ASAPEDUGO?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  ASAPEDUGO is an intensive 6-month study program designed to fast track your proficiency in the Finnish language. It includes 2 months of online preparation followed by 4 months of onsite learning at a boarding location in Finland.
                  </AccordionBody>
                </Accordion>

                <Accordion open={open === 2} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(2)} className='border border-white text-base font-medium text-[#170F49]'>
                  How does ASAPEDUGO work?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  ASAPEDUGO begins with 2 months of online preparation, where participants engage in interactive lessons and activities to build a solid foundation in Finnish. This is followed by 4 months of immersive onsite learning in Finland, where participants continue to develop their language skills in a real-life setting.
                  </AccordionBody>
                </Accordion>

                <Accordion open={open === 3} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(3)} className='border border-white text-base font-medium text-[#170F49]'>
                  What are the benefits of ASAPEDUGO?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <ol className="space-y-1 list-disc list-inside">
                      <li>Accelerated learning: Fast track your Finnish language proficiency in just 6 months.</li>
                      <li>Immersive experience: Spend 4 months living and learning in Finland, surrounded by the language and culture.</li>
                      <li>Free education opportunities: Unlock the opportunity to live and study in Finland for free, from high school to advanced degrees.</li>
                      <li>Comprehensive preparation: Begin with online preparation sessions to ensure readiness for onsite learning in Finland.</li>
                   
                    </ol>         
                  </AccordionBody>
                  {/* <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                 <div className='font-semibold'>We have two programs:</div> 
                  <span className='font-semibold'>Short program:</span> you learn the Finnish language in your home country for two months before
travelling to Finland and then proceed to learning the Finnish language in Finland for four months .
This also covers the accommodation and lunch. After the four months program you will do inteview

with our various Finnish partners schools before getting accepted . Once you are accepted, you will
be issued the school acceptance letter which is one of the documents to process your residence
permit stay I Finland.
<div ><span className='font-semibold'>Long program:</span> you learn the Finnish language in your home country for 8- 10 months before
travelling to Finland. This process entails learning the Finnish language online with our teachers and
other students across the world. It is 100% online ( virtual class ) . Once you complete your 8th
Months program, you proceed to applying for inteview with our partner schools. Once you get
accepted, you will be issued with the acceptance letter which is among the documents needed to
process your residence permit to Finland.</div>
                  </AccordionBody> */}
                </Accordion>

                <Accordion open={open === 4} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(4)} className='border border-white text-base font-medium text-[#170F49]'>
                  Who can participate in ASAPEDUGO?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  ASAPEDUGO is open to individuals who are serious about learning Finnish and are willing to commit to an intensive study program. Participants should be prepared to dedicate time and effort to both the online and onsite components of the program.
                  </AccordionBody>
                </Accordion>

                <Accordion open={open === 5} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(5)} className='border border-white text-base font-medium text-[#170F49]'>
                  What is the duration of the ASAPEDUGO program?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  ASAPEDUGO spans over 6 months, with 2 months of online preparation followed by 4 months of onsite learning in Finland.
                  </AccordionBody>
                </Accordion>

                <Accordion open={open === 6} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(6)} className='border border-white text-base font-medium text-[#170F49]'>
                  How much does ASAPEDUGO cost?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  The total cost of ASAPEDUGO is 14,200€, plus a one-time processing fee of 100€. This pricing includes both the online and onsite components of the program.
                  <br />
                 
                  <div className='font-light'>The program consists of two phases:</div> 
                  <br />

                  <span className='font-semibold'>Phase 1: Online Preparation (2 months)</span>
                  <ol className="space-y-1 list-disc list-inside">
                      <li>Cost: 605€ per month for 2 months</li>
                      <li>During this phase, you'll immerse yourself in the Finnish language, mastering key concepts and building a solid foundation for further study.</li>
                      <li>Simultaneously, we'll assist you in preparing your residence permit application process, ensuring a smooth transition to life in Finland.</li>
                    </ol> 
                    <br />

                    <span className='font-semibold'>Phase 2: Onsite Learning in Finland (4 months)</span>
                  <ol className="space-y-1 list-disc list-inside">
                      <li>Cost: 3465€ per month for 4 months (to be paid in Finland).</li>
                      <li>Once your online preparation is complete, you'll spend four months in Finland, fully.</li>
                    </ol> 
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 7} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(7)} className='border border-white text-base font-medium text-[#170F49]'>
                  Is financial assistance available for ASAPEDUGO?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                  At this time, ASAPEDUGO does not offer financial assistance or scholarships. However, the program's comprehensive preparation and potential for free study opportunities in Finland make it an attractive option for many individuals.
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 8} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(8)} className='border border-white text-base font-medium text-[#170F49]'>
                  What are the onsite learning sessions like?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                  Onsite learning sessions for ASAPEDUGO are intensive, with participants engaging in 4-5 hours of language learning activities Monday to Friday. The sessions are designed to immerse participants in the Finnish language and culture, helping them develop their skills quickly and effectively.
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 9} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(9)} className='border border-white text-base font-medium text-[#170F49]'>
                  How can I enroll in ASAPEDUGO?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  To enroll in ASAPEDUGO, visit our website and complete the online registration form. Once registered, you will receive further instructions on how to begin your journey to Finnish fluency.
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 10} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(10)} className='border border-white text-base font-medium text-[#170F49]'>
                  What if I have more questions?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  If you have any additional questions or concerns about ASAPEDUGO, please feel free to reach out to our customer support team. We are here to assist you every step of the way on your language learning journey  
                  </AccordionBody>
                </Accordion>
              </Fragment>
            {/* </div> */}


        
          </div>
        </div>
      </section>
      
      {/* <!-- End block --> */}

        </>
    )
}

export default AsapEduGoFaq