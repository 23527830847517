import React from "react";
import PropTypes from "prop-types";

// components

// import TableDropdown from "components/Dropdowns/TableDropdown.js";

export default function EmptyAssignment(color: any) {
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 border border-gray-200 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-black")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">

          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <p className="mt-1 ml-2" style={{ fontWeight: 'bold' }}>My Assignment</p>

          <div className=" m-20">
            <div className="flex justify-center">
              <img src="/images/empty-assignment.png" alt="hero" />
            </div>

            <div className="flex justify-center mt-7">
              <p style={{ fontSize: '14px', fontWeight: 'bold', color: 'rgba(102, 102, 102, 0.8)' }}> No assignments Available</p>
            </div>

            <div className="flex justify-center mt-1">
              <p style={{ fontSize: '12px', fontWeight: 'bold', color: '#B3B3B3' }}> You do not have any assignment or activities to perform yet </p>
            </div>
          </div>


        </div>
      </div>
    </>
  );
}

EmptyAssignment.defaultProps = {
  color: "light",
};

EmptyAssignment.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
