import React from 'react'
import Footer from '../../Footer'
import Navbar from '../../Navbar'
import blogbg from "../../../img/blog.png";

function FinnishDegree() {
    return (
        <>

            <Navbar />

            {/* <!-- Start block --> */}
            <section className=" body-font font-poppins pt-16" >
                <div
                    style={{
                        backgroundImage: `url(${blogbg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className=" lg:px-14 pt-10 pb-24 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center text-white pt-20">
                        <h1 className="md:text-5xl text-3xl font-bold">
                            The power of a Finnish degree
                        </h1>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-[#FBFBFB] body-font font-poppins">
                <div className='bg-white lg:mx-28 pb-10 lg:px-10 px-8 pt-10'>
                    <div>
                        <div className='flex justify-center mt-2'>
                            <img src="/images/finnish-degree.jpeg" alt="location" className='md:w-[50%] w-full' />
                        </div>
                        <div className='flex justify-center mx-auto md:px-44'>
                            <p className='italic mt-1 text-[#333333]'>The power of a Finnish degree</p>
                        </div>

                    </div>
                    <h3 className='text-base text-[#000000] font-normal mt-6'>Finnish degrees from vocational schools are widely recognized worldwide due to the country's strong education system and emphasis on practical skills. These vocational degrees are often referred to as "ammattitutkinto" or "ylioppilastutkinto" depending on the level.</h3>
                    <h3 className='text-base text-[#000000] font-normal mt-6'>
                    Finnish vocational education focuses on a combination of theoretical knowledge and hands-on experience. Students gain practical skills that are directly applicable to their chosen fields. This approach is highly regarded in the global job market as it produces graduates who are well-prepared to tackle real-world challenges.
                    </h3>

                    <h3 className='text-base text-[#000000] font-normal mt-6'>
                    One key aspect that contributes to the recognition of Finnish vocational degrees is the close collaboration between educational institutions and industries. This ensures that the curriculum aligns with the needs of employers, making graduates highly employable and relevant in the workforce. Many Finnish vocational schools also offer opportunities for internships, work placements, and apprenticeships, further enhancing students' practical experience.

                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-6'>
                    Another factor is the strong quality assurance mechanisms in Finnish education. The government sets high standards for vocational education, and institutions are regularly evaluated to ensure they meet these standards. This quality assurance process adds credibility to Finnish degrees and makes them trustworthy in the global context.
                    </h3>
                    <h3 className='text-base text-[#000000] font-normal'>
                    Moreover, Finland's reputation for excellence in education plays a significant role. Finnish schools consistently perform well in international education rankings, which positively impacts the perception of Finnish degrees by employers and institutions worldwide.
                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-6'>
                    Overall, Finnish vocational degrees offer students a competitive advantage in the global job market due to their emphasis on practical skills, industry alignment, quality assurance, and the country's strong education reputation.
                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-6'>
                    Begin your academic journey to Finland. Send us a DM today.
                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-6'>
                    FOR MORE INFORMATION 
CALL/SMS/WHATSAPP: +2347088914669
                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-6'>
                    OR 
                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-6'>
                    SEND US AN EMAIL: hello@afriproedu.com
                    </h3>
                   
                </div>

            </section>
            {/* <!-- End block --> */}

            <Footer />
        </>
    )
}

export default FinnishDegree;