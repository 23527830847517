import React from 'react'
import Navbar from '../Navbar'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import Footer from '../Footer';
function LUTCriteria() {
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value: any) => {
        setOpen(open === value ? 0 : value);
    };
    return (
        <>
            <Navbar />
            <section className='md:pt-20 pt-20'>
                <div className='bg-[#FBFBFB] sm:px-16 px-4 md:flex md:justify-center items-start pt-10 pb-8'>
                    <div className=' w-full mx-auto'>
                        <div className='md:pr-40 md:pl-40 mx-4 '>
                            <div>
                                <h1 className='text-center md:text-[42px] text-[20px] font-semibold'>Admission Criteria for Bachelor's programs in Technology</h1>
                                
                            </div>
                            <div className='mt-10'>
                                <Accordion open={open === 1} className="  mb-[2px] bg-[#ffffff] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(1)} className=' px-4 border border-white text-[20px] font-medium text-black'>
                                        Eligibility requirements
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='pt-2  px-1'>
                                            <p>You need to meet with the special eligibility requirements when applying to Bachelor's programs through Rolling admission.</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>You are eligible to apply through Rolling admission if</h3>
                                            <ul className=' list-disc space-y-1 pl-4'>
                                                <li>you have completed an upper secondary degree outside of Finland</li>
                                                <li>you will complete an upper secondary degree outside of Finland by 31 August 2024</li>
                                            </ul>
                                            <p>that gives you the eligibility for corresponding university studies in your home country.</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>You are not eligible to apply through Rolling admission if:</h3>
                                            <ul className=' list-disc space-y-1 pl-4'>
                                                <li>you have completed or will complete an upper secondary degree in Finland</li>
                                                <li>you have completed or will complete European Baccalaureate, International Baccalaureate, Reifeprüfung or Deutsche Internationale Abitur (DIA)</li>
                                            </ul>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>Required grades</h3>
                                            <p className='mt-2'>The evaluation and the admission process is based on the educational documents for your upper secondary degree while university-level documents are not considered in the academic evaluation.</p>
                                            <p className='mt-2'>The required grades are as follows on a scale from 0% to 100%:</p>
                                            <ul className=' list-disc space-y-1 pl-4'>
                                                <li>Arithmetic grade average: 70%</li>
                                                <li>Advanced mathematics: 80%</li>
                                                <li>Natural sciences (Physics & Chemistry): 80%</li>
                                            </ul>

                                            <p className='mt-2'>There are no exact grades, scores, or a percentile to be met with as every application is evaluated individually.</p>
                                            <p className='mt-2'>In general, we wish to see good English language skills, and grades above average in advanced mathematics and science (physics or chemistry).</p>
                                            <p className='mt-2'>When applying to the Bachelor's degree programme in Software and Systems Engineering, the grade average of IT subjects is taken into consideration instead of the grades in Physics or Chemistry. If subjects such as Computer Science or Information Technology are not included in the educational documents, the evaluation will then be based on science grades.</p>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 2} className="  mb-[2px] bg-[#ffffff] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(2)} className=' px-4 border border-white text-[20px] font-medium text-black'>
                                        Required documents
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='pt-2  px-1'>
                                            <p>When filling in your online application form you must upload the required documents to your application.</p>
                                            <p className='mt-2'>If failing to upload the required documents by the time of submitting your application, you will have a strict two-week deadline to supplement your application - the rule also applies to language test results.</p>
                                            <p className='mt-2'>If the required documents are not supplemented by the deadline assigned to you, your application will be discarded.</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>Required documents</h3>
                                            <ul className=' list-disc space-y-1 pl-4'>
                                                <li>Upper secondary degree certificate</li>
                                                <li>Upper secondary transcript of records</li>
                                                <li>Upper secondary mathematics curriculum (if applicable)</li>
                                                <li>English language test certificate (if applicable)</li>
                                                <li>Copy of your passport (ID page)</li>
                                                <li>Copy of your Finnish residence permit card (if applicable)</li>
                                            </ul>

                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>If the degree certificate, transcript of records or curriculum are not in English, an official translation is required.</h3>
                                            <p className='mt-2'>If not using your higher education certificates as a proof for your English language skills, you should not upload any higher education documents to the application.</p>
                                            <p className='mt-2'>Please see the further information under When a language test in English is not required.</p>

                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 3} className="  mb-[2px] bg-[#ffffff] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(3)} className=' px-4 border border-white text-[20px] font-medium text-black'>
                                        Applying with Mathematics Curriculum
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='pt-2  px-1'>
                                            <p>The applicant needs to upload a copy for the Mathematics Curriculum for the upper secondary degree on the application form at the time of applying.
                                                Further information on this page at Required documents.</p>
                                            <p className='mt-2'>The following areas of advanced mathematics must be included in the Mathematics Curriculum for the upper secondary degree.</p>
                                            <p className='mt-2'>The applicant must have studied these areas of advanced mathematics during their upper secondary studies, and they must have obtained the necessary skills required for the LUT Bachelor's studies.</p>

                                            <ul className=' list-disc space-y-1 pl-4'>
                                                <li>Circular Measure</li>
                                                <li>Coordinate Geometry</li>
                                                <li>Differentiation</li>
                                                <li>Functions</li>
                                                <li>Complex Numbers</li>
                                                <li>Indices, Surd and Logarithms</li>
                                                <li>Integration</li>
                                                <li>Linear Law</li>
                                                <li>Permutation and Combination</li>
                                                <li>Probability Distributions</li>
                                                <li>Progressions</li>
                                                <li>Quadratic Functions
                                                    <li>Solution of Triangles</li>
                                                    <li>Systems of Equations</li>
                                                    <li>Trigonometric Functions</li>
                                                    <li>Vectors</li>
                                                </li>
                                            </ul>
                                            <p>Each application and the educational documents are evaluated individually and the admission committee for the programme reserves a right to reject an application if there are notable deficiencies in the applicant's advanced mathematics skills.</p>

                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>About the mathematics curriculum</h3>
                                            <p className='mt-2'>For us to receive a thorough understanding of your mathematical abilities, you must attach your official upper secondary mathematics curriculum to your application. The document must be an official one, meaning that it has been published by an institution, board or ministry, for example. </p>
                                            <p className='mt-2'>A curriculum contains a list of study objectives, courses and course contents; it is a study plan. A curriculum is not equivalent to the transcript of records. Please note that the curriculum must cover the complete duration of the upper secondary studies.</p>

                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 4} className="  mb-[2px] bg-[#ffffff] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(4)} className=' px-4 border border-white text-[20px] font-medium text-black'>
                                        Applying with a SAT Test
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='pt-2  px-1'>
                                            <p>Applicants with a relevant degree on the upper secondary level of education are all able to apply to Bachelor's programs in English with a SAT test (Suite of Assesments Test).</p>
                                            <p className='mt-2'>When applying with a SAT test, there is no additional requirement for proving your proficiency in English or your advanced mathematics skills in any other way.</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>LUT University's institutional code for SAT-Test is 7603</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>Requirements for the Completed Degree</h3>
                                            <ul className=' list-disc space-y-1 pl-4'>
                                                <li>A qualification on the upper secondary level of education providing you with eligibility to equivalent University-level Bachelor's studies in the country where the degree was completed in</li>

                                            </ul>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>Minimum requirements for the SAT test results</h3>
                                            <ul className=' list-disc space-y-1 pl-4'>
                                                <li>Evidence-Based Reading and Writing, 550</li>
                                                <li>Math, 650</li>
                                                <li>Only the tests taken after 1 January 2021 are accepted.</li>
                                            </ul>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>Verifying your SAT results</h3>
                                            <p className='mt-2'>LUT University only accepts your SAT test scores if an official score report is received directly from the test organizer College Board. When proving your English-language skills via SAT scores, you will need to submit the results via College Board.</p>
                                            <p className='mt-2'>To get your test results submitted to LUT University, please choose LUT University as a score recipient when registering for the SAT tests – the institutional code required is 7603.</p>
                                            <p className='mt-2'>If not having chosen LUT University as a score recipient while registering for the test or, applying with earlier test results and wish to make them available to LUT University, you need to order additional SAT Score Reports.</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>About the SAT Test</h3>
                                            <p className='mt-2'>The SAT Tests are assessing the students' readiness and ability to successfully enter and complete college studies.</p>
                                            <ul className=' list-disc space-y-1 pl-4'>
                                                <li>General information on the SAT Tests by CollegeBoard

                                                </li>
                                                <li>There are test centers all around the world - you can find the closest one for you at

                                                    Find a test center</li>
                                                <li>There are only a very limited number of tests available, please see the dates
                                                    SAT Test dates and registering for the tests
                                                </li>




                                            </ul>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 5} className="  mb-[2px] bg-[#ffffff] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(5)} className=' px-4 border border-white text-[20px] font-medium text-black'>
                                        Applying with Cambridge International A Levels
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='  px-1'>

                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm'>Required grades</h3>

                                            <ul className=' list-disc space-y-1 pl-4 pt-2'>
                                                <li>Grade average: B</li>
                                                <li>Advanced mathematics: A</li>
                                                <li>Natural sciences average: A</li>
                                            </ul>

                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 6} className="  mb-[2px] bg-[#ffffff] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(6)} className=' px-4 border border-white text-[20px] font-medium text-black'>
                                        When a language test result in English is not required
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='  px-1'>
                                            <p>A language test result in English is not required in the following situations.</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>A degree in higher education completed in English in specific countries</h3>
                                            <p className='mt-2'>You have completed a higher education degree in English at a higher education institution located in the following countries: EU or EEA country, Australia, Canada, Ireland, New Zealand, Switzerland, the United Kingdom, or the United States.</p>
                                            <p className='mt-2'>You must have studied in the country in question for the complete duration of the studies.</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>Upper secondary degree completed in English in specific countries</h3>
                                            <p className='mt-2'>You have completed an upper secondary degree in English at an institution located in the following countries: EU or EEA country, Australia, Canada, Ireland, New Zealand, Switzerland, the United Kingdom, or the United States. You must have studied in the country in question for the complete duration of the studies.</p>


                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <Accordion open={open === 7} className="  mb-[2px] bg-[#ffffff] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(7)} className=' px-4 border border-white text-[20px] font-medium text-black'>
                                        When a language test results in English is required
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='  px-1'>
                                            <p>One of the following language test results in English with at least the minimum score mentioned is required from you for proving your proficiency in English.</p>
                                            <p className='mt-2'>If the results need to be verified via the test organiser's webpage, remember to verify your results before applying. If we cannot verify your language test results, the results cannot be used as proof for your English language skills.</p>
                                            <p className='mt-2'>It is advisable to submit your application after you have already taken a language test and received the results: in Bachelor's Rolling Admission, applicants are given a strict two-week deadline to supplement any missing documents to the application.</p>
                                            <p className='mt-2'>When having submitted your application, you must provide the language test certificate within a two-week time period at the latest. If any required documents are missing after the deadline, the application will be discarded.</p>
                                            <p className='mt-2'>A language certificate issued by your home university or any other institution or organization is not accepted.</p>
                                            <p className='mt-2'>All language test results accepted by LUT University are valid for two years from the date of taking the test.</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>Language test results accepted by LUT University</h3>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>Cambridge B2 First</h3>
                                            <p>Minimum score: 170</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>Cambridge International IGCSE</h3>

                                            <p>First language English</p>
                                            <p>Syllabus 0500 and 0990</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>TOEFL iBT & TOEFL iBT Home Edition</h3>
                                            <p>Minimum overall score: 80 iBT with a minimum score of 20 in writing </p>

                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>IELTS Academic or IELTS Online</h3>
                                            <p>Minimum overall score: 6.0 with a minimum score of 5.5 in writing</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>PTE Academic or PTE Academic Online</h3>
                                            <p>Minimum overall score: 60</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>C1 Advanced - C</h3>
                                            <p>by Cambridge Assessment English</p>
                                            <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>C2 Proficiency – C</h3>
                                            <p>by Cambridge Assessment English</p>


                                        </div>
                                    </AccordionBody>
                                </Accordion>

                                <Accordion open={open === 8} className="  mb-[2px] bg-[#ffffff] shadow-md ">
                                    <AccordionHeader onClick={() => handleOpen(8)} className=' px-4 border border-white text-[20px] font-medium text-black'>
                                    Applying with an unfinished degree
                                    </AccordionHeader>
                                    <AccordionBody className="text-[#6F6C90] px-4 text-sm font-normal bg-white pb-12">
                                        <div className='  px-1'>
                                            <p>Applicants applying with an unfinished degree must provide individual certificates for each school year or semester of the degree up until the application date. The applicant will be assessed based on the grades in these certificates.</p>
                                            <p className='mt-2'>A possible admission of an applicant with an unfinished degree is a conditional one.</p>
                                            <p className='mt-2'>An admitted student must:</p>
                                            <ul className=' list-disc space-y-1 pl-4 pt-2'>
                                                <li>graduate by 31 August 2024</li>
                                                <li>Provide us with the following documents by email by 31 August 2024 at 15:00 (UTC+3)</li>
                                                <li>Scan for your original Degree Certificate</li>
                                                <li>Scan for your original Transcript of Records</li>
                                                <li>Provide us with the following documents by email by 6 September 2024 at 15:00 (UTC+3)</li>
                                                <li>Official translations required, if the original documents are not issued in English, Finnish or Swedish.</li>
                                            </ul>
                                           
                                            <p className='mt-2'>Applicants who fail to graduate and submit the required documents as requested will automatically lose their places without any exceptions.</p>
                                  
                                        </div>
                                    </AccordionBody>
                                </Accordion>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default LUTCriteria