import React from 'react'
import { SvgElement, icontypesEnum } from "../../assets/svgElement";
import { useSelector } from "react-redux";

function StudentsList() {

  const userLoginData = useSelector((state:any) => state?.data?.login?.value);
  return (
    <div>
      <div className='flex flex-col justify-center items-center mt-16 pb-8 mx-6'>
        <div className='lg:w-[1000px] bg-white lg:px-[26px] px-[8px] pt-[29px] pb-4 rounded-t-[10px] mb-2'>
          <div className='flex space-x-3'>
            <div className=''>
              <SvgElement type={icontypesEnum.DEFAULTIMAGE} />
            </div>
            <div className='mt-2'>
              <h2 className='lg:text-[24px] text-[20px] font-bold'>Hi, {userLoginData?.name}</h2>
              <p className='text-[12px] text-[#777777] mt-1'>The list below shows the number of  students who have used your unique link or Refferal Code to sign up on our platform</p>
            </div>
          </div>
        </div>

        <div className='lg:w-[1000px] bg-white lg:px-[26px] lg:py-[29px] rounded-b-[10px] mx-6'>
          {/* <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                      <thead className="text-[12px] text-[#808080] bg-white ">
                        <tr>
                          <th></th>
                          <th scope="col" className="px-6 py-2">
                            Name/Email
                          </th>
                          <th scope="col" className="px-6 py-2">
                            Member ID
                          </th>
                          <th scope="col" className="px-6 py-2">
                            Phone Number
                          </th>
                          <th scope="col" className="px-6 py-2">
                            Date Added
                          </th>
                          <th scope="col" className="px-6 py-2">
                            Program Type
                          </th>
                          <th scope="col" className="px-6 py-2">
                            Payment Status
                          </th>



                        </tr>
                      </thead>

                      <tbody>
                        <tr className="even:bg-white odd:bg-[#F5F5F5]  text-[#000000] text-[12px]">

                          <td className="px-3 py-4">
                            #
                          </td>
                          <td className="px-6 py-4">
                            <h3>Deborah Steward</h3>
                            <h3>deborah@gmail.com</h3>
                          </td>
                          <td className="px-6 py-4">
                            #87612663
                          </td>

                          <td className="px-6 py-4">
                            +234(0)7065087655
                          </td>
                          <td className="px-6 py-4">
                            29,July 2024
                          </td>
                          <td className="px-6 py-4">
                            Vocational
                          </td>
                          <td className="px-6 py-4">
                            <span className="bg-[#E3C5A133] text-[#F29339] rounded-[22px] px-[25px] py-[6px]">Pending</span>
                          </td>

                        </tr>


                      </tbody>

                    </table>
                  </div> */}


          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>

                  <th scope="col" className="px-6 py-3">
                   Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                   Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Program Type
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr className="bg-white border-b ">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                    Apple MacBook Pro 17"
                  </th>
                  <td className="px-6 py-4">
                    Silver
                  </td>
                  <td className="px-6 py-4">
                    Laptop
                  </td>
                  <td className="px-6 py-4">
                    $2999
                  </td>
                </tr>
                <tr className="bg-white border-b  ">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                    Microsoft Surface Pro
                  </th>
                  <td className="px-6 py-4">
                    White
                  </td>
                  <td className="px-6 py-4">
                    Laptop PC
                  </td>
                  <td className="px-6 py-4">
                    $1999
                  </td>
                </tr>
                <tr className="bg-white ">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                    Magic Mouse 2
                  </th>
                  <td className="px-6 py-4">
                    Black
                  </td>
                  <td className="px-6 py-4">
                    Accessories
                  </td>
                  <td className="px-6 py-4">
                    $99
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>

        </div>
      </div>


    </div>
  )
}

export default StudentsList