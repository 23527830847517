import React, { useState } from "react";
import AdminSidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import { SvgElement, icontypesEnum } from "../../assets/svgElement";
import { Link } from "react-router-dom";
import { AdminApis } from "../../../apis/adminApi";
import { AxiosResponse } from "axios";
import Modal from "react-awesome-modal";
import { ToastContainer, toast } from "react-toastify";

function CohortList() {
  const [groupLists, setGroupList] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState("");
  const [classLink, setClassLink] = React.useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setClassLink(value); // Update classLink state with input value
  };

  React.useEffect(() => {
    const query: any = {
      search: searchText,
    };
    AdminApis.getAllGroups("")
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setGroupList(response?.data?.data);
        }
      })
      .catch(function (error) {
        // handle error
      });
  }, []);

  let [visible, setVisible] = React.useState(false);
  const [groupId, setGroupId] = useState("");

  function toggleModal(groupId: any) {
    setGroupId(groupId); // Set the groupId in state
    // console.log("Group ID:", groupId);
    setVisible(!visible);
  }

  const updateGroupLink = (id: any) => {
    const data = {
      class_link: classLink,
    };
    AdminApis.updateGroupLink(id, data)
      .then((response: any) => {
        if (response?.data) {
          toast.success(response?.data?.message);
          // Reset form after successful submission
          setClassLink(""); // Clear the classLink state
          toggleModal(id);

          //  refresh the group list after updating the link
          AdminApis.getAllGroups("")
            .then((response: AxiosResponse<any>) => {
              if (response?.data) {
                setGroupList(response?.data?.data);
              }
            })
            .catch(function (error) {
              console.log(error.response.data);
            });
        }
      })
      .catch((err) => {
        if (err?.response?.status == 422) {
          toast.error("Form not rightly filled. Kindly Cross check.");
        } else {
          toast.error("Some error occured.Please try again");
        }
      })
      .finally(() => {});
  };

  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = "";
      if (value !== null) {
        value2 = value;
      } else {
        value2 = "";
      }
      const query: any = {
        search: searchText,
      };

      AdminApis.getAllGroups(value2)
        .then((response: AxiosResponse<any>) => {
          if (response?.data) {
            setGroupList(response?.data?.data);
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
        });
    },
    [groupLists, searchText]
  );

  return (
    <>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />

        <div className="lg:pt-10 lg:px-10 px-6 ">
          <div className="flex justify-between">
            <h3 className=" font-medium text-lg">Cohorts</h3>
            <Link to="/add-cohort">
              <button
                type="button"
                className="font-normal text-white bg-[#1DB459] px-5 py-2 rounded-md"
              >
                Create New
              </button>
            </Link>
          </div>
        </div>

        <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#c4c4c4] mx-6 lg:mt-6 lg:mx-10 mt-5">
          <div className="flex justify-end px-8 py-6">
            <div className=" lg:ml-auto mr-3">
              <div className="relative flex w-full ">
                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#9da4aa"
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  placeholder="search Cohort..."
                  className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  "
                />
                {/* <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required /> */}
              </div>
            </div>
          </div>
          <table className="w-full text-sm text-left">
            <thead className="text-xs text-[#3B3D55] uppercase bg-gray-50 font-light">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Label
                </th>
                {/* <th scope="col" className="px-6 py-3">
                                    Students
                                </th> */}
                <th scope="col" className="px-6 py-3">
                  Date Created
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {groupLists?.data?.map((datas: any, index: any) => (
                <tr className="bg-white  ">
                  <td
                    scope="row"
                    className="px-6 py-4 font-medium text-[#6C757D] whitespace-nowrap"
                  >
                    {datas?.group_name}
                  </td>
                  <td className="px-6 py-4">{datas?.group_label}</td>
                  {/* <td className="px-6 py-4">
                                        {datas?.students}
                                        </td> */}
                  <td className="px-6 py-4">
                    {datas?.created_at.slice(0, 10)}
                  </td>
                  <td className="px-6 py-4 cursor-pointer">
                    {/* <Link to={`/edit-cohort/${datas}`}> */}
                    <button type="button" onClick={() => toggleModal(datas.id)}>
                      <SvgElement type={icontypesEnum.EDIT} />
                    </button>
                    {/* </Link> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* <div className='m-4'>
                        {
                            userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-green-500 text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }
                    </div> */}
        </div>
        <Modal
          visible={visible}
          width="700"
          minHeight="300"
          effect="fadeInUp"
          onClickAway={() => toggleModal}
        >
          <p
            className="flex justify-end mt-2 ml-2 mr-2 cursor-pointer font-bold"
            onClick={toggleModal}
          >
            X
          </p>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-xl rounded-lg mt-2">
            <div className="px-6">
              <div className="">
                <div className="flex justify-center">Update Meeting Link </div>
                {/* <div className='flex justify-center py-10'><button type='button' onClick={deleteCourse} className="text-white bg-[#b41d1d] px-3 py-1 rounded-md "> Proceed</button></div> */}
                <form
                // onSubmit={handleVerification}
                >
                  <div className="bg-white mt-[70px] p-6">
                    <div className="flex justify-end md:px-4">
                      {/* <h5 className='md:text-[16px] text-[14px]'><span className='text-[#EE1D52] font-bold'>*</span>indicates a required field</h5> */}
                    </div>
                    <div className="mb-6 w-full md:px-4">
                      <label className="block mb-2 text-sm font-semibold text-[#0A2E04]">
                        Class Link
                        <span className="text-[#EE1D52] font-bold">*</span>
                      </label>
                      <input
                        type="text"
                        value={classLink}
                        onChange={handleChange}
                        // onChange={handleChange}
                        className=" border border-[#D9D9D9] text-[#333333] text-sm block w-full p-3"
                        placeholder="Enter meeting link"
                        name="class_link"
                        required
                      />
                    </div>

                    <div className="flex mx-auto space-x-4 mt-6">
                      <button
                        type="button"
                        onClick={() => updateGroupLink(groupId)}
                        className=" text-white bg-[#1DB459] rounded-[5px] font-medium text-sm md:px-5 px-3 md:py-3 py-2.5 mr-2 mb-2"
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        className=" text-white bg-[#FF0000] rounded-[5px]  font-medium text-sm md:px-12 px-8 md:py-3 py-2.5 mr-2 mb-2"
                        onClick={toggleModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>

        <ToastContainer
          position="bottom-left"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}

export default CohortList;
