import React from 'react'
import Navbar from '../Navbar'
import hero from "../../img/winter-camp.png";
import { NavLink, useNavigate, } from "react-router-dom";
import Footer from '../Footer';
function WinterCamp() {
    return (
        <>
            <Navbar />
            {/* <!-- Start block --> */}
            <section className='hidden md:block md:pt-20 relative'>
                <div
                    style={{
                        backgroundImage: `url(${hero})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: 'center center'
                    }}
                    className='md:bg-cover relative bg-contain mt-[75px] md:mt-0 sm:px-16 md:px-14 px-6 md:pb-20 md:pt-20 pt-2  flex items-start'
                >
                    <div className=" text-white md:pt-40 pt-64 md:pb-5 pb-5">

                    </div>

                    {/* <div className='h-40 absolute top-2 text-white text-[32px] rounded-lg left-6 shadow-lg md:h-40 cursor-pointer' onClick={() => navigate(-1)}> ← </div> */}

                    <img src="/images/finest-logo.png" alt="location" className='h-40 absolute bottom-[-50px] rounded-[26px] md:left-28 left-5 shadow-lg md:h-40' />
                    {/* <img src={schoolData?.logo_url} alt={schoolData?.logo} className='h-40 absolute bottom-[-50px] rounded-[26px] md:left-28 left-5 shadow-lg md:h-40' /> */}
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Mobile section --> */}
            {/* <!-- Start block --> */}
            <section className='block md:hidden md:pt-20 relative'>
                <div
                    style={{
                        backgroundImage: `url(${hero})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: 'center center'
                    }}
                    className='md:bg-cover relative bg-contain mt-[75px] md:mt-0 sm:px-16 md:px-14 px-6 md:pb-20 md:pt-20 pt-2  flex items-start'
                >
                    <div className=" text-white md:pt-40 pt-64 md:pb-5 pb-5">

                    </div>

                    {/* <div className='h-40 absolute top-2 text-white text-[32px] rounded-lg left-6 shadow-lg cursor-pointer' onClick={() => navigate(-1)}> ← </div> */}

                    <img src="/images/finest-logo.png" alt="logo" className='h-[130px] absolute bottom-[-70px] rounded-[10px] md:left-28 left-5 shadow-lg  mb-5' />


                </div>
            </section>
            {/* <!-- Start block --> */}

            {/* <!-- Start block --> */}
            <div className=' sm:px-16 px-6 md:flex md:justify-center items-start md:pt-5 pt-10' >
                <div className='xl:max-w-[1200px] w-full mx-auto'>
                    <div className='flex md:flex-row flex-col sm:py-16 py-6 relative'>
                        <div className='basis-8/12'>
                            <div className=" text-[#000]  md:pb-5 pb-5">
                                <h1 className="text-[24px] md:text-[42px] text-[#1DB459] font-[600]">
                                    Winter Camp 2024 for students
                                </h1>

                                {/* <h5 className='md:text-[24px] text-[16px] font-[400] capitalize'>Tampere, Finland</h5> */}
                                <h5 className='md:text-[24px] text-[16px] font-[500] capitalize'>Salla city</h5>
                                <h5 className='md:text-[24px] text-[16px] font-[400] capitalize'>Finland</h5>
                            </div>

                            <div className='flex md:hidden justify-between  mb-3 gap-2 mt-[10px]'>
                                <NavLink to={"/winter-camp-application-form"} className=" w-full">
                                    <button type="button" className=" bg-[#1DB459]   text-[#FFFFFF] rounded-full md:text-[18px] text-[16px] px-6 min-w-full  py-3 shadow-md">Apply</button>
                                </NavLink>
                                {/* <NavLink to={"/"} className=" w-full pr-3">
                                    <button type="button" className="  bg-[#56D9F5] font-[500] text-[#001F26] rounded-full text-[14px] px-6 min-w-full  py-3 shadow-md">About School</button>
                                </NavLink> */}

                            </div>

                            <hr className='pb-3 md:hidden' />

                            <div className='grid md:grid-cols-3 grid-cols-2 md:space-x-5 pb-5 '>
                                <div className='bg-[#F0F5FE] font-medium mr-4 md:px-2 px-5 md:py-2 py-1 rounded shadow'>
                                    <div className='md:text-[30px] text-[18px] text-[#4D4D4D] font-bold text-center'>€ 2,800</div>
                                    <div className='md:text-[14px] text-[12px] text-[#4D4D4D] text-center'>Early Bird discount available</div>
                                </div>
                                <div className='bg-[#F0F5FE] font-medium mr-2 px-6  py-1 rounded shadow'>
                                    <h3 className='md:text-[30px] text-[18px] text-[#4D4D4D] font-bold text-center capitalize'>10 Days</h3>
                                    <h5 className='md:text-[14px] text-[12px] text-[#4D4D4D] text-center'>Duration</h5>
                                </div>

                                <div className='md:flex md:flex-col md:px-1 hidden'>
                                    <NavLink to={"/winter-camp-application-form"} className=" w-full">
                                        <button type="button" className=" bg-[#1DB459]  text-[#FFFFFF] rounded-full text-[16px]  px-6 min-w-full  py-1.5 shadow-md">Apply</button>
                                    </NavLink>
                                    {/* <NavLink to={"/"} className="mt-2 w-full">
                                        <button type="button" className="bg-[white] text-[#001F26] border border-[#1DB459] font-[500] rounded-full  text-[16px] px-6 min-w-full  py-1.5 shadow-md">About School</button>
                                    </NavLink> */}

                                </div>
                            </div>
                            <hr className='pt-3 hidden md:block' />


                            <div className='mt-3'>
                                <h1 className=' font-[700] text-[24px]'>Purpose of the Camp</h1>
                                <p className='pb-[10px] pt-[8px] font-normal text-[14px] text-[#4D4D4D]'>
                                    To offer students possibilities of exploring the country of Finland, and see if this is a good country for them to study in the near future.
                                </p>

                                <h1 className=' font-[700] text-[24px]'>Target </h1>
                                <p className='pb-[10px] pt-[8px] font-normal text-[14px] text-[#4D4D4D]'>
                                    For international students who would like to experience Finland and see the opportunities for education programs with Finest Future
                                </p>

                                <h1 className=' font-[700] text-[24px]'>Student rule during the camp duration</h1>
                                <h3 className='md:text-[20px] text-[16px] text-[#4D4D4D]'>1. General Rules</h3>
                                <ul className="max-w-md space-y-1 text-[#4D4D4D] list-disc list-outside pt-[8px] font-normal text-[14px] ml-4">
                                    <li>
                                        Participate in activities on time and comply with program regulations.
                                    </li>
                                    <li>
                                        Notify the coordinator immediately when there are any health-related problems.
                                    </li>
                                    <li>
                                        Proactively take care of personal belongings, always check your belongings before, during and after
                                    </li>
                                    <li>
                                        Participating in the activities during program.
                                    </li>
                                    <li>
                                        Notify the coordinator of any negligence or loss.
                                    </li>
                                    <li>
                                        When detecting any acts of abuse or harassment, notify and consult the camp coordinator.
                                    </li>
                                </ul>
                                <h3 className='md:text-[20px] text-[16px] text-[#4D4D4D] mt-5'>2.  In the dormitory room</h3>
                                <ul className="max-w-md space-y-1 text-[#4D4D4D] list-disc list-outside pt-[8px] font-normal text-[14px] ml-4">
                                    <li>
                                        Student live in their designated room, NOT allowed to enter other students' rooms without permission
                                    </li>
                                    <li>
                                        Students follow the schedule as directed by the coordinator
                                    </li>
                                    <li>
                                        Use of substances is prohibited.
                                    </li>
                                    <li>
                                        No aggressive behaviors are allowed.
                                    </li>
                                </ul>
                                <h3 className='md:text-[20px] text-[16px] text-[#4D4D4D] mt-5'>3.  In the common space</h3>
                                <ul className="max-w-md space-y-1 text-[#4D4D4D] list-disc list-outside pt-[8px] font-normal text-[14px] ml-4">
                                    <li>
                                        Keep the common space tidy and clean
                                    </li>
                                    <li>
                                        No damage of appliances and equipment during the camp
                                    </li>
                                    <li>
                                        Respect the hours of silence between 10pm to 7am
                                    </li>
                                </ul>
                                <h1 className=' font-[700] text-[24px] mt-5'>Ticket Information</h1>
                                <div className='mt-5'>
                                    <img src="/images/ticket-info1.png" alt="hero" className='w-full' />
                                </div>
                                <div className='mt-10'>
                                    <img src="/images/ticket-info2.png" alt="hero" className='w-full' />
                                </div>
                                <ul className=" space-y-3 text-[#4D4D4D] list-disc list-outside pt-[25px] font-normal text-[14px] ml-4">
                                    <li>
                                        In case of Visa rejection, Participants will be refunded 100% of the fee (minus the transaction fee and administrative fee: 100 EUR) if they inform FF to liquidate the contract within 21 days after payment.
                                    </li>
                                    <li>

                                        In case the student wants to cancel their registration after payment, the student can have two options, which are transferring 100% to another program organized by FF, or getting a 50% refund from the fee (deducting the transaction fee and administrative fee: 100 EUR) due to program preparation and sightseeing location reservation.
                                    </li>
                                    <li>

                                        After 7 days without payment and a signed agreement, participants' registration will be invalid, and they cannot benefit from the discount policy (Early bird prices).
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className='relative basis-4/12 md:ml-[100px]'>

                            <div className='mt-10 md:mt-0 sticky top-0 left-0'>
                                <div className='bg-white p-3 rounded flex-col border border-[#D9D9D9]'>
                                    <div className='flex-1 flex justify-center items-center'>
                                        <img src="/images/winter.png" alt="hero" className='w-full' />
                                    </div>
                                    <div
                                        className="text-white mt-1 bg-[#1DB459] text-center w-full font-medium rounded-[4px] text-sm  py-2.5">
                                        Key Information
                                    </div>
                                    <div className='flex justify-between pt-2 mt-1 mb-2 bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>Location </h3>
                                        <h3 className='text-[12px]'>Salla City, Finland</h3>
                                    </div>
                                    <div className='flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>Duration</h3>
                                        <h3 className='text-[12px]'>10 Days</h3>
                                    </div>
                                    <div className='flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>Language</h3>
                                        <h3 className='text-[12px]'>English</h3>
                                    </div>
                                    <div className='flex justify-between pt-2 mb-2 bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>Capacity</h3>
                                        <h3 className='text-[12px] capitalize'>50</h3>
                                    </div>
                                    {/* <div className='flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>Tags</h3>
                                        <h3 className='text-[12px] capitalize'>High School</h3>
                                    </div> */}
                                    <div className='flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>Start Date </h3>
                                        <h3 className='text-[12px] capitalize'>25-03-2024</h3>
                                    </div>
                                    <div className='flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1'>
                                        <h3 className='text-[12px]'>End Date </h3>
                                        <h3 className='text-[12px] capitalize'>03-04-2024</h3>
                                    </div>



                                    <div className='mt-[10px]'>
                                        {/* practical-nursing-application-form */}

                                        <div className=''>

                                            <NavLink to={"/winter-camp-application-form"}>
                                                <button
                                                    type="submit"
                                                    className="text-white bg-[#1DB459] w-full font-medium rounded-[4px] text-sm  py-2.5">
                                                    Apply Now
                                                </button>
                                            </NavLink>
                                        </div>

                                        <div className='mt-[10px] mb-3'>
                                            <div className=''>
                                                <div className='text-[20px] font-medium text-[#000F2F]'>Need help? </div>
                                                <NavLink to="/contact-us">
                                                    <div className=' text-[#1DB459] font-[400] text-[14px] cursor-pointer'>contact us for more information</div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </div>
            <div className=''>
                <Footer />
            </div>

            {/* <!-- End block --> */}
        </>
    )
}

export default WinterCamp