import React, { useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import applybg from "../../img/apply-bg.svg";
import applybgmobile from "../../img/apply-mobile.svg";
import boybg from "../../img/boy.svg";
import Footer from "../Footer";

function ApplyLoan() {

    const [openTestTab, setOpenTestTab] = useState(1);
    return (
        <>
            <Navbar />

            {/* <!-- Start block --> */}
            <section className="bg-[#FBFBFB] body-font font-poppins pt-20">
                <div className="hidden md:block">
                    <div
                        style={{
                            backgroundImage: `url(${applybg})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "",
                        }}
                        className=" grid max-w-screen-xl px-8 lg:px-14 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 "
                    >
                        <div className="mr-auto place-self-center lg:col-span-7 mb-10">
                            <h1 className=" max-w-2xl mb-4 text-4xl text-[#48B774] font-semibold leading-10 tracking-normal  md:text-3xl xl:text-5xl">
                                Apply for a Study Loan
                                <br />
                                <span className="text-[#000000] font-normal">
                                    with us in minutes
                                </span>
                                <br />
                                <span className="text-[#000000] font-semibold">
                                    Flexible repayment periods
                                </span>
                            </h1>

                            <div className=" mt-16">
                                <a href="sign-in">
                                    <button
                                        type="submit"
                                        className="py-2.5 px-5  text-sm font-medium text-white bg-[#48B774] rounded-lg border border-[#48B774] "
                                    >
                                        Apply Now
                                    </button>

                                </a>

                            </div>

                            <div className="flex mt-6">
                                <div className="flex space-x-2 pr-5">
                                    <img src="/images/connect-logo.svg" alt="friends" />
                                    <p className="mt-2 text-xs text-[#B3B3B3]">
                                        Flexible repayment periods{" "}
                                    </p>
                                </div>
                                <div className="flex space-x-2">
                                    <img src="/images/pay-logo.svg" alt="friends" />
                                    <p className="mt-2 text-xs text-[#B3B3B3]">
                                        Pay at your convenience{" "}
                                    </p>
                                </div>
                            </div>
                            <div className="flex space-x-2 mt-6">
                                <img src="/images/edu-logo.svg" alt="friends" />
                                <p className="mt-2 text-xs text-[#B3B3B3]">For study loans </p>
                            </div>
                        </div>
                        <div className="my-6 lg:mt-0 lg:col-span-5 lg:flex w-full pt-10">
                            <img src="/images/loan-banner.svg" alt="hero" />
                        </div>
                    </div>
                </div>
                <div className="pt-10">
                    <div className="block md:hidden pt-8 pb-8 px-6"
                        style={{
                            backgroundImage: `url(${applybgmobile})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "",
                        }}
                    >
                        <h1 className=" max-w-2xl mb-4 text-2xl text-[#48B774] font-semibold tracking-wide   xl:text-5xl">
                            Apply for a Study Loan
                            <br />
                            <span className="text-[#000000] font-normal">
                                with us in minutes <span className="text-[#000000] font-semibold">Flexible</span>
                            </span>
                            <br />
                            <span className="text-[#000000] font-semibold">
                                repayment periods
                            </span>
                        </h1>
                        <div className="my-3 lg:col-span-5 lg:flex w-full pt-4">
                            <img src="/images/loan-banner.svg" alt="hero" />
                        </div>
                        <div className=" mt-4">
                            <a href="sign-in">
                                <button
                                    type="submit"
                                    className="py-2.5 px-5  text-sm font-medium text-white bg-[#48B774] rounded-lg border border-[#48B774] "
                                >
                                    Apply Now
                                </button>

                            </a>

                        </div>
                        <div className="flex mt-6">
                            <div className="flex space-x-2 pr-5">
                                <img src="/images/connect-logo.svg" alt="friends" />
                                <p className="mt-2 text-xs text-[#B3B3B3]">
                                    Flexible repayment periods{" "}
                                </p>
                            </div>
                            <div className="flex space-x-2">
                                <img src="/images/edu-logo.svg" alt="friends" />
                                <p className="mt-2 text-xs text-[#B3B3B3]">For study loans </p>

                            </div>
                        </div>
                        <div className="flex space-x-2 mt-6">
                            <img src="/images/pay-logo.svg" alt="friends" />
                            <p className="mt-2 text-xs text-[#B3B3B3]">
                                Pay at your convenience{" "}
                            </p>
                        </div>

                    </div>
                </div>


            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="  body-font font-poppins">
                <div className="hidden md:block">
                    <div
                        style={{
                            backgroundImage: `url(${boybg})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                        }}
                        className="  lg:px-14 pt-10 pb-24 mx-auto flex justify-center lg:justify-end"
                    >
                        <div className=" mt-20 px-8 text-white bg-[#58a170] py-10 md:bg-inherit">
                            <h1 className="text-white md:text-4xl text-2xl font-medium">
                                Loan collection has never <br />
                                been made this easy.
                            </h1>

                            <div className="mt-10">
                                <p className="text-white text-sm leading-relaxed">
                                    Our lending partners are carefully vetted to ensure that they offer fair and <br />
                                    reasonable loan options. We work with a variety of partners to provide <br />
                                    students with a range of loan options to choose from, allowing them to find <br />
                                    the loan that best fits their needs and budget.
                                </p>
                            </div>

                            <div className="mt-12">
                                <div className="flex  space-x-4 ">
                                    <div className="md:w-[58px] md:h-[58px] w-[45px] h-[38px] mt-5 md:mt-0 rounded-full border-4 border-white flex justify-center items-center">
                                        <p className="text-white md:text-4xl text-xl">1</p>
                                    </div>
                                    <p className="text-sm mt-2">
                                        Taking into considerations credit score check by providing valid <br />documents to enable the loan collection

                                    </p>
                                </div>
                                <div className="flex  space-x-4 mt-8">
                                    <div className="md:w-[58px] md:h-[58px] w-[45px] h-[38px] mt-5 md:mt-0 rounded-full border-4 border-white flex justify-center items-center">
                                        <p className="text-white md:text-4xl text-xl">2</p>
                                    </div>
                                    <p className="text-sm mt-2">
                                        Allowing students the freedom to use guarantors and collaterals
                                    </p>
                                </div>
                                <div className="flex  space-x-4 mt-8">
                                    <div className="md:w-[58px] md:h-[58px] w-[45px] h-[38px] mt-5 md:mt-0 rounded-full border-4 border-white flex justify-center items-center">
                                        <p className="text-white md:text-4xl text-xl">3</p>
                                    </div>
                                    <p className="text-sm mt-2">
                                        Providing a simple form and a swift and seamless loan collection process<br /> provided all criterias are met.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* mobile */}
                <div className="block md:hidden">
                    <div className="  px-8 text-white bg-[#58a170] py-10 ">
                        <h1 className="text-white text-2xl font-medium">
                            Loan collection has never <br />
                            been made this easy.
                        </h1>

                        <div className="mt-10">
                            <p className="text-white text-sm leading-relaxed">
                                Our lending partners are carefully vetted to ensure that they offer fair and <br />
                                reasonable loan options. We work with a variety of partners to provide <br />
                                students with a range of loan options to choose from, allowing them to find <br />
                                the loan that best fits their needs and budget.
                            </p>
                        </div>

                        <div className="mt-12">
                            <div className="flex  space-x-4 ">
                                <div className="md:w-[58px] md:h-[58px] w-[45px] h-[38px] mt-5 md:mt-0 rounded-full border-4 border-white flex justify-center items-center">
                                    <p className="text-white md:text-4xl text-xl">1</p>
                                </div>
                                <p className="text-sm mt-2">
                                    Taking into considerations credit score check by providing valid <br />documents to enable the loan collection

                                </p>
                            </div>
                            <div className="flex  space-x-4 mt-8">
                                <div className="md:w-[58px] md:h-[58px] w-[45px] h-[38px] mt-5 md:mt-0 rounded-full border-4 border-white flex justify-center items-center">
                                    <p className="text-white md:text-4xl text-xl">2</p>
                                </div>
                                <p className="text-sm mt-2">
                                    Allowing students the freedom to use guarantors and collaterals
                                </p>
                            </div>
                            <div className="flex  space-x-4 mt-8">
                                <div className="md:w-[58px] md:h-[58px] w-[45px] h-[38px] mt-5 md:mt-0 rounded-full border-4 border-white flex justify-center items-center">
                                    <p className="text-white md:text-4xl text-xl">3</p>
                                </div>
                                <p className="text-sm mt-2">
                                    Providing a simple form and a swift and seamless loan collection process<br /> provided all criterias are met.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-white md:pt-10">
                <div className="hidden md:block">
                    <div className="flex flex-col lg:flex-row max-w-screen-xl px-8 lg:px-14 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-12 lg:py-16  ">
                        <div className="lg:col-span-2 ">
                            <div className="max-w-xs p-6 bg-[#D7F5DC] border border-[#D7F5DC] rounded-lg">

                                <img src="/images/cash-fast.svg" alt="hero" />

                                <div >
                                    <h5 className="mb-2 text-base font-semibold tracking-tight text-[#666666] ">
                                        Swift and Easy
                                    </h5>
                                </div>
                                <p className="mb-3 font-normal text-sm text-[#666666]">
                                    You can now have access to credit in a very short time.
                                </p>

                            </div>
                            <div className="mt-8 max-w-xs p-6 bg-[#D7F5DC] border border-[#D7F5DC] rounded-lg">

                                <img src="/images/cash-fast.svg" alt="hero" />

                                <div >
                                    <h5 className="mb-2 text-base font-semibold tracking-tight text-[#666666] ">
                                        Basic Loan Requirements
                                    </h5>
                                </div>
                                <p className="mb-3 font-normal text-sm text-[#666666]">
                                    The requirements to take loans are the most basic things you have at your perusal
                                </p>

                            </div>
                        </div>
                        <div className="hidden my-6 lg:mt-0 lg:col-span-3 lg:flex">
                            <img src="/images/girl-loan.svg" alt="hero" />
                        </div>
                        <div className="lg:col-span-2 ">
                            <div className="mt-8 lg:mt-0 max-w-xs p-6 bg-[#D7F5DC] border border-[#D7F5DC] rounded-lg">

                                <img src="/images/cash-fast.svg" alt="hero" />

                                <div >
                                    <h5 className="mb-2 text-base font-semibold tracking-tight text-[#666666] ">
                                        Competitive interest rate
                                    </h5>
                                </div>
                                <p className="mb-3 font-normal text-sm text-[#666666]">
                                    Our partners offer you the best interest rates in the market.
                                </p>

                            </div>
                            <div className="mt-8 max-w-xs p-6 bg-[#D7F5DC] border border-[#D7F5DC] rounded-lg">

                                <img src="/images/cash-fast.svg" alt="hero" />

                                <div >
                                    <h5 className="mb-2 text-base font-semibold tracking-tight text-[#666666] ">
                                        Loan repayment time
                                    </h5>
                                </div>
                                <p className="mb-3 font-normal text-sm text-[#666666]">
                                    We have different loan partners with different repayment periods  to pick the one that’s works best for you.
                                </p>

                            </div>
                        </div>
                        <div className="my-6 lg:mt-0 lg:col-span-3  ">
                            <h1 className="font-bold text-3xl">Why you should <br />take a loan for your<br />Language Learning</h1>
                            <p className="mt-6 text-[#666666] text-sm">
                                We are committed to providing excellent
                                customer service and support throughout the<br />
                                loan process. Our team is always available to <br />
                                answer any questions or concerns that students<br />
                                may have, and we strive to make the loan <br />
                                application and approval process as quick and <br />easy as possible.
                            </p>
                            {/* <div className=" mt-8">
                                <button
                                    type="submit"
                                    className="py-2.5 px-8  text-sm font-medium text-white bg-[#1DB459] rounded-lg border border-[#1DB459] "
                                >
                                    Apply Now
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* mobile */}
                <div className=" block md:hidden px-8 py-6">
                    <h1 className="font-bold text-3xl">Why you should take a loan for your <span className="text-[#1DB459]">Language Learning</span></h1>
                    <div className=" my-6 lg:mt-0 lg:col-span-3 lg:flex">
                        <img src="/images/girl-loan.svg" alt="hero" />
                    </div>
                    <p className="mt-3 text-[#666666] text-sm">
                        We are committed to providing excellent
                        customer service and support throughout the<br />
                        loan process. Our team is always available to <br />
                        answer any questions or concerns that students<br />
                        may have, and we strive to make the loan <br />
                        application and approval process as quick and <br />easy as possible.
                    </p>
                    <div className=" mt-5 max-w-xs p-6 bg-[#D7F5DC] border border-[#D7F5DC] rounded-lg">

                        <img src="/images/cash-fast.svg" alt="hero" />

                        <div >
                            <h5 className="mb-2 text-base font-semibold tracking-tight text-[#666666] ">
                                Swift and Easy
                            </h5>
                        </div>
                        <p className="mb-3 font-normal text-sm text-[#666666]">
                            You can now have access to credit in a very short time.
                        </p>

                    </div>
                    <div className="mt-3 max-w-xs p-6 bg-[#D7F5DC] border border-[#D7F5DC] rounded-lg">

                        <img src="/images/cash-fast.svg" alt="hero" />

                        <div >
                            <h5 className="mb-2 text-base font-semibold tracking-tight text-[#666666] ">
                                Basic Loan Requirements
                            </h5>
                        </div>
                        <p className="mb-3 font-normal text-sm text-[#666666]">
                            The requirements to take loans are the most basic things you have at your perusal
                        </p>

                    </div>
                    <div className="mt-3 lg:mt-0 max-w-xs p-6 bg-[#D7F5DC] border border-[#D7F5DC] rounded-lg">

                        <img src="/images/cash-fast.svg" alt="hero" />

                        <div >
                            <h5 className="mb-2 text-base font-semibold tracking-tight text-[#666666] ">
                                Competitive interest rate
                            </h5>
                        </div>
                        <p className="mb-3 font-normal text-sm text-[#666666]">
                            Our partners offer you the best interest rates in the market.
                        </p>

                    </div>
                    <div className="mt-3 max-w-xs p-6 bg-[#D7F5DC] border border-[#D7F5DC] rounded-lg">

                        <img src="/images/cash-fast.svg" alt="hero" />

                        <div >
                            <h5 className="mb-2 text-base font-semibold tracking-tight text-[#666666] ">
                                Loan repayment time
                            </h5>
                        </div>
                        <p className="mb-3 font-normal text-sm text-[#666666]">
                            We have different loan partners with different repayment periods  to pick the one that’s works best for you.
                        </p>

                    </div>
                    {/* <div className=" mt-8">
                        <button
                            type="submit"
                            className="py-2.5 px-8  text-sm font-medium text-white bg-[#1DB459] rounded-lg border border-[#1DB459] "
                        >
                            Apply Now
                        </button>
                    </div> */}
                </div>
            </section>
            {/* <!-- End block --> */}




            {/* <!-- Start block --> */}
            <section className="bg-[#D7F5DC] body-font font-poppins ">

                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl px-8 lg:px-14 pt-10 pb-5  mx-auto lg:gap-20 ">
                    <div className="mr-auto place-self-center lg:mb-8">

                        <h1 className="max-w-2xl md:mb-2 md:text-3xl text-2xl text-[#098A3C] font-semibold md:leading-10 lg:text-4xl xl:text-4xl">
                            What are you waiting for
                        </h1>
                        <h1 className="max-w-2xl md:mb-2 md:text-3xl text-2xl text-[#098A3C] font-semibold md:leading-10 lg:text-4xl xl:text-4xl">
                            Apply Now

                        </h1>
                        <div className=" lg:px-0">
                            <p className="max-w-2xl  mt-6 mb-6 font-medium text-black  text-sm leading-relaxed">
                                Thank you for considering our student loan page. We look forward to working with you to help you achieve your academic goals. If you have any questions or would like to learn more about our loan options, please don't hesitate to contact us.
                            </p>


                        </div>


           
                    </div>
                    <div className=" mr-auto place-self-center my-6 lg:mt-0 ">
                        <NavLink to="/terms" className="md:mt-6 flex justify-center items-center">
                            <button
                                type="submit"
                                className="py-3 px-14  text-sm font-medium text-white bg-[#48B774] rounded-lg border border-[#48B774]"
                            >
                                Apply Now
                            </button>
                        </NavLink>
                    </div>
                </div>
            </section>

            <Footer />
            {/* <!-- End block --> */}
        </>
    );
}

export default ApplyLoan;
