import React from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";

function AfripayLoan() {
  return (
    <>
    <Sidebar />
    <div className="relative md:ml-64 bg-blueGray-100">
    <UserNavbar title="Loan" />

    <div className=' lg:w-[50%] mt-10 px-10'   >
            <h3 className='lg:text-[16px] text-[14px] text-[#14B266] font-bold'>Coming soon</h3>
            <h1 className='lg:text-[36px] text-[30px] font-bold leading-[43px]'> Get Notified when this product is available</h1>
            <div className='mt-4'>
              <form>

                <div className="relative">
                  <input type="email" id="email" className="block w-full p-3 text-sm text-gray-900 border-[1px] border-[#14B266] rounded-[30px] bg-transparent" placeholder="Enter your email" required />
                  <button type="submit" className=" absolute right-2 bottom-1.5 bg-[#14B266] text-[12px] text-white py-[8px] px-[23px] rounded-[28px]">
                    Notify Me
                  </button>
                </div>
                <div className='flex space-x-2 mt-1'>
                <small className='text-[12px]'>Don't worry we will not spam you </small>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="#14B266" d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2M8.5 9a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 8.5 9m7.741 7.244a5.982 5.982 0 0 1-3.034 1.634a6.042 6.042 0 0 1-3.541-.349a5.997 5.997 0 0 1-2.642-2.176l1.658-1.117c.143.211.307.41.488.59a3.988 3.988 0 0 0 1.273.86c.243.102.495.181.749.232a4.108 4.108 0 0 0 1.616 0c.253-.052.505-.131.75-.233c.234-.1.464-.224.679-.368c.208-.142.407-.306.591-.489c.183-.182.347-.381.489-.592l1.658 1.117c-.214.32-.461.62-.734.891M13 12s.5-2 2.5-2c1.999 0 2.5 2 2.5 2z"/></svg>
                </div>
                
              </form>
            </div>
            <div className='mt-2'>
              <img src="/images/Dashboard/coming.png" alt="logo" className="" />
              </div>
          </div>
</div>
    
    </>
  )
}

export default AfripayLoan