import React from 'react'
import { Link } from "react-router-dom";
import AdminSidebar from '../../Sidebar/AdminSidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import { SvgElement, icontypesEnum } from '../../assets/svgElement'
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import { CSVLink, CSVDownload } from "react-csv";

function UsersList() {
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userLists, setUserList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');
    const [csvData, setCsvData] = React.useState<any>([]);

    const [userList, setUserLists] = React.useState<any>([]);
    React.useEffect(() => {
        AdminApis.getUserCount().then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setUserLists(response?.data?.data)
                }
            }
        ).catch(function (error) {

        })


        AdminApis.getUsers().then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setCsvData(response?.data?.data)
                }
            }
        ).catch(function (error) {
            console.log(error.response.data);
        })
    }, []);



    React.useEffect(() => {

        const query: any = {
            search: searchText,
        };
        AdminApis.getAllUsers('', query).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setUserList(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })
    }, []);



    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }
            const query: any = {
                search: searchText,
            };

            AdminApis.getAllUsers(value2, query).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setUserList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [userLists, searchText]);


    const userDetailsPage = React.useCallback(
        (value: any) => {
            console?.log('d')
            navigate(`/user-details/${value}`);
        }, [searchText]
    )

    const deleteUser = React.useCallback(
        (id: any) => {
            AdminApis.deleteUser(id).then(
                (response: any) => {
                    if (response?.data) {
                        toast.success(response?.data?.message);

                        const query: any = {
                            search: searchText,
                        };
                        AdminApis.getAllUsers('', query).then(
                            (response: AxiosResponse<any>) => {
                                if (response?.data) {
                                    setUserList(response?.data?.data)
                                }
                            }
                        )
                    }
                }
            ).catch((err) => {
                if (err?.response?.status == 422) {
                    toast.error('Form not rightly filled. Kindly Cross check.');
                } else {
                    toast.error('Some error occured.Please try again');
                }


            }).finally(() => {

            });
        },
        [userList]
    );


    const updateAgentStatus = React.useCallback(
        (id: any) => {
            AdminApis.updateAgentStatus(id).then(
                (response: any) => {
                    if (response?.data) {
                        toast.success(response?.data?.message);

                        const query: any = {
                            search: searchText,
                        };
                        AdminApis.getAllUsers('', query).then(
                            (response: AxiosResponse<any>) => {
                                if (response?.data) {
                                    setUserList(response?.data?.data)
                                }
                            }
                        )
                    }
                }
            ).catch((err) => {
                if (err?.response?.status == 422) {
                    toast.error('Form not rightly filled. Kindly Cross check.');
                } else {
                    toast.error('Some error occured.Please try again');
                }


            }).finally(() => {

            });
        },
        [userList]
    );



    const exportCSV = React.useCallback(
        (value: any) => {

        }, [])







    return (
        <>
            <AdminSidebar />
            <div className="relative md:ml-64 bg-blueGray-100">

                <UserNavbar />



                <div className="space-y-2 lg:grid lg:grid-cols-4 sm:gap-5  lg:space-y-0 lg:py-10 lg:px-10 px-6 ">

                    <div className="bg-[#ffffff] border border-[#D8D8D8] rounded-lg flex justify-between px-4 py-4">
                        <div>
                            <h3 className=" text-base text-black">Total Students</h3>
                            <p className="text-3xl  text-black">
                                {userList?.total_student}
                            </p>
                        </div>
                        <div className=" mt-2">
                            <img src="/images/Dashboard/icon-sch1.svg" alt="hero" />
                        </div>

                    </div>
                    <div className="bg-[#ffffff] border border-[#D8D8D8] rounded-lg flex justify-between px-4 py-4">
                        {/* <div>
                            <h3 className=" text-base text-black">High School Students</h3>
                            <p className="text-3xl  text-black">
                                {userList?.hight_school}
                            </p>
                        </div> */}
                        <div className=" mt-2">
                            <img src="/images/Dashboard/icon-sch.svg" alt="hero" />
                        </div>

                    </div>
                    <div className="bg-[#ffffff] border border-[#D8D8D8] rounded-lg flex justify-between px-4 py-4">
                        <div>
                            <h3 className=" text-base text-black">Vocational Students</h3>
                            <p className="text-3xl  text-black">
                                {userList?.vocational}
                            </p>
                        </div>
                        <div className=" mt-2">
                            <img src="/images/Dashboard/icon-sch.svg" alt="hero" />
                        </div>

                    </div>
                    <div className="bg-[#ffffff] border border-[#D8D8D8] rounded-lg flex justify-between px-4 py-4">
                        <div>
                            <h3 className=" text-base text-black">Status</h3>
                            <div className='mt-4 flex justify-between space-x-10'>
                                <p className="text-md  text-black">
                                    Active: <span className=' text-xl'>{userList?.status?.active}</span>
                                </p>
                                <p>Inactive : <span className='text-xl'>{userList?.status?.inactive}</span>
                                </p>
                            </div>
                        </div>
                        <div className=" mt-1">
                            <img src="/images/Dashboard/icon-sch1.svg" alt="hero" />
                        </div>

                    </div>

                </div>




                <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#c4c4c4] mx-6 lg:mt-1 lg:mx-10 mt-5">
                    <div className="flex justify-between px-8 py-6">
                        <h3>Users List</h3>
                        <div className='flex justify-end'>
                            <div className=" lg:ml-auto mr-3 flex justify-end">
                                <div className="relative flex w-full flex-wrap items-stretch">
                                    <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                                    </div>
                                    <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                                </div>

                                <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#1DB459] px-3 py-0.5 rounded-md"}>Search</button> </div>

                                <div className='ml-4 mt-1 cursor-pointer' onClick={() => { }}>
                                    <CSVLink data={csvData} filename={"usersList.csv"} target="_blank">

                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18 7H6V3H18V7ZM18 12.5C18.2833 12.5 18.5207 12.404 18.712 12.212C18.904 12.0207 19 11.7833 19 11.5C19 11.2167 18.904 10.979 18.712 10.787C18.5207 10.5957 18.2833 10.5 18 10.5C17.7167 10.5 17.4793 10.5957 17.288 10.787C17.096 10.979 17 11.2167 17 11.5C17 11.7833 17.096 12.0207 17.288 12.212C17.4793 12.404 17.7167 12.5 18 12.5ZM16 19V15H8V19H16ZM18 21H6V17H2V11C2 10.15 2.29167 9.43767 2.875 8.863C3.45833 8.28767 4.16667 8 5 8H19C19.85 8 20.5627 8.28767 21.138 8.863C21.7127 9.43767 22 10.15 22 11V17H18V21Z" fill="#9DA4AA" />
                                        </svg>

                                    </CSVLink>

                                </div>

                            </div>

                            {/* <div className='mt-1'>Filter </div> */}
                        </div>

                    </div>
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 font-light">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Number Id
                                </th>

                                <th scope="col" className="px-6 py-3">
                                    Referral
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Role
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Phone No
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Date Added
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Course
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userLists?.data?.filter((data:any) => data?.role !== 'admin')?.map(
                                    (datas: any, index: any) => (
                                        <tr className="bg-white  ">

                                            {/* <td>
                                               {index} 
                                            </td> */}
                                            <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                <div className="flex space-x-3">
                                                    {/* <div>
                                                        <img src="/images/Dashboard/Userpic1.svg" alt="hero" />
                                                    </div> */}
                                                    <div>
                                                        <h3 className=" text-sm capitalize">{datas?.name} {datas?.last_name}</h3>
                                                        <p className=" text-xs text-[#647380]">{datas?.email}</p>

                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4">
                                                {datas?.user_id}
                                            </td>
                                            <td className="px-6 py-4">
                                                {datas?.referrer}
                                            </td>
                                            <td className="px-6 py-4">
                                                {datas?.role}
                                            </td>
                                            <td className="px-6 py-4">
                                                {datas?.phone_number}
                                            </td>
                                            <td className="px-6 py-4">
                                                {datas?.created_at}
                                            </td>
                                            <td className="px-6 py-4" onClick={()=>updateAgentStatus(datas?.id)}>
                                                <a href="#" className={"font-normal text-white " + (datas?.isVerified === 'true' ? 'bg-[#1DB459] ' : 'bg-[#f92f2f] ') + " px-3 py-1 rounded-sm"}>{datas?.isVerified ? datas?.isVerified : "false"}</a>
                                            </td>
                                            <td className="px-6 py-4 capitalize">
                                                {datas?.programs_type}
                                            </td>
                                            <td className="px-6 py-4">
                                                <div className='flex space-x-3'>
                                                    <span style={{ cursor: 'pointer' }} onClick={() => userDetailsPage(datas?.id)}>
                                                        <SvgElement type={icontypesEnum.EYE} /> 
                                                    </span>

                                                    <div className='mt-1 cursor-pointer' onDoubleClick={()=>deleteUser(datas?.user_id)}>
                                                        <SvgElement type={icontypesEnum.DELETE} />
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )
                            }

                        </tbody>
                    </table>

                    <div className='m-4'>
                        {
                            userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-green-500 text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }

                    </div>
                </div>
            </div>

            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />

        </>
    )
}

export default UsersList