import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "./../Navbar";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import Footer from "../Footer";
import WFooter from "../WFooter";


function Afripay() {
    const [open, setOpen] = useState(1);
    const handleOpen = (value: any) => {
        setOpen(open === value ? 0 : value);
    };
    return (
        <>
            <Navbar />
            {/* <!-- Start block --> */}
            <section className="bg-[#F6F6F4] md:pt-20 pt-20">
                <div className="">
                    <div className="grid px-8 lg:px-14 lg:gap-8 xl:gap-0  lg:grid-cols-12">
                        <div className="mr-auto place-self-center lg:col-span-5">
                            <div className="mt-10 md:mt-0">
                                <img src="/images/afripay-logo.png" alt="hero" />
                            </div>

                            <h1 className="max-w-2xl mb-4 md:text-[48px] text-[38px] text-[#171717] font-[600] md:leading-[52px] leading-[35px] tracking-normal  md:text-3xl xl:text-5xl">
                                Simplifying Global </h1>
                            <h1 className="max-w-2xl mb-4 md:text-[48px] text-[38px] text-[#171717] font-[600] md:leading-[52px] leading-[35px] tracking-normal  md:text-3xl xl:text-5xl">Tuition Payments</h1>


                            <p className="md:block hidden max-w-2xl lg:pr-[30px]  mb-6 md:mt-3 mt-5 font-normal text-gray-500 lg:mb-8 text-base break-all">
                                AfriPay aims to revolutionize the EdTech payment landscape by<br/>
                                 providing an integrated payment solution for students and their <br/>
                                 families, simplifying international tuition fee payments.
                            </p>
                            <p className="md:hidden block  mb-6 md:mt-3 mt-5 font-normal text-gray-500 lg:mb-8 text-base break-all">
                                AfriPay aims to revolutionize the EdTech payment landscape by
                                 providing an integrated payment solution for students and their
                                 families,<br/> simplifying international tuition fee payments.
                            </p>

                            <div className="flex ">
                                <NavLink to="/terms" className="">
                                    <button
                                        type="submit"
                                        className="py-4 px-5  text-sm font-medium text-white bg-[#48B774] rounded-full border border-[#48B774] "
                                    >
                                        Apply for Loan
                                    </button>
                                </NavLink>
                                <NavLink to="/terms">
                                    <button
                                        type="button"
                                        className="ml-5 text-[#48B774] border border-[#48B774]  font-medium rounded-full text-sm py-4 px-5 text-center items-center"
                                    >
                                        Proof of funds

                                    </button>
                                </NavLink>
                            </div>
                        </div>
                        <div className="hidden md:block lg:col-span-2 pt-64">
                            <img src="/images/arrow.png" alt="hero" />
                        </div>

                        <div className=" mt-3 lg:mt-0 lg:col-span-5 ">
                            <img src="/images/afripay-banner.png" alt="hero" />
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-[#FFFFFF] pt-16" >
                <div className=" mx-auto text-center md:py-4 ">
                    <h1 className="text-[#000000] md:text-[36px] text-[30px] px-6 font-[700]">
                        Moving to Finland has been made <span className="text-[#1DB459]">Easier!</span>
                    </h1>
                    <p className=" font-medium text-[16px] mt-1">We have heard your plea and we have decided to make things easy with AfriPay</p>
                </div>
                <div className="lg:px-14 md:grid md:grid-cols-3 gap-4 md:py-10 py-6 p-6">
                    <div className="flex space-x-3 md:mt-0 mt-5">
                        <div className="h-[37px] w-[43px] rounded-full bg-[#F6F6F4] border border-[#1DB459]"></div>
                        <div>
                            <h3 className=" font-semibold text-[16px]">Convenient</h3>
                            <p className=" font-normal text-[15px] max-w-[300px]">You can easily make all payments from the comfort of your home</p>
                        </div>
                    </div>
                    <div className="flex space-x-3 md:mt-0 mt-5">
                        <div className="h-[37px] w-[43px] rounded-full bg-[#F6F6F4] border border-[#1DB459]"></div>
                        <div>
                            <h3 className=" font-semibold text-[16px]">Faster </h3>
                            <p className=" font-normal text-[15px] max-w-[300px]">You can easily make all payments from the comfort of your home </p>
                        </div>
                    </div>
                    <div className="flex space-x-3 md:mt-0 mt-5">
                        <div className="h-[37px] w-[43px] rounded-full bg-[#F6F6F4] border border-[#1DB459]"></div>
                        <div>
                            <h3 className=" font-semibold text-[16px]">Simpler</h3>
                            <p className=" font-normal text-[15px] max-w-[300px]">You can easily make all payments from the comfort of your home </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}



            {/* <!-- Start block --> */}
            <section className="bg-[#FFFFFF] md:pt-[100px] pt-[20px] sm:px-14 px-6 flex justify-center items-start" >
                <div className='xl:max-w-[1280px] w-full'>
                    <div className='flex md:flex-row flex-col sm:py-14 py-6'>
                        <div className='flex-1 flex justify-center items-start flex-col'>
                            <div className="md:flex flex-col w-full">

                                <h1 className="flex-1 font-poppins font-semibold md:text-[40px] text-[25px] md:mt-[20px] mt-[10px] text-[#1B212F] md:leading-[48px] leading-[38px]">
                                    Proof of Funds
                                </h1>
                                <p className='md:mt-[20px] mt-[10px] text-[#707070] md:text-[16px] text-sm max-w-[500px]'>Dummy text but Afripay offers a service for proof of funds. Afripay  assists individuals in providing the necessary documentation to prove that they have the required financial resources for their studies.</p>
                            </div>
                            <div className='mt-[24px]'>
                                <div className="flex space-x-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">Low-Interest Rates</h3>
                                </div>
                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">Low-Interest Rates</h3>
                                </div>
                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">Low-Interest Rates</h3>
                                </div>
                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">Low-Interest Rates</h3>
                                </div>




                            </div>
                            <NavLink to={"/terms"} className='mb-6 mt-[25px]'>
                                <button type="button" className="text-[#1DB459] border border-[#1DB459]  font-medium rounded-full text-sm px-6 py-3 text-center items-center">
                                    Apply Now
                                </button>
                            </NavLink>

                        </div>
                        <div className='flex-1 flex justify-center items-center'>
                            <img src="/images/proof-of-funds.png" alt="hero" />
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-[#FFFFFF] md:pt-[100px] pt-[20px] sm:px-14 px-6 flex justify-center items-start" >
                <div className='xl:max-w-[1280px] w-full'>
                    <div className='flex md:flex-row flex-col sm:py-14 py-6'>

                        <div className='flex-1 flex justify-center items-center'>
                            <img src="/images/loan.png" alt="hero" />
                        </div>

                        <div className='flex-1 flex justify-center items-start flex-col md:ml-[120px] ml-0'>
                            <div className="md:flex flex-col w-full">

                                <h1 className="flex-1 font-poppins font-semibold md:text-[40px] text-[25px] md:mt-[20px] mt-[10px] text-[#1B212F] md:leading-[48px] leading-[38px]">
                                    Loans
                                </h1>
                                <p className='md:mt-[20px] mt-[10px] text-[#707070] md:text-[16px] text-sm max-w-[500px]'>Afripay provides student loans through our partners as a financial solution to support students in pursuing their education. These loans are specifically designed to cater to the unique financial needs of students, helping them cover the costs associated with their studies, such as tuition fees, accommodation, and living expenses.</p>
                            </div>
                            <div className='mt-[24px]'>
                                <div className="flex space-x-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">Low-Interest Rates</h3>
                                </div>
                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">Flexible Repayment Plans</h3>
                                </div>
                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">Quick Approval</h3>
                                </div>
                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">No Collateral Required</h3>
                                </div>
                                <div className="flex space-x-2 mt-[20px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="#1db459"><path d="M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535Z" opacity=".5" /><path d="M18.581 9.474a.75.75 0 1 0-1.162-.948l-5.168 6.33a.749.749 0 0 0-.879 1.116l.286.438a.75.75 0 0 0 1.209.064l5.714-7Zm-4 0a.75.75 0 1 0-1.162-.948l-5.133 6.288l-1.705-2.088a.75.75 0 0 0-1.162.948l2.286 2.8a.75.75 0 0 0 1.162 0l5.714-7Z" /></g></svg>
                                    <h3 className="mt-1 font-normal text-[#838383] text-[14px]">Access to Education</h3>
                                </div>




                            </div>
                            <NavLink to={"/terms"} className='mb-6 mt-[25px]'>
                                <button type="button" className="text-[#1DB459] border border-[#1DB459]  font-medium rounded-full text-sm px-6 py-3 text-center items-center">
                                    Apply Now
                                </button>
                            </NavLink>

                        </div>

                    </div>
                </div>
                
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-[#000] md:pt-20 pt-10 pb-10">
                <div className='xl:max-w-[1280px] w-full'>
                    <div className='flex md:flex-row flex-col py-6 lg:px-14 p-6'>
                        <div className=" md:w-6/12 flex-1 flex-col justify-center items-center">
                            <h3 className="text-white font-[600] md:text-[30px] text-[26px]">Some of the things you <br />may want to know </h3>
                            <h5 className="text-white font-medium text-[16px] mt-1">Frequently asked questions</h5>
                        </div>
                        <div className=" md:w-6/12 flex-1 flex justify-center items-start flex-col md:ml-[150px] ml-0 md:mt-0 mt-8">
                            <Accordion open={open === 1} className=" px-4 mb-3  shadow-md rounded-lg ">
                                <AccordionHeader onClick={() => handleOpen(1)} className='border-b border-white text-base font-medium text-[#fff]'>
                                    What does Afripay do?
                                </AccordionHeader>
                                <AccordionBody className="text-[#fff] text-sm font-normal ">
                                    Yes. It is recommended to consider the distance from the accommodation to the school in
                                    order to avoid too long travels to and from the school.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 2} className=" px-4 mb-3  shadow-md rounded-lg ">
                                <AccordionHeader onClick={() => handleOpen(2)} className='border-b border-white text-base font-medium text-[#fff]'>
                                    What does Afripay do?
                                </AccordionHeader>
                                <AccordionBody className="text-[#fff] text-sm font-normal ">
                                    Yes. It is recommended to consider the distance from the accommodation to the school in
                                    order to avoid too long travels to and from the school.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 3} className=" px-4 mb-3  shadow-md rounded-lg ">
                                <AccordionHeader onClick={() => handleOpen(3)} className='border-b border-white text-base font-medium text-[#fff]'>
                                    What does Afripay do?
                                </AccordionHeader>
                                <AccordionBody className="text-[#fff] text-sm font-normal ">
                                    Yes. It is recommended to consider the distance from the accommodation to the school in
                                    order to avoid too long travels to and from the school.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 4} className=" px-4 mb-3  shadow-md rounded-lg ">
                                <AccordionHeader onClick={() => handleOpen(4)} className='border-b border-white text-base font-medium text-[#fff]'>
                                    What does Afripay do?
                                </AccordionHeader>
                                <AccordionBody className="text-[#fff] text-sm font-normal ">
                                    Yes. It is recommended to consider the distance from the accommodation to the school in
                                    order to avoid too long travels to and from the school.
                                </AccordionBody>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}


            {/* <!-- Start block --> */}
            <Footer/>
            {/* <WFooter/> */}
            {/* <!-- End block --> */}

        </>
    )
}

export default Afripay