import React from 'react'
import Navbar from '../../Navbar'
import blogbg from "../../../img/blog.png";
import Footer from '../../Footer';
import { NavLink } from 'react-router-dom';

function VocationalSchools() {
    return (
        <>
            <Navbar />

            {/* <!-- Start block --> */}
            <section className=" body-font font-poppins pt-16" >
                <div
                    style={{
                        backgroundImage: `url(${blogbg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className=" lg:px-14 pt-10 pb-24 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center text-white pt-20">
                        <h1 className="md:text-5xl text-3xl font-bold">
                            Vocational Schools in Finland: <br />Definitive Insight for Africans
                        </h1>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-[#F5F5F5]  body-font font-poppins">
                <div className='bg-white lg:mx-28 pb-10 lg:px-10 px-8 pt-10 '>
                    <div className='flex justify-center mt-5'>
                        <img src="/images/blog-img5.png" alt="location" className='md:w-[70%] w-full' />
                    </div>
                    <h3 className='text-base text-[#000000] font-normal mt-5'>Wondering how vocational education and training (VET) operate in Finland? If yea, it’s your lucky day! Many international students enjoy less strict VET qualification requirements, solid employment opportunities, eligibility for university studies in Finland, and more. Read this article till the end to learn everything you need about VET admission processing and requirements for international students.
                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-4'>
                        Consider this quick overview of <NavLink to="/high-school" className="text-[#48B774] font-medium">Education in Finland</NavLink> before we delve deeper:
                    </h3>

                    {/* <h3 className='text-base text-[#000000] font-normal mt-3'>
                        Perhaps, you’re asking what is high school like in Finland, right? It’s an atmosphere to learn and build cordial relationship with your classmates and teachers. Get your teachers’ attention and learn in less crowded classrooms from any of the 380 high schools in Finland. To foster connection, Finland’s education system allows you to call your teachers by their first names. Sounds strange, right? It is to get the best from your teachers.

                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-2'>
                        High schools in Finland offer the same standard and quality education irrespective of the location or size. Complete your high school program within 2 to 4 years and access free higher education. Get a permanent residency after your high school education and become a Finnish citizen.

                    </h3> */}
                    <h3 className='text-base text-[#000000] font-normal mt-2'>
                        Receive lesser homework—which means more liberty to build relationships with friends, spend more time with family members, or do other things. Teachers maximize their time in class and so, bulky homework isn’t necessary.

                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-2'>
                        Enjoy free Finland’s local food for lunch—potato with meat and varieties of soup, dark rye bread, and so on. Customarily, expect pancake roll and pea soup every Thursday. Don’t forget, it is not compulsory to eat the school’s lunch—in that instance, go home or visit a grocery store so you can get what to eat during lunchtime.
                    </h3>
                    <h3 className='text-base text-[#000000] font-normal mt-2'>
                        Engage in extracurricular activities to make new friends. Download a language app on your phone or always go with a dictionary to overcome language and communication barriers. Visit different places and participate in sporting activities in your free time.

                    </h3>


                    <div>
                        <h1 className='font-bold text-2xl mt-6'>To Understand How Vocational Education <br />and Training Operate in Finland:</h1>
                        <ul className="space-y-1 text-black list-disc list-inside pl-4 mt-1">
                            {/* <li>
                                Understand The Difference Between Vocational Education and High School Education in Finland
                            </li> */}
                            <li>
                                Discover The Benefits of Vocational Education in Finland for Africans
                            </li>
                            <li>
                                Know The List of Vocational Education and Trainings Available in Finland
                                Ready? Let’s dive deeper!
                            </li>

                        </ul>
                    </div>

                    <div>
                        <h1 className='font-bold text-2xl mt-6'>Understand The Difference Between Vocational<br /> Education in Finland</h1>
                        <div className='flex justify-center mt-5'>
                            <img src="/images/blog-img6.png" alt="location" className='md:w-[70%] w-full' />
                        </div>
                        <h3 className='text-base text-[#000000] font-normal mt-6'>
                            Vocational education and training equip you with vital skills for working life. VET is good for you if you just finished your basic education or you’re a working adult. Finland and takes three years to complete. Choose which qualification to acquire: vocational qualification, vocational upper secondary qualification or specialist vocational qualification.

                        </h3>
                        <h3 className='text-base text-[#000000] font-normal mt-4'>
                            Understand the fundamental skills you need to excel in your field with vocational upper secondary qualification which takes up to three years to complete. Courses that fall to this category include working life skills, mathematics, communication, and interactive skills.
                        </h3>
                        <h3 className='text-base text-[#000000] font-normal mt-4'>
                            For vocational qualification, you work as an apprentice and study simultaneously. Apply for vocational qualification if you have previous knowledge in the skill. For apprenticeship training, you work at a job that pays you a salary until you complete your studies. Sounds cool, right? Sure, it does. Don’t forget to contact your educational institute counselor if you want to choose the apprenticeship study option.

                        </h3>
                        <h3 className='text-base text-[#000000] font-normal mt-4'>
                            Complete your vocational qualification or specialized vocational degree as you develop and gain competence to solve difficult problems in your field.
                        </h3>
                    </div>
                    <div>
                        <h1 className='font-bold text-2xl mt-6'>Discover The Benefits of Vocational<br /> Education in Finland for Africans
                        </h1>
                        <h3 className='text-base text-[#000000] font-normal mt-6'>
                            Wondering while Africans are flooding Finland for vocational education studies? Let’s consider what vacation education in Finland has for you:

                        </h3>
                        <ul className="space-y-1 text-black list-disc list-inside pl-4 mt-3">
                            <li>
                                In 2000, the country made adjustments in policies that make it easy for you to progress your study at the University of applied sciences level after your vocational training.
                            </li>
                            <li>
                                Finland restructured the curriculum to feature the basic requirements that qualify you for university admission and offer on-the-job training and continuous learning standards.
                            </li>
                            <li>
                                Also, vocational education and training centers in Finland relate directly with parents and create structures that allow parents to visit their children.

                            </li>

                        </ul>
                        <h3 className='text-base text-[#000000] font-normal mt-2'>
                            Enjoy flexible study time—much more if you have previous knowledge of your field of study. Choose where you study—online, at work, or at an educational center. Select the qualification you desire (either a complete qualification or a part of the qualification).

                        </h3>
                    </div>
                    <div>
                        <h1 className='font-bold text-2xl mt-6'>Know The List of Vocational Education and<br /> Trainings Available in Finland</h1>
                        <h3 className='text-base text-[#000000] font-normal mt-6'>
                            Earlier, you saw the difference between vocational education in Finland. Later, we explored the benefits of vocational education in Finland. Choose any of the following vocational field of study in Finland:
                        </h3>
                        <ol className="space-y-1 text-black list-decimal list-inside pl-4 mt-3">
                            <li>
                                Health and wellness: Gain adequate competence to work in industries like Pharmaceutical and Dental Technology, welfare, and so on. In these industries, you care for patients to aid their well-being, enforce safety standards, and so on.
                            </li>
                            <li>
                                Education: Build competencies in instruction, communication, and education and work at establishments such as churches, schools, and so on.
                            </li>
                            <li>
                                Social sciences: Obtain a qualification in library services and information and work in an educational institution, mobile library jobs, and so on.
                            </li>
                            <li>
                                Natural sciences: Build competence in studying nature and the environment. Work at jobs such as nature tourism, reindeer husbandry, and so on.

                            </li>
                            <li>
                                Agriculture and Forestry:

                            </li>
                            <li>
                                Information and communication technologies, ICT: Access a wide range of jobs with professionals, occupy expert or supporting positions, and so on.
                            </li>
                            <li>
                                Humanities and arts: Acquire either vocational qualification, vocational upper secondary qualification, or specialist vocational qualification where you develop the capacity to do visual arts, music, theater art, and so on.
                            </li>

                        </ol>
                    </div>

                    <div>
                        <h1 className='font-bold text-2xl mt-6'>Conclusion</h1>
                        <h3 className='text-base text-[#000000] font-normal mt-6'>
                            Vocational education and training in Finland is evolving remarkably and you pay nothing if you graduate from the country. Check the admission requirements and apply now.

                        </h3>

                    </div>


                </div>

            </section>
            {/* <!-- End block --> */}

            <Footer />
        </>
    )
}

export default VocationalSchools