import React, { useState } from 'react'
// @ts-ignore
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Sidebar from '../Sidebar/Sidebar'
import UserNavbar from "../Navbars/UserNavbar"
import ProfileNav from './ProfileNav'
import { DashboardApi } from '../../apis/DashboardApi';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function UploadDoc() {
  const [data, setData] = useState<any>();
  const [recall, setRecall] = useState<any>();

  React.useEffect(() => {
    DashboardApi.getUserDetails().then(
      (response: any) => {
        if (response?.data) {
          setData(response?.data?.data?.user_details)
        }
      }
    );

  }, [recall]);

  // const [fileName2, setFileName2] = React.useState("No selected file");
  // const [coverPhotoName, SetCoverPhotoName] = React.useState<any>("No selected file")

  const [selectedPics2, setSelectedPics2] = React.useState('No selected file');
  const changeHandler22 = (event: any) => {
    // console?.log(2)
    const target = event.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    if (target.files && target.files[0]) {
      if (size > 1) {
        if (targetName == 'image1') {
          target.value = ''
          console.log('error')
        }
      }
      if (size <= 1) {
        if (targetName == 'image1') {
          setSelectedPics2(event.target.files[0]);
        }
      }
    }
  };


  
  const [selectedPics3, setSelectedPics3] = React.useState('No selected file');
  const changeHandler23 = (event: any) => {
    // console?.log(2)
    const target = event.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    if (target.files && target.files[0]) {
      if (size > 2) {
        if (targetName == 'image3') {
          target.value = ''
          console.log('error')
        }
      }
      if (size <= 2) {
        if (targetName == 'image3') {
          setSelectedPics3(event.target.files[0]);
        }
      }
    }
  };


  const [birthCertificate, setBirthCertificate] = React.useState('No selected file');
  const birthCert = (event: any) => {
    // console?.log(2)
    const target = event.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    if (target.files && target.files[0]) {
      if (size > 2) {
        if (targetName == 'birthCert') {
          target.value = ''
          console.log('error')
        }
      }
      if (size <= 1) {
        if (targetName == 'birthCert') {
          setBirthCertificate(event.target.files[0]);
        }
      }
    }
  };


  const [schoolCertificate, setSchoolCertificate] = React.useState('No selected file');
  const schoolCert = (event: any) => {
    // console?.log(2)
    const target = event.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    if (target.files && target.files[0]) {
      if (size > 2) {
        if (targetName == 'schoolCert') {
          target.value = ''
          console.log('error')
        }
      }
      if (size <= 2) {
        if (targetName == 'schoolCert') {
          setSchoolCertificate(event.target.files[0]);
        }
      }
    }
  };


  const [marriageCertificate, setMarriageCertificate] = React.useState('No selected file');
  const marriageCert = (event: any) => {
    // console?.log(2)
    const target = event.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    if (target.files && target.files[0]) {
      if (size > 2) {
        if (targetName == 'marriageCert') {
          target.value = ''
          console.log('error')
        }
      }
      if (size <= 2) {
        if (targetName == 'marriageCert') {
          setMarriageCertificate(event.target.files[0]);
        }
      }
    }
  };

  const [studentStatement, setStudentStatement] = React.useState('No selected file');
  const studStmt = (event: any) => {
    const target = event.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    if (target.files && target.files[0]) {
      if (size > 2) {
        if (targetName == 'studStmt') {
          target.value = ''
          console.log('error')
        }
      }
      if (size <= 2) {
        if (targetName == 'studStmt') {
          setStudentStatement(event.target.files[0]);
        }
      }
    }
  };


  const [passport, setPassport] = React.useState('No selected file');
  const passpt = (event: any) => {
    const target = event.target
    let size = (target.files[0].size / 1048576.0)
    let targetName = target.name
    if (target.files && target.files[0]) {
      if (size > 2) {
        if (targetName == 'passpt') {
          target.value = ''
          console.log('error')
        }
      }
      if (size <= 2) {
        if (targetName == 'passpt') {
          setPassport(event.target.files[0]);
        }
      }
    }
  };


  const UpdateUser = React.useCallback(
    (e: any) => {
      e.preventDefault();
      const userData = new FormData();
      userData.append('id', data?.id);
      userData.append('document1', selectedPics2);
      userData.append('document2', selectedPics3);
      userData.append('birth_certificate', birthCertificate);
      userData.append('school_certificate', schoolCertificate);
      userData.append('marriage_certificate', marriageCertificate);
      userData.append('statement_of_student', studentStatement);
      userData.append('passport_data', passport);
    
      DashboardApi.updateDocuments(userData).then(
        (response: any) => {
          if (response?.data) {
            toast.success(response?.data?.message);
            console?.log(response?.data)
            setRecall('response?.data?.message')
            // navigate('/wallet');
            // dispatch(login({ email: userLoginData?.email, token: userLoginData?.token, id: userLoginData?.id, name: response.data.data.name,data:response.data.data}))
          }
        }
      ).catch((err) => {
        if (err?.response?.status == 422) {
          toast.error('Form not rightly filled. Kindly Cross check.');
        } else {
          toast.error('Some error occured.Please try again');
        }


      }).finally(() => {

      });
    },
    [selectedPics3, selectedPics2,recall,birthCertificate,schoolCertificate,marriageCertificate,studentStatement,passport]
  );

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar title="Profile" />
        <div className='md:py-16'>
          <ProfileNav />


          <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl  md:pl-3 md:pr-4 mt-5">
            <div className="w-full lg:w-12/12 md:px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full  ">
                <div className="flex-auto px-4  py-10 pt-0">
                <form onSubmit={UpdateUser}>
                    <div className="flex flex-wrap">
                     
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#404D61] text-sm font-medium mb-2"
                            htmlFor="grid-password"
                          >
                            Upload Document 1
                          </label>

                          <input accept="image/x-png,image/gif,image/jpeg" className="block w-full text-lg text-gray-900 border border-[#D9D9D9] rounded-lg cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#D9D9D9] ease-linear transition-all duration-150 " 
                          id="large_size"  
                          name='image1' 
                          onChange={changeHandler22} type="file" />
                        </div>

                        <Link target="_blank" to={data?.document1} className=' text-blue-400'> <u>Download Document 1</u> </Link>
                      </div>

                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#404D61] text-sm font-medium mb-2"
                            htmlFor="grid-password"
                          >
                            Upload Document 2
                          </label>

                          <input accept="image/x-png,image/gif,image/jpeg" className="block w-full text-lg text-gray-900 border border-[#D9D9D9] rounded-lg cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#D9D9D9] ease-linear transition-all duration-150 " id="large_size" name='image3' onChange={changeHandler23} type="file" />
                        </div>
                        <Link target="_blank" to={data?.document2} className=' text-blue-400'> <u>Download Document 2</u> </Link>
                      </div>


                      <div className="w-full lg:w-6/12 px-4 pt-10">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#404D61] text-sm font-medium mb-2"
                            htmlFor="grid-password"
                          >
                            Notarized copy of Birth certificate
                          </label>

                          <input accept="image/x-png,image/gif,image/jpeg" className="block w-full text-lg text-gray-900 border border-[#D9D9D9] rounded-lg cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#D9D9D9] ease-linear transition-all duration-150 " id="large_size"  name='birthCert' onChange={birthCert} type="file" />
                        </div>

                        <Link target="_blank" to={data?.birth_certificate} className=' text-blue-400'> <u>Download Birth Certificate</u> </Link>
                      </div>



                      <div className="w-full lg:w-6/12 px-4 pt-10">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#404D61] text-sm font-medium mb-2"
                            htmlFor="grid-password"
                          >
                           Notarized copy of school certificate
                          </label>

                          <input accept="image/x-png,image/gif,image/jpeg" className="block w-full text-lg text-gray-900 border border-[#D9D9D9] rounded-lg cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#D9D9D9] ease-linear transition-all duration-150 " id="large_size"  name='schoolCert' onChange={schoolCert} type="file" />
                        </div>

                        <Link target="_blank" to={data?.school_certificate} className=' text-blue-400'> <u>Download School Certificate</u> </Link>
                      </div>

                      <div className="w-full lg:w-6/12 px-4 pt-10">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#404D61] text-sm font-medium mb-2"
                            htmlFor="grid-password"
                          >
                          Notarized copy of Marriage Certificate
                          </label>

                          <input accept="image/x-png,image/gif,image/jpeg" className="block w-full text-lg text-gray-900 border border-[#D9D9D9] rounded-lg cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#D9D9D9] ease-linear transition-all duration-150 " id="large_size"  name='marriageCert' onChange={marriageCert} type="file" />
                        </div>

                        <Link target="_blank" to={data?.marriage_certificate} className=' text-blue-400'> <u>Download marriage Crtificate</u> </Link>
                      </div>

                      <div className="w-full lg:w-6/12 px-4 pt-10">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#404D61] text-sm font-medium mb-2"
                            htmlFor="grid-password"
                          >
                          Bank statement of Student
                          </label>

                          <input accept="image/x-png,image/gif,image/jpeg" className="block w-full text-lg text-gray-900 border border-[#D9D9D9] rounded-lg cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#D9D9D9] ease-linear transition-all duration-150 " id="large_size"  name='studStmt' onChange={studStmt} type="file" />
                        </div>

                        <Link target="_blank" to={data?.statement_of_student} className=' text-blue-400'> <u>Download Statement of student</u> </Link>
                      </div>
                      <div className="w-full lg:w-6/12 px-4 pt-10">
                        <div className="relative w-full mb-6">
                          <label
                            className="block text-[#404D61] text-sm font-medium mb-2"
                            htmlFor="grid-password"
                          >
                          Passport Data page
                          </label>

                          <input accept="image/x-png,image/gif,image/jpeg" className="block w-full text-lg text-gray-900 border border-[#D9D9D9] rounded-lg cursor-pointer file:py-2 file:px-6 file:border-0 file:bg-[#D9D9D9] ease-linear transition-all duration-150 " id="large_size"  name='passpt' onChange={passpt} type="file" />
                        </div>

                        <Link target="_blank" to={data?.passport_data} className=' text-blue-400'> <u>Download passport</u> </Link>
                      </div>

                    </div>
                    <div className='px-4 mt-5'>
                      <button
                        type="submit"
                        className=" text-white bg-[#1DB459] hover:bg-[#05401C] focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                      >
                        Save
                      </button>
                    </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default UploadDoc