import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer';
function LUTScholarship() {
    return (
        <>
            <Navbar />
            <section className='md:pt-20 pt-20'>
                <div className='bg-[#FBFBFB] sm:px-16 px-4 md:flex md:justify-center items-start pt-10 pb-8'>
                    <div className=' w-full mx-auto'>
                        <div className='md:pr-40 md:pl-40 mx-4 '>
                            <div>
                                <h1 className='text-center md:text-[42px] text-[20px] font-semibold'>Scholarships for Bachelor's Studies</h1>

                            </div>
                            <div className='mt-10'>
                                {/* <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>Scholarship for: Computational Engineering, Sustainable International Business and Industrial Engineering and Management,Technology and Engineering Science.</h3> */}
                                <ul className=' list-disc space-y-1 pl-4 text-[14px] text-[#808080] mt-3'>
                                    <li>Achieve 60 ECTS credits in your first year and Get a 50% scholarship for your second year.</li>
                                    <li>Successfully complete 120 ECTS credits in the first two years and Enjoy a 50% Tuition Fee Waiver for your third year.</li>
                                    
                                </ul>

                                <h3 className='text-[#170F49] font-medium md:text-base text-sm mt-2'>Master's Program:</h3>
                                <ul className=' list-disc space-y-1 pl-4 text-[14px] text-[#808080] mt-1'>
                                    <li>Transition to a Master's program after completing your Bachelor's. Pay only EUR 7,500 for your first year of Master's studies.</li>                                  
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default LUTScholarship