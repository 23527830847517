import { AxiosPromise } from "axios";
import configs from "../configs";
import { DashboardLiveApi } from "./live/DashboardLiveApis";



export class DashboardApi {
    private static dashboardApi: DashboardLiveApi = new DashboardLiveApi();

    static getUserDetails(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getUserDetails();
        }
    }

    static getLearningDetails(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getLearningDetails();
        }
    }

    

    static getCallback(reference:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getCallback(reference);
        }
    }

    static verifyResidentPayment(reference:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.verifyResidentPayment(reference);
        }
    }
    

    static getWalletDetails(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getWalletDetails();
        }
    } 

    static payForCourse(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.payForCourse(data);
        }
    }  

    static updateUser(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.updateUser(data);
        }
    } 

    static dependantInfo(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.dependantInfo(data);
        }
    } 


    static updateDocuments(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.updateDocuments(data);
        }
    } 

    static addWishlist(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.addWishlist(data);
        }
    }

    static deleteWishlist(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.deleteWishlist(data);
        }
    }

    static getWishlist(): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.getWishlist();
        }
    } 

    static makePayment(amount:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.makePayment(amount);
        }
    }

    static makeResidentPayment(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.dashboardApi.makeResidentPayment(data);
        }
    }

    

    


}