import React, { useState } from 'react'
import { SvgElement, icontypesEnum } from './assets/svgElement';
import { Link, NavLink } from "react-router-dom";

function WFooter() {
  const date = new Date();
  const [emailError, setEmailError] = useState('');

  const [userData, setUserdata] = useState({
    'email': "",
  });
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });



    if (name === 'email') {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
  
      // Update the email error state based on validation result
      setEmailError(isValidEmail ? '' : 'Please enter a valid email address.');
    }
  };
  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

  }
  return (
    <>
      <section className='bg-white pt-4 '>
        <div className="max-w-screen-xl px-8 lg:px-14  pb-8 mx-auto lg:gap-8 xl:gap-12 lg:py-10  ">
          <div className='grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-12'>
            <div className='col-span-4 mb-6'>
              <div className=''>
                <img src="./images/logofooter.svg" className="" alt="Afripro Logo" />
              </div>


              <form onSubmit={handleSubmit}>
                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only ">Subscribe</label>

                <div className="relative w-full">
                  <input type="email" 
                     name="email"
                      onChange={handleChange}
                      value={userData.email}
                      id="search-dropdown" className="block p-2.5 w-full z-20 text-sm text-gray-900  rounded-lg  border border-[#48B774] " placeholder="Your Email Here..." required />
                  <button type="submit" className="text-white absolute top-0 right-0 p-2.5 h-full border border-[#1DB459] bg-[#1DB459] hover:bg-[#05401C] font-medium text-sm rounded-r-lg">Subscribe</button>
                </div>
                {emailError && <div className="text-red-500 text-sm mt-1">{emailError}</div>}

              </form>
            </div>

            <div className=' col-span-2'>
              <h4 className='text-[#1DB459] font-medium mb-2'>Products</h4>
              <Link to='/study-in-finnish'> <h3 className='text-[#838383] font-normal'>Learn in Finnish</h3></Link>
              {/* <Link to='/high-school'> <h3 className='text-[#838383] font-normal'>High School</h3></Link> */}
              {/* <Link to='/apply-loan'> <h3 className='text-[#838383] font-normal'>Loans</h3></Link> */}
              <Link to='/study-in-english'> <h3 className='text-[#838383] font-normal'>Learn in English</h3></Link>
            </div>

            <div className=' col-span-2'>
              <h4 className='text-[#1DB459] font-medium mb-2'>About Us</h4>
              {/* <h3>Branding</h3> */}
              <Link to='/blog'> <h3 className='text-[#838383] font-normal'>Blog</h3></Link>
              <Link to='/about-us'> <h3 className='text-[#838383] font-normal'>About us</h3></Link>
              {/* <h3>Affiliates</h3> */}
              {/* <h3>Careers</h3> */}
            </div>
            <div className=' col-span-2'>
              <h4 className='text-[#1DB459] font-medium mb-2'>Help and Support</h4>
              <Link to='/consultation'><h3 className='text-[#838383] font-normal'>Consultation</h3></Link>
              <Link to='/contact-us'>  <h3 className='text-[#838383] font-normal'>Contact Us</h3></Link>
              <Link to='/terms-condition'><h3 className='text-[#838383] font-normal'>Privacy & Terms</h3></Link>
              {/* <h3>Safety information</h3> */}
            </div>
            {/* <div className=' col-span-2'>
            <h4 className='text-[#1DB459] font-medium mb-2'>Partners</h4>
            <Link to='https://finestfuture.org/' target='_blank'><h3 className='text-[#838383] font-normal'>Finest Future</h3></Link>
            <Link to='https://edusampo-online.fi/en/etusivu/' target='_blank'><h3 className='text-[#838383] font-normal'>Edusampo</h3></Link>
              <Link to='https://www.takk.fi/en/takk/international-takk/global-vet-1' target='_blank'> <h3 className='text-[#838383] font-normal'>Takk</h3></Link>
              <Link to='https://www.uwasa.fi/en' target='_blank'> <h3 className='text-[#838383] font-normal'>Vassa</h3></Link>
              <Link to='https://www.lut.fi/en' target='_blank'> <h3 className='text-[#838383] font-normal'>LUT and LAB</h3></Link>
            </div> */}

          </div>

          <div className='flex flex-col lg:flex-row lg:justify-between mt-16' >
            <div className='flex space-x-12 text-[#1DB459]'>
              <p> &copy; copyright {date.getFullYear()}</p>
              <div className='flex lg:space-x-8 space-x-3'>
                {/* <p>Help</p> */}
                {/* <Link to='/terms-condition'><p>Privacy</p></Link> */}
                <Link to='/terms-condition'><p>Terms and Condition</p></Link>
              </div>
            </div>
            <div className='flex space-x-12 mt-4 lg:mt-0'>
              <div className="flex space-x-2 mt-1">
                <NavLink to="https://twitter.com/afriproedu?s=21&t=A8e-6qmPz4A32mqYaoclXA" className=" hover:text-gray-900 mt-1">
                  <SvgElement type={icontypesEnum.TWITTER} />
                </NavLink>
                <NavLink to="https://www.facebook.com/AfriProEdu?mibextid=ZbWKwL" className=" hover:text-gray-900">
                  <SvgElement type={icontypesEnum.FACEBOOK} />
                </NavLink>
                <NavLink to="https://instagram.com/afriproedu?igshid=YmMyMTA2M2Y=" className=" hover:text-gray-900">
                  <SvgElement type={icontypesEnum.INSTAGRAM} />
                </NavLink>
                <NavLink to="https://www.linkedin.com/company/afriproedu/" className=" hover:text-gray-900">
                  <SvgElement type={icontypesEnum.LINKEDIN} />
                </NavLink>
              </div>
              <div className='text-[#1DB459] pl-3 mt-1'>US-English</div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WFooter;