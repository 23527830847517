import React from "react";
import AdminSidebar from "../../Sidebar/AdminSidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import { Link, NavLink } from "react-router-dom";
import { AdminApis } from "../../../apis/adminApi";
import { AxiosResponse } from "axios";


const FaqAdmin = () => {
  const [faqLists, setFaqList] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState("");

  // Function to sanitize HTML content
  const sanitizeHTML = (html:any) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  React.useEffect(() => {
    const query: any = {
      search: searchText,
    };
    AdminApis.getAllFaq()
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setFaqList(response?.data?.data);
          console.log(response?.data?.data);
          // Filter out "Master's Program" category
          // const filteredData = response?.data?.data.filter((course: any) => course.category !== "master\'s program");
          // setGroupList(filteredData);
        }
      })
      .catch(function (error) {
        // handle error
      });
  }, []);

  
  return (
    <div>
      <AdminSidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar />

        <div className="lg:pt-10 lg:px-10 px-6 ">
          <div className="flex justify-between">
            <h3 className=" font-bold text-[#303840] text-[30px]">Faq</h3>
            <Link to="/add-faq">
              <button
                type="button"
                className="font-normal text-white bg-[#1DB459] px-5 py-2 rounded-md"
              >
                Add New Faq
              </button>
            </Link>
          </div>
        </div>

        <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#c4c4c4] mx-6 lg:mt-6 lg:mx-10 mt-5">
          <div className="flex md:justify-end justify-start px-6 md:px-8 pt-6">
            <div className=" lg:ml-auto mr-3">
              <div className="relative flex w-full ">
                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#9da4aa"
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  placeholder="Search Courses..."
                  className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  "
                />
                {/* <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required /> */}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 md:gap-10 gap-4 md:grid-cols-3 lg:px-5 px-6 pt-5 pb-24 mx-auto lg:grid lg:grid-cols-3 xl:gap-4  lg:space-y-0 lg:py-6 lg:mt-1">
            {/* <NavLink to={`/edit-faq`} >
                            <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow p-2 ">  
                         
                                <div className=" pb-2 pt-2"> 

                                    <h5 className="text-[16px] font-semibold text-black "> Can parents opt to arrange accommodation for their Children?
</h5>
                                    <p className='text-[14px] truncate-3-lines-custom text-black '>Yes. It is recommended to consider the distance from the accommodation to the school in order to avoid too long travels to and from the school.</p>
                                </div>
                            </div>
                            </NavLink>
                            <NavLink to={`/edit-faq`} >
                            <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow p-2 ">  
                         
                                <div className=" pb-2 pt-2"> 

                                    <h5 className="text-[16px] font-semibold text-black ">What are the processes we offer?

</h5>
                                    <p className='text-[14px] truncate-3-lines-custom text-black '>Access to our expert language teachers
Access to our Finnish Language certificate
Access to apply to our partner schools in Finland
Access to our language materials
Access to our learning portals and entire language learning journey.
Access to our visa and documentation processing and support
Access to get support to apply for apartments and some basic supports needed on or before arrival to Finland</p>
                                </div>
                            </div>
                            </NavLink>

                            <NavLink to={`/edit-faq`} >
                            <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow p-2 ">  
                         
                                <div className=" pb-2 pt-2"> 

                                    <h5 className="text-[16px] font-semibold text-black ">What are the products we offer ( Long and Short)

</h5>
                                    <p className='text-[14px] truncate-3-lines-custom text-black '>We have two programs:
Short program: you learn the Finnish language in your home country for two months before travelling to Finland and then proceed to learning the Finnish language in Finland for four months . This also covers the accommodation and lunch. After the four months program you will do inteview with our various Finnish partners schools before getting accepted . Once you are accepted, you will be issued the school acceptance letter which is one of the documents to process your residence permit stay I Finland.
Long program: you learn the Finnish language in your home country for 8- 10 months before travelling to Finland. This process entails learning the Finnish language online with our teachers and other students across the world. It is 100% online ( virtual class ) . Once you complete your 8th Months program, you proceed to applying for inteview with our partner schools. Once you get accepted, you will be issued with the acceptance letter which is among the documents needed to process your residence permit to Finland.</p>
                                </div>
                            </div>
                            </NavLink> */}


            {faqLists?.map((datas: any, index: any) => (
              <NavLink to={`/edit-faq/${datas?.id}`}>
                <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow p-2 ">
                  <div className=" pb-2 pt-2">
                    <h5 className="text-[16px] font-semibold text-black ">
                      {datas.question}
                    </h5>
                    <h4 className="text-[14px] truncate-3-lines-custom text-black ">
                    {sanitizeHTML(datas.answer)}
                    </h4>
                    <p className="text-[12px]  text-right text-black">
                      {datas.faq_category}
                    </p>
                  </div>
                </div>
              </NavLink>
            ))}
          </div>

          {/* <div className='m-4'>
                        {
                            groupLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-green-500 text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default FaqAdmin;
