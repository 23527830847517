import React, { useState } from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar';
import UserNavbar from '../../Navbars/UserNavbar'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AdminApis } from '../../../apis/adminApi';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import configs from '../../../configs';
import { useNavigate } from "react-router-dom";
//@ts-ignore
import Modal from 'react-awesome-modal';

function AddBlog() {

    const params: any = useParams();
    let userId: string = params?.id;
    const navigate = useNavigate();


    const [value, setValue] = useState<any>('');

    const [blogData, setBlogData] = React.useState<any>('');
    const [searchText, setSearchText] = React.useState('');

    let [visible, setVisible] = React.useState(false);
    function toggleModal() {
      setVisible(!visible)
    }

    const deleteCourse = React.useCallback(
        (e: any) => {
            AdminApis.deleteBlog(userId).then(
                (response: any) => {
                    if (response?.data) {
                        console.log(response?.data?.data)
                        toast.success(response?.data?.message);
                        setVisible(false)
                        navigate('/blog-list');
                    }
                }
            ).catch((err: any) => {
                if (err?.response?.status == 422) {
                    //   toast.error('Form not rightly filled. Kindly Cross check.');
                } else {
                    //   toast.error('Some error occured.Please try again');
                }
  
  
            })
        },
        []
    );



    React.useEffect(() => {
        AdminApis.getSingleBlog(userId).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setBlogData(response?.data?.data)
                    console?.log(response?.data?.data);
                    setValue(response?.data?.data?.article_body)
                }
            }
        ).catch(function (error) {

        })
    }, []);


    const [image, setImage] = useState(null)
    const [fileName, setFileName] = useState("No selected file")
    const [fileName2, setFileName2] = useState("No selected file")
    const [errorMes, setErrorMes] = React.useState("")

    var toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        ['bold', 'italic', 'underline', 'link'],        // toggled buttons
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        ['image'],

        ['clean']                                         // remove formatting button
    ];
    const module = {
        toolbar: toolbarOptions
    }

    let [userData, setUserData] = React.useState({
        article_title: "", author_name: "",
        article_category: "", blog_summary: "",
        status: "", article_body: "",
        blog_url: "", reading_minute: "",
        date_posted: ""
    });

    const handleChange = (e: any) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    }


    const [selectedPics1, setSelectedPics1] = React.useState('No selected file');
    const changeHandler1 = (e: any) => {
        // console?.log(e.target.files)
        const target = e.target
        let size = (target.files[0].size / 1048576.0)
        let targetName = target.name
        setFileName(e.target.files[0].name)
        // console?.log(size)
        if (target.files && target.files[0]) {
            if (size > 1) {
                if (targetName == 'image1') {
                    setErrorMes("File too big!")
                    target.value = ''
                    console.log('error')
                }
            }
            if (size <= 1) {
                if (targetName == 'image1') {
                    setErrorMes(" ")
                    setSelectedPics1(e.target.files[0]);
                    console.log(e.target.files[0])
                }
            }
        }
    };

    const [selectedPics2, setSelectedPics2] = React.useState('No selected file');
    const changeHandler22 = (event: any) => {
        console?.log(2)
        const target = event.target
        let size = (target.files[0].size / 1048576.0)
        let targetName = target.name
        setFileName2(event.target.files[0].name)
        // console?.log(size)
        if (target.files && target.files[0]) {
            if (size > 1) {
                if (targetName == 'image2') {
                    setErrorMes("File too big!")
                    target.value = ''
                    console.log('error')
                }
            }
            if (size <= 1) {
                if (targetName == 'image2') {
                    setErrorMes(" ")
                    setSelectedPics2(event.target.files[0]);
                }
            }
        }
    };



    const UpdateUser = React.useCallback(
        (e: any) => {
            e.preventDefault();
            const blogData2 = new FormData();
            blogData2.append('article_image', selectedPics1);
            blogData2.append('article_image2', selectedPics2);
            blogData2.append('article_title', (userData?.article_title == '' ? blogData?.article_title : userData?.article_title));
            blogData2.append('author_name', (userData?.author_name == '' ? blogData?.author_name : userData?.author_name));
            blogData2.append('article_category', (userData?.article_category == '' ? blogData?.article_category : userData?.article_category));
            blogData2.append('reading_minute', (userData?.reading_minute == '' ? blogData?.reading_minute : userData?.reading_minute));
            blogData2.append('blog_summary', (userData?.blog_summary == '' ? blogData?.blog_summary : userData?.blog_summary));
            blogData2.append('status', (userData?.status == '' ? blogData?.status : userData?.status));
            blogData2.append('article_body', value);
            blogData2.append('id', blogData?.id);
            blogData2.append('blog_url', (userData?.blog_url == '' ? blogData?.blog_url : userData?.blog_url));
            blogData2.append('reading_minute', (userData?.author_name == '' ? blogData?.author_name : userData?.author_name));
            blogData2.append('date_posted', (userData?.date_posted == '' ? blogData?.date_posted : userData?.date_posted));


            AdminApis.updateBlog(blogData2).then(
                (response: any) => {
                    if (response?.data) {
                        console.log(response?.data?.data)
                        toast.success(response?.data?.message);
                        navigate('/blog-list');
                    }
                }
            ).catch((err: any) => {
                if (err?.response?.status == 422) {
                    //   toast.error('Form not rightly filled. Kindly Cross check.');
                } else {
                    //   toast.error('Some error occured.Please try again');
                }


            }).finally(() => {

            });
        },
        [userData, selectedPics1, selectedPics2, value]
    );

    return (
        <>
            <AdminSidebar />

            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar />
                <form onSubmit={UpdateUser}>
                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl md:pt-10  pl-3 pr-4">
                        <div className="w-full lg:w-12/12">
                            <div className='flex justify-between px-4 pb-3'>
                                <h3 className=' font-bold text-[25px]'>Edit Blog</h3>
                                <span> <button type='button' onClick={toggleModal} className="text-white bg-[#b41d1d] px-3 py-1 rounded-md"> Delete</button></span>
                            </div>
                            <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                <div className="flex-auto md:px-4  py-10 pt-0">
                                    <div className="flex flex-wrap">
                                        {/* upload image */}
                                        <div className="w-full lg:w-6/12 px-4">
                                            <div className="flex items-center justify-center w-full">
                                                <label className="flex flex-col items-center justify-center w-full h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                    <div className="flex flex-col items-center justify-center pt-16 ">
                                                        {fileName == "No selected file" ?
                                                            <img className="" src={blogData?.article_image_url} alt={blogData?.article_image} style={{ maxHeight: "20vh", minWidth: "100%", maxWidth: '100%', minHeight: "20vh" }} />
                                                            :
                                                            ''
                                                        }


                                                        <p className="mb-2 text-sm text-[#6C757D] font-medium ">{selectedPics1 === "No selected file" ? "No selected file" : fileName}</p>
                                                    </div>
                                                    <input id="dropzone-file" name='image1' onChange={changeHandler1} type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
                                                </label>
                                            </div>

                                        </div>
                                        {/* upload image */}
                                        <div className="w-full lg:w-6/12 px-4 pt-2 md:pt-0">
                                            <div className="flex items-center justify-center w-full">
                                                <label className="flex flex-col items-center justify-center w-full h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                    <div className="flex flex-col items-center justify-center pt-16 ">
                                                        {fileName2 == "No selected file" ?
                                                            <img className="" src={blogData?.article_image2_url} alt={blogData?.article_image2} style={{ maxHeight: "20vh", minWidth: "100%", maxWidth: '100%', minHeight: "20vh" }} />
                                                            :
                                                            ''
                                                        }
                                                        <p className="mb-2 text-sm text-[#6C757D] font-medium ">{selectedPics2 === "No selected file" ? "No selected file 2" : fileName2}</p>
                                                    </div>
                                                    <input id="dropzone-file2" name='image2' onChange={changeHandler22} type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
                                                </label>
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-4/12 px-4 pt-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Blog Title
                                                </label>
                                                <input
                                                    type="text"
                                                    name="article_title"
                                                    defaultValue={blogData?.article_title}
                                                    onChange={handleChange}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-4/12 px-4 md:pt-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Author Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="author_name"
                                                    defaultValue={blogData?.author_name}
                                                    onChange={handleChange}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder="study-in-finland"

                                                />
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-4/12 px-4 md:pt-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Date Posted
                                                </label>
                                                <input
                                                    type="text"
                                                    name="date_posted"
                                                    defaultValue={blogData?.date_posted}
                                                    onChange={handleChange}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder="26 Jun 2023"

                                                />
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-4/12 px-4 ">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Blog Category ({blogData?.article_category})
                                                </label>
                                                <select
                                                    id="category"
                                                    name="article_category"
                                                    onChange={handleChange}
                                                    className=" border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg  block w-full p-2.5 "
                                                >
                                                    <option selected></option>
                                                    <option value="vocational">Vocational</option>
                                                    {/* <option value="high school">High School</option> */}
                                                    <option value="degree program">Degree Program</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-4/12 px-4 ">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Reading Minutes
                                                </label>
                                                <input
                                                    type="text"
                                                    name="reading_minute"
                                                    defaultValue={blogData?.reading_minute}
                                                    onChange={handleChange}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-4/12 px-4 ">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Status
                                                </label>
                                                <input
                                                    type="text"
                                                    name="status"
                                                    defaultValue={blogData?.status}
                                                    onChange={handleChange}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-12/12 px-4 ">
                                            <div>Blog Body</div>
                                            <ReactQuill theme="snow"
                                                modules={module}
                                                value={value}
                                                onChange={setValue}
                                                placeholder={'Content of the Editor'}
                                                className='lg:h-60' />
                                        </div>

                                        <div className="w-full lg:w-2/12 px-4 mt-5">
                                            <div className='flex justify-center items-center lg:pt-16'>
                                                <button type='submit' className="font-normal text-white bg-[#1DB459] px-6 py-1.5 rounded-md">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <section>
                <Modal
                    visible={visible}
                    width="700"
                    minHeight="300"
                    effect="fadeInUp"
                    onClickAway={() => toggleModal}
                >
                    <p className="flex justify-end mt-2 ml-2 mr-2 cursor-pointer font-bold" onClick={toggleModal}>X</p>
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-xl rounded-lg mt-2" >
                        <div className="px-6">

                            <div className=''>
                            <div className='flex justify-center'>Are you sure you want to delete this Partner?</div>
                            <div className='flex justify-center py-10'><button type='button' onClick={deleteCourse} className="text-white bg-[#b41d1d] px-3 py-1 rounded-md "> Proceed</button></div>
                            </div>
                                


                        </div>
                    </div>
                </Modal>
            </section>


            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    );
}

export default AddBlog;