import React from 'react'
import Navbar from '../../Navbar'
import blogbg from "../../../img/blog.png";
import Footer from '../../Footer';
import { NavLink } from 'react-router-dom';

function Safety() {
    return (
        <>
            <Navbar />

            {/* <!-- Start block --> */}
            <section className=" body-font font-poppins pt-16" >
                <div
                    style={{
                        backgroundImage: `url(${blogbg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className="lg:px-14 pt-10 pb-24 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center text-white pt-20">
                        <h1 className="md:text-5xl text-3xl font-bold">
                        Why Finland is Among the Safest Countries in the World to Live, Study and Work.
                        </h1>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-[#F5F5F5]  body-font font-poppins">
                <div className='bg-white lg:mx-28 pb-10 lg:px-10 px-8 pt-10 '>
                    <div>
                    <div className='flex justify-center mt-5'>
                        <img src="/images/Safe.jpg" alt="location" className='md:w-[70%] w-full'/> 
                    </div>
                    <div className='flex justify-center mx-auto md:px-44'>
                    <p className='italic mt-1 text-[#333333]'>Why Finland is Among the Safest Countries in the World to Live, Study and Work.</p>
                    </div>
                    
                    </div>
                    <h3 className='text-base text-[#000000] font-normal mt-5'>Finland is often considered one of the safest countries in the world. While safety rankings can
vary depending on the criteria used and the specific methodology, Finland consistently ranks
high in various global safety indexes and reports.</h3>

<h3 className='text-base text-[#000000] font-normal mt-5'>The Global Peace Index (GPI), which measures the relative peacefulness of countries and
regions, has consistently ranked Finland among the top countries in the world. In the 2021 GPI
report, Finland was ranked as the third safest country globally.</h3>

<h3 className='text-base text-[#000000] font-normal mt-5'>Finland also has a reputation for having low crime rates. Violent crimes, such as homicides, are
relatively rare in Finland compared to many other countries. The country has a well-functioning
legal system, effective law enforcement agencies, and a strong commitment to promoting social
welfare and equality.</h3>

<h3 className='text-base text-[#000000] font-normal mt-5'>In addition to low crime rates, Finland is known for its emphasis on public safety and
preparedness. The country has comprehensive emergency management systems, efficient
healthcare infrastructure, and high-quality education and social services that contribute to a
secure environment.</h3>

<h3 className='text-base text-[#000000] font-normal mt-5'>However, it&#39;s important to note that no country is completely free from crime or safety concerns.
While Finland generally enjoys a high level of safety, it&#39;s still essential to exercise caution and
follow local guidelines and regulations to ensure personal safety, as situations can vary
depending on specific locations and circumstances.</h3>


    
    <h3 className='text-base text-[#000000] font-normal mt-5'>Finland is considered a safe country for several reasons:</h3>


<ol className="space-y-1 text-[#000000] list-decimal list-inside mt-3">
    <li><span className='font-semibold'>Low crime rate: </span>Finland consistently ranks among the countries with the lowest crime
rates in the world. Violent crime is relatively rare, and the overall crime levels are
significantly lower compared to many other countries. This contributes to a sense of
safety and security among residents and visitors.</li>
<li><span className='font-semibold'>Effective law enforcement: </span>Finland has a well-functioning and efficient police force that
ensures public safety. The police are generally regarded as trustworthy, professional, and
responsive to the needs of the community.</li>
<li><span className='font-semibold'>Stable political environment: </span>Finland has a stable political system and a strong rule of
law. The country has a long tradition of democracy, transparency, and accountability.
Political stability contributes to an environment of safety and security.</li>
<li><span className='font-semibold'>Social equality and welfare: </span>Finland place a strong emphasis on social equality and has a
comprehensive welfare system that supports its citizens. The country&#39;s social policies,
such as affordable healthcare, education, and social security, help create a more equitable
society and contribute to a sense of well-being and safety.</li>
<li><span className='font-semibold'>High standard of living: </span>Finland consistently ranks high in various quality-of-life indexes.
The country offers a high standard of living, including access to excellent healthcare,
education, and social services. These factors contribute to a general sense of safety and
well-being among the population.</li>
<li><span className='font-semibold'>Natural environment: </span>Finland is known for its pristine nature, vast forests, and clean air.
The country&#39;s natural environment is well preserved and offers plenty of opportunities for outdoor activities. This contributes to a sense of safety and well-being, as people can
enjoy the outdoors without significant concerns about environmental hazards.
</li>

</ol>
                    
                 
                    <div>
                        <h3 className='text-base text-[#000000] font-normal mt-5'>
                        It&#39;s important to note that while Finland is generally considered a safe country, no place is
entirely free from crime or risk. It&#39;s always advisable to exercise common sense, follow local
laws and regulations, and take necessary precautions when visiting or living in any country.
                        </h3>
                       
                    </div>

          
                </div>

            </section>
            {/* <!-- End block --> */}

            <Footer />
        </>
    )
}

export default Safety;