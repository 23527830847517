import React, { useState } from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar';
import UserNavbar from '../../Navbars/UserNavbar'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AdminApis } from '../../../apis/adminApi';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

function AddBlog() {

    const [value, setValue] = useState('');
    const [image, setImage] = useState(null)
    const [fileName, setFileName] = useState("No selected file")
    const [fileName2, setFileName2] = useState("No selected file")
    const [errorMes, setErrorMes] = React.useState("")

    const navigate = useNavigate();

    var toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        ['bold', 'italic', 'underline', 'link'],        // toggled buttons
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        ['image'],

        ['clean']                                         // remove formatting button
    ];
    const module = {
        toolbar: toolbarOptions
    }

    let [userData, setUserData] = React.useState({
        article_title: "", author_name: "",
        article_category: "", blog_summary: "",
        status: "", article_body: "",
        blog_url: "", reading_minute: "",
        date_posted: ""
    });

    const handleChange = (e: any) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    }


    const [selectedPics1, setSelectedPics1] = React.useState('No selected file');
    const changeHandler1 = (e: any) => {
        // console?.log(e.target.files)
        const target = e.target
        let size = (target.files[0].size / 1048576.0)
        let targetName = target.name
        setFileName(e.target.files[0].name)
        // console?.log(size)
        if (target.files && target.files[0]) {
            if (size > 1) {
                if (targetName == 'image1') {
                    setErrorMes("File too big!")
                    target.value = ''
                    console.log('error')
                }
            }
            if (size <= 1) {
                if (targetName == 'image1') {
                    setErrorMes(" ")
                    setSelectedPics1(e.target.files[0]);
                    console.log(e.target.files[0])
                }
            }
        }
    };

    const [selectedPics2, setSelectedPics2] = React.useState('No selected file');
    const changeHandler22 = (event: any) => {
        console?.log(2)
        const target = event.target
        let size = (target.files[0].size / 1048576.0)
        let targetName = target.name
        setFileName2(event.target.files[0].name)
        // console?.log(size)
        if (target.files && target.files[0]) {
            if (size > 1) {
                if (targetName == 'image2') {
                    setErrorMes("File too big!")
                    target.value = ''
                    console.log('error')
                }
            }
            if (size <= 1) {
                if (targetName == 'image2') {
                    setErrorMes(" ")
                    setSelectedPics2(event.target.files[0]);
                }
            }
        }
    };



    const UpdateUser = React.useCallback(
        (e: any) => {
            e.preventDefault();
            const blogData = new FormData();
            blogData.append('article_image', selectedPics1);
            blogData.append('article_image2', selectedPics2);
            blogData.append('article_title', userData?.article_title);
            blogData.append('author_name', userData?.author_name);
            blogData.append('article_category', userData?.article_category);
            blogData.append('reading_minute', userData?.reading_minute);
            blogData.append('blog_summary', userData?.blog_summary);
            blogData.append('status', userData?.status);
            blogData.append('article_body', value);
            blogData.append('blog_url', 'url');
            blogData.append('date_posted', userData?.date_posted);

            console?.log()

            AdminApis.createBlog(blogData).then(
                (response: any) => {
                    if (response?.data) {
                        console.log(response?.data?.data)
                        toast.success(response?.data?.message);
                        navigate('/blog-list');
                    }
                }
            ).catch((err: any) => {
                toast.error(err.response.data?.message);

            }).finally(() => {

            });
        },
        [userData, selectedPics1, selectedPics2, value]
    );

    return (
        <>
            <AdminSidebar />

            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar />
                <form onSubmit={UpdateUser}>
                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl md:pt-10  pl-3 pr-4">
                        <div className="w-full lg:w-12/12">
                            <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                <div className="flex-auto md:px-4  py-10 pt-0">
                                    <div className="flex flex-wrap">
                                        {/* upload image */}
                                        <div className="w-full lg:w-6/12 px-4">
                                            <div className="flex items-center justify-center w-full">
                                                <label className="flex flex-col items-center justify-center w-full h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                    <div className="flex flex-col items-center justify-center pt-16 ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 24 24"><path fill="#666" d="M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5l-5 5z" /></svg>
                                                        <p className="mb-2 text-sm text-[#6C757D] font-medium ">{selectedPics1 === "No selected file" ? "No selected file" : fileName}</p>
                                                    </div>
                                                    <input id="dropzone-file" name='image1' onChange={changeHandler1} type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
                                                </label>
                                            </div>

                                        </div>
                                        {/* upload image */}
                                        <div className="w-full lg:w-6/12 px-4 pt-2 md:pt-0">
                                            <div className="flex items-center justify-center w-full">
                                                <label className="flex flex-col items-center justify-center w-full h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                    <div className="flex flex-col items-center justify-center pt-16 ">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 24 24"><path fill="#666" d="M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5l-5 5z" /></svg>
                                                        <p className="mb-2 text-sm text-[#6C757D] font-medium ">{selectedPics2 === "No selected file" ? "No selected file 2" : fileName2}</p>
                                                    </div>
                                                    <input id="dropzone-file2" name='image2' onChange={changeHandler22} type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
                                                </label>
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-4/12 px-4 pt-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Blog Title
                                                </label>
                                                <input
                                                    type="text"
                                                    name="article_title"
                                                    onChange={handleChange}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-4/12 px-4 md:pt-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Author Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="author_name"
                                                    onChange={handleChange}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder=" "

                                                />
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-4/12 px-4 md:pt-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Date Posted
                                                </label>
                                                <input
                                                    type="text"
                                                    name="date_posted"
                                                    onChange={handleChange}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder="26 Jun 2023"

                                                />
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-4/12 px-4 ">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Blog Category
                                                </label>
                                                <select
                                                    id="category"
                                                    name="article_category"
                                                    onChange={handleChange}
                                                    className=" border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg  block w-full p-2.5 "
                                                >
                                                    <option selected></option>
                                                    <option value="vocational">Vocational</option>
                                                    {/* <option value="high school">High School</option> */}
                                                    <option value="degree program">Degree Program</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-4/12 px-4 ">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Reading Minutes
                                                </label>
                                                <input
                                                    type="text"
                                                    name="reading_minute"
                                                    onChange={handleChange}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-4/12 px-4 ">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#666666] text-sm font-medium md:pl-1 mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Status
                                                </label>
                                                <input
                                                    type="text"
                                                    name="status"
                                                    onChange={handleChange}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-12/12 px-4 ">
                                            <div>Blog Body</div>
                                            <ReactQuill theme="snow"
                                                modules={module}
                                                value={value}
                                                onChange={setValue}
                                                placeholder={'Content of the Editor'}
                                                className='h-60' />
                                        </div>


                                        <div className="w-full lg:w-2/12 px-4">
                                            <div className='flex justify-center items-center lg:pt-16'>
                                                <button type='submit' className="font-normal text-white bg-[#1DB459] px-6 py-1.5 rounded-md">Create</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>


            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    );
}

export default AddBlog;