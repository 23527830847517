import React, { useState } from 'react'
import Navbar from '../Navbar'
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { NavLink, useNavigate } from "react-router-dom";
import hero from "../../img/new-course-bg.png";
import herosmall from "../../img/new-course-small.png";
import { useParams } from 'react-router-dom';
import { AdminApis } from '../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { AuthApis } from '../../apis/authApis';
import configs from '../../configs';
import Footer from '../Footer';

export const SchoolDetails = () => {

    const [openTestTab, setOpenTestTab] = useState(1);
    const [courseList, setCourseList] = React.useState<any>('');

    const params: any = useParams();
    let partnerId: string = params?.schoolId;
    const navigate = useNavigate();

    const [schoolData, setSchooldata] = React.useState<any>('');

    React.useEffect(() => {
        AuthApis.getSinglePartnerSchoolById(partnerId).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setSchooldata(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error 

        })


    }, []);

    console?.log(schoolData)

    // console?.log(schoolData)

    React.useEffect(() => {
        const query: any = {
            title: '',
            category: ''
        };
        AuthApis.getFilteredCourse('', query).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setCourseList(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })
    }, []);



    return (
        <>
            <Navbar />
            {/* <!-- Start block --> */}

            <section className=' hidden md:block body-font font-poppins md:pt-20 relative'>
                
                <div
                    style={{
                        backgroundImage: `url(${hero})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className='md:bg-cover bg-contain relative mt-[75px] md:mt-0 sm:px-16 md:px-14 px-6 md:pb-20 md:pt-20 pt-2  flex items-start'
                >
                   
                    <div className='h-40 absolute top-2 text-white text-[32px] rounded-lg left-6 shadow-lg md:h-40' onClick={() => navigate(-1)}> ← </div>
                    
                    <div className=" text-white md:pt-20 md:pb-5 pb-5 md:pl-16 flex md:flex-row flex-col md:justify-center">
                    
                        <div className='md:mr-10'>
                            <img src={schoolData?.logo_url} alt={schoolData?.logo} className=' md:h-[200px]' />
                        </div>

                        <div className=" text-white  pb-5 md:pb-0 ">
                            <h1 className="md:text-5xl text-xl font-bold mt-[12px] lg:max-w-[800px] flex justify-center">
                                {schoolData?.school_name}
                            </h1>
                            <p className='mt-[10px] font-light md:text-base text-sm '>Visiting Address : {schoolData?.school_contact_details}</p>
                            <p className='hidden md:block font-light md:text-base text-sm'>Postal Address : {schoolData?.website_url}</p>
                            {/* <p className='font-light md:text-base text-sm'>Program: {schoolData?.program}</p> */}
                            <p className='font-light md:text-base text-sm'>Tel.  {schoolData?.twitter_link}</p>
                            <p className='font-light md:text-base text-sm'>Email :  {schoolData?.instagram_link}</p>
                            {/* <p className=' font-light md:text-base text-sm'>Year Founded : {schoolData?.year_founded}</p> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Mobile Section --> */}
            {/* <!-- Start block --> */}
            <section className='block md:hidden md:pt-20 relative'>
                <div
                    style={{
                        backgroundImage: `url(${herosmall})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className='md:bg-cover bg-contain relative mt-[75px] md:mt-0 sm:px-16 md:px-14 px-6 md:pb-20 md:pt-20 pt-2  flex items-start'
                >
                    <div className='h-40 absolute -top-3 text-white text-[32px] rounded-lg left-6 shadow-lg md:h-40' onClick={() => navigate(-1)}> ← </div>
                    
                    <div className=" text-white md:pt-20 pb-3 md:pl-16 flex md:flex-row flex-col md:justify-center">
                        <div className='mt-6'>
                            <img src={schoolData?.logo_url} alt={schoolData?.logo} className='h-16 md:h-[200px]' />
                        </div>

                        <div className=" text-white  pb-5 md:pb-0 ">
                            <h1 className="md:text-5xl text-xl font-bold mt-[10px] lg:max-w-[800px] ">
                                {schoolData?.school_name}
                            </h1>
                            <p className='mt-[6px] font-light md:text-base text-sm '>Visiting Address : {schoolData?.school_contact_details}</p>
                            <p className='font-light md:text-base text-sm'>Postal Address : {schoolData?.website_url}</p>
                            {/* <p className='font-light md:text-base text-sm'>Program: {schoolData?.program}</p> */}
                            <p className='font-light md:text-base text-sm'>Tel.  {schoolData?.twitter_link}</p>
                            <p className='font-light md:text-base text-sm'>Email : {schoolData?.instagram_link}</p>
                            {/* <p className=' font-light md:text-base text-sm'>Year Founded : {schoolData?.year_founded}</p> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <div className=' sm:px-16 px-6 md:flex md:justify-center items-start pt-10 pb-10' >
                <div className='xl:max-w-[1200px] w-full mx-auto'>
                    <div className='flex md:flex-row flex-col sm:py-16 py-6'>
                        <div className='basis-8/12'>
                            <div>
                                <img src="/images/school-img1.png" alt="hero" className='w-full' />
                            </div>
                            <div>
                                <div className='mt-[30px]'>
                                    <div className=" text-sm font-medium text-center divide-x divide-[#CBD6E2] border border-[#CBD6E2] sm:flex ">

                                        <div
                                            onClick={() => setOpenTestTab(1)}
                                            className={` ${openTestTab === 1 ? "bg-[#1DB459] text-[#ffffff]" : ""}  w-full py-3 px-2 text[#002147] text-sm cursor-pointer`}>Description</div>

                                        <div
                                            onClick={() => setOpenTestTab(2)}
                                            className={` ${openTestTab === 2 ? "bg-[#1DB459] text-[#ffffff]" : ""} w-full py-3 px-2 text[#002147] text-sm cursor-pointer`}>Courses Offered</div>

                                        <div
                                            onClick={() => setOpenTestTab(3)}
                                            className={` ${openTestTab === 3 ? "bg-[#1DB459] text-[#ffffff]" : ""} w-full py-3 px-2 text[#002147] text-sm cursor-pointer`}>Why </div>

                                        <div
                                            onClick={() => setOpenTestTab(4)}
                                            className={` ${openTestTab === 4 ? "bg-[#1DB459] text-[#ffffff]" : ""} w-full py-3 px-2 text[#002147] text-sm `}>Review</div>

                                    </div>
                                </div>

                                <div className='mt-[23px]'>
                                    <div className={openTestTab === 1 ? "block" : "hidden"}>
                                        <p className='text-[16px] text-[#565872] leading-[27px]' dangerouslySetInnerHTML={{ __html: schoolData?.description }}></p>
                                    </div>
                                    <div className={openTestTab === 2 ? "block" : "hidden"}>
                                        <p className='text-[16px] text-[#565872] leading-[27px]' dangerouslySetInnerHTML={{ __html: schoolData?.degrees_offered }}></p>
                                    </div>
                                    <div className={openTestTab === 3 ? "block" : "hidden"}>
                                        <p className='text-[16px] text-[#565872] leading-[27px]' dangerouslySetInnerHTML={{ __html: schoolData?.why }}></p>
                                    </div>
                                    <div className={openTestTab === 4 ? "block" : "hidden"}>
                                        <p className='text-[16px] text-[#565872] leading-[27px]' dangerouslySetInnerHTML={{ __html: schoolData?.review }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='basis-4/12 md:ml-[100px]'>
                            <div className='mt-10 md:mt-0'>
                                <div className=' flex-col border border-[#CBD6E2] rounded-[10px]'>
                                    <div className='bg-[#1DB459] pl-[44px] md:py-[14px] py-[12px] rounded-t-[10px]'>
                                        <h3 className='text-[20px] font-semibold text-white'>Key Information </h3>
                                    </div>
                                    <div className='pl-[20px] pr-[20px] pb-[20px]'>
                                        
                                        <div className='flex justify-between px-1 pt-2'>
                                            <h3 className='text-[#002147] font-medium text-[12px]'>Campus :</h3>

                                            <h3 className='text-[#002147] font-medium text-[12px] capitalize'>{schoolData?.nunber_of_campus}</h3>

                                        </div>
                                        <div className='flex justify-between px-1 pt-2'>
                                            <h3 className='text-[#002147] font-medium text-[12px]'>Categories :</h3>
                                            <h3 className='text-[#002147] font-medium text-[12px] capitalize'>{schoolData?.category}</h3>

                                        </div>
                                        
                                        <div className='flex justify-between px-1 pt-2'>
                                            <h3 className='text-[#002147] font-medium text-[12px]'>Founded :</h3>
                                            <h3 className='text-[#002147] font-medium text-[12px]'>{schoolData?.year_founded}</h3>

                                        </div>
                                    </div>

                                    <div className='bg-[#F0F4F9] px-[23px] py-[10px]'>
                                        
                                        <div className='mt-2 text-sm'>
                                            Need help? Reach us at <a href="mailto:hello@afriproedu.com" className=' text-green-700'>hello@afriproedu.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-10'>
                                <div className='pb-[25px]'>
                                    <h3 className='text-[#1DB459] font-semibold text-[24px]'>Trending Course</h3>
                                </div>
                                {
                                    courseList?.data?.slice(0, 1)?.map(
                                        (datas: any, index: any) => (
                                            <NavLink to={`/course-details/${datas?.id}`}>
                                                <div className='bg-white p-3 rounded flex-col border border-[#D9D9D9]'>
                                                    <div className='flex-1 flex justify-center items-center'>
                                                        <img className="" src={datas?.image_url} alt={datas?.cover_photo} style={{ width: "384px", height: '188px' }} />
                                                    </div>
                                                    <div className='flex justify-between pt-2'>
                                                        <h3 className='text-[#002147] md:text-[14px] text-[12px] font-normal'>{datas?.title}</h3>
                                                        <div className='mt-1'>
                                                            ➜
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        )
                                    )
                                }
                            </div>

                        </div>
                    </div>

                    <div>
                        <hr className="h-px my-4 bg-[#E3E3E3] border-0" />
                        <div>
                            <h3 className='text-black font-semibold text-[24px]'>Other Courses</h3>
                        </div>
                        <div className='grid grid-cols-1 md:gap-10 gap-3 md:grid-cols-3 md:mt-[20px] mt-[40px]'>
                            {
                                courseList?.data?.slice(0, 3)?.map(
                                    (datas: any, index: any) => (
                                        <NavLink to={`/course-details/${datas?.id}`}>
                                            <div className='bg-white p-3 rounded flex-col border border-[#D9D9D9]'>
                                                <div className='flex-1 flex justify-center items-center'>
                                                    <img className="" src={datas?.image_url} alt={datas?.cover_photo} style={{ width: "384px", height: '188px' }} />
                                                </div>
                    
                                                <div className='flex justify-between pt-2'>
                                                    <h3 className='text-[#002147] md:text-[18px] text-sm font-normal'>{datas?.title}</h3>
                                                    <div className='mt-1'>
                                                        ➜
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    )
                                )
                            }


                        </div>
                    </div>

                </div>
            </div>
            {/* <!-- End block --> */}
            <div className=''>
                <Footer />
            </div>
        </>
    )
}
