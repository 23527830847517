import React from 'react'
import { Checkbox } from "@material-tailwind/react";
import Sidebar from '../../Sidebar/Sidebar'
import UserNavbar from "../../Navbars/UserNavbar";
import { NavLink, Link } from "react-router-dom";

function AvailablePrograms() {
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar title="Find Programs" />
                <div className='md:py-16'>
                    <div className='md:px-16 pl-3 pr-4'>
                        {/* <Checkbox label="Remember Me" id="ripple-on" ripple={true} color="green" className="bg-[#D9D9D9] rounded-md" /> */}
                        <div className="flex items-center mb-4">
                            <input id="default-radio-1" type="radio" name='default-radio' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                            <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">Search all institutions</label>
                        </div>

                        <div className="flex items-center mb-4">
                            <input id="default-radio-2" type="radio" name='default-radio' value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded " />
                            <label htmlFor="default-radio-2" className="ml-2 text-sm font-medium text-gray-900 ">Choose specific institutions</label>
                        </div>

                        <form>
                            <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                            <div className="relative">

                                <input type="search" id="default-search" className="block w-full p-4 pl-10 text-sm text-gray-900 placeholder:text-xl border border-gray-300 rounded-full " placeholder="Search Here..." required />
                                <button type="submit" className="text-white absolute right-0 bottom-0 border border-[#48B774] bg-[#48B774] hover:bg-[#05401C] lg:w-[200px] font-medium rounded-r-full text-sm px-10 py-4 ">Search</button>
                            </div>
                        </form>

                        <div className='space-y-8 lg:grid lg:grid-cols-4 xl:gap-10 '>
                            <div className='mt-8'>
                                <h3 className='text-black font-medium'>Choose Program Type</h3>
                                {/* <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">High School</label>
                                </div> */}

                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Vocational School</label>
                                </div>
                            </div>

                            <div>
                                <h3 className='text-black font-medium'>Academic terms:</h3>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Summer</label>
                                </div>

                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Fall</label>
                                </div>
                            </div>

                            <div>
                                <h3 className='text-black font-medium'>Study Courses :</h3>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Nursing </label>
                                </div>

                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Restaurant and Catering</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Software Developer</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Gardener</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Mechanical Fitter, Machinist</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Site Facilities Operative</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Rural Entrepreneur</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Construction</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Unspecified</label>
                                </div>
                            </div>

                            <div>
                                <h3 className='text-black font-medium'>Study Languages:</h3>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Finnish</label>
                                </div>

                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">combined Finnish/English</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">combined Finnish/English</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">combined Finnish/Russian</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-gray-300 rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">Swedish</label>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 accent-green-600 bg-[#D9D9D9] border-[#D9D9D9] rounded focus:ring-blue-500" />
                                    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 ">combined English/Finnish</label>
                                </div>
                            </div>

                        </div>

                        <div>
                            <div className='flex justify-between lg:ml-72 mt-8'>
                                <div className='flex'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className='mr-3 mt-1'  viewBox="0 0 16 16"><path fill="#098A3C" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z"/></svg>
                                <h3 className=' font-normal text-black text-lg'>Search brought <span className='font-bold text-[#098A3C]'>15 Results</span></h3>
                                </div>
                                <div>
                                <button
                    type="submit"
                    className=" text-white bg-[#EE1D52] font-medium rounded-md text-sm px-10 py-2.5 mr-2 mb-2"
                  >
                    Reset
                  </button>
                                </div>
                            </div>

                            <div className="w-full  border border-gray-200 rounded-lg shadow pb-0 mb-0 my-6">

                                <div>
                                    {/* <div className=" px-4 pt-4 pb-0  md:px-8 md:pt-8 space-y-8 lg:space-y-0 lg:grid lg:grid-cols-3 xl:gap-10">
                                        <div>
                                            <img src="/images/Dashboard/IMAGE.svg" alt="hero" />
                                            <h2 className='text-base text-[#03702E] font-medium'>Kinnula High School</h2>
                                            <h3 className='text-sm'>Finland, Kinnula, Central Finland</h3>
                                            <h5 className='text-xs'>General High School, full-time, Kinnula, Central Finland</h5>
                                        </div>
                                        <div>
                                            <h2 className='text-base text-[#03702E] font-medium'>Finnish Matriculation Degree</h2>
                                            <h3 className='text-sm'>High School, full-time, 3 years</h3>
                                            <h3 className='text-sm'>Study language: Finnish</h3>
                                        </div>
                                        <div className=' place-self-center'>
                                            <h4 className='text-xs'>Not available for applying at the moment</h4>
                                        </div>

                                    </div> */}
                                    <Link to="/find-programs-details" className='flex justify-center mt-2'>
                                        <button type="button" className=" text-gray-700 bg-[#E0E1E2] font-medium rounded-t-lg text-sm px-10 py-2.5 mr-2 lg:w-[400px] ">More information</button>
                                    </Link>


                                </div>
                            </div>

                            <div className="w-full  border border-gray-200 rounded-lg shadow pb-0 mb-0 my-6">

                                <div>
                                    {/* <div className=" px-4 pt-4 pb-0  md:px-8 md:pt-8 space-y-8 lg:space-y-0 lg:grid lg:grid-cols-3 xl:gap-10">
                                        <div>
                                            <img src="/images/Dashboard/IMAGE.svg" alt="hero" />
                                            <h2 className='text-base text-[#03702E] font-medium'>Kinnula High School</h2>
                                            <h3 className='text-sm'>Finland, Kinnula, Central Finland</h3>
                                            <h5 className='text-xs'>General High School, full-time, Kinnula, Central Finland</h5>
                                        </div>
                                        <div>
                                            <h2 className='text-base text-[#03702E] font-medium'>Finnish Matriculation Degree</h2>
                                            <h3 className='text-sm'>High School, full-time, 3 years</h3>
                                            <h3 className='text-sm'>Study language: Finnish</h3>
                                        </div>
                                        <div className=' place-self-center'>
                                            <h4 className='text-xs'>Not available for applying at the moment</h4>
                                        </div>

                                    </div> */}

                                    <Link to="/find-programs-details" className='flex justify-center mt-2'>
                                        <button type="button" className=" text-gray-700 bg-[#E0E1E2] font-medium rounded-t-lg text-sm px-10 py-2.5 mr-2 lg:w-[400px] ">More information</button>
                                    </Link>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AvailablePrograms;