import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react'
import React, { Fragment, useState } from 'react'

const EduDirectFaq = () => {
    const [open, setOpen] = useState(1);
    const [openTestTab, setOpenTestTab] = useState(1);
  
    const handleOpen = (value: any) => {
      setOpen(open === value ? 0 : value);
    };

  return (
    <div>
    {/* <!-- Start block --> */}
    <section className="bg-[#FFFFFF] body-font font-poppins pt-10">
      <div className="mx-auto flex justify-center text-center">
        <p className="hidden md:block text-[#000000] max-w-lg  md:text-3xl px-6 font-semibold text-center">
          Frequently Asked <span className="text-[#48B774]">Questions on EDUDIRECT</span>
        </p>
        <p className="block md:hidden text-[#000000]   text-2xl px-6 font-semibold text-center">
          Frequently Asked <span className="text-[#48B774]"><br/>Questions on EDUDIRECT</span>
        </p>
      </div>
      
      <div className="flex justify-center max-w-screen-xl  px-8 lg:px-14 pt-5 md:pt-16 pb-8 mx-auto  ">
     


        <div className=" lg:mb-20 max-w-3xl">


          {/* <div className={openTestTab === 1 ? "block" : "hidden"}> */}
            <Fragment>
              <Accordion open={open === 1} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                <AccordionHeader onClick={() => handleOpen(1)} className='border border-white text-base font-medium text-[#170F49]'>
                What is EduDirect?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                EduDirect is a unique pathway program that offers direct admission to Finnish Universities of Applied Sciences (UAS) after completing a 9-month online abridged program, enabling students to study in English.
                </AccordionBody>
              </Accordion>

              <Accordion open={open === 2} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                <AccordionHeader onClick={() => handleOpen(2)} className='border border-white text-base font-medium text-[#170F49]'>
                Who is eligible for EduDirect?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                EduDirect is open to students who have completed high school and are interested in pursuing higher education in Finland, particularly those looking for a streamlined admission process.
       
                </AccordionBody>
              </Accordion>

              <Accordion open={open === 3} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                <AccordionHeader onClick={() => handleOpen(3)} className='border border-white text-base font-medium text-[#170F49]'>
                Do I need to take the IELTS to qualify for EduDirect?

                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                No, IELTS is not required for EduDirect. The program is designed to provide direct admission to Finnish UAS without the need for language exams.
        
                </AccordionBody>
       
              </Accordion>

              <Accordion open={open === 4} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                <AccordionHeader onClick={() => handleOpen(4)} className='border border-white text-base font-medium text-[#170F49]'>
                What programs can I study through EduDirect?

                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                EduDirect offers access to 8 different bachelor's programs in English at Finnish UAS, ranging from business to technology and more.
                </AccordionBody>
              </Accordion>

              <Accordion open={open === 5} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                <AccordionHeader onClick={() => handleOpen(5)} className='border border-white text-base font-medium text-[#170F49]'>
                How does the 9-month online abridged program work?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                The 9-month program covers 30 ECTS of higher education studies, preparing you for direct entry into a Finnish UAS. It includes coursework that is recognized by European universities.

                </AccordionBody>
              </Accordion>

              <Accordion open={open === 6} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                <AccordionHeader onClick={() => handleOpen(6)} className='border border-white text-base font-medium text-[#170F49]'>
                Is there a discount on tuition fees?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                Yes, students who enroll through EduDirect receive a 30% discount on their first tuition fee. Additionally, learning Finnish can lead to further reductions.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 7} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                <AccordionHeader onClick={() => handleOpen(7)} className='border border-white text-base font-medium text-[#170F49]'>
                Can I complete my bachelor's degree faster through EduDirect?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                Yes, EduDirect can reduce your study period by approximately 6 months, allowing you to complete your degree more quickly.

                </AccordionBody>
              </Accordion>
              <Accordion open={open === 8} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                <AccordionHeader onClick={() => handleOpen(8)} className='border border-white text-base font-medium text-[#170F49]'>
                What support does AfriProEdu provide during the relocation process?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                AfriProEdu offers comprehensive support, including assistance with language learning, relocation logistics, airport pickup, and settling into Finland.

                </AccordionBody>
              </Accordion>
              <Accordion open={open === 9} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                <AccordionHeader onClick={() => handleOpen(9)} className='border border-white text-base font-medium text-[#170F49]'>
                Is my admission guaranteed with EduDirect?

                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                Yes, EduDirect guarantees admission to a Finnish UAS without the hassle of being placed on a waitlist.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 10} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                <AccordionHeader onClick={() => handleOpen(10)} className='border border-white text-base font-medium text-[#170F49]'>
                How do I apply for EduDirect?
                </AccordionHeader>
                <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                You can apply by visiting our website, filling out the application form, and following the instructions provided. For more details, feel free to contact us directly.

                </AccordionBody>
              </Accordion>

        
            </Fragment>
          {/* </div> */}


      
        </div>
      </div>
    </section>
    
    {/* <!-- End block --> */}
    </div>
  )
}

export default EduDirectFaq