import React, { useState } from 'react'
import Sidebar from '../../../Sidebar/Sidebar'
import UserNavbar from "../../../Navbars/UserNavbar"

function FindProgramsDetails() {
    const [toggleBtn, setToggleBtn] = useState(false);
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar title="Find Programs" />
                <div className='md:py-10'>
                    <div className='md:px-16 pl-3 pr-4'>
                        <h3 className=' font-medium text-lg text-[#48B774]'>Find programs</h3>
                        <div>
                            <img src="/images/Dashboard/IMAGE1.svg" alt="hero" />
                        </div>

                        <div className='space-y-8 lg:space-y-0 lg:grid lg:grid-cols-3 xl:gap-10 mt-8'>
                            <div className='flex justify-center'>
                                <img src="/images/Dashboard/IMAGE.svg" alt="hero" />
                            </div>
                            <div className=''>
                                <h2 className=' font-medium text-lg'>Building Constructor | Vocational Qualification in Construction</h2>
                                <h4 className='text-xs text-[#03702E]'>Vuoksi</h4>
                                <h4 className='text-xs'>Finland, Kauhajoki</h4>
                                <h4 className='text-xs text-[#03702E]'>Vuoksi | Kauhajoki</h4>
                            </div>
                            <div className='ml-24'>
                                <button
                                    type="submit"
                                    onClick={() => setToggleBtn((prev) => !prev)}
                                    className={" text-white" + (!toggleBtn ? "bg-[#1DB459] " : "bg-[#FF0000] ") + " font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"}
                                >
                                    {!toggleBtn
                                        ?
                                        "Add to Interest"
                                        : "Added to Interest"}
                                </button>
                            </div>
                        </div>

                        <div className='space-y-8 lg:space-y-0 lg:grid lg:grid-cols-3 xl:gap-10 mt-8'>
                            <div>
                                <h3>Study location</h3>
                            </div>
                            <div>
                                <h3>Finland, Kauhajoki</h3>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FindProgramsDetails