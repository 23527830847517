import React from 'react'
import Navbar from '../Navbar'
import { NavLink } from "react-router-dom";
import blog from "../../img/blog.png";
import Footer from '../Footer';
import { AuthApis } from '../../apis/authApis';
import { AxiosResponse } from 'axios';
import configs from '../../configs';
import Blog from './Blog';
import { MutatingDots } from 'react-loader-spinner'

function NewBlog() {

    const [groupLists, setGroupList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');
    const [loader, setLoader] = React.useState<boolean>(true);

    React.useEffect(() => {
        setLoader(true)
        AuthApis.getAllBlogs('').then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setGroupList(response?.data?.data)
                    setLoader(false)
                    console?.log(response?.data?.data)

                }
            }
        ).catch(function (error) {
            // handle error

        })
    }, []);


    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }
            setLoader(true)
            AuthApis.getAllBlogs(value2).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setLoader(false)
                        setGroupList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [groupLists, loader]);

    return (
        <>
            <Navbar />

            {/* <!-- Start block --> */}
            <section className=" body-font font-poppins pt-16" >
                <div
                    style={{
                        backgroundImage: `url(${blog})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className=" lg:px-14 md:pb-24 pb-10 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    {/* <div className=" hidden lg:block place-self-center">
            <img src="/images/img6.svg" alt="hero" />

            <img src="/images/safety-small.jpg" alt="friends" />

          </div> */}
                    <div className="place-self-center text-center text-white md:pt-20 pt-8">
                        <h1 className="md:text-5xl text-3xl font-bold">
                            Blog Section
                        </h1>
                        <div className="mt-5 w-full">
                            <form>
                                <div className="relative">
                                    <input type="email" id="" className="block w-full p-2 pl-3 text-sm text-gray-900 placeholder:text-sm border border-gray-300 rounded-md " placeholder="Your email here..." required />
                                    <button type="submit" className="text-white absolute right-0 bottom-0 border border-[#48B774] bg-[#48B774] hover:bg-[#05401C]  font-medium rounded-r-md text-sm  py-2 px-3 ">Get Started</button>
                                </div>
                            </form>

                        </div>
                        <p className="mt-5 md:text-base text-sm">
                            "Welcome to our blog, where you'll<br /> find insightful articles, helpful tips,<br /> and inspiring stories."
                        </p>
                    </div>
                    {/* <div className="hidden lg:block place-self-center">
            <img src="/images/img7.svg" alt="hero" />
          </div> */}
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-[#FBFBFB] body-font font-poppins">
                <div className='lg:pl-16 px-8 pt-2'>
                    <h3 className='mt-5 pl-4 md:text-[30px] text-[20px] text-[#1B212F] font-bold underline underline-offset-8 decoration-4 decoration-[#48B774]'>Featured posts</h3>
                    <div className="flex flex-col ">

                        {
                            loader ?
                                <div className='flex justify-center'>
                                    <MutatingDots
                                        height="100"
                                        width="100"
                                        color="#4fa94d"
                                        secondaryColor='#4fa94d'
                                        radius='12.5'
                                        ariaLabel="mutating-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={loader}
                                    />
                                </div>
                                :
                                groupLists?.data?.map(
                                    (datas: any, index: any) => (
                                        <div className='flex flex-col md:flex-row md:space-x-10 mt-10 border border-[#D9D9D9] p-4 rounded-[10px] '>
                                            <img src={datas?.article_image_url} alt={datas?.article_image} className=' md:max-w-[20vw]' />

                                            <div>
                                                <h5 className='text-xs text-[#48B774] font-semibold mt-3'>{datas?.date_posted} </h5>
                                                <h3 className='max-w-2xl font-bold md:text-[28px] text-[19px] mt-3 text-[#1B212F] '>
                                                    {datas?.article_title}</h3>

                                                {/* <p className="max-w-2xl font-normal text-[#666666] md:text-[16px]  text-[14px] mt-4" dangerouslySetInnerHTML={{ __html: datas?.blog_summary }}></p> */}

                                                <div className='mt-3'>
                                                    <NavLink to={`/blog-detail/${datas?.id}`}>
                                                        <button
                                                            type="button"
                                                            className=" text-[#48B774] bg-white hover:text-[#ffffff] hover:bg-[#48B774] border-2 border-[#48B774]  font-medium rounded-full text-sm px-5 py-2 text-center inline-flex items-center"
                                                        >
                                                            Read More
                                                        </button>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>

                                    ))
                        }





                    </div>

                    <div className='m-4 mt-10 flex justify-end'>
                        {
                            groupLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-green-500 text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }

                    </div>
                </div>
                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl px-8 lg:pl-14 lg:pt-10 pb-8 mx-auto lg:space-x-8 ">



                </div>
            </section>
            {/* <!-- End block --> */}


            <Footer />
        </>
    )
}

export default NewBlog;