import React from 'react'
import Navbar from '../../Navbar'
import blogbg from "../../../img/blog.png";
import Footer from '../../Footer';
import { NavLink } from 'react-router-dom';

function BenefitsOfFootball() {
    return (
        <>
            <Navbar />

            {/* <!-- Start block --> */}
            <section className=" body-font font-poppins pt-16" >
                <div
                    style={{
                        backgroundImage: `url(${blogbg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                    className="lg:px-14 pt-10 pb-24 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center text-white pt-20">
                        <h1 className="md:text-5xl text-3xl font-bold">
                           Benefits of Football and Education for African Youths
                        </h1>
                    </div>
                </div>
            </section>
            {/* <!-- End block --> */}

            {/* <!-- Start block --> */}
            <section className="bg-[#F5F5F5]  body-font font-poppins">
                <div className='bg-white lg:mx-28 pb-10 lg:px-10 px-8 pt-10 '>
                    <div>
                    <div className='flex justify-center mt-5'>
                        <img src="/images/football.jpg" alt="location" className='md:w-[50%] w-full'/> 
                    </div>
                    <div className='flex justify-center mx-auto md:px-44'>
                    <p className='italic mt-1 text-[#333333]'>Benefits of Football and Education for African Youths</p>
                    </div>
                    
                    </div>
                    <h3 className='text-base text-[#000000] font-normal mt-5'>Education and football are both important aspects of development for African students. Let's discuss each of them separately:
</h3>
                    
<ol className="space-y-1 text-[#000000] list-decimal list-inside">
    <li className='mt-4'><span className='font-semibold'>Education: </span>Education plays a vital role in empowering African students and improving their future prospects. It equips them with knowledge, skills, and critical thinking abilities, enabling them to pursue higher education, gain employment, and contribute to the development of their communities and nations. However, access to quality education remains a challenge in many parts of Africa due to various factors such as poverty, inadequate infrastructure, and limited resources.</li>
    <h3 className='text-base text-[#000000] font-normal mt-2'>
    To promote education for African students, governments, non-governmental organizations, and international bodies should focus on the following:
                    </h3>
                    <h4 className='text-base text-[#000000] font-normal mt-2'><span className='font-semibold'>a) Investment in Infrastructure: </span>Building and improving schools, classrooms, libraries, and other educational facilities to create conducive learning environments.</h4>
                    <h4 className='text-base text-[#000000] font-normal mt-2'><span className='font-semibold'>b) Teacher Training and Recruitment: </span>Enhancing the quality of teaching by providing professional development opportunities for teachers and attracting talented individuals to the teaching profession.</h4>
                    <h4 className='text-base text-[#000000] font-normal mt-2'><span className='font-semibold'>c) Curriculum Development: </span>Designing relevant and inclusive curricula that address the needs of African students and prepare them for the challenges of the modern world.</h4>
                    <h4 className='text-base text-[#000000] font-normal mt-2'><span className='font-semibold'>d) Access to Technology: </span>Increasing access to computers, internet connectivity, and digital resources to bridge the digital divide and promote digital literacy.
</h4>
<h4 className='text-base text-[#000000] font-normal mt-2'><span className='font-semibold'>e) Scholarships and Financial Support: </span>Providing scholarships, grants, and financial aid programs to ensure that deserving students can access and complete their education.
</h4>

<li className='mt-5'><span className='font-semibold'>Football: </span>Football, or soccer, is immensely popular in Africa and has the potential to positively impact the lives of African students. The sport provides numerous benefits, including physical fitness, teamwork, discipline, and personal development. Additionally, football can serve as a vehicle for social change, community building, and promoting unity and national pride.</li>

<h3 className='text-base text-[#000000] font-normal mt-2'>
To support football for African students, the following initiatives can be considered:
                    </h3>
                    <h4 className='text-base text-[#000000] font-normal mt-2'><span className='font-semibold'>a) Sports Infrastructure: </span>Building and improving sports facilities, including football fields, in schools and communities to provide students with access to proper training and playing opportunities.</h4>
                    <h4 className='text-base text-[#000000] font-normal mt-2'><span className='font-semibold'>b) Coaching and Training Programs: </span>Developing coaching programs to train talented coaches who can mentor and guide young football players. Providing opportunities for student athletes to receive professional training and exposure to higher levels of competition.</h4>
                    <h4 className='text-base text-[#000000] font-normal mt-2'><span className='font-semibold'>c) Education and Football Scholarships: </span>Establishing scholarship programs that combine education and football, enabling talented young players to pursue their academic studies while receiving specialized football training.</h4>
                    <h4 className='text-base text-[#000000] font-normal mt-2'><span className='font-semibold'>d) Youth Development Programs: </span>Creating grassroots football programs that focus on identifying and nurturing young talent from a young age, providing them with opportunities to develop their skills and reach their full potential.
</h4>
<h4 className='text-base text-[#000000] font-normal mt-2'><span className='font-semibold'>e) Community Engagement: </span>Encouraging community involvement by organizing football tournaments, leagues, and events that promote sportsmanship, teamwork, and healthy competition.
</h4>


</ol>
                    
                   
                    
                    
                   
                    <div>
                        <h3 className='text-base text-[#000000] font-normal mt-5'>
                        It is important to note that while football can be an excellent platform for personal growth and development, it should not overshadow the significance of education. Balancing sports and academics is crucial to ensure that students receive a well-rounded education and are prepared for a variety of future opportunities.

                        </h3>
                       
                    </div>

          
                </div>

            </section>
            {/* <!-- End block --> */}

            <Footer />
        </>
    )
}

export default BenefitsOfFootball;