import React from 'react'
import AdminSidebar from '../../Sidebar/AdminSidebar';
import UserNavbar from '../../Navbars/UserNavbar'
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function AddCohort() {

    const [studentLists, setStudentList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');

    React.useEffect(() => {
        const query: any = {
            search: searchText,
        };
        AdminApis.getPaidStudents('').then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setStudentList(response?.data?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })
    }, []);



    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }
            const query: any = {
                search: searchText,
            };

            AdminApis.getPaidStudents(value2).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setStudentList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [studentLists, searchText]);


    const [userinfo, setUserInfo] = React.useState<any>({
        languages: [],
        response: [],
    });

    const handleChange = (e: any) => {
        // Destructuring
        const { value, checked } = e.target;
        const { languages } = userinfo;



        // Case 1 : The user checks the box
        if (checked) {
            setUserInfo({
                languages: [...languages, value],
                response: [...languages, value],
            });
        }

        // Case 2  : The user unchecks the box
        else {
            setUserInfo({
                languages: languages.filter((e: any) => e !== value),
                response: languages.filter((e: any) => e !== value),
            });
        }
    };

    // console.log(userinfo?.languages);


    //ceate group module
    const [userData, setUserdata] = React.useState({
        'group_name': "",
        'group_label': "",
        'group_description': "",
        'student': userinfo?.languages,
        'class_link': "",
    });

    const handleChange2 = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUserdata({ ...userData, [name]: value });
    };

    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('group_name', userData?.group_name)
        formData.append('group_label', userData?.group_label)
        formData.append('group_description', userData?.group_description)
        formData.append('student', userinfo?.languages.toString())
        formData.append('class_link', userData?.class_link)

       // console?.log(userData?.class_link)
        AdminApis.createGroup(formData).then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    if (response?.data?.success === true) {
                        toast?.success(response?.data?.message)
                    } else {
                        toast.error(response?.data?.message);
                    }
                } else {

                }

                // toast.success(response?.data?.message);
            }
        ).catch(function (error) {
            // handle error
            console.log(error.response.data);
            toast.error(error.response.data?.message);
        }).finally(() => {
            // toast.error("No Internet Connection");

        });
    }


    return (
        <>
            <AdminSidebar />
            
            <div className="relative md:ml-64 bg-blueGray-100">
                <UserNavbar />
                <form onSubmit={handleSubmit}>
                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl md:pt-10  pl-3 pr-4">
                {/* */}
                    <div className="w-full lg:w-10/12">
                        <div className="relative flex flex-col min-w-0 break-words w-full  ">
                            <div className="flex-auto px-4  py-10 pt-0">
                                
                                    <div className="flex flex-wrap">
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#404D61] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Group Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="group_name"
                                                    onChange={handleChange2}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder="Cohort"

                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#404D61] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Group Label
                                                </label>
                                                <input
                                                    type="text"
                                                    name="group_label"
                                                    onChange={handleChange2}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-4/12 px-4">
                                            <div className="relative w-full mb-6">
                                                <label
                                                    className="block text-[#404D61] text-sm font-medium mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Group Link
                                                </label>
                                                <input
                                                    type="text"
                                                    name="class_link"
                                                    onChange={handleChange2}
                                                    className="  border border-[#D9D9D9] text-[#6E768C] text-sm rounded-lg block w-full p-3 placeholder-[#6E768C] ease-linear transition-all duration-150"
                                                    placeholder=""

                                                />
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-12/12 px-4">
                                            <div className="relative w-full mb-6">

                                                <textarea id="message" onChange={(e)=>setUserdata({ ...userData, "group_description": e.target.value })} rows={4} name="group_description" className="block p-2.5 w-full text-sm text-[#393939] rounded-lg border border-[#E2E8F0]" placeholder="Enter Description" />
                                            </div>
                                        </div>
                                    </div>
                               
                            </div>
                        </div>
                    </div>

                    <div className="w-full lg:w-2/12 px-4">
                        <div className='flex justify-center items-center lg:pt-16'>
                            <button type='submit' className="font-normal text-white bg-[#1DB459] px-6 py-1.5 rounded-md">Create</button>
                        </div>
                    </div>
                    {/* </form> */}
                </div>
                </form>

                <div className="lg:pt-10 lg:px-10 px-6 pt-4">
                    <div className='flex justify-between'>
                        <h3 className=' font-medium text-lg'>Choose Students</h3>
                        <div className="relative flex">
                            <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                            </div>
                            <input type="text" placeholder='search Cohort...' className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " />
                            {/* <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required /> */}
                        </div>
                    </div>
                </div>

                <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#c4c4c4] mx-6 lg:mt-6 lg:mx-10 mt-5">

                    <table className="w-full text-sm text-left">
                        <thead className="text-xs text-[#3B3D55] uppercase bg-gray-50 font-light">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    User Id
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Language Learning
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Date Added
                                </th>
                                {/* <th scope="col" className="px-6 py-3">
                                    Course
                                </th> */}
                                <th scope="col" className="px-6 py-3">
                                    status
                                </th>

                            </tr>
                        </thead>
                        <tbody>

                            {studentLists?.data?.map(
                                (datas: any, index: any) => (
                                    <tr className="bg-white  ">
                                        <td scope="row" className="px-6 py-4 font-medium text-[#6C757D] whitespace-nowrap">
                                            <div className="flex space-x-3">
                                                <div className="flex items-center ">
                                                    <input id="default-checkbox" type="checkbox" value={datas?.user_id} onChange={handleChange} className="w-4 h-4 accent-green-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 cursor-pointer" />
                                                </div>

                                                <div>
                                                    <h3 className=" text-sm">{datas?.user_name}</h3>
                                                    {/* <p className=" text-xs text-[#647380]">modupeige@gmail.com</p> */}

                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            {datas?.user_id}
                                        </td>
                                        <td className="px-6 py-4">
                                            {datas?.language_type}
                                        </td>
                                        <td className="px-6 py-4">
                                            {datas?.created_at.slice(0, 10)}
                                        </td>
                                        {/* <td className="px-6 py-4 capitalize">
                                            vocational
                                        </td> */}
                                        <td className="px-6 py-4">
                                            <div className="font-normal text-white bg-[#1DB459] text-center px-3 py-1 rounded-sm">Active</div>
                                        </td>
                                    </tr>
                                )
                            )
                            }


                        </tbody>
                    </table>


                </div>

            </div>

            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </>
    );
}

export default AddCohort;