import React from 'react'
import { NavLink } from "react-router-dom";
import Sidebar from '../../Sidebar/Sidebar'
import UserNavbar from '../../Navbars/UserNavbar'
import CardSettings from '../../ReuseableComp/CardSettings';
import ProfileNav from '../../ReuseableComp/ProfileNav';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

function Profile() {

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">

        <UserNavbar title="Profile" />
        <div className='md:py-16'>
          <ProfileNav />

          {/* Profile header */}
          {/* Personal info */}
          <div>
            <CardSettings />
          </div>

        </div>
      </div>
    </>
  )
}

export default Profile