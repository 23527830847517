import React, { Fragment, useState } from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
  } from "@material-tailwind/react";




function EasyGoEduFaq() {
    const [open, setOpen] = useState(1);
    const [openTestTab, setOpenTestTab] = useState(1);
  
    const handleOpen = (value: any) => {
      setOpen(open === value ? 0 : value);
    };

    return (
        <>

      {/* <!-- Start block --> */}
      <section className="bg-[#FFFFFF] body-font font-poppins pt-10">
        <div className="mx-auto flex justify-center text-center">
          <p className="hidden md:block text-[#000000] max-w-lg  md:text-3xl px-6 font-semibold text-center">
            Frequently Asked <span className="text-[#48B774]">Questions on EASYGOEDU</span>
          </p>
          <p className="block md:hidden text-[#000000]   text-2xl px-6 font-semibold text-center">
            Frequently Asked <span className="text-[#48B774]"><br/>Questions on EASYGOEDU</span>
          </p>
        </div>
        
        <div className="flex justify-center max-w-screen-xl  px-8 lg:px-14 pt-5 md:pt-16 pb-8 mx-auto  ">
       


          <div className=" lg:mb-20 max-w-3xl">


            {/* <div className={openTestTab === 1 ? "block" : "hidden"}> */}
              <Fragment>
                <Accordion open={open === 1} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(1)} className='border border-white text-base font-medium text-[#170F49]'>
                  What is EASYGOEDU?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  EASYGOEDU is an innovative online language program designed to help individuals learn the Finnish language from the comfort of their own homes. It offers convenient access to expert instruction and immersive learning experiences over a 9-month period.
                  </AccordionBody>
                </Accordion>

                <Accordion open={open === 2} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(2)} className='border border-white text-base font-medium text-[#170F49]'>
                  How does EASYGOEDU work?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  EASYGOEDU provides  2 hours 15 minutes per day ( Mondays, Tuesdays and Wednesdays) where participants can engage in interactive lessons and practice their Finnish language skills. The program covers various aspects of Finnish language and culture to ensure comprehensive learning.
                  </AccordionBody>
                </Accordion>

                <Accordion open={open === 3} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(3)} className='border border-white text-base font-medium text-[#170F49]'>
                  What are the benefits of EASYGOEDU?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                    <ol className="space-y-1 list-disc list-inside">
                      <li>Study Finnish from anywhere with an internet connection.</li>
                      <li>Learn from experienced instructors who specialize in teaching Finnish as a foreign language.</li>
                      {/* <li>Immerse yourself in Finnish language and culture without having to travel to Finland.</li> */}
                      <li>Unlock the opportunity to live and study in Finland for free, with immediate work opportunities upon arrival.</li>
                   
                    </ol>         
                  </AccordionBody>
                  {/* <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                 <div className='font-semibold'>We have two programs:</div> 
                  <span className='font-semibold'>Short program:</span> you learn the Finnish language in your home country for two months before
travelling to Finland and then proceed to learning the Finnish language in Finland for four months .
This also covers the accommodation and lunch. After the four months program you will do inteview

with our various Finnish partners schools before getting accepted . Once you are accepted, you will
be issued the school acceptance letter which is one of the documents to process your residence
permit stay I Finland.
<div ><span className='font-semibold'>Long program:</span> you learn the Finnish language in your home country for 8- 10 months before
travelling to Finland. This process entails learning the Finnish language online with our teachers and
other students across the world. It is 100% online ( virtual class ) . Once you complete your 8th
Months program, you proceed to applying for inteview with our partner schools. Once you get
accepted, you will be issued with the acceptance letter which is among the documents needed to
process your residence permit to Finland.</div>
                  </AccordionBody> */}
                </Accordion>

                <Accordion open={open === 4} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(4)} className='border border-white text-base font-medium text-[#170F49]'>
                  Who can participate in EASYGOEDU?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  EASYGOEDU is open to individuals of all backgrounds and proficiency levels who are interested in learning the Finnish language. Whether you're a beginner or looking to improve your existing skills, EASYGOEDU welcomes you.
                  </AccordionBody>
                </Accordion>

                <Accordion open={open === 5} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(5)} className='border border-white text-base font-medium text-[#170F49]'>
                  What is the duration of the EASYGOEDU program?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  The EASYGOEDU program spans over 9 months, allowing participants ample time to progress through the curriculum and achieve fluency in Finnish.
                  </AccordionBody>
                </Accordion>

                <Accordion open={open === 6} className=" px-4 mb-4 bg-white border border-[#1DB459] shadow-md rounded-lg ">
                  <AccordionHeader onClick={() => handleOpen(6)} className='border border-white text-base font-medium text-[#170F49]'>
                  How much does EASYGOEDU cost?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  The cost of EASYGOEDU 5500€ ( 4 instalment  i.e  1,375€ per month), with a one-time processing fee of 200€. This pricing structure makes the program affordable and accessible to all.
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 7} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(7)} className='border border-white text-base font-medium text-[#170F49]'>
                  Is there any financial assistance available for EASYGOEDU?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                  At this time, EASYGOEDU does not offer financial assistance or scholarships. However, the program's affordable pricing and potential for free study opportunities in Finland make it an attractive option for many individuals.
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 8} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(8)} className='border border-white text-base font-medium text-[#170F49]'>
                  What are the sessions like?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal">
                  EASYGOEDU sessions are interactive and engaging, with a focus on practical language skills such as speaking, listening, reading, and writing. Participants will have the opportunity to interact with instructors and fellow learners in a supportive online environment.
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 9} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(9)} className='border border-white text-base font-medium text-[#170F49]'>
                  How can I enroll in EASYGOEDU?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  To enroll in EASYGOEDU, you can take the first step right here on our website, and complete the online registration form. Once registered, you will receive further instructions on how to access the program and begin your Finnish language journey.       
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 10} className=" px-4 mb-4 bg-white rounded-lg border border-[#1DB459] shadow-md">
                  <AccordionHeader onClick={() => handleOpen(10)} className='border border-white text-base font-medium text-[#170F49]'>
                  What if I have more questions?
                  </AccordionHeader>
                  <AccordionBody className="text-[#6F6C90] text-sm font-normal ">
                  If you have any additional questions or concerns about EASYGOEDU, please don't hesitate to contact our customer support team. We're here to help you every step of the way on your language learning journey        
                  </AccordionBody>
                </Accordion>
              </Fragment>
            {/* </div> */}


        
          </div>
        </div>
      </section>
      
      {/* <!-- End block --> */}

        </>
    )
}

export default EasyGoEduFaq