import React from "react";
import UserNavbar from "../../Navbars/UserNavbar";
import Sidebar from "../../Sidebar/Sidebar";

function Transaction() {
  const [transactions, setTransactions] = React.useState<any>([]);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <UserNavbar title="Transaction" />
        <div className="md:py-16">
          <div className="flex justify-between md:px-14">
            <h3 className=" font-medium text-lg">Transactions</h3>
          </div>
        </div>

        <div className="relative overflow-x-auto shadow-md rounded-lg border-2 border-[#c4c4c4] mx-6 lg:mt-6 lg:mx-10 mt-5">
          <div className="flex justify-end px-8 py-6">
            <div className=" lg:ml-auto mr-3">
              <div className="relative flex w-full ">
                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#9da4aa"
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  placeholder="search Cohort..."
                  className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  "
                />
                {/* <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required /> */}
              </div>
            </div>
          </div>
          <table className="w-full text-sm text-left">
            <thead className="text-xs text-[#3B3D55] uppercase bg-gray-50 font-light">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Ref Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Trx type
                </th>
                {/* <th scope="col" className="px-6 py-3">
                                    Students
                                </th> */}
                <th scope="col" className="px-6 py-3">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Date 
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white  ">
                <td
                  // scope="row"
                  className="px-6 py-4 "
                >
                  #TK-74894903jfnnld80
                </td>
                <td className="px-6 py-4">Ijaola</td>
                <td className="px-6 py-4">School fees</td>
                {/* <td className="px-6 py-4">
                                        {datas?.students}
                                        </td> */}
                <td className="px-6 py-4">
                  {/* {datas?.created_at.slice(0, 10)} */}500000
                </td>
                <td className="px-6 py-4 cursor-pointer">
                  24 Dec 2023 05:57 AM
                </td>
              </tr>
              {/* {groupLists?.data?.map((datas: any, index: any) => (
                <tr className="bg-white  ">
                  <td
                    scope="row"
                    className="px-6 py-4 font-medium text-[#6C757D] whitespace-nowrap"
                  >
                    {datas?.group_name}
                  </td>
                  <td className="px-6 py-4">{datas?.group_label}</td>
               
                  <td className="px-6 py-4">
                    {datas?.created_at.slice(0, 10)}
                  </td>
                  <td className="px-6 py-4 cursor-pointer">
                    <button type="button" onClick={() => toggleModal(datas.id)}>
                      <SvgElement type={icontypesEnum.EDIT} />
                    </button>
                 
                  </td>
                </tr>
              ))} */}
            </tbody>
          </table>

          {/* <div className='m-4'>
                        {
                            userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-green-500 text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }
                    </div> */}
        </div>
      </div>
    </>
  );
}

export default Transaction;
