import React from "react";

export enum icontypesEnum {
    FACEBOOK = "FACEBOOK",
    INSTAGRAM = "INSTAGRAM",
    LINKEDIN = "LINKEDIN",
    TWITTER = "TWITTER",
    EYE = "EYE",
    DELETE = "DELETE",
    EDIT = "EDIT",
    LOCATION = "LOCATION",
    PHONE = "PHONE",
    MAIL = "MAIL",
    DEFAULTIMAGE = "DEFAULTIMAGE",
    DELETETWO = "DELETETWO",
    CALL = "CALL",
    ARROW = "ARROW",
    CELEBRATE = "CELEBRATE",
    DANGER = "DANGER"



}

interface SvgElementProps {
    type: icontypesEnum;
    onClick?: (e: React.MouseEvent<SVGElement>) => void;
}


export const SvgElement: React.FunctionComponent<SvgElementProps> = (props: SvgElementProps): React.ReactElement<void> => {
    switch (props.type) {

        case icontypesEnum.FACEBOOK:
            return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z" fill="#48B774" />
            </svg>;


        case icontypesEnum.INSTAGRAM:
            return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z" fill="#48B774" />
            </svg>;

        case icontypesEnum.ARROW:
            return <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <path d="M0 0H23V12H0V0Z" fill="url(#pattern0)" />
                <defs>
                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                        <use xlinkHref="#image0_2696_8000" transform="scale(0.0434783 0.0833333)" />
                    </pattern>
                    <image id="image0_2696_8000" width="23" height="12" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAMCAYAAACJOyb4AAAABHNCSVQICAgIfAhkiAAAAKVJREFUOE+1k7EVgzAQQyWKtGEEs0FGSANzwESwATBG0pANkhEYIRs4Z/wINo/uzJUuvmSdjtCMuefAZQL4wfxo9ihq2DDlDczeC8Ni2Avo4A5qyloE+iMBPfwvwE7iuYY/SAP3AhIRX6EAUVTf5SH1yA5OhNsxbSzgJNHksHbE/KzTwF1jwDYEu5T18KiK3vG6Ph08OqIYrHfuz1/qBzn/zfHq/AeFXDsZMa2RCQAAAABJRU5ErkJggg==" />
                </defs>
            </svg>
                ;
                case icontypesEnum.CELEBRATE:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6154 12.3666L14.6174 12.3646L14.6034 12.3416C14.5639 12.2588 14.5152 12.1806 14.4584 12.1086C13.6532 10.8753 12.4713 9.93507 11.0885 9.42788C9.70574 8.92069 8.19615 8.87365 6.78449 9.29377C5.37283 9.7139 4.13462 10.5787 3.2542 11.7595C2.37379 12.9402 1.89827 14.3737 1.89844 15.8466C1.89844 18.2756 3.18644 20.3966 5.09544 21.6096C6.30344 22.4196 9.12944 21.1796 11.6074 18.6996C13.9704 16.3366 15.2074 13.6576 14.6154 12.3666Z" fill="#F6BF3F"/>
            <path d="M14.6154 12.3666L14.6174 12.3646L14.6034 12.3416C14.5639 12.2588 14.5152 12.1806 14.4584 12.1086C13.6532 10.8753 12.4713 9.93507 11.0885 9.42788C9.70574 8.92069 8.19615 8.87365 6.78449 9.29377C5.37283 9.7139 4.13462 10.5787 3.2542 11.7595C2.37379 12.9402 1.89827 14.3737 1.89844 15.8466C1.89844 18.2756 3.18644 20.3966 5.09544 21.6096C6.30344 22.4196 9.12944 21.1796 11.6074 18.6996C13.9704 16.3366 15.2074 13.6576 14.6154 12.3666Z" fill="url(#paint0_radial_5074_7989)"/>
            <path d="M14.6154 12.3666L14.6174 12.3646L14.6034 12.3416C14.5639 12.2588 14.5152 12.1806 14.4584 12.1086C13.6532 10.8753 12.4713 9.93507 11.0885 9.42788C9.70574 8.92069 8.19615 8.87365 6.78449 9.29377C5.37283 9.7139 4.13462 10.5787 3.2542 11.7595C2.37379 12.9402 1.89827 14.3737 1.89844 15.8466C1.89844 18.2756 3.18644 20.3966 5.09544 21.6096C6.30344 22.4196 9.12944 21.1796 11.6074 18.6996C13.9704 16.3366 15.2074 13.6576 14.6154 12.3666Z" fill="url(#paint1_radial_5074_7989)"/>
            <path d="M14.6154 12.3666L14.6174 12.3646L14.6034 12.3416C14.5639 12.2588 14.5152 12.1806 14.4584 12.1086C13.6532 10.8753 12.4713 9.93507 11.0885 9.42788C9.70574 8.92069 8.19615 8.87365 6.78449 9.29377C5.37283 9.7139 4.13462 10.5787 3.2542 11.7595C2.37379 12.9402 1.89827 14.3737 1.89844 15.8466C1.89844 18.2756 3.18644 20.3966 5.09544 21.6096C6.30344 22.4196 9.12944 21.1796 11.6074 18.6996C13.9704 16.3366 15.2074 13.6576 14.6154 12.3666Z" fill="url(#paint2_radial_5074_7989)"/>
            <g filter="url(#filter0_i_5074_7989)">
            <path d="M17.1251 12.3666L17.1231 12.3646L17.1361 12.3416C17.1761 12.2576 17.2261 12.1796 17.2821 12.1086C18.0873 10.8753 19.2692 9.93507 20.652 9.42788C22.0348 8.92069 23.5444 8.87365 24.956 9.29377C26.3677 9.7139 27.6059 10.5787 28.4863 11.7595C29.3667 12.9402 29.8422 14.3737 29.8421 15.8466C29.8421 18.2756 28.5941 20.5036 26.6861 21.7166C25.4781 22.5256 22.6021 21.1786 20.1241 18.7006C17.7601 16.3366 16.5331 13.6576 17.1251 12.3666Z" fill="#F7CC36"/>
            <path d="M17.1251 12.3666L17.1231 12.3646L17.1361 12.3416C17.1761 12.2576 17.2261 12.1796 17.2821 12.1086C18.0873 10.8753 19.2692 9.93507 20.652 9.42788C22.0348 8.92069 23.5444 8.87365 24.956 9.29377C26.3677 9.7139 27.6059 10.5787 28.4863 11.7595C29.3667 12.9402 29.8422 14.3737 29.8421 15.8466C29.8421 18.2756 28.5941 20.5036 26.6861 21.7166C25.4781 22.5256 22.6021 21.1786 20.1241 18.7006C17.7601 16.3366 16.5331 13.6576 17.1251 12.3666Z" fill="url(#paint3_radial_5074_7989)"/>
            <path d="M17.1251 12.3666L17.1231 12.3646L17.1361 12.3416C17.1761 12.2576 17.2261 12.1796 17.2821 12.1086C18.0873 10.8753 19.2692 9.93507 20.652 9.42788C22.0348 8.92069 23.5444 8.87365 24.956 9.29377C26.3677 9.7139 27.6059 10.5787 28.4863 11.7595C29.3667 12.9402 29.8422 14.3737 29.8421 15.8466C29.8421 18.2756 28.5941 20.5036 26.6861 21.7166C25.4781 22.5256 22.6021 21.1786 20.1241 18.7006C17.7601 16.3366 16.5331 13.6576 17.1251 12.3666Z" fill="url(#paint4_radial_5074_7989)"/>
            </g>
            <g filter="url(#filter1_f_5074_7989)">
            <path d="M26.7147 21.3718C25.7309 22.3555 22.9048 21.1243 20.4023 18.6219C17.8999 16.1194 16.6687 13.2933 17.6524 12.3095C18.6361 11.3258 21.4623 12.557 23.9647 15.0595C26.4672 17.5619 27.6984 20.3881 26.7147 21.3718Z" stroke="url(#paint5_linear_5074_7989)" stroke-width="0.5"/>
            </g>
            <path d="M26.7147 21.3718C25.7309 22.3555 22.9048 21.1243 20.4023 18.6219C17.8999 16.1194 16.6687 13.2933 17.6524 12.3095C18.6361 11.3258 21.4623 12.557 23.9647 15.0595C26.4672 17.5619 27.6984 20.3881 26.7147 21.3718Z" fill="url(#paint6_linear_5074_7989)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8819 22.8639C11.1289 22.7671 11.4041 22.7724 11.6472 22.8785C11.8902 22.9846 12.0811 23.183 12.1779 23.4299C12.6099 24.5349 13.3839 27.3119 11.3849 29.7969C11.2181 30.0019 10.977 30.1325 10.7141 30.1601C10.4513 30.1878 10.1882 30.1104 9.98237 29.9447C9.7765 29.779 9.64459 29.5385 9.61547 29.2759C9.58635 29.0132 9.66238 28.7497 9.82694 28.5429C11.1269 26.9269 10.6739 25.0759 10.3149 24.1599C10.267 24.0376 10.2437 23.907 10.2463 23.7757C10.2488 23.6443 10.2773 23.5148 10.3299 23.3944C10.3826 23.2741 10.4584 23.1653 10.5532 23.0743C10.6479 22.9832 10.7596 22.9118 10.8819 22.8639Z" fill="#FC28A2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8819 22.8639C11.1289 22.7671 11.4041 22.7724 11.6472 22.8785C11.8902 22.9846 12.0811 23.183 12.1779 23.4299C12.6099 24.5349 13.3839 27.3119 11.3849 29.7969C11.2181 30.0019 10.977 30.1325 10.7141 30.1601C10.4513 30.1878 10.1882 30.1104 9.98237 29.9447C9.7765 29.779 9.64459 29.5385 9.61547 29.2759C9.58635 29.0132 9.66238 28.7497 9.82694 28.5429C11.1269 26.9269 10.6739 25.0759 10.3149 24.1599C10.267 24.0376 10.2437 23.907 10.2463 23.7757C10.2488 23.6443 10.2773 23.5148 10.3299 23.3944C10.3826 23.2741 10.4584 23.1653 10.5532 23.0743C10.6479 22.9832 10.7596 22.9118 10.8819 22.8639Z" fill="url(#paint7_linear_5074_7989)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8532 14.5456C22.9619 14.5922 23.0603 14.6596 23.1429 14.7442C23.2255 14.8288 23.2906 14.9289 23.3346 15.0386C23.3785 15.1484 23.4004 15.2657 23.3989 15.384C23.3975 15.5022 23.3728 15.619 23.3262 15.7276C22.5292 17.5836 21.3502 18.9586 20.1592 19.7226C20.1451 19.7802 20.1301 19.8375 20.1142 19.8946C19.9412 20.5046 19.6642 21.0726 19.3912 21.4156C19.2498 21.5944 19.0458 21.7128 18.8205 21.7471C18.5951 21.7813 18.3652 21.7288 18.1771 21.6001C17.989 21.4714 17.8567 21.2761 17.807 21.0536C17.7573 20.8312 17.7938 20.5982 17.9092 20.4016C17.8195 20.3939 17.7303 20.3809 17.6422 20.3626C16.8802 20.2066 16.3172 19.6726 16.0882 18.8906C15.8982 18.2436 15.9582 17.6136 16.2792 17.0976C16.5892 16.5966 17.0852 16.3016 17.5832 16.1876C18.4892 15.9826 19.6372 16.3506 20.0912 17.4546C20.6612 16.8666 21.2262 16.0546 21.6712 15.0176C21.7653 14.7983 21.9428 14.6253 22.1644 14.5368C22.3861 14.4483 22.6338 14.4515 22.8532 14.5456ZM17.8082 18.0456C18.1472 17.6656 18.6122 18.1376 18.4992 18.5426C18.0222 18.8906 17.5222 18.4076 17.8092 18.0466" fill="url(#paint8_linear_5074_7989)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8532 14.5456C22.9619 14.5922 23.0603 14.6596 23.1429 14.7442C23.2255 14.8288 23.2906 14.9289 23.3346 15.0386C23.3785 15.1484 23.4004 15.2657 23.3989 15.384C23.3975 15.5022 23.3728 15.619 23.3262 15.7276C22.5292 17.5836 21.3502 18.9586 20.1592 19.7226C20.1451 19.7802 20.1301 19.8375 20.1142 19.8946C19.9412 20.5046 19.6642 21.0726 19.3912 21.4156C19.2498 21.5944 19.0458 21.7128 18.8205 21.7471C18.5951 21.7813 18.3652 21.7288 18.1771 21.6001C17.989 21.4714 17.8567 21.2761 17.807 21.0536C17.7573 20.8312 17.7938 20.5982 17.9092 20.4016C17.8195 20.3939 17.7303 20.3809 17.6422 20.3626C16.8802 20.2066 16.3172 19.6726 16.0882 18.8906C15.8982 18.2436 15.9582 17.6136 16.2792 17.0976C16.5892 16.5966 17.0852 16.3016 17.5832 16.1876C18.4892 15.9826 19.6372 16.3506 20.0912 17.4546C20.6612 16.8666 21.2262 16.0546 21.6712 15.0176C21.7653 14.7983 21.9428 14.6253 22.1644 14.5368C22.3861 14.4483 22.6338 14.4515 22.8532 14.5456ZM17.8082 18.0456C18.1472 17.6656 18.6122 18.1376 18.4992 18.5426C18.0222 18.8906 17.5222 18.4076 17.8092 18.0466" fill="url(#paint9_linear_5074_7989)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8532 14.5456C22.9619 14.5922 23.0603 14.6596 23.1429 14.7442C23.2255 14.8288 23.2906 14.9289 23.3346 15.0386C23.3785 15.1484 23.4004 15.2657 23.3989 15.384C23.3975 15.5022 23.3728 15.619 23.3262 15.7276C22.5292 17.5836 21.3502 18.9586 20.1592 19.7226C20.1451 19.7802 20.1301 19.8375 20.1142 19.8946C19.9412 20.5046 19.6642 21.0726 19.3912 21.4156C19.2498 21.5944 19.0458 21.7128 18.8205 21.7471C18.5951 21.7813 18.3652 21.7288 18.1771 21.6001C17.989 21.4714 17.8567 21.2761 17.807 21.0536C17.7573 20.8312 17.7938 20.5982 17.9092 20.4016C17.8195 20.3939 17.7303 20.3809 17.6422 20.3626C16.8802 20.2066 16.3172 19.6726 16.0882 18.8906C15.8982 18.2436 15.9582 17.6136 16.2792 17.0976C16.5892 16.5966 17.0852 16.3016 17.5832 16.1876C18.4892 15.9826 19.6372 16.3506 20.0912 17.4546C20.6612 16.8666 21.2262 16.0546 21.6712 15.0176C21.7653 14.7983 21.9428 14.6253 22.1644 14.5368C22.3861 14.4483 22.6338 14.4515 22.8532 14.5456ZM17.8082 18.0456C18.1472 17.6656 18.6122 18.1376 18.4992 18.5426C18.0222 18.8906 17.5222 18.4076 17.8092 18.0466" fill="url(#paint10_radial_5074_7989)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8532 14.5456C22.9619 14.5922 23.0603 14.6596 23.1429 14.7442C23.2255 14.8288 23.2906 14.9289 23.3346 15.0386C23.3785 15.1484 23.4004 15.2657 23.3989 15.384C23.3975 15.5022 23.3728 15.619 23.3262 15.7276C22.5292 17.5836 21.3502 18.9586 20.1592 19.7226C20.1451 19.7802 20.1301 19.8375 20.1142 19.8946C19.9412 20.5046 19.6642 21.0726 19.3912 21.4156C19.2498 21.5944 19.0458 21.7128 18.8205 21.7471C18.5951 21.7813 18.3652 21.7288 18.1771 21.6001C17.989 21.4714 17.8567 21.2761 17.807 21.0536C17.7573 20.8312 17.7938 20.5982 17.9092 20.4016C17.8195 20.3939 17.7303 20.3809 17.6422 20.3626C16.8802 20.2066 16.3172 19.6726 16.0882 18.8906C15.8982 18.2436 15.9582 17.6136 16.2792 17.0976C16.5892 16.5966 17.0852 16.3016 17.5832 16.1876C18.4892 15.9826 19.6372 16.3506 20.0912 17.4546C20.6612 16.8666 21.2262 16.0546 21.6712 15.0176C21.7653 14.7983 21.9428 14.6253 22.1644 14.5368C22.3861 14.4483 22.6338 14.4515 22.8532 14.5456ZM17.8082 18.0456C18.1472 17.6656 18.6122 18.1376 18.4992 18.5426C18.0222 18.8906 17.5222 18.4076 17.8092 18.0466" fill="url(#paint11_radial_5074_7989)"/>
            <path d="M15.9529 29.8569C16.4532 29.8569 16.8589 29.4513 16.8589 28.9509C16.8589 28.4506 16.4532 28.0449 15.9529 28.0449C15.4525 28.0449 15.0469 28.4506 15.0469 28.9509C15.0469 29.4513 15.4525 29.8569 15.9529 29.8569Z" fill="url(#paint12_radial_5074_7989)"/>
            <path d="M18.9373 25.8257C19.4376 25.8257 19.8433 25.42 19.8433 24.9197C19.8433 24.4193 19.4376 24.0137 18.9373 24.0137C18.4369 24.0137 18.0312 24.4193 18.0312 24.9197C18.0312 25.42 18.4369 25.8257 18.9373 25.8257Z" fill="url(#paint13_radial_5074_7989)"/>
            <path d="M22.8748 28.8257C23.3751 28.8257 23.7808 28.42 23.7808 27.9197C23.7808 27.4193 23.3751 27.0137 22.8748 27.0137C22.3744 27.0137 21.9688 27.4193 21.9688 27.9197C21.9688 28.42 22.3744 28.8257 22.8748 28.8257Z" fill="url(#paint14_radial_5074_7989)"/>
            <path d="M11.9566 21.7531C12.4073 21.7531 12.7726 21.3878 12.7726 20.9371C12.7726 20.4864 12.4073 20.1211 11.9566 20.1211C11.506 20.1211 11.1406 20.4864 11.1406 20.9371C11.1406 21.3878 11.506 21.7531 11.9566 21.7531Z" fill="url(#paint15_radial_5074_7989)"/>
            <path d="M8.94603 27.6372C9.35417 27.6372 9.68503 27.3063 9.68503 26.8982C9.68503 26.49 9.35417 26.1592 8.94603 26.1592C8.53789 26.1592 8.20703 26.49 8.20703 26.8982C8.20703 27.3063 8.53789 27.6372 8.94603 27.6372Z" fill="url(#paint16_radial_5074_7989)"/>
            <path d="M8.94603 27.6372C9.35417 27.6372 9.68503 27.3063 9.68503 26.8982C9.68503 26.49 9.35417 26.1592 8.94603 26.1592C8.53789 26.1592 8.20703 26.49 8.20703 26.8982C8.20703 27.3063 8.53789 27.6372 8.94603 27.6372Z" fill="url(#paint17_radial_5074_7989)"/>
            <g filter="url(#filter2_f_5074_7989)">
            <path d="M5.15642 21.3728C6.14015 22.3565 8.96628 21.1253 11.4688 18.6228C13.9712 16.1204 15.2024 13.2942 14.2187 12.3105C13.235 11.3268 10.4088 12.558 7.90636 15.0604C5.40388 17.5629 4.17269 20.389 5.15642 21.3728Z" stroke="url(#paint18_linear_5074_7989)" stroke-width="0.3"/>
            </g>
            <path d="M5.15252 21.3728C6.13625 22.3565 8.96238 21.1253 11.4649 18.6228C13.9673 16.1204 15.1985 13.2942 14.2148 12.3105C13.2311 11.3268 10.4049 12.558 7.90245 15.0604C5.39997 17.5629 4.16878 20.389 5.15252 21.3728Z" fill="url(#paint19_linear_5074_7989)"/>
            <g filter="url(#filter3_i_5074_7989)">
            <path d="M6.27762 16.9649C6.68362 16.3979 7.16462 15.8199 7.71062 15.2549C7.88462 15.6099 8.05462 16.0029 8.21062 16.4189C8.64162 17.5719 9.00662 19.0099 9.05762 20.5099C9.11762 22.2439 8.54362 23.8149 7.83062 25.0499C7.11962 26.2829 6.23262 27.2489 5.56262 27.7899C5.3558 27.9489 5.09504 28.0208 4.83597 27.9902C4.5769 27.9596 4.34002 27.829 4.17588 27.6263C4.01174 27.4235 3.93334 27.1647 3.95739 26.9049C3.98144 26.6451 4.10604 26.4051 4.30462 26.2359C4.78062 25.8509 5.50762 25.0759 6.09762 24.0519C6.68662 23.0319 7.10162 21.8309 7.05962 20.5789C7.01662 19.3449 6.71262 18.1239 6.33662 17.1189L6.27762 16.9649Z" fill="#24A4DC"/>
            <path d="M6.27762 16.9649C6.68362 16.3979 7.16462 15.8199 7.71062 15.2549C7.88462 15.6099 8.05462 16.0029 8.21062 16.4189C8.64162 17.5719 9.00662 19.0099 9.05762 20.5099C9.11762 22.2439 8.54362 23.8149 7.83062 25.0499C7.11962 26.2829 6.23262 27.2489 5.56262 27.7899C5.3558 27.9489 5.09504 28.0208 4.83597 27.9902C4.5769 27.9596 4.34002 27.829 4.17588 27.6263C4.01174 27.4235 3.93334 27.1647 3.95739 26.9049C3.98144 26.6451 4.10604 26.4051 4.30462 26.2359C4.78062 25.8509 5.50762 25.0759 6.09762 24.0519C6.68662 23.0319 7.10162 21.8309 7.05962 20.5789C7.01662 19.3449 6.71262 18.1239 6.33662 17.1189L6.27762 16.9649Z" fill="url(#paint20_linear_5074_7989)"/>
            <path d="M6.27762 16.9649C6.68362 16.3979 7.16462 15.8199 7.71062 15.2549C7.88462 15.6099 8.05462 16.0029 8.21062 16.4189C8.64162 17.5719 9.00662 19.0099 9.05762 20.5099C9.11762 22.2439 8.54362 23.8149 7.83062 25.0499C7.11962 26.2829 6.23262 27.2489 5.56262 27.7899C5.3558 27.9489 5.09504 28.0208 4.83597 27.9902C4.5769 27.9596 4.34002 27.829 4.17588 27.6263C4.01174 27.4235 3.93334 27.1647 3.95739 26.9049C3.98144 26.6451 4.10604 26.4051 4.30462 26.2359C4.78062 25.8509 5.50762 25.0759 6.09762 24.0519C6.68662 23.0319 7.10162 21.8309 7.05962 20.5789C7.01662 19.3449 6.71262 18.1239 6.33662 17.1189L6.27762 16.9649Z" fill="url(#paint21_radial_5074_7989)"/>
            <path d="M6.27762 16.9649C6.68362 16.3979 7.16462 15.8199 7.71062 15.2549C7.88462 15.6099 8.05462 16.0029 8.21062 16.4189C8.64162 17.5719 9.00662 19.0099 9.05762 20.5099C9.11762 22.2439 8.54362 23.8149 7.83062 25.0499C7.11962 26.2829 6.23262 27.2489 5.56262 27.7899C5.3558 27.9489 5.09504 28.0208 4.83597 27.9902C4.5769 27.9596 4.34002 27.829 4.17588 27.6263C4.01174 27.4235 3.93334 27.1647 3.95739 26.9049C3.98144 26.6451 4.10604 26.4051 4.30462 26.2359C4.78062 25.8509 5.50762 25.0759 6.09762 24.0519C6.68662 23.0319 7.10162 21.8309 7.05962 20.5789C7.01662 19.3449 6.71262 18.1239 6.33662 17.1189L6.27762 16.9649Z" fill="url(#paint22_radial_5074_7989)"/>
            </g>
            <path d="M7.89453 15.0675L7.90253 15.0595C8.39249 14.568 8.91465 14.1096 9.46553 13.6875C9.61053 14.0275 9.81553 14.4685 10.0595 14.9235C10.3955 15.5515 10.7695 16.1415 11.1225 16.5235C11.3455 16.7655 11.5405 16.7985 12.1565 16.7925L12.2365 16.7915C12.8205 16.7835 13.8095 16.7715 14.6405 17.6185C15.4825 18.4465 15.4545 19.3985 15.4375 19.9805C15.4355 20.0545 15.4335 20.1235 15.4335 20.1845V22.3225C15.4335 22.3888 15.4467 22.4543 15.4723 22.5155C15.4979 22.5766 15.5353 22.632 15.5825 22.6785L16.2135 23.3005C16.4025 23.4867 16.5098 23.7403 16.5117 24.0056C16.5137 24.2708 16.4102 24.526 16.224 24.715C16.0379 24.904 15.7842 25.0112 15.519 25.0132C15.2537 25.0152 14.9985 24.9117 14.8095 24.7255C14.1195 24.1125 13.4195 23.3515 13.4395 22.1285L13.4335 20.1845C13.4335 20.0905 13.4355 19.9985 13.4365 19.9185V19.9105C13.4375 19.8245 13.4395 19.7515 13.4385 19.6815C13.4385 19.5415 13.4325 19.4475 13.4195 19.3725C13.4007 19.2437 13.3352 19.1262 13.2355 19.0425L13.2255 19.0325L13.2155 19.0225C13.0115 18.8115 12.8335 18.7855 12.1755 18.7925H12.1475C11.5295 18.7985 10.5075 18.8085 9.65153 17.8775C9.12753 17.3095 8.65853 16.5455 8.29553 15.8675C8.15529 15.6042 8.02158 15.3374 7.89453 15.0675Z" fill="#FA3F99"/>
            <path d="M7.89453 15.0675L7.90253 15.0595C8.39249 14.568 8.91465 14.1096 9.46553 13.6875C9.61053 14.0275 9.81553 14.4685 10.0595 14.9235C10.3955 15.5515 10.7695 16.1415 11.1225 16.5235C11.3455 16.7655 11.5405 16.7985 12.1565 16.7925L12.2365 16.7915C12.8205 16.7835 13.8095 16.7715 14.6405 17.6185C15.4825 18.4465 15.4545 19.3985 15.4375 19.9805C15.4355 20.0545 15.4335 20.1235 15.4335 20.1845V22.3225C15.4335 22.3888 15.4467 22.4543 15.4723 22.5155C15.4979 22.5766 15.5353 22.632 15.5825 22.6785L16.2135 23.3005C16.4025 23.4867 16.5098 23.7403 16.5117 24.0056C16.5137 24.2708 16.4102 24.526 16.224 24.715C16.0379 24.904 15.7842 25.0112 15.519 25.0132C15.2537 25.0152 14.9985 24.9117 14.8095 24.7255C14.1195 24.1125 13.4195 23.3515 13.4395 22.1285L13.4335 20.1845C13.4335 20.0905 13.4355 19.9985 13.4365 19.9185V19.9105C13.4375 19.8245 13.4395 19.7515 13.4385 19.6815C13.4385 19.5415 13.4325 19.4475 13.4195 19.3725C13.4007 19.2437 13.3352 19.1262 13.2355 19.0425L13.2255 19.0325L13.2155 19.0225C13.0115 18.8115 12.8335 18.7855 12.1755 18.7925H12.1475C11.5295 18.7985 10.5075 18.8085 9.65153 17.8775C9.12753 17.3095 8.65853 16.5455 8.29553 15.8675C8.15529 15.6042 8.02158 15.3374 7.89453 15.0675Z" fill="url(#paint23_radial_5074_7989)"/>
            <path d="M7.89453 15.0675L7.90253 15.0595C8.39249 14.568 8.91465 14.1096 9.46553 13.6875C9.61053 14.0275 9.81553 14.4685 10.0595 14.9235C10.3955 15.5515 10.7695 16.1415 11.1225 16.5235C11.3455 16.7655 11.5405 16.7985 12.1565 16.7925L12.2365 16.7915C12.8205 16.7835 13.8095 16.7715 14.6405 17.6185C15.4825 18.4465 15.4545 19.3985 15.4375 19.9805C15.4355 20.0545 15.4335 20.1235 15.4335 20.1845V22.3225C15.4335 22.3888 15.4467 22.4543 15.4723 22.5155C15.4979 22.5766 15.5353 22.632 15.5825 22.6785L16.2135 23.3005C16.4025 23.4867 16.5098 23.7403 16.5117 24.0056C16.5137 24.2708 16.4102 24.526 16.224 24.715C16.0379 24.904 15.7842 25.0112 15.519 25.0132C15.2537 25.0152 14.9985 24.9117 14.8095 24.7255C14.1195 24.1125 13.4195 23.3515 13.4395 22.1285L13.4335 20.1845C13.4335 20.0905 13.4355 19.9985 13.4365 19.9185V19.9105C13.4375 19.8245 13.4395 19.7515 13.4385 19.6815C13.4385 19.5415 13.4325 19.4475 13.4195 19.3725C13.4007 19.2437 13.3352 19.1262 13.2355 19.0425L13.2255 19.0325L13.2155 19.0225C13.0115 18.8115 12.8335 18.7855 12.1755 18.7925H12.1475C11.5295 18.7985 10.5075 18.8085 9.65153 17.8775C9.12753 17.3095 8.65853 16.5455 8.29553 15.8675C8.15529 15.6042 8.02158 15.3374 7.89453 15.0675Z" fill="url(#paint24_radial_5074_7989)"/>
            <path d="M7.89453 15.0675L7.90253 15.0595C8.39249 14.568 8.91465 14.1096 9.46553 13.6875C9.61053 14.0275 9.81553 14.4685 10.0595 14.9235C10.3955 15.5515 10.7695 16.1415 11.1225 16.5235C11.3455 16.7655 11.5405 16.7985 12.1565 16.7925L12.2365 16.7915C12.8205 16.7835 13.8095 16.7715 14.6405 17.6185C15.4825 18.4465 15.4545 19.3985 15.4375 19.9805C15.4355 20.0545 15.4335 20.1235 15.4335 20.1845V22.3225C15.4335 22.3888 15.4467 22.4543 15.4723 22.5155C15.4979 22.5766 15.5353 22.632 15.5825 22.6785L16.2135 23.3005C16.4025 23.4867 16.5098 23.7403 16.5117 24.0056C16.5137 24.2708 16.4102 24.526 16.224 24.715C16.0379 24.904 15.7842 25.0112 15.519 25.0132C15.2537 25.0152 14.9985 24.9117 14.8095 24.7255C14.1195 24.1125 13.4195 23.3515 13.4395 22.1285L13.4335 20.1845C13.4335 20.0905 13.4355 19.9985 13.4365 19.9185V19.9105C13.4375 19.8245 13.4395 19.7515 13.4385 19.6815C13.4385 19.5415 13.4325 19.4475 13.4195 19.3725C13.4007 19.2437 13.3352 19.1262 13.2355 19.0425L13.2255 19.0325L13.2155 19.0225C13.0115 18.8115 12.8335 18.7855 12.1755 18.7925H12.1475C11.5295 18.7985 10.5075 18.8085 9.65153 17.8775C9.12753 17.3095 8.65853 16.5455 8.29553 15.8675C8.15529 15.6042 8.02158 15.3374 7.89453 15.0675Z" fill="url(#paint25_radial_5074_7989)"/>
            <g filter="url(#filter4_f_5074_7989)">
            <path d="M14.668 20.6699V22.3499C14.668 22.7369 14.818 23.1099 15.087 23.3889L15.574 23.8949" stroke="#FF5CAC" stroke-width="0.4" stroke-linecap="round"/>
            </g>
            <g filter="url(#filter5_i_5074_7989)">
            <path d="M25.3725 16.6699C25.8535 17.3199 26.2475 17.9259 26.5195 18.5169C26.0815 19.2269 25.6815 20.1099 25.4885 21.0229C25.1635 22.5549 25.3825 24.2529 26.8975 25.8429C26.9881 25.938 27.0591 26.05 27.1064 26.1725C27.1538 26.295 27.1765 26.4257 27.1733 26.557C27.1702 26.6883 27.1412 26.8177 27.088 26.9378C27.0349 27.0579 26.9586 27.1663 26.8635 27.2569C26.7684 27.3475 26.6564 27.4185 26.5339 27.4659C26.4114 27.5132 26.2807 27.5359 26.1494 27.5328C26.0181 27.5296 25.8887 27.5006 25.7686 27.4475C25.6485 27.3943 25.5401 27.318 25.4495 27.2229C23.4145 25.0879 23.0915 22.6879 23.5315 20.6089C23.8615 19.0489 24.6165 17.6709 25.3725 16.6699Z" fill="#2BBAFE"/>
            <path d="M25.3725 16.6699C25.8535 17.3199 26.2475 17.9259 26.5195 18.5169C26.0815 19.2269 25.6815 20.1099 25.4885 21.0229C25.1635 22.5549 25.3825 24.2529 26.8975 25.8429C26.9881 25.938 27.0591 26.05 27.1064 26.1725C27.1538 26.295 27.1765 26.4257 27.1733 26.557C27.1702 26.6883 27.1412 26.8177 27.088 26.9378C27.0349 27.0579 26.9586 27.1663 26.8635 27.2569C26.7684 27.3475 26.6564 27.4185 26.5339 27.4659C26.4114 27.5132 26.2807 27.5359 26.1494 27.5328C26.0181 27.5296 25.8887 27.5006 25.7686 27.4475C25.6485 27.3943 25.5401 27.318 25.4495 27.2229C23.4145 25.0879 23.0915 22.6879 23.5315 20.6089C23.8615 19.0489 24.6165 17.6709 25.3725 16.6699Z" fill="url(#paint26_linear_5074_7989)"/>
            </g>
            <path d="M25.3725 16.6699C25.8535 17.3199 26.2475 17.9259 26.5195 18.5169C26.0815 19.2269 25.6815 20.1099 25.4885 21.0229C25.1635 22.5549 25.3825 24.2529 26.8975 25.8429C26.9881 25.938 27.0591 26.05 27.1064 26.1725C27.1538 26.295 27.1765 26.4257 27.1733 26.557C27.1702 26.6883 27.1412 26.8177 27.088 26.9378C27.0349 27.0579 26.9586 27.1663 26.8635 27.2569C26.7684 27.3475 26.6564 27.4185 26.5339 27.4659C26.4114 27.5132 26.2807 27.5359 26.1494 27.5328C26.0181 27.5296 25.8887 27.5006 25.7686 27.4475C25.6485 27.3943 25.5401 27.318 25.4495 27.2229C23.4145 25.0879 23.0915 22.6879 23.5315 20.6089C23.8615 19.0489 24.6165 17.6709 25.3725 16.6699Z" fill="url(#paint27_radial_5074_7989)"/>
            <g filter="url(#filter6_f_5074_7989)">
            <path d="M25.4192 19.5127C24.7272 20.6587 23.9692 23.6447 26.4822 26.4197" stroke="url(#paint28_linear_5074_7989)" stroke-width="0.6" stroke-linecap="round"/>
            </g>
            <path d="M20.256 19.6592C20.2239 19.6805 20.1916 19.7015 20.159 19.7222C20.145 19.7798 20.13 19.8371 20.114 19.8942C19.941 20.5042 19.664 21.0722 19.391 21.4152C19.2496 21.5939 19.0457 21.7124 18.8203 21.7466C18.595 21.7809 18.3651 21.7283 18.1769 21.5996C17.9888 21.4709 17.8566 21.2756 17.8068 21.0532C17.7571 20.8308 17.7936 20.5977 17.909 20.4012L17.881 20.3982C18.388 20.3802 19.547 20.1022 20.182 19.7002C20.2059 19.6851 20.2306 19.6714 20.256 19.6592Z" fill="#2A82D3"/>
            <path d="M20.256 19.6592C20.2239 19.6805 20.1916 19.7015 20.159 19.7222C20.145 19.7798 20.13 19.8371 20.114 19.8942C19.941 20.5042 19.664 21.0722 19.391 21.4152C19.2496 21.5939 19.0457 21.7124 18.8203 21.7466C18.595 21.7809 18.3651 21.7283 18.1769 21.5996C17.9888 21.4709 17.8566 21.2756 17.8068 21.0532C17.7571 20.8308 17.7936 20.5977 17.909 20.4012L17.881 20.3982C18.388 20.3802 19.547 20.1022 20.182 19.7002C20.2059 19.6851 20.2306 19.6714 20.256 19.6592Z" fill="url(#paint29_radial_5074_7989)"/>
            <path d="M20.256 19.6592C20.2239 19.6805 20.1916 19.7015 20.159 19.7222C20.145 19.7798 20.13 19.8371 20.114 19.8942C19.941 20.5042 19.664 21.0722 19.391 21.4152C19.2496 21.5939 19.0457 21.7124 18.8203 21.7466C18.595 21.7809 18.3651 21.7283 18.1769 21.5996C17.9888 21.4709 17.8566 21.2756 17.8068 21.0532C17.7571 20.8308 17.7936 20.5977 17.909 20.4012L17.881 20.3982C18.388 20.3802 19.547 20.1022 20.182 19.7002C20.2059 19.6851 20.2306 19.6714 20.256 19.6592Z" fill="url(#paint30_radial_5074_7989)"/>
            <path d="M20.0919 17.4543C19.6379 16.3503 18.4899 15.9823 17.5839 16.1883C17.3172 16.2459 17.0646 16.3559 16.8407 16.512C16.6169 16.668 16.4263 16.867 16.2799 17.0973C16.0552 17.4655 15.9527 17.8953 15.9869 18.3253H17.7289C17.7217 18.2757 17.725 18.225 17.7388 18.1767C17.7526 18.1285 17.7765 18.0837 17.8089 18.0453C18.1479 17.6653 18.6129 18.1373 18.4999 18.5423C18.8549 18.3823 19.6749 17.8833 20.0919 17.4543Z" fill="url(#paint31_radial_5074_7989)"/>
            <g filter="url(#filter7_f_5074_7989)">
            <path d="M18.8393 19.4822C18.3933 19.5672 17.5293 19.5322 17.1213 18.6822C16.6063 17.6092 18.0103 16.4192 19.1803 17.5462" stroke="url(#paint32_linear_5074_7989)" stroke-width="0.5" stroke-linecap="round"/>
            </g>
            <g filter="url(#filter8_f_5074_7989)">
            <path d="M22.8743 15.4492C22.5833 16.1372 21.6313 18.1872 19.9062 19.2622" stroke="url(#paint33_linear_5074_7989)" stroke-width="0.5" stroke-linecap="round"/>
            </g>
            <defs>
            <filter id="filter0_i_5074_7989" x="16.7266" y="9.00977" width="13.1172" height="12.9414" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="-0.25"/>
            <feGaussianBlur stdDeviation="0.375"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.945098 0 0 0 0 0.698039 0 0 0 0 0.380392 0 0 0 1 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5074_7989"/>
            </filter>
            <filter id="filter1_f_5074_7989" x="16.8625" y="11.5207" width="10.6422" height="10.6402" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur_5074_7989"/>
            </filter>
            <filter id="filter2_f_5074_7989" x="4.56797" y="11.7213" width="10.2391" height="10.24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.05" result="effect1_foregroundBlur_5074_7989"/>
            </filter>
            <filter id="filter3_i_5074_7989" x="3.95312" y="15.2549" width="5.25938" height="12.7422" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="0.15"/>
            <feGaussianBlur stdDeviation="0.25"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.054902 0 0 0 0 0.462745 0 0 0 0 0.701961 0 0 0 1 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5074_7989"/>
            </filter>
            <filter id="filter4_f_5074_7989" x="13.8687" y="19.8697" width="2.50469" height="4.825" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.3" result="effect1_foregroundBlur_5074_7989"/>
            </filter>
            <filter id="filter5_i_5074_7989" x="23.3594" y="16.6699" width="4.1125" height="11.1633" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="0.3" dy="0.3"/>
            <feGaussianBlur stdDeviation="0.425"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.0705882 0 0 0 0 0.329412 0 0 0 0 0.6 0 0 0 1 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5074_7989"/>
            </filter>
            <filter id="filter6_f_5074_7989" x="23.7953" y="18.5629" width="3.63594" height="8.80684" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.325" result="effect1_foregroundBlur_5074_7989"/>
            </filter>
            <filter id="filter7_f_5074_7989" x="16.0117" y="16.0908" width="4.16797" height="4.42188" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_5074_7989"/>
            </filter>
            <filter id="filter8_f_5074_7989" x="18.9062" y="14.4492" width="4.96875" height="5.81348" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.375" result="effect1_foregroundBlur_5074_7989"/>
            </filter>
            <radialGradient id="paint0_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.4984 13.3566) rotate(-132.541) scale(7.21029 5.23286)">
            <stop stop-color="#FFE94C"/>
            <stop offset="1" stop-color="#FFE94C" stop-opacity="0"/>
            </radialGradient>
            <radialGradient id="paint1_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.68557 9.01142) rotate(61.118) scale(13.4572 13.5847)">
            <stop offset="0.684" stop-color="#B9706D" stop-opacity="0"/>
            <stop offset="1" stop-color="#B9706D"/>
            </radialGradient>
            <radialGradient id="paint2_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.0604 15.8566) rotate(176.876) scale(9.17626 9.19049)">
            <stop offset="0.839" stop-color="#F3C893" stop-opacity="0"/>
            <stop offset="1" stop-color="#F3C893"/>
            </radialGradient>
            <radialGradient id="paint3_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.3231 16.5426) rotate(-47.8156) scale(7.48729 6.243)">
            <stop stop-color="#FFE76D"/>
            <stop offset="1" stop-color="#FFE76D" stop-opacity="0"/>
            </radialGradient>
            <radialGradient id="paint4_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.6411 11.1046) rotate(27.79) scale(3.03077 3.03547)">
            <stop stop-color="#D6BE6F"/>
            <stop offset="1" stop-color="#D6BE6F" stop-opacity="0"/>
            </radialGradient>
            <linearGradient id="paint5_linear_5074_7989" x1="26.4248" y1="19.8204" x2="22.4289" y2="25.2376" gradientUnits="userSpaceOnUse">
            <stop stop-color="#ECB43E"/>
            <stop offset="1" stop-color="#925960"/>
            </linearGradient>
            <linearGradient id="paint6_linear_5074_7989" x1="23.5023" y1="18.4182" x2="29.4646" y2="27.6841" gradientUnits="userSpaceOnUse">
            <stop stop-color="#572943"/>
            <stop offset="1" stop-color="#4D2C30"/>
            </linearGradient>
            <linearGradient id="paint7_linear_5074_7989" x1="11.8089" y1="21.9819" x2="13.1839" y2="27.1699" gradientUnits="userSpaceOnUse">
            <stop stop-color="#C9136C"/>
            <stop offset="1" stop-color="#C9136C" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint8_linear_5074_7989" x1="21.9982" y1="15.9196" x2="16.9982" y2="21.7566" gradientUnits="userSpaceOnUse">
            <stop offset="0.253" stop-color="#1DA2CD"/>
            <stop offset="1" stop-color="#255EB1"/>
            </linearGradient>
            <linearGradient id="paint9_linear_5074_7989" x1="23.6232" y1="15.1376" x2="22.5922" y2="16.0126" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0D8CBA"/>
            <stop offset="1" stop-color="#0D8CBA" stop-opacity="0"/>
            </linearGradient>
            <radialGradient id="paint10_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.2492 15.4816) rotate(119.705) scale(5.61257 3.63651)">
            <stop offset="0.835" stop-color="#3165CF" stop-opacity="0"/>
            <stop offset="1" stop-color="#3165CF"/>
            </radialGradient>
            <radialGradient id="paint11_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.7912 18.6616) rotate(-168.317) scale(6.40706 6.26868)">
            <stop offset="0.808" stop-color="#2471AD" stop-opacity="0"/>
            <stop offset="0.95" stop-color="#2471AD"/>
            </radialGradient>
            <radialGradient id="paint12_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.4999 28.6999) rotate(130.855) scale(1.5287)">
            <stop stop-color="#43C7F4"/>
            <stop offset="1" stop-color="#2D64CF"/>
            </radialGradient>
            <radialGradient id="paint13_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.2737 24.5887) rotate(140.042) scale(1.28272)">
            <stop stop-color="#FF48A3"/>
            <stop offset="1" stop-color="#DB1D93"/>
            </radialGradient>
            <radialGradient id="paint14_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.4281 27.6777) rotate(144.498) scale(1.43947)">
            <stop stop-color="#F7C851"/>
            <stop offset="1" stop-color="#D97654"/>
            </radialGradient>
            <radialGradient id="paint15_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.098 21.2471) rotate(-96.843) scale(1.18028)">
            <stop stop-color="#D48D3B"/>
            <stop offset="1" stop-color="#B46518"/>
            </radialGradient>
            <radialGradient id="paint16_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.26336 26.794) rotate(138.621) scale(0.87458)">
            <stop stop-color="#F8AC4B"/>
            <stop offset="1" stop-color="#E5A437"/>
            </radialGradient>
            <radialGradient id="paint17_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.73963 27.02) rotate(-25.755) scale(0.98886)">
            <stop offset="0.757" stop-color="#EB9530" stop-opacity="0"/>
            <stop offset="1" stop-color="#EB9530"/>
            </radialGradient>
            <linearGradient id="paint18_linear_5074_7989" x1="8.19698" y1="13.5083" x2="12.1928" y2="18.9255" gradientUnits="userSpaceOnUse">
            <stop stop-color="#EABE3F"/>
            <stop offset="1" stop-color="#976061"/>
            </linearGradient>
            <linearGradient id="paint19_linear_5074_7989" x1="14.2155" y1="12.3098" x2="5.15252" y2="21.3728" gradientUnits="userSpaceOnUse">
            <stop stop-color="#5E2B40"/>
            <stop offset="1" stop-color="#6E4546"/>
            </linearGradient>
            <linearGradient id="paint20_linear_5074_7989" x1="4.23462" y1="28.2739" x2="6.03862" y2="26.6129" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3067D9"/>
            <stop offset="1" stop-color="#3067D9" stop-opacity="0"/>
            </linearGradient>
            <radialGradient id="paint21_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.21662 19.9849) rotate(-5.85598) scale(5.30098 11.3412)">
            <stop offset="0.655" stop-color="#47CEFA" stop-opacity="0"/>
            <stop offset="1" stop-color="#47CEFA"/>
            </radialGradient>
            <radialGradient id="paint22_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.96777 17.1652) rotate(16.44) scale(1.50961 3.19917)">
            <stop stop-color="#1569A5"/>
            <stop offset="1" stop-color="#1569A5" stop-opacity="0"/>
            </radialGradient>
            <radialGradient id="paint23_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.8938 14.6067) rotate(63.941) scale(6.71021 1.69109)">
            <stop stop-color="#A80963"/>
            <stop offset="1" stop-color="#A80963" stop-opacity="0"/>
            </radialGradient>
            <radialGradient id="paint24_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.0805 17.2995) rotate(92.782) scale(6.36907 2.65451)">
            <stop offset="0.198" stop-color="#A80963"/>
            <stop offset="1" stop-color="#A80963" stop-opacity="0"/>
            </radialGradient>
            <radialGradient id="paint25_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.3853 22.4458) rotate(-83.904) scale(3.75316 1.62491)">
            <stop stop-color="#A80963"/>
            <stop offset="1" stop-color="#A80963" stop-opacity="0"/>
            </radialGradient>
            <linearGradient id="paint26_linear_5074_7989" x1="26.6835" y1="17.1699" x2="25.2665" y2="19.2319" gradientUnits="userSpaceOnUse">
            <stop stop-color="#184E8C"/>
            <stop offset="1" stop-color="#184E8C" stop-opacity="0"/>
            </linearGradient>
            <radialGradient id="paint27_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.1735 24.8259) rotate(164.748) scale(1.54427 5.07605)">
            <stop stop-color="#4BDCFF"/>
            <stop offset="1" stop-color="#4BDCFF" stop-opacity="0"/>
            </radialGradient>
            <linearGradient id="paint28_linear_5074_7989" x1="26.4822" y1="26.4197" x2="25.6142" y2="19.5127" gradientUnits="userSpaceOnUse">
            <stop stop-color="#4BDAFF"/>
            <stop offset="1" stop-color="#4BDAFF" stop-opacity="0"/>
            </linearGradient>
            <radialGradient id="paint29_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.92 19.8872) rotate(76.1713) scale(1.04594 3.43088)">
            <stop offset="0.306" stop-color="#1E58A8"/>
            <stop offset="1" stop-color="#1E58A8" stop-opacity="0"/>
            </radialGradient>
            <radialGradient id="paint30_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.139 20.3252) rotate(163.015) scale(2.46048 3.46176)">
            <stop offset="0.59" stop-color="#1E58A8" stop-opacity="0"/>
            <stop offset="1" stop-color="#1E58A8"/>
            </radialGradient>
            <radialGradient id="paint31_radial_5074_7989" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.0149 18.7863) rotate(-92.629) scale(1.53286 1.2601)">
            <stop offset="0.187" stop-color="#3258A2"/>
            <stop offset="1" stop-color="#3258A2" stop-opacity="0"/>
            </radialGradient>
            <linearGradient id="paint32_linear_5074_7989" x1="17.8403" y1="16.7812" x2="18.0343" y2="19.5122" gradientUnits="userSpaceOnUse">
            <stop stop-color="#46B4E2"/>
            <stop offset="1" stop-color="#46B4E2" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint33_linear_5074_7989" x1="22.6873" y1="15.4502" x2="20.3472" y2="19.8892" gradientUnits="userSpaceOnUse">
            <stop stop-color="#46B4E2"/>
            <stop offset="1" stop-color="#46B4E2" stop-opacity="0"/>
            </linearGradient>
            </defs>
            </svg>
            
                ;

                case icontypesEnum.DANGER:
                    return   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-2 -3 24 24"><path fill="#ff3c3c" d="m12.8 1.613l6.701 11.161c.963 1.603.49 3.712-1.057 4.71a3.213 3.213 0 0 1-1.743.516H3.298C1.477 18 0 16.47 0 14.581c0-.639.173-1.264.498-1.807L7.2 1.613C8.162.01 10.196-.481 11.743.517c.428.276.79.651 1.057 1.096M10 14a1 1 0 1 0 0-2a1 1 0 0 0 0 2m0-9a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V6a1 1 0 0 0-1-1"/></svg>
                        ;


        case icontypesEnum.LINKEDIN:
            return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z" fill="#48B774" />
            </svg>;

        case icontypesEnum.DEFAULTIMAGE:
            return <svg width="800px" height="800px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" style={{ maxWidth: '90px', maxHeight: '100px' }}>
                <path fill="#494c4e" d="M9 0a9 9 0 0 0-9 9 8.654 8.654 0 0 0 .05.92 9 9 0 0 0 17.9 0A8.654 8.654 0 0 0 18 9a9 9 0 0 0-9-9zm5.42 13.42c-.01 0-.06.08-.07.08a6.975 6.975 0 0 1-10.7 0c-.01 0-.06-.08-.07-.08a.512.512 0 0 1-.09-.27.522.522 0 0 1 .34-.48c.74-.25 1.45-.49 1.65-.54a.16.16 0 0 1 .03-.13.49.49 0 0 1 .43-.36l1.27-.1a2.077 2.077 0 0 0-.19-.79v-.01a2.814 2.814 0 0 0-.45-.78 3.83 3.83 0 0 1-.79-2.38A3.38 3.38 0 0 1 8.88 4h.24a3.38 3.38 0 0 1 3.1 3.58 3.83 3.83 0 0 1-.79 2.38 2.814 2.814 0 0 0-.45.78v.01a2.077 2.077 0 0 0-.19.79l1.27.1a.49.49 0 0 1 .43.36.16.16 0 0 1 .03.13c.2.05.91.29 1.65.54a.49.49 0 0 1 .25.75z" />
            </svg>;


        case icontypesEnum.TWITTER:
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#48b774" d="M9.294 6.928L14.357 1h-1.2L8.762 6.147L5.25 1H1.2l5.31 7.784L1.2 15h1.2l4.642-5.436L10.751 15h4.05L9.294 6.928ZM7.651 8.852l-.538-.775L2.832 1.91h1.843l3.454 4.977l.538.775l4.491 6.47h-1.843l-3.664-5.28Z"/></svg>
            // <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            //     <g clip-path="url(#clip0_120_1459)">
            //         <path d="M12 0.480011C5.63758 0.480011 0.47998 5.63761 0.47998 12C0.47998 18.3624 5.63758 23.52 12 23.52C18.3624 23.52 23.52 18.3624 23.52 12C23.52 5.63761 18.3624 0.480011 12 0.480011ZM16.686 9.91681C16.6908 10.0152 16.692 10.1136 16.692 10.2096C16.692 13.2096 14.4108 16.6668 10.2372 16.6668C9.00393 16.6689 7.79634 16.3147 6.75958 15.6468C6.93598 15.6684 7.11718 15.6768 7.30078 15.6768C8.36398 15.6768 9.34198 15.3156 10.1184 14.706C9.64524 14.6967 9.18678 14.54 8.80691 14.2578C8.42704 13.9756 8.14468 13.5819 7.99918 13.1316C8.33897 13.1962 8.689 13.1827 9.02278 13.092C8.50924 12.9882 8.04742 12.7099 7.71563 12.3044C7.38384 11.8989 7.20251 11.3911 7.20238 10.8672V10.8396C7.50838 11.0088 7.85878 11.112 8.23078 11.124C7.74934 10.8035 7.40852 10.3112 7.27803 9.74777C7.14754 9.18432 7.23724 8.59231 7.52878 8.09281C8.09873 8.79361 8.80949 9.36691 9.61506 9.77558C10.4206 10.1843 11.303 10.4192 12.2052 10.4652C12.0905 9.97838 12.1398 9.4673 12.3456 9.01142C12.5513 8.55553 12.9019 8.1804 13.3429 7.94433C13.7838 7.70826 14.2904 7.62448 14.7838 7.70602C15.2773 7.78755 15.73 8.02984 16.0716 8.39521C16.5794 8.29474 17.0664 8.10848 17.5116 7.84441C17.3423 8.37017 16.988 8.81662 16.5144 9.10081C16.9642 9.04663 17.4034 8.9257 17.8176 8.74201C17.5134 9.19788 17.1301 9.59573 16.686 9.91681Z" fill="#48B774" />
            //     </g>
            //     <defs>
            //         <clipPath id="clip0_120_1459">
            //             <rect width="24" height="24" fill="white" />
            //         </clipPath>
            //     </defs>
            // </svg>
                ;
        case icontypesEnum.EYE:
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z" /></svg>
                ;

        case icontypesEnum.DELETE:
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#e21e32" d="M7 3h2a1 1 0 0 0-2 0ZM6 3a2 2 0 1 1 4 0h4a.5.5 0 0 1 0 1h-.564l-1.205 8.838A2.5 2.5 0 0 1 9.754 15H6.246a2.5 2.5 0 0 1-2.477-2.162L2.564 4H2a.5.5 0 0 1 0-1h4Zm1 3.5a.5.5 0 0 0-1 0v5a.5.5 0 0 0 1 0v-5ZM9.5 6a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 1 0v-5a.5.5 0 0 0-.5-.5Z" /></svg>
                ;

        case icontypesEnum.EDIT:
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M5 23.7q-.825 0-1.413-.587T3 21.7v-14q0-.825.588-1.413T5 5.7h8.925l-2 2H5v14h14v-6.95l2-2v8.95q0 .825-.588 1.413T19 23.7H5Zm7-9Zm4.175-8.425l1.425 1.4l-6.6 6.6V15.7h1.4l6.625-6.625l1.425 1.4l-6.625 6.625q-.275.275-.638.438t-.762.162H10q-.425 0-.713-.288T9 16.7v-2.425q0-.4.15-.763t.425-.637l6.6-6.6Zm4.275 4.2l-4.275-4.2l2.5-2.5q.6-.6 1.438-.6t1.412.6l1.4 1.425q.575.575.575 1.4T22.925 8l-2.475 2.475Z" /></svg>
                ;
        case icontypesEnum.LOCATION:
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#9da4aa" d="M12 12q.825 0 1.413-.588T14 10q0-.825-.588-1.413T12 8q-.825 0-1.413.588T10 10q0 .825.588 1.413T12 12Zm0 7.35q3.05-2.8 4.525-5.088T18 10.2q0-2.725-1.738-4.462T12 4Q9.475 4 7.737 5.738T6 10.2q0 1.775 1.475 4.063T12 19.35ZM12 22q-4.025-3.425-6.012-6.362T4 10.2q0-3.75 2.413-5.975T12 2q3.175 0 5.588 2.225T20 10.2q0 2.5-1.988 5.438T12 22Zm0-11.8Z" /></svg>
                ;
        case icontypesEnum.PHONE:
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#9da4aa" d="M10.5 20h3q.2 0 .35-.15t.15-.35q0-.2-.15-.35T13.5 19h-3q-.2 0-.35.15t-.15.35q0 .2.15.35t.35.15ZM7 23q-.825 0-1.413-.588T5 21V3q0-.825.588-1.413T7 1h10q.825 0 1.413.588T19 3v18q0 .825-.588 1.413T17 23H7Zm0-7h10V6H7v10Z" /></svg>
                ;
        case icontypesEnum.MAIL:
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="#9da4aa" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="m7 9l5 3.5L17 9" /><path d="M2 17V7a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2Z" /></g></svg>
                ;
        case icontypesEnum.DELETETWO:
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" /></svg>
                ;
        case icontypesEnum.CALL:
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1c0 9.39 7.61 17 17 17c.55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1c-1.24 0-2.45-.2-3.57-.57a.84.84 0 0 0-.31-.05c-.26 0-.51.1-.71.29l-2.2 2.2a15.149 15.149 0 0 1-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02A11.36 11.36 0 0 1 8.5 4c0-.55-.45-1-1-1z" /></svg>
                ;












        default:
            return <svg></svg>;
    }
}